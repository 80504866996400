import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { CompanyApi } from 'services/apis/company/CompanyApi'
import { Company } from 'models/Company'
import {
  DeleteCompanyRequest,
  GetCompanyRequest,
  UpdateCompanyRequest
} from './CompaniesRequest'
import { DeletingState, LoadingState, UpdatingState } from '../CommonState'

interface CompaniesState extends UpdatingState, LoadingState, DeletingState {
  currentCompany: Company
}

const initialState: CompaniesState = {
  updating: false,
  loading: false,
  deleting: false,
  error: false,
  currentCompany: {
    id: '',
    name: '',
    logoPath: '',
    public: false,
    createdAt: '',
    updatedAt: ''
  }
}

export const getCompany = createAsyncThunk(
  'companies/get',
  async ({ companyId }: GetCompanyRequest) => {
    return await CompanyApi.getCompany(companyId)
  }
)

export const deleteCompany = createAsyncThunk(
  'companies/delete',
  async ({ companyId }: DeleteCompanyRequest) => {
    return await CompanyApi.deleteCompany(companyId)
  }
)

export const updateCompany = createAsyncThunk(
  'companies/update',
  async ({ companyId, data }: UpdateCompanyRequest) => {
    return await CompanyApi.updateCompany(companyId, data)
  }
)

export const companiesSlice = createSlice({
  name: 'companiesSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(deleteCompany.pending, (state) => {
      state.deleting = true
      state.error = false
    })
    builder.addCase(deleteCompany.fulfilled, (state) => {
      state.deleting = false
    })
    builder.addCase(deleteCompany.rejected, (state) => {
      state.deleting = false
      state.error = true
    })

    builder.addCase(getCompany.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.currentCompany = action.payload
      state.loading = false
    })
    builder.addCase(getCompany.rejected, (state) => {
      state.currentCompany = {
        id: '',
        name: '',
        logoPath: '',
        public: false,
        createdAt: '',
        updatedAt: ''
      }
      state.loading = false
      state.error = true
    })
    builder.addCase(updateCompany.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      state.updating = false
      state.currentCompany = { ...state.currentCompany, ...action.payload }
    })
    builder.addCase(updateCompany.rejected, (state) => {
      state.updating = false
      state.error = true
    })
  }
})

export default companiesSlice.reducer
