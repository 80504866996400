import { FormulaNutrient } from 'models/Formula'
import { NutrientRowProps } from './components/NutrientsTable/components/NutrientRow'
import { NutrientTableRowGroup } from './components/NutrientsTable/NutrientsTable'

const toTableNutrientsRow = (
  fn: FormulaNutrient,
  level = 0
): NutrientRowProps => {
  return {
    nutrient: {
      id: fn.nutrient.type,
      name: fn.nutrient.name,
      amount: fn.total,
      unit: fn.nutrient.unit,
      dvPercentage: fn.dvPercentage,
      ingredientsBreakdown: fn.ingredients.map((i) => {
        return {
          ingredient: {
            id: i.ingredientNutrient.ingredient.id,
            name: i.ingredientNutrient.ingredient.name
          },
          nutrient: {
            amount: i.ingredientNutrient.amount,
            unit: i.ingredientNutrient.nutrient.unit
          }
        }
      }),
      modifiers: fn.modifiers.map((m) => {
        return {
          name: m.name,
          amount: m.amount,
          unit: m.unit
        }
      })
    },
    level: level
  }
}

const toTableNutrientRows = (
  fn: FormulaNutrient,
  level = 0
): NutrientRowProps[] => {
  const children = fn.children.flatMap((c) => {
    return toTableNutrientRows(c, level + 1)
  })
  return [toTableNutrientsRow(fn, level), ...children]
}

export const toTableNutrientsRowGroup = (
  formulaNutrientGroups: FormulaNutrient[][]
): NutrientTableRowGroup[] => {
  return formulaNutrientGroups.map((fng) => ({
    nutrientRows: fng.flatMap((fn) => toTableNutrientRows(fn, 0))
  }))
}
