import React from 'react'
import { LabelOverrides } from './LabelOverrides'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setNutritionFactLabelEdits } from 'state/labels/nutritions/NutritionFactLabelsSlice'

export const LabelOverridesContainer: React.FC = () => {
  const dispatch = useAppDispatch()

  const nutritionFactLabelEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits
  )

  const hanldeServingSizeChange = (value: string) => {
    void dispatch(
      setNutritionFactLabelEdits({
        ...nutritionFactLabelEdits,
        nutritionFactLabelPreview: {
          ...nutritionFactLabelEdits.nutritionFactLabelPreview,
          servingWeightOverride: value
        }
      })
    )
  }

  return (
    <LabelOverrides
      servingSize={{
        value:
          nutritionFactLabelEdits.nutritionFactLabelPreview
            .servingWeightOverride,
        onChange: hanldeServingSizeChange
      }}
    />
  )
}
