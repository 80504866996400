import { Typography } from '@mui/material'
import { BoxRow, SwitchControlLabelStyled } from 'components/common'
import { EntrSwitch } from 'pages/Formulator/FormulatorStyles'
import React from 'react'

export interface EditorSwitchProps {
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
  labelIcon?: React.ReactNode
}

export const EditorSwitch: React.FC<EditorSwitchProps> = ({
  label,
  checked,
  onChange,
  disabled = false,
  labelIcon = null
}) => {
  return (
    <SwitchControlLabelStyled
      sx={{ padding: '4px' }}
      control={
        <EntrSwitch
          size={'small'}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        />
      }
      label={
        <BoxRow sx={{ gap: '4px' }}>
          <Typography>{label}</Typography>
          {labelIcon}
        </BoxRow>
      }
    />
  )
}
