import { BasicIngredient } from './Ingredient'
import { BasicSupplier } from './Supplier'

export enum AllergenDisplayNameLanguage {
  ENGLISH = 'english',
  FRENCH = 'french',
  SPANISH = 'spanish'
}

export interface AllergenDisplayName {
  [AllergenDisplayNameLanguage.ENGLISH]?: string
  [AllergenDisplayNameLanguage.FRENCH]?: string
  [AllergenDisplayNameLanguage.SPANISH]?: string
}

export interface AllergensPerLanguage {
  [AllergenDisplayNameLanguage.ENGLISH]?: string[]
  [AllergenDisplayNameLanguage.FRENCH]?: string[]
  [AllergenDisplayNameLanguage.SPANISH]?: string[]
}

export interface BasicAllergen {
  name: string
  type: string
  displayNames?: AllergenDisplayName
  description?: string
}

export interface SupplierAllergen {
  supplier: BasicSupplier
  allergen: BasicAllergen
}

export interface SimpleIngredientAllergen {
  ingredient: BasicIngredient
  allergen: BasicAllergen
}

export interface SuggestedAllergen {
  allergen: BasicAllergen
  ingredients: BasicIngredient[]
}
