import { Box, styled } from '@mui/material'
import React from 'react'
import { NutrientRowProps } from './components/NutrientRow'
import NutrientRowGroup from './components/NutrientRowGroup'

const Separator = styled(Box)`
  height: 8px;
  background: var(--Beige, #f6f5f4);
`

const getPlaceholderRowGroups = () => {
  return (
    <>
      <NutrientRowGroup nutrientRows={[]} header placeholderRows={5} />
      <Separator />
      <NutrientRowGroup nutrientRows={[]} placeholderRows={5} />
      <Separator />
      <NutrientRowGroup nutrientRows={[]} placeholderRows={5} />
      <Separator />
      <NutrientRowGroup nutrientRows={[]} placeholderRows={5} />
    </>
  )
}

export interface NutrientsTableProps {
  nutrientTableRowGroups?: NutrientTableRowGroup[]
}
export interface NutrientTableRowGroup {
  nutrientRows: NutrientRowProps[]
}

export const NutrientsTable: React.FC<NutrientsTableProps> = ({
  nutrientTableRowGroups = []
}) => {
  return (
    <>
      {nutrientTableRowGroups.length > 0 &&
        nutrientTableRowGroups.map((nrg, index) => (
          <React.Fragment key={`nrg-frag-${index}`}>
            <NutrientRowGroup
              key={`nrg-${index}`}
              nutrientRows={nrg.nutrientRows}
              header={index === 0}
            />
            {index < nutrientTableRowGroups.length - 1 && (
              <Separator key={`nrg-sep-${index}`} />
            )}
          </React.Fragment>
        ))}
      {nutrientTableRowGroups.length === 0 && getPlaceholderRowGroups()}
    </>
  )
}

export default NutrientsTable
