import Analytics, { PageParams } from '@segment/analytics-node'
import { v4 as uuidv4 } from 'uuid'

export class EntrAnalytics {
  private analytics?: Analytics
  private userId?: string
  private lastPageRecorded?: PageParams
  private sessionId?: string

  constructor(writeKey: string, enabled = true) {
    this.sessionId = uuidv4()
    if (enabled) {
      this.analytics = new Analytics({
        writeKey: writeKey
      })
    }
  }

  getUserId(): string | undefined {
    return this.userId
  }

  setUserId(userId: string): void {
    this.userId = userId
  }

  identify(name: string): void {
    if (this.analytics && this.userId) {
      this.analytics.identify({
        userId: this.userId,
        traits: {
          name: name
        }
      })
    }
  }

  private track(name: string, properties: object = {}): void {
    if (this.analytics && this.userId) {
      properties = {
        ...properties,
        session_id: this.sessionId
      }

      this.analytics.track({
        userId: this.userId,
        event: name,
        properties: properties,
        timestamp: new Date().toString()
      })
    }
  }

  private hasPageBeenRecorded(currentPage: PageParams): boolean {
    if (!this.lastPageRecorded) {
      return false
    }
    return (
      this.lastPageRecorded.name === currentPage.name &&
      JSON.stringify(this.lastPageRecorded.properties) ===
        JSON.stringify(currentPage.properties)
    )
  }

  private page(name: string, properties: object = {}): void {
    // TODO: Update logic once issue with unmount/mount of components is resolved.
    if (this.analytics && this.userId) {
      properties = {
        ...properties,
        session_id: this.sessionId
      }
      const newPage = {
        userId: this.userId,
        name: name,
        properties: properties,
        timestamp: new Date().toString()
      }
      if (!this.hasPageBeenRecorded(newPage)) {
        this.lastPageRecorded = newPage
        this.analytics.page(newPage)
      }
    }
  }

  // Formulas.
  viewedFormulasPage() {
    this.page('Formulas')
  }

  createdNewFormula() {
    this.track('Formula created')
  }

  // Formulator.
  viewedFormulatorPage(formulaId: string) {
    this.page('Formulator', { formula_id: formulaId })
  }

  duplicatedFormula(formulaId: string) {
    this.track('Formula duplicated', { formula_id: formulaId })
  }

  deletedFormula(formulaId: string) {
    this.track('Formula deleted', { formula_id: formulaId })
  }

  archivedFormula(formulaId: string, archived: boolean) {
    this.track('Formula archived/unarchived', {
      formula_id: formulaId,
      archived: archived
    })
  }

  modifiedNotes(formulaId: string) {
    this.track('Notes modified', { formula_id: formulaId })
  }

  uploadedDocument(formulaId: string) {
    this.track('Document uploaded', { formula_id: formulaId })
  }

  advancedSearchedIngredient(formulaId: string) {
    this.track('Ingredient advanced searched', { formula_id: formulaId })
  }

  searchedIngredient(formulaId: string) {
    this.track('Ingredient searched', { formula_id: formulaId })
  }

  updatedWastePercentage(formulaId: string) {
    this.track('Waste percentage updated', { formula_id: formulaId })
  }

  changedIngredientSupplier(formulaId: string) {
    this.track('Ingredient supplier changed', { formula_id: formulaId })
  }

  previewedDocument(formulaId: string) {
    this.track('Document previewed', { formula_id: formulaId })
  }

  deletedDocument(formulaId: string) {
    this.track('Document deleted', { formula_id: formulaId })
  }

  downloadedDocument(formulaId: string) {
    this.track('Document downloaded', { formula_id: formulaId })
  }

  modifiedFormulaName(formulaId: string) {
    this.track('Name modified', { formula_id: formulaId })
  }

  modifiedFormulaFriendlyId(formulaId: string) {
    this.track('Friendly ID modified', { formula_id: formulaId })
  }

  modifiedFormulaStatus(formulaId: string) {
    this.track('Status modified', { formula_id: formulaId })
  }

  modifiedFormulaServingWeight(formulaId: string) {
    this.track('Serving weight modified', { formula_id: formulaId })
  }

  // Formulator - Ingredients.
  addedIngredientToFormula(formulaId: string) {
    this.track('Ingredient added', { formula_id: formulaId })
  }

  removedIngredientFromFormula(formulaId: string) {
    this.track('Ingredient removed', { formula_id: formulaId })
  }

  modifiedIngredientPercentage(formulaId: string) {
    this.track('Ingredient percentage modified', { formula_id: formulaId })
  }

  modifiedIngredientWeight(formulaId: string) {
    this.track('Ingredient weight modified', { formula_id: formulaId })
  }

  modifiedIngredientMeasurement(formulaId: string) {
    this.track('Ingredient measurement modified', { formula_id: formulaId })
  }

  modifiedIngredientSupplier(formulaId: string) {
    this.track('Ingredient supplier modified', { formula_id: formulaId })
  }

  scaledIngredients(formulaId: string) {
    this.track('Ingredients scaled', { formula_id: formulaId })
  }

  // Formulator - Nutrients.
  viewedFormulatorNutrientsTab(formulaId: string) {
    this.page('Formulator', { formula_id: formulaId, tab: 'Nutrients' })
  }

  hoveredOverNutrient(formulaId: string) {
    this.track('Nutrient hovered', { formula_id: formulaId })
  }

  // Formulator - Label.
  viewedFormulatorLabelTab(formulaId: string) {
    this.page('Formulator', { formula_id: formulaId, tab: 'Label' })
  }

  modifiedIngredientStatement(formulaId: string) {
    this.track('Ingredient statement modified', { formula_id: formulaId })
  }

  // Formulator - Comments.
  viewedFormulatorCommentsTab(formulaId: string) {
    this.page('Formulator', { formula_id: formulaId, tab: 'Comments' })
  }

  addedComment(formulaId: string) {
    this.track('Comment added', { formula_id: formulaId })
  }

  // Formulator - Targets.
  viewedFormulatorTargetsTab(formulaId: string) {
    this.page('Formulator', { formula_id: formulaId, tab: 'Targets' })
  }

  updatedReferenceFood(formulaId: string) {
    this.track('Reference food updated', { formula_id: formulaId })
  }

  updatedRACC(formulaId: string) {
    this.track('RACC updated', { formula_id: formulaId })
  }

  updatedMealOrMainDish(formulaId: string) {
    this.track('Meal or main dish updated', { formula_id: formulaId })
  }

  selectedTarget(formulaId: string) {
    this.track('Target selected', { formula_id: formulaId })
  }

  deselectedTarget(formulaId: string) {
    this.track('Target deselected', { formula_id: formulaId })
  }

  // Formulator - History.
  viewedFormulatorHistoryTab(formulaId: string) {
    this.page('Formulator', { formula_id: formulaId, tab: 'History' })
  }

  // General.
  loggedOut() {
    this.track('Logged out')
  }
}
