import React from 'react'
import {
  DocumentThumbnailContainer,
  DocumentThumbnailTypography
} from './DocumentThumbnailStyles'

interface DocumentThumbnailProps {
  type: string
  size?: number
}

export const DocumentThumbnail: React.FC<DocumentThumbnailProps> = ({
  type,
  size = 40
}) => {
  return (
    <DocumentThumbnailContainer
      sx={{ width: `${size}px`, height: `${size}px` }}
    >
      <DocumentThumbnailTypography>
        {type.toUpperCase()}
      </DocumentThumbnailTypography>
    </DocumentThumbnailContainer>
  )
}
