import { TextField } from 'components/TextField/TextField'
import React from 'react'

interface LabelOverridesProps {
  servingSize: {
    value: string
    onChange: (value: string) => void
  }
}

export const LabelOverrides: React.FC<LabelOverridesProps> = ({
  servingSize
}) => {
  return (
    <TextField
      label={`Serving Size`}
      type="text"
      placeholder={'eg. 200mL'}
      value={servingSize.value}
      onChange={(value) => servingSize.onChange(value)}
    />
  )
}
