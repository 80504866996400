import { BasicCompany, Company } from 'models/Company'
import {
  BasicCompanyApiResponse,
  CompanyApiResponse
} from './CompanyApiResponse'

export const toBasicCompany = (
  response: BasicCompanyApiResponse
): BasicCompany => {
  return {
    ...response
  }
}

export const toCompany = (response: CompanyApiResponse): Company => {
  return {
    ...response
  }
}
