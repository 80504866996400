import { Typography } from '@mui/material'
import React from 'react'
import { BoxRow } from '../common'

interface NameCellProps {
  name: string
}

export const NameCell: React.FC<NameCellProps> = ({ name }) => {
  return (
    <BoxRow alignItems="center" title={name}>
      <Typography
        sx={{
          marginLeft: '8px',
          fontWeight: 'bold',
          fontSize: '15px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '100%'
        }}
      >
        {name}
      </Typography>
    </BoxRow>
  )
}
