import { Box, Slide } from '@mui/material'
import { SxProps } from '@mui/system'
import React from 'react'
import FilePreview, { FilePreviewProps } from './FilePreview'
import { FilePreviewWrapperInnerContainer } from './FilePreviewStyles'

interface FilePreviewState
  extends Omit<FilePreviewProps, 'hidePreviewCallback'> {}

interface FilePreviewContextInterface {
  showFilePreview: (fielPreview: FilePreviewState) => void
  closeFilePreview: () => void
  setFilePreviewLeftTargetRef: (targetRef: React.RefObject<HTMLElement>) => void
  clearFilePreviewLeftTargetRef: () => void
}

export const FilePreviewContext =
  React.createContext<FilePreviewContextInterface>({
    showFilePreview: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    closeFilePreview: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    setFilePreviewLeftTargetRef: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    clearFilePreviewLeftTargetRef: () => {} // eslint-disable-line @typescript-eslint/no-empty-function
  })

export const FilePreviewProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [filePreview, setFilePreview] = React.useState<
    FilePreviewState | undefined
  >()
  const [open, setOpen] = React.useState<boolean>(false)
  const [width, setWidth] = React.useState<number | null>(window.innerWidth)

  let leftTargetRef = React.useRef<HTMLElement | null>(null)

  React.useEffect(() => {
    const handleResize = () => {
      calculateNewWidth()
    }

    // Add window resize event listener
    window.addEventListener('resize', handleResize)

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const closeFilePreview = () => {
    setOpen(false)
    setTimeout(() => {
      setFilePreview(undefined)
    }, 100)
  }

  const clearFilePreviewLeftTargetRef = () => {
    leftTargetRef.current = null
    setWidth(window.innerWidth)
  }

  const showFilePreview = (filePreview: FilePreviewState) => {
    setFilePreview(filePreview)
    setOpen(true)
  }

  const calculateNewWidth = () => {
    let calculatedWidth = window.innerWidth // 100% of window.
    if (leftTargetRef && leftTargetRef.current) {
      const rect = leftTargetRef.current.getBoundingClientRect()
      calculatedWidth = window.innerWidth - rect.width - rect.y
    }
    setWidth(calculatedWidth)
  }

  const setFilePreviewLeftTargetRef = (ref: React.RefObject<HTMLElement>) => {
    leftTargetRef = ref
    calculateNewWidth()
  }

  return (
    <FilePreviewContext.Provider
      value={{
        showFilePreview,
        closeFilePreview,
        setFilePreviewLeftTargetRef,
        clearFilePreviewLeftTargetRef
      }}
    >
      <Box sx={{ position: 'absolute' }}>
        <Box
          sx={{
            width: '100vw',
            height: '100vh',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <Slide in={Boolean(open)} direction="left">
            <Box
              sx={{
                backgroundColor: 'white',
                height: '100vh',
                zIndex: 2,
                width: '100%',
                position: 'absolute'
              }}
            >
              {filePreview && (
                <FilePreviewWrapperInnerContainer sx={{ width: { width } }}>
                  <FilePreview
                    fileName={filePreview.fileName}
                    downloadFileCallback={filePreview.downloadFileCallback}
                    fileSource={filePreview.fileSource}
                    hidePreviewCallback={closeFilePreview}
                    downloadPreviewCallback={
                      filePreview.downloadPreviewCallback
                    }
                    fileType={filePreview.fileType}
                  />
                </FilePreviewWrapperInnerContainer>
              )}
            </Box>
          </Slide>
        </Box>
      </Box>

      {children}
    </FilePreviewContext.Provider>
  )
}
