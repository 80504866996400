import React from 'react'
import { BoxColumnBasic, ViewerContainer } from 'components/common'
import { Box } from '@mui/material'
import { Tabs } from 'components/Tabs/Tabs'
import { Tab } from 'components/Tabs/Tab/Tab'
import { TabPanel } from 'components/Tabs/TabPanel/TabPanel'
import { TargetRequirements } from './TargetRequirements'
import { TargetSettings } from './TargetSettings'
import { TargetViewerHeader } from './TargetViewerHeader'

interface TargetViewerProps {
  show: boolean
  setShow: (show: boolean) => void
}

export const TargetViewer: React.FC<TargetViewerProps> = ({
  show = false,
  setShow
}) => {
  const [activeTab, setActiveTab] = React.useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  return (
    <ViewerContainer
      data-show={show}
      onClick={(e) => e.stopPropagation()}
      style={{
        width: '70%',
        overflow: 'auto',
        padding: 0
      }}
    >
      <BoxColumnBasic height="100%" width="100%" minWidth="36.8vw">
        <TargetViewerHeader setShow={setShow} />
        <Tabs
          sx={{
            padding: '20px',
            alignItems: 'center',
            overflow: 'hidden',
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08)',
            flex: '0 0 auto',
            zIndex: '1',
            borderBottom: '2px solid rgba(0, 0, 0, 0.06)'
          }}
          value={activeTab}
          onChange={handleTabChange}
        >
          <Tab label="Requirements" />
          <Tab label="Settings" />
        </Tabs>
        <Box flex="1 1 auto" zIndex={1} overflow="hidden">
          <TabPanel value={activeTab} index={0}>
            <TargetRequirements />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <TargetSettings />
          </TabPanel>
        </Box>
      </BoxColumnBasic>
    </ViewerContainer>
  )
}
