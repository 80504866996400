import { Tabs } from '@mui/material'
import { SxProps, styled } from '@mui/material/styles'

interface StyledTabsProps {
  sx?: SxProps
}

export const StyledTabs = styled(
  Tabs,
  {}
)<StyledTabsProps>(() => ({
  backgroundColor: 'transparent',
  borderBottom: 'none',
  overflow: 'unset',
  '& .Mui-selected': {
    fontWeight: 'bold',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderBottom: 'none'
  },
  '& .MuiTabs-indicator': {
    display: 'none'
  }
}))
