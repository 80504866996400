import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DeletingState,
  DownloadingState,
  LoadingState,
  UploadingState
} from '../../CommonState'
import {
  DeleteSupplierDocumentRequest,
  DownloadSupplierDocumentRequest,
  GetSupplierDocumentsRequest,
  PreviewSupplierDocumentRequest,
  UploadSupplierDocumentRequest
} from './SupplierDocumentsRequest'
import { BasicDocument } from 'models/Document'
import { SupplierApi } from 'services/apis/supplier/SupplierApi'

export interface SupplierDocumentState
  extends LoadingState,
    DeletingState,
    UploadingState,
    DownloadingState {
  supplierDocuments: BasicDocument[]
  previewedDocument?: BasicDocument
}

const initialState: SupplierDocumentState = {
  supplierDocuments: [],
  previewedDocument: undefined,
  loading: false,
  deleting: false,
  uploading: false,
  downloading: false,
  error: false
}

export const uploadSupplierDocument = createAsyncThunk(
  'supplier/documents/upload',
  async ({
    companyId,
    supplierId,
    file,
    uploadProgressListener
  }: UploadSupplierDocumentRequest) => {
    return await SupplierApi.uploadSupplierDocument(
      companyId,
      supplierId,
      file,
      uploadProgressListener
    )
  }
)

export const previewSupplierDocument = createAsyncThunk(
  'supplier/documents/preview',
  async ({
    companyId,
    supplierId,
    file
  }: PreviewSupplierDocumentRequest): Promise<BasicDocument> => {
    const url = await SupplierApi.getSupplierDocument(
      companyId,
      supplierId,
      file.name,
      false
    )
    return {
      ...file,
      path: url
    }
  }
)

export const downloadSupplierDocument = createAsyncThunk(
  'supplier/documents/download',
  async ({
    companyId,
    supplierId,
    fileName
  }: DownloadSupplierDocumentRequest) => {
    return await SupplierApi.getSupplierDocument(
      companyId,
      supplierId,
      fileName
    )
  }
)

export const deleteSupplierDocument = createAsyncThunk(
  'supplier/documents/delete',
  async ({
    companyId,
    supplierId,
    fileName
  }: DeleteSupplierDocumentRequest) => {
    return await SupplierApi.deleteSupplierDocument(
      companyId,
      supplierId,
      fileName
    )
  }
)

export const getSupplierDocuments = createAsyncThunk(
  'supplier/documents/get',
  async ({ companyId, supplierId }: GetSupplierDocumentsRequest) => {
    return await SupplierApi.getSupplierDocuments(companyId, supplierId)
  }
)

const SupplierDocumentSlice = createSlice({
  name: 'supplierDocumentsSlice',
  initialState,
  reducers: {
    resetPreviewedDocument: (state) => {
      state.previewedDocument = undefined
    }
  },
  extraReducers: (builder) => {
    // upload supplier document.
    builder
      .addCase(
        uploadSupplierDocument.pending,
        (state: SupplierDocumentState) => {
          state.uploading = true
        }
      )
      .addCase(
        uploadSupplierDocument.fulfilled,
        (state: SupplierDocumentState) => {
          state.uploading = false
        }
      )
      .addCase(
        uploadSupplierDocument.rejected,
        (state: SupplierDocumentState) => {
          state.uploading = false
          state.error = true
        }
      )

    // download supplier document
    builder
      .addCase(
        downloadSupplierDocument.pending,
        (state: SupplierDocumentState) => {
          state.downloading = true
        }
      )
      .addCase(
        downloadSupplierDocument.fulfilled,
        (state: SupplierDocumentState) => {
          state.downloading = false
        }
      )
      .addCase(
        downloadSupplierDocument.rejected,
        (state: SupplierDocumentState) => {
          state.downloading = false
          state.error = true
        }
      )

    // preview supplier document
    builder
      .addCase(
        previewSupplierDocument.pending,
        (state: SupplierDocumentState) => {
          state.downloading = true
        }
      )
      .addCase(
        previewSupplierDocument.fulfilled,
        (
          state: SupplierDocumentState,
          action: PayloadAction<BasicDocument>
        ) => {
          state.downloading = false
          state.previewedDocument = action.payload
        }
      )
      .addCase(
        previewSupplierDocument.rejected,
        (state: SupplierDocumentState) => {
          state.downloading = false
          state.error = true
        }
      )

    // delete supplier document
    builder
      .addCase(
        deleteSupplierDocument.pending,
        (state: SupplierDocumentState) => {
          state.deleting = true
        }
      )
      .addCase(
        deleteSupplierDocument.fulfilled,
        (state: SupplierDocumentState, action: PayloadAction<string>) => {
          state.deleting = false
          state.supplierDocuments = state.supplierDocuments.filter(
            (document) => document.name !== action.payload
          )
        }
      )
      .addCase(
        deleteSupplierDocument.rejected,
        (state: SupplierDocumentState) => {
          state.deleting = false
          state.error = true
        }
      )

    // get simple ingredient documents
    builder
      .addCase(getSupplierDocuments.pending, (state: SupplierDocumentState) => {
        state.loading = true
      })
      .addCase(
        getSupplierDocuments.fulfilled,
        (
          state: SupplierDocumentState,
          action: PayloadAction<BasicDocument[]>
        ) => {
          state.loading = false
          state.supplierDocuments = action.payload.map((basicDocument) => {
            return {
              ...basicDocument
            }
          })
        }
      )
      .addCase(
        getSupplierDocuments.rejected,
        (state: SupplierDocumentState) => {
          state.loading = false
          state.error = true
        }
      )
  }
})

export const { resetPreviewedDocument } = SupplierDocumentSlice.actions
export default SupplierDocumentSlice.reducer
