import { Box } from '@mui/material'
import { styled } from '@mui/styles'

/*
  Styled components containing css directly from Figma
*/
export const StyledSideBar = styled(Box)({
  position: 'relative',
  top: '0px',
  left: '0px',
  maxwidth: '15vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around'
})
export const MenuContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  margin: '15px 0px',
  borderRight: `1px solid #E5E5E5`,
  padding: '10px 10px',
  top: '24px',
  bottom: '105px'
})
