import { TableCell, TableRow, Typography } from '@mui/material'
import { convertToFixedFloat } from 'common/utils'
import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { InfoBubble } from 'components/InfoBubble/InfoBubble'
import { BoxRow } from 'components/common'
import { IngredientViewerPanelInput } from '../../../IngredientViewStyles'

export interface NutrientRowProps {
  nutrient: {
    id: string
    name: string
    amount: number
    unit: string
  }
  disabled: boolean
  calculated: boolean
  onAmountChange: (nutrientId: string, amount: number) => void
  level?: number
}

export const NutrientRow: React.FC<NutrientRowProps> = ({
  nutrient,
  onAmountChange,
  disabled = false,
  level = 0,
  calculated = false
}) => {
  const [value, setValue] = React.useState<string>('')
  const MAX_DECIMAL = 3
  const DEBOUNCE_TIME = 1000

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = convertToFixedFloat(e.target.value || '0', MAX_DECIMAL)
    setValue(inputValue)
    handleAmountChangeDebounced(inputValue)
  }

  const handleAmountChangeDebounced = useDebouncedCallback(
    (inputValue: string) => {
      if (onAmountChange) {
        onAmountChange(nutrient.id, parseFloat(inputValue))
      }
    },
    DEBOUNCE_TIME
  )

  React.useEffect(() => {
    if (nutrient.amount !== undefined) {
      setValue(convertToFixedFloat(nutrient.amount, MAX_DECIMAL, true))
    }
  }, [nutrient.amount])

  return (
    <TableRow key={nutrient.id + '-nutrient-row'}>
      <TableCell align="left">
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '16px',
            marginLeft: level > 0 ? `${level * 12}px` : '0px'
          }}
        >
          {nutrient.name}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <BoxRow justifyContent={'flex-end'} gap={'5px'}>
          {calculated && (
            <InfoBubble
              text="This value is derived from another nutrient and cannot be edited."
              width="300px"
            />
          )}
          <IngredientViewerPanelInput
            data-value={value}
            type="number"
            placeholder="0"
            inputProps={{ min: 0 }}
            value={value === '0' ? '' : value}
            disabled={disabled}
            onChange={handleAmountChange}
            endAdornment={<Typography>{nutrient.unit}</Typography>}
          />
        </BoxRow>
      </TableCell>
    </TableRow>
  )
}

export default NutrientRow
