import { Popover, Typography } from '@mui/material'
import { ReactComponent as OpenArrowIcon } from 'assets/OpenArrow.svg'
import React from 'react'
import {
  IngredientSupplierPopper,
  SupplierProps
} from '../../common/IngredientSupplierPopper'
import { StyledSupplierSelectButton } from './IngredientSupplierDropdownStyle'

export interface IngredientSupplierDropdownProps {
  currentSupplierName: string
  getIngredientSuppliers: () => Promise<SupplierProps[]>
  onSelect: (supplierId: string) => void
  noSuppliers?: boolean
}

export const IngredientSupplierDropdown: React.FC<
  IngredientSupplierDropdownProps
> = ({
  currentSupplierName,
  getIngredientSuppliers,
  onSelect,
  noSuppliers
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [suppliers, setSuppliers] = React.useState<SupplierProps[]>([])

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!noSuppliers) {
      void getIngredientSuppliers().then((s) => setSuppliers(s))
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (supplierId: string) => {
    onSelect(supplierId)
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'supplier-select-id' : undefined

  return (
    <>
      <StyledSupplierSelectButton
        aria-describedby={id}
        onClick={handleOpen}
        sx={{
          width: '100%',
          height: '32px',
          justifyContent: 'space-between',
          overflow: 'hidden',
          cursor: noSuppliers ? 'default' : 'pointer'
        }}
        color="secondary"
      >
        <Typography
          variant="subtitle1"
          color="secondary"
          fontWeight={400}
          paddingLeft="1em"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {currentSupplierName}
        </Typography>
        {!noSuppliers && <OpenArrowIcon />}
      </StyledSupplierSelectButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        sx={{
          borderRadius: '8px'
        }}
      >
        <IngredientSupplierPopper
          ingredient={{}}
          suppliers={suppliers}
          onClick={handleSelect}
        />
      </Popover>
    </>
  )
}
