import { SearchFiltersProvider } from 'components/EntrTable/Filter/SearchFiltersContext'
import React from 'react'
import { AdvancedIngredientSearch } from './AdvancedIngredientSearch'
import { AdvancedIngredientsSearchTableContainer } from './AdvancedIngredientSearchTable/AdvancedIngredientsSearchTableContainer'
import { AdvancedIngredientsSearchFiltersContainer } from './AdvancedIngredientsSearchFilters/AdvancedIngredientsSearchFiltersContainer'

interface AdvancedIngredientSearchContainerProps {
  initialSearchTerm?: string
  onIngredientClick: (ingredientId: string, supplierId?: string) => void
}

export const AdvancedIngredientSearchContainer: React.FC<
  AdvancedIngredientSearchContainerProps
> = ({ initialSearchTerm, onIngredientClick }) => {
  return (
    <SearchFiltersProvider>
      <AdvancedIngredientSearch
        filterAndSearch={
          <AdvancedIngredientsSearchFiltersContainer
            initialSearchTerm={initialSearchTerm}
          />
        }
        table={
          <AdvancedIngredientsSearchTableContainer
            onIngredientClick={onIngredientClick}
          />
        }
      />
    </SearchFiltersProvider>
  )
}
