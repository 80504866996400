import { Typography, useTheme } from '@mui/material'
import { convertToFixedFloat } from 'common/utils'
import { BoxRow } from 'components/common'
import { UnstyledInput } from 'pages/Formulator/FormulatorStyles'
import React, { KeyboardEvent } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export interface YieldQuantityEditorProps {
  amount: {
    value: number
    onUpdate?: (amount: number) => void
    max?: number
  }
  percentage: {
    value: number
    onUpdate?: (percentage: number) => void
  }
}

export const blockInvalidChar = (e: KeyboardEvent) =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

export const YieldQuantityEditor: React.FC<YieldQuantityEditorProps> = ({
  amount,
  percentage
}) => {
  const theme = useTheme()
  const [textPercentage, setTextPercentage] = React.useState<string>(
    convertToFixedFloat(percentage.value * 100, 2)
  )
  const [textAmount, setTextAmount] = React.useState<string>(
    convertToFixedFloat(amount.value, 2)
  )

  React.useEffect(() => {
    if (textPercentage !== '') {
      setTextPercentage(convertToFixedFloat(percentage.value * 100, 2))
    }
    if (textAmount !== '') {
      setTextAmount(convertToFixedFloat(amount.value, 2))
    }
  }, [amount, percentage])

  const handleAmountChange = (value: string) => {
    // Remove leading zeros.
    let newValue = value
    if (value && value[0] !== '.' && value[value.length - 1] !== '0') {
      newValue = parseFloat(value).toString()
    }

    // Limit the number to be between 0 and 100.
    if (amount.max && parseFloat(newValue) > amount.max) {
      newValue = (amount.max || 0).toString()
    } else if (parseFloat(newValue) < 0) {
      newValue = '0'
    }

    // Limit to 7 digits.
    if (newValue.length > 7) {
      newValue = newValue.slice(0, 7)
    }

    if (newValue != textAmount) {
      setTextAmount(newValue)
      handleAmountChangeDebounced(newValue)
    }
  }

  const handleAmountChangeDebounced = useDebouncedCallback((value: string) => {
    if (amount.onUpdate) {
      amount.onUpdate(parseFloat(value) || 0)
    }
  }, 500)

  const handlePercentageChange = (value: string) => {
    // Remove leading zeros.
    let newValue = value
    if (value && value[0] !== '.' && value[value.length - 1] !== '0') {
      newValue = parseFloat(value).toString()
    }

    // Limit the number to be between 0 and 100.
    if (parseFloat(newValue) > 100) {
      newValue = '100'
    } else if (parseFloat(newValue) < 0) {
      newValue = '0'
    }

    // Limit to 7 digits.
    if (newValue.length > 7) {
      newValue = newValue.slice(0, 7)
    }

    if (newValue != textAmount) {
      setTextPercentage(newValue)
      handlePercentageChangeDebounced(newValue)
    }
  }

  const handlePercentageChangeDebounced = useDebouncedCallback(
    (value: string) => {
      if (percentage.onUpdate) {
        percentage.onUpdate(parseFloat(value) / 100 || 0)
      }
    },
    500
  )

  const handleAmountOnBlur = (value: string) => {
    if (value === '' || value[0] === '.') {
      setTextAmount(convertToFixedFloat(amount.value, 2))
    }
  }

  const handlePercentageOnBlur = (value: string) => {
    if (value === '' || value[0] === '.') {
      setTextPercentage(convertToFixedFloat(percentage.value * 100, 2))
    }
  }

  return (
    <BoxRow>
      <UnstyledInput
        value={textPercentage}
        sx={{
          borderRadius: '8px 0px 0px 8px',
          flex: 2
        }}
        endAdornment={
          <Typography marginLeft={'0.25em'} color={theme.palette.tertiary.main}>
            %
          </Typography>
        }
        inputProps={{ style: { textAlign: 'right' }, step: 0.00001 }}
        onChange={(event) => {
          handlePercentageChange(event.target.value)
        }}
        onBlur={(event) => {
          handlePercentageOnBlur(event.target.value)
        }}
        onKeyDown={(e) => blockInvalidChar(e)}
        type="number"
      />
      <UnstyledInput
        value={textAmount}
        onChange={(event) => {
          handleAmountChange(event.target.value)
        }}
        inputProps={{
          style: { textAlign: 'right' },
          step: 0.00001
        }}
        onBlur={(event) => {
          handleAmountOnBlur(event.target.value)
        }}
        type="number"
        sx={{
          borderLeft: '2px solid rgba(0, 0, 0, 0.06)',
          borderRadius: '0px 8px 8px 0px',
          flex: 2
        }}
        onKeyDown={(e) => blockInvalidChar(e)}
        endAdornment={
          <Typography marginLeft={'0.25em'} color={theme.palette.tertiary.main}>
            g
          </Typography>
        }
      />
    </BoxRow>
  )
}
