import React from 'react'
import {
  YieldAdjustment,
  YieldAdjustmentProps
} from './components/YieldAdjustment/YieldAdjustment'
import { BoxColumnBasic } from 'components/common'
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import { FormulaTableHeaderCell } from 'pages/Formulator/FormulatorStyles'

interface YieldAdjustmentsProps {
  adjustments: YieldAdjustmentProps[]
  loading?: boolean
}

const getPlaceholderRows = (rows: number) => {
  const cellCount = 4
  return (
    <>
      {Array.from({ length: rows }).map((_, rowIdx) => (
        <TableRow key={rowIdx}>
          {Array.from({ length: cellCount }).map((_, cellIdx) => (
            <TableCell key={cellIdx}>
              <Skeleton
                variant="rectangular"
                height={'32px'}
                sx={{ marginTop: '3px', marginBottom: '3px' }}
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

export const YieldAdjustments: React.FC<YieldAdjustmentsProps> = ({
  adjustments,
  loading = false
}) => {
  return adjustments.length > 0 ? (
    <BoxColumnBasic>
      <Table size="small" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <FormulaTableHeaderCell key="adjustment" sx={{ width: '45%' }}>
              <div data-sortable>Yield Adjustments</div>
            </FormulaTableHeaderCell>
            <FormulaTableHeaderCell
              key="adjustment_type"
              sx={{ width: '21%' }}
            />
            <FormulaTableHeaderCell
              key="adjustment_quantity"
              sx={{ width: '29%' }}
            />
            <FormulaTableHeaderCell
              key="adjustment_remove"
              sx={{ width: '5%' }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? getPlaceholderRows(2)
            : adjustments.map((a) => (
                <YieldAdjustment key={`ya-${a.adjustment.id}`} {...a} />
              ))}
        </TableBody>
      </Table>
    </BoxColumnBasic>
  ) : (
    <></>
  )
}
