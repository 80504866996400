import React from 'react'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import { SettingSubPageLayout } from './components/SettingSubPageLayout'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { deleteUser, getUser, updateUser } from 'state/users/UsersSlice'
import { Can } from 'core/Permissions/AbilityContext'
import { Actions, Subjects } from 'core/Permissions/AbilityConstants'
import { ModalContext } from 'components/Modal/ModalContext'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { ProfileSkeleton } from './components/ProfileSkeleton'
import { InputFieldLabel } from 'components/InputFieldLabel/InputFieldLabel'
import { Button } from 'components/Button/Button'

const Profile: React.FC = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.users.currentUser)
  const loading = useAppSelector((state) => state.users.loading)
  const updating = useAppSelector((state) => state.users.updating)
  const error = useAppSelector((state) => state.users.error)
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [imagePath, setImagePath] = React.useState('')
  const [initFirstName, setInitFirstName] = React.useState<string>()
  const [initLastName, setInitLastName] = React.useState<string>()
  const [initImagePath, setInitImagePath] = React.useState<string>()
  const [hasChanges, setHasChanges] = React.useState(false)
  const { showConfirmationModal } = React.useContext(ModalContext)
  const { showError, showSuccess } = React.useContext(SnackbarContext)

  const handleSaveChanges = () => {
    void dispatch(updateUser({ firstName, lastName, imagePath }))
  }

  const handleDeleteUser = () => {
    showConfirmationModal({
      title: 'Delete Account',
      message: 'Are you sure you want to permanently delete your account?',
      danger: true,
      yesText: 'Delete',
      noText: 'Cancel',
      onYesClicked: () => void dispatch(deleteUser())
    })
  }

  const handlePasswordChange = () => {
    alert('change password')
  }

  React.useEffect(() => {
    if (user.id !== '') {
      setInitFirstName(user.firstName)
      setInitLastName(user.lastName)
      setInitImagePath(user.imagePath)
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setImagePath(user.imagePath)
    }
  }, [user])

  React.useEffect(() => {
    if (initFirstName && initLastName && initImagePath) {
      const arr = [
        firstName === initFirstName,
        lastName === initLastName,
        imagePath === initImagePath
      ]
      setHasChanges(!arr.reduce((prev, cur) => (!prev ? prev : cur), true))
    }
  }, [
    firstName,
    lastName,
    imagePath,
    initFirstName,
    initLastName,
    initImagePath
  ])

  React.useEffect(() => {
    if (!updating && hasChanges && !error) {
      setInitFirstName(firstName)
      setInitLastName(lastName)
      setInitImagePath(imagePath)
      showSuccess('Profile has been updated.')
    }
  }, [updating])

  React.useEffect(() => {
    if (error && hasChanges) {
      if (initFirstName) setFirstName(initFirstName)
      if (initLastName) setLastName(initLastName)
      if (initImagePath) setImagePath(initImagePath)
      showError('There was a problem updating your profile.')
      void dispatch(getUser())
    }
  }, [error])

  return (
    <>
      {loading && <ProfileSkeleton />}

      {!loading && (
        <SettingSubPageLayout title="Profile">
          <Grid container spacing={2} direction="column" width={'20vw'}>
            <Grid item container spacing={2} alignItems="center">
              <Grid item>
                <Avatar
                  src={initImagePath}
                  sx={{ width: '55px', height: '55px' }}
                />
              </Grid>

              <Can I={Actions.UPDATE} a={Subjects.CURRENT_USER}>
                <Grid item>
                  <Button color="secondary" size="small" disabled={updating}>
                    Change Profile Photo
                  </Button>
                </Grid>
              </Can>
            </Grid>

            <Can I={Actions.UPDATE} a={Subjects.CURRENT_USER} passThrough>
              {(can) => (
                <>
                  <Grid item sx={{ marginTop: '2px' }}>
                    <InputFieldLabel
                      label="First Name"
                      initialValue={initFirstName}
                      disabled={!can || updating}
                      onChange={(value: string) => setFirstName(value)}
                    />
                  </Grid>

                  <Grid item sx={{ marginTop: '2px' }}>
                    <InputFieldLabel
                      label="Last Name"
                      initialValue={initLastName}
                      disabled={!can || updating}
                      onChange={(value: string) => setLastName(value)}
                    />
                  </Grid>
                </>
              )}
            </Can>

            <Grid item sx={{ marginTop: '2px' }}>
              <InputFieldLabel
                label="Email"
                initialValue={user?.email}
                disabled={true}
              />
            </Grid>

            <Can I={Actions.UPDATE} a={Subjects.CURRENT_USER}>
              {/* <Grid item sx={{ marginTop: '15px' }}>
                <Button
                  color="secondary"
                  disabled={updating}
                  onClick={handlePasswordChange}
                  fullWidth
                >
                  Change Password
                </Button>
              </Grid> */}

              <Grid item sx={{ marginTop: '15px' }}>
                <Button
                  color="secondary"
                  disabled={updating || !hasChanges}
                  onClick={handleSaveChanges}
                  fullWidth
                >
                  Save Changes
                </Button>
              </Grid>
            </Can>

            <Can I={Actions.DELETE} a={Subjects.CURRENT_USER}>
              <Grid item sx={{ marginTop: '15px' }}>
                <Button
                  color="danger"
                  disabled={updating}
                  onClick={handleDeleteUser}
                  fullWidth
                >
                  Delete Account
                </Button>
              </Grid>
            </Can>
          </Grid>
        </SettingSubPageLayout>
      )}
    </>
  )
}

export default Profile
