import { BasicDocument } from 'models/Document'
import { DocumentApiResponse } from './DocumentApiResponse'

const toBasicDocument = (response: DocumentApiResponse): BasicDocument => {
  return {
    ...response,
    lastModified: response.lastModified
  }
}

export const toBasicDocuments = (
  response: DocumentApiResponse[]
): BasicDocument[] => {
  return response.map((document) => {
    return toBasicDocument(document)
  })
}
