import { Box, Typography } from '@mui/material'
import styled from 'styled-components'

export const DocumentThumbnailContainer = styled(Box)({
  flexShrink: 0,
  borderRadius: '8px',
  border: '1px solid var(--Highlight, rgba(0, 0, 0, 0.06))',
  background: '#FFF',
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.08)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

export const DocumentThumbnailTypography = styled(Typography)({
  color: 'var(--Tertiary, rgba(35, 60, 88, 0.43))',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal'
})
