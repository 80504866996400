import { BasicUserApiResponse } from '../user/UserApiResponse'
import { BasicFormulaApiResponse } from './FormulaApiResponse'
import { BaseApiPaginatedResponse } from '../config/BaseApiPaginatedResponse'

export enum FormulaHistoryModificationApiField {
  NAME = 'name',
  STATUS = 'status',
  IMAGE_PATH = 'image_path',
  NOTE = 'note',
  INGREDIENT_STATEMENT = 'ingredient_statement',
  AMOUNT = 'amount',
  WASTE_PERCENTAGE = 'waste_percentage',
  SERVING_WEIGHT = 'serving_weight',
  CONTAINER_WEIGHT = 'container_weight',
  MEASUREMENT = 'measurement',
  RACC = 'racc',
  RACC_UNIT = 'racc_unit',
  IS_MEAL_OR_MAIN_DISH = 'is_meal_or_main_dish',
  REFERENCE_FORMULA = 'reference_formula'
}

export interface FormulaHistoryModificationApiResponse {
  field: FormulaHistoryModificationApiField
  oldValue: string
  newValue: string
}

export enum FormulaHistoryActivityApiSubject {
  FORMULA = 'formula',
  INGREDIENT = 'ingredient',
  INGREDIENTS = 'ingredients',
  ALLERGEN = 'allergen',
  ALLERGENS = 'allergens'
}

export enum FormulaHistoryActivityApiAction {
  CREATED = 'created',
  DUPLICATED = 'duplicated',
  ADDED = 'added',
  REMOVED = 'removed',
  UPDATED = 'updated'
}

export interface FormulaHistoryActivityApiResponse {
  revisionId: string
  user: BasicUserApiResponse
  subject: FormulaHistoryActivityApiSubject
  action: FormulaHistoryActivityApiAction
  createdAt: string
  name?: string
  names?: string[]
  modifications?: FormulaHistoryModificationApiResponse[]
}

export type FormulaHistoryActivityApiPaginatedResponse =
  BaseApiPaginatedResponse<FormulaHistoryActivityApiResponse>

export interface FormulaHistoryApiResponse {
  formula: BasicFormulaApiResponse
  activity: FormulaHistoryActivityApiPaginatedResponse
}
