import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { MenuButtonStyled } from './MenuButtonViewStyles'

interface MenuButtonProps {
  menuItems: {
    itemTitle: string
    itemStyle?: React.CSSProperties
    itemCallback: () => void
    itemIcon?: React.ReactNode
  }[]
  menuStyle?: React.CSSProperties
  buttonStyle?: React.CSSProperties
  buttonVariant?: 'text' | 'outlined' | 'contained'
  showButton?: boolean
}

export const MenuButton: React.FC<MenuButtonProps> = ({
  menuItems,
  menuStyle,
  buttonStyle,
  buttonVariant,
  showButton = true
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <>
      <MenuButtonStyled
        aria-controls="menu"
        aria-haspopup="true"
        color="secondary"
        variant={buttonVariant ? buttonVariant : 'contained'}
        onClick={handleClick}
        style={{
          ...buttonStyle,
          visibility: showButton ? 'visible' : 'hidden'
        }}
      >
        ···
      </MenuButtonStyled>

      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={menuStyle}
      >
        {menuItems.map((menuItem, index) => (
          <MenuItem
            key={index}
            onClick={(e) => {
              menuItem.itemCallback()
              handleClose(e)
            }}
            style={menuItem.itemStyle}
          >
            {menuItem.itemIcon}
            {menuItem.itemTitle}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
