import React from 'react'
import { NutrientsOverridesContainer } from './components/Nutrients/NutrientsOverridesContainer'
import { Overrides } from './Overrides'
import { LabelOverridesContainer } from './components/Label/LabelOverridesContainer'

export const OverridesContainer: React.FC = () => {
  return (
    <Overrides
      label={<LabelOverridesContainer />}
      nutrients={<NutrientsOverridesContainer />}
    />
  )
}
