import styled, { css } from 'styled-components'
import React from 'react'
import { POINT_TO_PIXEL_RATIO } from './NutritionLabelStyles'

export type SeparatorType =
  | 'normal'
  | 'thick'
  | 'hairline'
  | 'normal-canadian'
  | 'thick-canadian'
  | 'hairline-canadian'
export type SeparatorOrientation = 'vertical' | 'horizontal'

export interface SeparatorProps {
  type?: SeparatorType
  short?: boolean
  orientation?: SeparatorOrientation
}

const SeparatorStyles = styled.div<SeparatorProps>`
  ${(props) =>
    props.short &&
    css`
      margin-left: ${20 * POINT_TO_PIXEL_RATIO}px;
    `}

  ${(props) => {
    if (props.orientation === 'horizontal') {
      switch (props.type) {
        case 'hairline':
          return css`
            border-bottom: ${Math.ceil(0.25 * POINT_TO_PIXEL_RATIO)}px solid
              black;
          `
        case 'hairline-canadian':
          return css`
            border-bottom: ${0.25 * POINT_TO_PIXEL_RATIO}px solid black;
          `
        case 'thick':
          return css`
            border-bottom: ${Math.ceil(7 * POINT_TO_PIXEL_RATIO)}px solid black;
          `
        case 'normal-canadian':
          return css`
            border-bottom: ${Math.ceil(1 * POINT_TO_PIXEL_RATIO)}px solid black;
          `
        case 'thick-canadian':
          return css`
            border-bottom: ${Math.ceil(2.5 * POINT_TO_PIXEL_RATIO)}px solid
              black;
          `
        case 'normal':
        default:
          return css`
            border-bottom: ${Math.ceil(3 * POINT_TO_PIXEL_RATIO)}px solid black;
          `
      }
    } else {
      switch (props.type) {
        case 'hairline':
          return css`
            border-right: ${0.25 * POINT_TO_PIXEL_RATIO}px solid black;
            padding-left: ${2 * POINT_TO_PIXEL_RATIO}px;
            margin-right: ${2 * POINT_TO_PIXEL_RATIO}px;
          `
        case 'thick':
          return css`
            border-right: ${7 * POINT_TO_PIXEL_RATIO}px solid black;
            padding-left: ${2 * POINT_TO_PIXEL_RATIO}px;
            margin-right: ${2 * POINT_TO_PIXEL_RATIO}px;
          `
        case 'normal-canadian':
          return css`
            border-right: ${1 * POINT_TO_PIXEL_RATIO}px solid black;
            padding-left: ${2 * POINT_TO_PIXEL_RATIO}px;
            margin-right: ${2 * POINT_TO_PIXEL_RATIO}px;
          `
        case 'thick-canadian':
          return css`
            border-right: ${2.5 * POINT_TO_PIXEL_RATIO}px solid black;
            padding-left: ${2 * POINT_TO_PIXEL_RATIO}px;
            margin-right: ${2 * POINT_TO_PIXEL_RATIO}px;
          `
        case 'normal':
        default:
          return css`
            border-right: ${3 * POINT_TO_PIXEL_RATIO}px solid black;
            padding-left: ${2 * POINT_TO_PIXEL_RATIO}px;
            margin-right: ${2 * POINT_TO_PIXEL_RATIO}px;
          `
      }
    }
  }}
`

export const NutritionLabelSeparator: React.FC<SeparatorProps> = ({
  type = 'normal',
  short = false,
  orientation = 'horizontal'
}) => {
  return <SeparatorStyles type={type} short={short} orientation={orientation} />
}
