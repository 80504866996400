import React from 'react'
import { TabContent } from '../TabContent'
import { TargetsHeader, TargetsHeaderProps } from './components/TargetsHeader'
import { Box, Skeleton, Typography } from '@mui/material'
import { BoxColumnBasic, BoxRow } from 'components/common'

interface TargetsProps {
  targetsResults: React.ReactNode
  targetsSettings: React.ReactNode
  targetsHeaderProps: TargetsHeaderProps
  loading?: boolean
}

const TargetSkeleton: React.FC = () => {
  return (
    <BoxRow padding="4px 24px 4px 0px" alignItems="center" gap="12px">
      <Skeleton variant="circular" height={30} width={30} />
      <BoxColumnBasic gap="2px">
        <Skeleton variant="rectangular" width={80} height={14} />
        <Skeleton variant="rectangular" width={60} height={14} />
      </BoxColumnBasic>
    </BoxRow>
  )
}

const TargetsSkeleton: React.FC = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" width={'30%'} height={20} />
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap={2}
        alignItems={'center'}
      >
        <Box gridColumn="span 4">
          <TargetSkeleton />
          <TargetSkeleton />
          <TargetSkeleton />
        </Box>
        <Box gridColumn="span 4">
          <TargetSkeleton />
          <TargetSkeleton />
          <TargetSkeleton />
        </Box>
        <Box gridColumn="span 4">
          <TargetSkeleton />
          <TargetSkeleton />
          <TargetSkeleton />
        </Box>
      </Box>
    </Box>
  )
}

const LoadingSkeleton: React.FC = () => {
  return (
    <Box position={'relative'}>
      <Box
        sx={{
          zIndex: 2
        }}
        alignItems={'center'}
        position={'absolute'}
        top={'50%'}
        left={'25%'}
        right={'25%'}
      >
        <Typography textAlign={'center'}>
          Formula is being analyzed
          <br />
          This may take a few seconds
        </Typography>
      </Box>
      <BoxColumnBasic gap={'30px'}>
        <TargetsSkeleton />
        <TargetsSkeleton />
        <TargetsSkeleton />
      </BoxColumnBasic>
    </Box>
  )
}

export const Targets: React.FC<TargetsProps> = ({
  targetsResults,
  targetsSettings,
  targetsHeaderProps,
  loading = false
}) => {
  return (
    <TabContent>
      {loading && <LoadingSkeleton />}
      {!loading && (
        <BoxColumnBasic sx={{ gap: '30px', padding: '10px' }}>
          <Box>
            <TargetsHeader {...targetsHeaderProps} />
          </Box>
          {!targetsHeaderProps.editMode && <Box>{targetsResults}</Box>}
          {targetsHeaderProps.editMode && <Box>{targetsSettings}</Box>}
        </BoxColumnBasic>
      )}
    </TabContent>
  )
}
