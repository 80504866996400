import React from 'react'
import {
  useAutocomplete,
  Input,
  List,
  ListItem,
  ListItemText,
  Box
} from '@mui/material'
import { useDebouncedCallback } from 'use-debounce'

interface SimpleAutoCompleteProps {
  options: string[]
  initialValue?: string
  onChange?: (value: string) => void
  debounceTime?: number
  inputStyle?: React.CSSProperties
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  textFormatter?: (text: string) => string
  textDeformatter?: (text: string) => string
  disabled?: boolean
  showFullTextTooltip?: boolean
}

export const SimpleAutoComplete: React.FC<SimpleAutoCompleteProps> = ({
  options,
  initialValue,
  onChange,
  debounceTime = 0,
  inputStyle,
  onBlur,
  textFormatter,
  textDeformatter,
  disabled = false,
  showFullTextTooltip = false
}) => {
  const [value, setValue] = React.useState<string>('')

  React.useEffect(() => {
    if (initialValue && initialValue !== value) {
      setValue(initialValue)
    }
  }, [initialValue])

  const formatText = (text: string) => {
    return textFormatter ? textFormatter(text) : text
  }

  const handleInputChangeDebounced = useDebouncedCallback(
    (inputValue: string) => {
      setValue(inputValue)
      if (onChange) {
        onChange(inputValue)
      }
    },
    debounceTime
  )

  const localOnBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      e.target.value = textDeformatter
        ? textDeformatter(e.target.value)
        : e.target.value
      onBlur(e)
    }
  }

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions
  } = useAutocomplete({
    id: Math.random().toString(),
    value,
    options,
    onInputChange: (event, newValue) => {
      if (onChange) {
        handleInputChangeDebounced(newValue)
      }
    },
    isOptionEqualToValue: (option, value) => {
      return option === value || value === '' || formatText(option) === value
    },
    getOptionLabel: (option) => formatText(option),
    disabled: disabled
  })

  return (
    <Box>
      <Box {...getRootProps()}>
        <Input
          value={value}
          inputProps={getInputProps()}
          style={{
            height: '32px',
            padding: '0 25px 0 8px',
            ...inputStyle
          }}
          sx={{
            '& .MuiInputBase-input': {
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }
          }}
          onBlur={localOnBlurHandler}
          disabled={disabled}
          title={showFullTextTooltip ? value : undefined}
        />
      </Box>
      {groupedOptions.length > 0 ? (
        <List
          {...getListboxProps()}
          style={{
            width: 'fit-content',
            position: 'absolute',
            zIndex: 1,
            backgroundColor: '#FFF',
            borderRadius: '4px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '0px',
            margin: '0px',
            maxHeight: '200px',
            overflowY: 'scroll'
          }}
        >
          {(groupedOptions as typeof options).map((option, index) => (
            <ListItem {...getOptionProps({ option, index })} key={index}>
              <ListItemText primary={formatText(option)} />
            </ListItem>
          ))}
        </List>
      ) : null}
    </Box>
  )
}
