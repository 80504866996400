import { Input, InputProps } from '@mui/material'
import { useDebouncedCallback } from 'use-debounce'

import React from 'react'
import { LabelTypography } from '../common'

interface InputFieldLabelProps extends Omit<InputProps, 'onChange' | 'value'> {
  label: string
  labelIcon?: React.ReactNode
  onChange?: (value: string) => void
  initialValue?: string
  debounceTime?: number
  scale?: 'medium' | 'large'
}

export const InputFieldLabel: React.FC<InputFieldLabelProps> = ({
  label,
  onChange,
  initialValue,
  scale = 'medium',
  labelIcon,
  debounceTime = 0,
  ...rest
}) => {
  const [value, setValue] = React.useState<string>()

  React.useEffect(() => {
    /**
     * allow undefined or blank value
     * helps when using same input when dataset changes
     * ie: IngredientsViewer - selecting or creating ingredient
     */
    setValue(initialValue)
  }, [initialValue])

  const handleInputChangeDebounced = useDebouncedCallback(
    (inputValue: string) => {
      if (onChange) {
        onChange(inputValue)
      }
    },
    debounceTime
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    setValue(inputValue)
    handleInputChangeDebounced(inputValue)
  }

  return (
    <div>
      <LabelTypography variant="subtitle2">
        {label} {rest.required && '*'} {labelIcon}
      </LabelTypography>

      <Input
        fullWidth
        value={value || ''} // Bind input value to state
        onChange={handleInputChange}
        {...rest}
        sx={{
          ...{
            boxSizing: 'border-box',
            background: '#FDFDFD',
            border: '1px solid rgba(161, 171, 183, 0.3)',
            boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.05)',
            borderRadius: '8px',
            position: 'relative',
            display: 'flex',
            overflow: 'hidden',

            '& input': {
              width: '100%',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: scale == 'large' ? '20px' : '16px',
              lineHeight: '20px',
              color: '#16191C',
              padding: '0px'
            }
          },
          ...rest.sx
        }}
      />
    </div>
  )
}
