import { Box } from '@mui/material'
import { BoxColumnBasic } from 'components/common'
import React from 'react'

interface TabContentProps {
  children?: React.ReactNode
  footerElement?: React.ReactNode
  scrollToBottom?: boolean
  padding?: string
}

export const TabContent: React.FC<TabContentProps> = ({
  children,
  footerElement,
  scrollToBottom = false,
  padding = '20px'
}) => {
  const scrollRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (scrollRef.current && scrollToBottom) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [children])

  return (
    <BoxColumnBasic height="100%">
      <BoxColumnBasic
        ref={scrollRef}
        overflow="auto"
        flexGrow="1"
        marginBottom={footerElement ? '0px' : '20px'}
      >
        <BoxColumnBasic padding={padding} flex="1">
          {children}
        </BoxColumnBasic>
      </BoxColumnBasic>
      {footerElement && (
        <Box
          borderTop="2px solid rgba(0, 0, 0, 0.06)"
          boxShadow="47px 107px 47px rgba(0, 0, 0, 0.01), 27px 60px 40px rgba(0, 0, 0, 0.05), 12px 27px 29px rgba(0, 0, 0, 0.09), 3px 7px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)"
          zIndex="2"
        >
          {footerElement}
        </Box>
      )}
    </BoxColumnBasic>
  )
}
