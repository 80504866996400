import { FlexContainerItemRow } from 'components/NutritionLabel/components/NutritionLabelStyles'
import { NutritionLabelText } from 'components/NutritionLabel/components/NutritionLabelText'

export interface ItemRowSettings {
  level?: number
  boldName?: boolean
  boldPercentage?: boolean
  topLine?: boolean
}

export interface ItemRowValues {
  name: string
  amount?: string | number
  unit?: string
  dvPercentage?: string
  override?: React.ReactNode
}

export interface ItemRowProps extends ItemRowSettings {
  name: string
  amount?: string | number
  unit?: string
  dvPercentage?: string
  override?: React.ReactNode
}

export const ItemRow: React.FC<ItemRowProps> = ({
  name,
  amount,
  unit,
  dvPercentage,
  level = 0,
  boldName = false,
  boldPercentage = false,
  override
}) => {
  return (
    <>
      <FlexContainerItemRow
        style={{
          paddingLeft: `${level * 10}px`
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline'
          }}
        >
          {!override && (
            <>
              <NutritionLabelText bold={boldName} points={8} leading={1}>
                {name}
              </NutritionLabelText>
              <NutritionLabelText points={8} leading={1}>
                {'\u00A0'}
                {amount}
                {'\u00A0'}
                {unit}
              </NutritionLabelText>
            </>
          )}
          {override}
        </div>
        <div>
          <NutritionLabelText bold={boldPercentage} points={8}>
            {dvPercentage && `${dvPercentage} %`}
          </NutritionLabelText>
        </div>
      </FlexContainerItemRow>
    </>
  )
}
