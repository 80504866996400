import { Box } from '@mui/material'
import {
  DocumentsPanelLinear,
  DocumentsPanelProps
} from 'components/DocumentsPanel/Linear/DocumentsPanelLinear'
import React from 'react'
import { NoteTextField, NotesBottom } from './NotesStyle'

export interface NotesProps {
  note: {
    value: string
    onChange: (newValue: string) => void
  }
  documents: DocumentsPanelProps
}

export const Notes = React.memo(({ note, documents }: NotesProps) => {
  return (
    <Box marginBottom={'10px'} marginTop={'30px'}>
      <NoteTextField
        defaultValue={note.value}
        debounceTime={1000}
        onChange={(e) => note.onChange(e.target.value)}
        placeholder="Enter note..."
        fullWidth
        multiline
        rows={6}
        inputProps={{
          style: {
            marginLeft: '0px'
          }
        }}
      />
      <NotesBottom>
        <DocumentsPanelLinear {...documents} maxDocuments={6} />
      </NotesBottom>
    </Box>
  )
})
