import { InputAdornment } from '@mui/material'
import { infoMessages } from 'common/constants'
import { langDisplaySorter } from 'common/utils'
import { SelectOption } from 'components/common'
import { InfoBubble } from 'components/InfoBubble/InfoBubble'
import { MarkdownRenderer } from 'components/MarkdownRenderer/MarkdownRenderer'
import { Select } from 'components/Select/Select'
import { TextField } from 'components/TextField/TextField'
import { FormulaNutritionFactLanguage } from 'models/FormulaLabel'
import React from 'react'
import { RegulationName } from 'services/apis/regulation/RegulationApiResponse'

interface SelectProps<T> {
  options: SelectOption<T>[]
  value: string
  onChange: (value: T) => void
}

interface MultiLanguageText {
  language: string
  value: string
  onChange: (value: string) => void
  suggestedValue?: string
  onBlur?: () => void
}

export interface LayoutProps {
  regulation: SelectProps<string>
  category: SelectProps<string>
  format: SelectProps<string>
  servingSize: {
    language: FormulaNutritionFactLanguage
    value: string
    onChange: (value: string) => void
  }[]
  servingsPerContainer: {
    value: number
    onChange: (value: number | null) => void
  }
  containerWeight: {
    value: number
    onChange: (value: number | null) => void
    visible: boolean
  }
  getServingSizePlaceholder: (language: FormulaNutritionFactLanguage) => string
  selectedRegulationName?: string
  ingredients: MultiLanguageText[]
  descriptions: MultiLanguageText[]
}

export const Layout: React.FC<LayoutProps> = ({
  regulation,
  category,
  format,
  servingSize,
  servingsPerContainer,
  containerWeight,
  getServingSizePlaceholder,
  selectedRegulationName,
  ingredients,
  descriptions
}) => {
  const sortedServingSize = servingSize
    .slice()
    .sort((a, b) => langDisplaySorter(a.language, b.language))
  return (
    <>
      <Select {...regulation} />
      <Select {...category} />
      <Select {...format} />
      {sortedServingSize.map((serving, index) => (
        <TextField
          key={index}
          label={`Descriptive Serving Size ${
            serving.language !== FormulaNutritionFactLanguage.ENGLISH
              ? `(${serving.language})`
              : ''
          }`}
          type="text"
          // placeholder should be based on the language
          placeholder={getServingSizePlaceholder(serving.language)}
          value={serving.value}
          onChange={(value) => serving.onChange(value)}
        />
      ))}
      {selectedRegulationName !== RegulationName.CANADA && (
        <TextField
          label="Servings Per container"
          type="number"
          placeholder="e.g. 1"
          value={servingsPerContainer.value.toString()}
          onChange={(value) => servingsPerContainer.onChange(Number(value))}
        />
      )}
      {containerWeight.visible && (
        <TextField
          label="Container Weight"
          type="number"
          placeholder="e.g. 100"
          endAdornment={<InputAdornment position="end">g</InputAdornment>}
          value={containerWeight.value.toString()}
          onChange={(value) => containerWeight.onChange(Number(value))}
        />
      )}
      {ingredients.map((ingredient, index) => (
        <TextField
          key={index}
          label={`Ingredient Statement ${
            ingredient.language !== FormulaNutritionFactLanguage.ENGLISH
              ? `(${ingredient.language})`
              : ''
          }`}
          labelIcon={
            <InfoBubble
              text={
                <MarkdownRenderer
                  markdownText={infoMessages.formula.INGREDIENT_STATEMENT}
                />
              }
              width="300px"
            />
          }
          type="text"
          value={ingredient.value.toUpperCase()}
          onChange={(value) => ingredient.onChange(value.toUpperCase())}
          rows={3}
          placeholder="eg. WATER, COCOA, VANILLA EXTRACT"
          multiline
          onBlur={ingredient.onBlur}
        />
      ))}
      {descriptions.map((description, index) => (
        <TextField
          key={index}
          label={`Description ${
            description.language !== FormulaNutritionFactLanguage.ENGLISH
              ? `(${description.language})`
              : ''
          }`}
          placeholder="eg. Austin, TX"
          type="text"
          value={description.value}
          onChange={(value) => description.onChange(value)}
          rows={3}
          multiline
        />
      ))}
    </>
  )
}
