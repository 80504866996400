import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React from 'react'
import { FormControlStyled, LabelTypography } from '../common'

export interface SelectOption {
  value: string | number
  label: string
}

interface SelectOptionsLabelProps {
  label?: string
  labelIcon?: React.ReactNode
  options: SelectOption[]
  disabled?: boolean
  required?: boolean
  onChange?: (value: string | number) => void
  initialValue?: string | number
  name?: string
}

export const SelectOptionsLabel: React.FC<SelectOptionsLabelProps> = ({
  label,
  labelIcon,
  options,
  disabled,
  required,
  onChange,
  initialValue,
  name
}) => {
  const [value, setValue] = React.useState<string | number>('')

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    const inputValue = event.target.value
    setValue(inputValue)
    if (onChange) {
      onChange(inputValue)
    }
  }

  React.useEffect(() => {
    if (initialValue !== undefined) {
      setValue(initialValue)
    }
  }, [initialValue])

  return (
    <div className="SelectOptionsLabel">
      {!!label && (
        <LabelTypography variant="subtitle2">
          {label} {labelIcon}
        </LabelTypography>
      )}

      <FormControlStyled size="small" sx={{ width: '100%' }}>
        <Select
          name={name}
          displayEmpty
          disabled={disabled}
          required={required}
          value={value}
          sx={{ width: '100%', height: '33px' }}
          onChange={handleChange}
        >
          {options.map((option) => (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControlStyled>
    </div>
  )
}
