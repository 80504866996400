export const grayedOutInputStyle: React.CSSProperties = {
  display: 'flex',
  padding: '7px 12px',
  alignItems: 'center',
  background: 'var(--Beige, #F6F5F4)',
  border: 'none',
  borderRadius: '6px',
  whiteSpace: 'nowrap'
}

export const dateTextStyles: React.CSSProperties = {
  color: 'var(--secondary, rgba(26, 44, 66, 0.75))',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
}

export const formulaCountTextStyles: React.CSSProperties = {
  color: 'var(--Body, #14202E)',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
}

export const noFormulasTextStyles: React.CSSProperties = {
  color: 'var(--Body, #14202E)',
  opacity: 0.3,
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
}

export const targetCategoryTextStyles: React.CSSProperties = {
  color: 'var(--Body, #14202E)',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  opacity: 0.8
}

export const customTargetCategoryTextStyles: React.CSSProperties = {
  color: 'var(--Tertiary, rgba(35, 60, 88, 0.43))',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
}

export const buttonStyle: React.CSSProperties = {
  color: 'var(--secondary, rgba(26, 44, 66, 0.75))',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500
}

export const buttonTextStyle: React.CSSProperties = {
  color: 'var(--secondary, rgba(26, 44, 66, 0.75))',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500
}
