import React from 'react'
import { useAppSelector } from 'state/hooks'
import { isComparisonOperation } from 'services/apis/target/TargetApiMapper'
import { Nutrient, NutrientType, NutrientUnit } from 'models/Nutrient'
import {
  ComparisonOperator,
  ComparisonType,
  ComparisonUnit,
  NormalizedComparisonOperation,
  TagsQuantifier,
  ExtendedNutrientUnitForTargets,
  NormalizedOperation,
  NutrientTargetUnit
} from 'models/Target'
import {
  Box,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material'
import { SimpleAutoComplete } from '../SimpleAutoComplete'
import { InputWithAdornment } from '../../../../../components/InputWithAdornment/InputWithAdornment'
import { grayedOutInputStyle } from '../../../TargetsStyles'
import { AddSecondNutrientIcon } from './Components/AddSecondNutrientIcon'
import { SecondNutrientField } from './Components/SecondNutrientField'

interface ComparisonOperationProps {
  operationId: string
  setNewOperation: (newOperation: any) => void
  disableEdit?: boolean
}

export const ComparisonOperation: React.FC<ComparisonOperationProps> = ({
  operationId,
  setNewOperation,
  disableEdit = false
}) => {
  const operation: NormalizedOperation = useAppSelector(
    (state) => state.targets.activeOperations[operationId]
  )

  const availableNutrients = useAppSelector(
    (state) => state.targets.availableNutrients,
    (a, b) => a.length === b.length
  )

  const availableTags = useAppSelector(
    (state) => state.tags.tags,
    (a, b) => a.length === b.length
  )

  if (!operationId || !isComparisonOperation(operation)) {
    return <></>
  }

  const [selectedUnit, setSelectedUnit] = React.useState<
    NutrientTargetUnit | undefined
  >(operation.comparisonUnit)

  const [unitOptions, setUnitOptions] = React.useState<NutrientTargetUnit[]>([])
  const [firstNutrientDisabled, setFirstNutrientDisabled] =
    React.useState<boolean>(!!operation.secondNutrient)

  React.useEffect(() => {
    setFirstNutrientDisabled(!!operation.secondNutrient)
  }, [operation.secondNutrient])

  React.useEffect(() => {
    setUnitOptions(getUnitOptions(operation.nutrient))
  }, [
    operation.nutrient,
    operation.comparedToRdi,
    operation.comparedToReferenceFormula
  ])

  React.useEffect(() => {
    if (!selectedUnit) return
    if (unitOptions.includes(selectedUnit)) {
      return
    }
    const currentNutrientUnit = getNutrientUnit(operation.nutrient)
    if (unitOptions.includes(currentNutrientUnit)) {
      setSelectedUnit(currentNutrientUnit)
      return
    }
    setSelectedUnit(operation.comparisonUnit)
  }, [unitOptions])

  const getUnitOptions = (nutrientType: NutrientType | undefined) => {
    const options = [getNutrientUnit(nutrientType)]
    if (operation.comparedToRdi || operation.comparedToReferenceFormula) {
      return [ExtendedNutrientUnitForTargets.PERCENTAGE]
    }
    if (operation.comparisonType === ComparisonType.CALORIES_FROM_NUTRIENT) {
      return [
        NutrientUnit.KILOCALORIE,
        ExtendedNutrientUnitForTargets.PERCENTAGE
      ]
    }
    return options
  }
  const handleNutrientChange = (e: React.FocusEvent<HTMLInputElement>) => {
    if (disableEdit) {
      return
    }
    const newNutrient = e.target.value as NutrientType
    const newNutrientType = getNutrientTypeFromName(newNutrient)
    setSelectedUnit(getNutrientUnit(newNutrientType))
    if (isComparisonOperation(operation)) {
      setNewOperation({
        ...operation,
        nutrient: newNutrientType
      } as NormalizedComparisonOperation)
    }
  }

  const handleSecondNutrientChange = (
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    if (disableEdit) {
      return
    }
    const newNutrient = e.target.value as NutrientType
    const newNutrientType = getNutrientTypeFromName(newNutrient)
    if (isComparisonOperation(operation)) {
      setNewOperation({
        ...operation,
        secondNutrient: newNutrientType
      } as NormalizedComparisonOperation)
    }
  }

  const removeSecondNutrient = () => {
    if (disableEdit) {
      return
    }
    if (isComparisonOperation(operation)) {
      setNewOperation({
        ...operation,
        secondNutrient: undefined
      } as NormalizedComparisonOperation)
    }
  }

  const initializeSecondNutrient = () => {
    if (disableEdit) {
      return
    }
    if (isComparisonOperation(operation)) {
      const currentNutrientUnit = getNutrientUnit(operation.nutrient)
      const nutrientsWithUnit = getNutrientsHavingUnit(currentNutrientUnit)
      const firstNutrient = nutrientsWithUnit && nutrientsWithUnit[0]
      setNewOperation({
        ...operation,
        secondNutrient: firstNutrient?.type
      } as NormalizedComparisonOperation)
    }
  }

  const handleComparisonOperatorChange = (
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    if (disableEdit) {
      return
    }
    const newOperator = e.target.value as ComparisonOperator
    if (
      isComparisonOperation(operation) &&
      newOperator !== operation.operator
    ) {
      setNewOperation({
        ...operation,
        operator: newOperator
      } as NormalizedComparisonOperation)
    }
  }

  const handleTargetValueAndUnitChange = (value: number, unit: string) => {
    if (disableEdit) {
      return
    }
    if (
      isComparisonOperation(operation) &&
      (operation.targetValue !== value || operation.targetUnit !== unit)
    ) {
      setNewOperation({
        ...operation,
        targetValue: value,
        targetUnit: unit
      } as NormalizedComparisonOperation)
    }
  }

  const handleNutrientValueAndUnitChange = (value: number, unit: string) => {
    if (disableEdit) {
      return
    }
    if (
      isComparisonOperation(operation) &&
      (operation.value !== value || operation.comparisonUnit !== unit)
    ) {
      setNewOperation({
        ...operation,
        value: value,
        comparisonUnit: unit
      } as NormalizedComparisonOperation)
    }
  }

  const handleComparedToReferenceFormulaChange = (
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    if (disableEdit) {
      return
    }
    const newComparedToReferenceFormula =
      e.target.value === 'reference food' ? true : false
    if (
      isComparisonOperation(operation) &&
      newComparedToReferenceFormula !== operation.comparedToReferenceFormula
    ) {
      setNewOperation({
        ...operation,
        comparedToReferenceFormula: newComparedToReferenceFormula
      } as NormalizedComparisonOperation)
    }
  }

  const getNutrientNameFromType = (nutrientType: NutrientType | undefined) => {
    if (!nutrientType) {
      return ''
    }
    const nutrient = availableNutrients.find(
      (nutrient) => nutrient.type === nutrientType
    )
    if (!nutrient) {
      return ''
    }
    return nutrient.name
  }
  const getNutrientTypeFromName = (nutrientName: string) => {
    if (!nutrientName) {
      return undefined
    }
    const nutrient = availableNutrients.find(
      (nutrient) => nutrient.name === nutrientName
    )
    if (!nutrient) {
      return undefined
    }
    return nutrient.type
  }
  const getAvailableNutrientsNames = () => availableNutrients.map((n) => n.name)
  const getNutrientUnit = (nutrientType: NutrientType | undefined) => {
    const nutrient = availableNutrients.find(
      (nutrient) => nutrient.type === nutrientType
    )
    if (!nutrient) {
      return NutrientUnit.GRAM
    }
    return nutrient.unit
  }

  const getNutrientsHavingUnit = (
    unit?: NutrientTargetUnit | undefined
  ): Nutrient[] => {
    if (!unit) {
      return getNutrientsHavingUnit(getNutrientUnit(operation.nutrient))
    }
    return unit
      ? availableNutrients.filter((nutrient) => nutrient.unit === unit)
      : []
  }

  const getFirstTagId = () => {
    const firstTag = availableTags[0] ? availableTags[0] : undefined
    return firstTag?.id
  }

  const handleTagChange = (e: React.FocusEvent<HTMLInputElement>) => {
    if (disableEdit) {
      return
    }
    const newTag = e.target.value
    const newTagId = getTagIdFromName(newTag)
    if (isComparisonOperation(operation)) {
      setNewOperation({
        ...operation,
        tagId: newTagId
      } as NormalizedComparisonOperation)
    }
  }

  const getTagIdFromName = (tagName: string) => {
    if (!tagName) {
      return undefined
    }
    const tag = availableTags.find((tag) => tag.name === tagName)
    if (!tag) {
      return undefined
    }
    return tag.id
  }

  const getTagNameFromId = (tagId: string | undefined) => {
    if (!tagId) {
      return ''
    }
    const tag = availableTags.find((tag) => tag.id === tagId)
    if (!tag) {
      return ''
    }
    return tag.name
  }
  const getAvailableTagsNames = () => availableTags.map((t) => t.name)

  const getQuantifierFromComparisonValue = (
    operation: NormalizedComparisonOperation
  ) => {
    if (operation.value === 0) {
      return TagsQuantifier.NONE
    }
    return TagsQuantifier.ALL
  }

  const handleQuantifierChange = (e: SelectChangeEvent<TagsQuantifier>) => {
    if (disableEdit) {
      return
    }
    const newQuantifier = e.target.value as TagsQuantifier
    const valueFromQuantifier = newQuantifier === TagsQuantifier.ALL ? 1 : 0
    if (isComparisonOperation(operation)) {
      setNewOperation({
        ...operation,
        value: valueFromQuantifier
      } as NormalizedComparisonOperation)
    }
  }

  const handleTextChange = (text: string) => {
    if (disableEdit) {
      return
    }
    if (isComparisonOperation(operation)) {
      setNewOperation({
        ...operation,
        text
      } as NormalizedComparisonOperation)
    }
  }

  if (!operationId || !isComparisonOperation(operation)) {
    return <></>
  }

  if (operation.comparisonType === ComparisonType.PER_RACC) {
    if (operation.comparedToRdi) {
      return (
        <>
          {/* The following is for the nutrient */}
          <SimpleAutoComplete
            initialValue={getNutrientNameFromType(operation.nutrient)}
            options={getAvailableNutrientsNames()}
            onBlur={(e) => handleNutrientChange(e)}
            disabled={disableEdit}
          />
          {/* The following is for the comparison operator */}
          <SimpleAutoComplete
            initialValue={operation.operator}
            options={Object.values(ComparisonOperator)}
            onBlur={(e) => handleComparisonOperatorChange(e)}
            disabled={disableEdit}
          />
          {/* The following is for the nutrient value and unit*/}
          <InputWithAdornment
            initialValue={operation.value}
            initialUnit={selectedUnit}
            unitOptions={unitOptions}
            getValueAndUnit={(value, unit) => {
              handleNutrientValueAndUnitChange(value, unit)
            }}
            disabled={disableEdit}
            inputStyle={{ height: '32px' }}
          />
          <Box style={grayedOutInputStyle}>of RDI</Box>
          <Box style={grayedOutInputStyle}>
            <Typography>in</Typography>
          </Box>
          <SimpleAutoComplete
            initialValue={
              operation.comparedToReferenceFormula
                ? 'reference food'
                : 'formula'
            }
            options={['reference food', 'formula']}
            onBlur={handleComparedToReferenceFormulaChange}
            disabled={disableEdit}
          />
          <Box style={grayedOutInputStyle}>per</Box>
          <Box style={grayedOutInputStyle}>RACC</Box>
        </>
      )
    }
    return (
      <>
        {/* The following is for the nutrient */}
        <Box style={{ position: 'relative' }}>
          <SimpleAutoComplete
            initialValue={getNutrientNameFromType(operation.nutrient)}
            options={getAvailableNutrientsNames()}
            onBlur={(e) => handleNutrientChange(e)}
            disabled={disableEdit || firstNutrientDisabled}
            showFullTextTooltip={true}
          />
          <AddSecondNutrientIcon
            onClick={initializeSecondNutrient}
            disableEdit={disableEdit}
            show={!operation.secondNutrient}
          />
        </Box>
        {/* second nutrient */}
        <SecondNutrientField
          initialValue={getNutrientNameFromType(operation.secondNutrient)}
          options={getNutrientsHavingUnit().map((n) => n.name)}
          onSecondNutrientChange={handleSecondNutrientChange}
          removeSecondNutrient={removeSecondNutrient}
          disableEdit={disableEdit}
          show={!!operation.secondNutrient}
        />
        {/* The following is for the comparison operator */}
        <SimpleAutoComplete
          initialValue={operation.operator}
          options={Object.values(ComparisonOperator)}
          onBlur={(e) => handleComparisonOperatorChange(e)}
          disabled={disableEdit}
        />
        {/* The following is for the nutrient value and unit*/}
        <InputWithAdornment
          initialValue={operation.value}
          initialUnit={selectedUnit}
          unitOptions={unitOptions}
          getValueAndUnit={(value, unit) => {
            handleNutrientValueAndUnitChange(value, unit)
          }}
          disabled={disableEdit}
          inputStyle={{ height: '32px' }}
        />
        <Box style={grayedOutInputStyle}>
          <Typography>in</Typography>
        </Box>
        <SimpleAutoComplete
          initialValue={
            operation.comparedToReferenceFormula ? 'reference food' : 'formula'
          }
          options={['reference food', 'formula']}
          onBlur={handleComparedToReferenceFormulaChange}
          disabled={disableEdit}
        />
        <Box style={grayedOutInputStyle}>per</Box>
        <Box style={grayedOutInputStyle}>RACC</Box>
      </>
    )
  }

  if (operation.comparisonType === ComparisonType.PER_TARGET_VALUE) {
    return (
      <>
        {/* The following is for the nutrient */}
        <Box style={{ position: 'relative' }}>
          <SimpleAutoComplete
            initialValue={getNutrientNameFromType(operation.nutrient)}
            options={getAvailableNutrientsNames()}
            onBlur={(e) => handleNutrientChange(e)}
            disabled={disableEdit || firstNutrientDisabled}
            showFullTextTooltip={true}
          />
          <AddSecondNutrientIcon
            onClick={initializeSecondNutrient}
            disableEdit={disableEdit}
            show={!operation.secondNutrient}
          />
        </Box>
        {/* second nutrient */}
        <SecondNutrientField
          initialValue={getNutrientNameFromType(operation.secondNutrient)}
          options={getNutrientsHavingUnit().map((n) => n.name)}
          onSecondNutrientChange={handleSecondNutrientChange}
          removeSecondNutrient={removeSecondNutrient}
          disableEdit={disableEdit}
          show={!!operation.secondNutrient}
        />
        {/* The following is for the comparison operator */}
        <SimpleAutoComplete
          initialValue={operation.operator}
          options={Object.values(ComparisonOperator)}
          onBlur={(e) => handleComparisonOperatorChange(e)}
          disabled={disableEdit}
        />
        {/* The following is for the nutrient value and unit*/}
        <InputWithAdornment
          initialValue={operation.value}
          initialUnit={selectedUnit}
          unitOptions={unitOptions}
          getValueAndUnit={(value, unit) => {
            handleNutrientValueAndUnitChange(value, unit)
          }}
          disabled={disableEdit}
          inputStyle={{ height: '32px' }}
        />
        <Box style={grayedOutInputStyle}>
          <Typography>in</Typography>
        </Box>
        <SimpleAutoComplete
          initialValue={
            operation.comparedToReferenceFormula ? 'reference food' : 'formula'
          }
          options={['reference food', 'formula']}
          onBlur={handleComparedToReferenceFormulaChange}
          disabled={disableEdit}
        />
        <Box style={grayedOutInputStyle}>per</Box>
        {/* the following is for the target value and unit*/}
        <InputWithAdornment
          initialValue={operation.targetValue}
          initialUnit={operation.targetUnit}
          unitOptions={Object.values(ComparisonUnit)}
          getValueAndUnit={(value, unit) => {
            handleTargetValueAndUnitChange(value, unit)
          }}
          disabled={disableEdit}
          inputStyle={{ height: '32px' }}
        />
      </>
    )
  }

  if (operation.comparisonType === ComparisonType.PER_LABELED_SERVING) {
    return (
      <>
        {/* The following is for the nutrient */}
        <Box style={{ position: 'relative' }}>
          <SimpleAutoComplete
            initialValue={getNutrientNameFromType(operation.nutrient)}
            options={getAvailableNutrientsNames()}
            onBlur={(e) => handleNutrientChange(e)}
            disabled={disableEdit || firstNutrientDisabled}
            showFullTextTooltip={true}
          />
          <AddSecondNutrientIcon
            onClick={initializeSecondNutrient}
            disableEdit={disableEdit}
            show={!operation.secondNutrient}
          />
        </Box>
        {/* second nutrient */}
        <SecondNutrientField
          initialValue={getNutrientNameFromType(operation.secondNutrient)}
          options={getNutrientsHavingUnit().map((n) => n.name)}
          onSecondNutrientChange={handleSecondNutrientChange}
          removeSecondNutrient={removeSecondNutrient}
          disableEdit={disableEdit}
          show={!!operation.secondNutrient}
        />
        {/* The following is for the comparison operator */}
        <SimpleAutoComplete
          initialValue={operation.operator}
          options={Object.values(ComparisonOperator)}
          onBlur={(e) => handleComparisonOperatorChange(e)}
          disabled={disableEdit}
        />
        {/* The following is for the nutrient value and unit*/}
        <InputWithAdornment
          initialValue={operation.value}
          initialUnit={selectedUnit}
          unitOptions={unitOptions}
          getValueAndUnit={(value, unit) => {
            handleNutrientValueAndUnitChange(value, unit)
          }}
          disabled={disableEdit}
          inputStyle={{ height: '32px' }}
        />
        <Box style={grayedOutInputStyle}>per</Box>
        <Box style={grayedOutInputStyle}>labeled serving</Box>
      </>
    )
  }

  if (operation.comparisonType === ComparisonType.CALORIES_FROM_NUTRIENT) {
    return (
      <>
        <Box style={grayedOutInputStyle}>Calories</Box>
        {/* The following is for the comparison operator */}
        <SimpleAutoComplete
          initialValue={operation.operator}
          options={Object.values(ComparisonOperator)}
          onBlur={(e) => handleComparisonOperatorChange(e)}
          disabled={disableEdit}
        />
        {/* The following is for the nutrient value and unit*/}
        <InputWithAdornment
          initialValue={operation.value}
          initialUnit={selectedUnit}
          unitOptions={unitOptions}
          getValueAndUnit={(value, unit) => {
            handleNutrientValueAndUnitChange(value, unit)
          }}
          disabled={disableEdit}
          inputStyle={{ height: '32px' }}
        />
        <Box style={grayedOutInputStyle}>from</Box>
        <Box style={{ position: 'relative' }}>
          <SimpleAutoComplete
            initialValue={getNutrientNameFromType(operation.nutrient)}
            options={getAvailableNutrientsNames()}
            onBlur={(e) => handleNutrientChange(e)}
            disabled={disableEdit}
          />
          <AddSecondNutrientIcon
            onClick={initializeSecondNutrient}
            disableEdit={disableEdit}
            show={!operation.secondNutrient}
          />
        </Box>
        {/* second nutrient */}
        <SecondNutrientField
          initialValue={getNutrientNameFromType(operation.secondNutrient)}
          options={getNutrientsHavingUnit().map((n) => n.name)}
          onSecondNutrientChange={handleSecondNutrientChange}
          removeSecondNutrient={removeSecondNutrient}
          disableEdit={disableEdit}
          show={!!operation.secondNutrient}
        />
      </>
    )
  }

  if (operation.comparisonType === ComparisonType.TAGS_IN_FORMULA) {
    return (
      <>
        <Select
          value={getQuantifierFromComparisonValue(operation)}
          onChange={handleQuantifierChange}
          disabled={disableEdit}
          style={{ width: '80px', height: '32px' }}
        >
          <MenuItem value={TagsQuantifier.ALL}>{TagsQuantifier.ALL}</MenuItem>
          <MenuItem value={TagsQuantifier.NONE}>{TagsQuantifier.NONE}</MenuItem>
        </Select>
        <Box style={grayedOutInputStyle}>
          {operation.value === 0
            ? "of the formula's ingredients has the"
            : "of the formula's ingredients have the"}
        </Box>
        <SimpleAutoComplete
          initialValue={getTagNameFromId(operation.tagId || getFirstTagId())}
          options={getAvailableTagsNames()}
          onBlur={(e) => {
            handleTagChange(e)
          }}
          disabled={disableEdit}
        />
        <Box style={grayedOutInputStyle}>tag</Box>
      </>
    )
  }
  if (operation.comparisonType === ComparisonType.TEXT_IN_FORMULA) {
    return (
      <>
        <Select
          value={getQuantifierFromComparisonValue(operation)}
          onChange={handleQuantifierChange}
          disabled={disableEdit}
          style={{ width: '80px', height: '32px' }}
        >
          <MenuItem value={TagsQuantifier.ALL}>{TagsQuantifier.ALL}</MenuItem>
          <MenuItem value={TagsQuantifier.NONE}>{TagsQuantifier.NONE}</MenuItem>
        </Select>
        <Box style={grayedOutInputStyle}>
          of the formula's ingredients have the text
        </Box>
        <Input
          value={operation.text || ''}
          placeholder="Enter text..."
          onChange={(e) => handleTextChange(e.target.value)}
          disabled={disableEdit}
          style={{ minWidth: '100px', height: '32px' }}
        />
        <Box style={grayedOutInputStyle}>in their statement or name</Box>
      </>
    )
  }
  return <></>
}
