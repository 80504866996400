import React from 'react'
import { SettingSubPageLayout } from './SettingSubPageLayout'
import { Grid, Skeleton } from '@mui/material'
import { MembersTableSkeleton } from './MembersTableSkeleton'

export const MembersSkeleton: React.FC = () => {
  return (
    <SettingSubPageLayout title="Members">
      <Grid container spacing={2} direction="column" width={'20vw'}>
        <Grid item>
          <Skeleton variant={'text'} height={20} width={163} />
          <Skeleton variant={'rectangular'} height={32} />
        </Grid>
        <Grid item>
          <Skeleton variant={'rectangular'} height={39} />
        </Grid>
      </Grid>

      <Grid container spacing={2} marginTop={'20px'} width={'80vw'}>
        <MembersTableSkeleton />
        <MembersTableSkeleton />
      </Grid>
    </SettingSubPageLayout>
  )
}
