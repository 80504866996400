import { Box, Typography } from '@mui/material'
import { BoxColumnBasic } from 'components/common'
import styled from 'styled-components'

export const EditorGroup = styled(BoxColumnBasic)({
  gap: '12px',
  '&:first-child': {
    paddingTop: 0
  },
  '&:last-child': {
    paddingBottom: 0
  }
})

export const EditorGroupSeparator = styled(Box)({
  width: '100%',
  height: '1px',
  margin: '10px 0px',
  opacity: 0.08,
  backgroundColor: '#000',
  flexShrink: 0
})

export const EditorGroupTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px'
})

export const EditorBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '14px 12px',
  gap: '9px',
  overflow: 'hidden auto',
  borderLeft: '1px solid rgba(0, 0, 0, 0.06)'
})
