import { Box, Typography } from '@mui/material'
import { langDisplaySorter } from 'common/utils'
import {
  FormulaNutritionFactLabelLangSpecificFields,
  FormulaNutritionFactLanguage
} from 'models/FormulaLabel'
import React from 'react'

export interface IngredientStatementProps {
  statement: FormulaNutritionFactLabelLangSpecificFields
  suggestedStatement: FormulaNutritionFactLabelLangSpecificFields
}

const translateIngredients = (
  language: FormulaNutritionFactLanguage
): JSX.Element => {
  switch (language) {
    case FormulaNutritionFactLanguage.ENGLISH:
      return <b>INGREDIENTS: </b>
    case FormulaNutritionFactLanguage.FRENCH:
      return <b>INGRÉDIENTS: </b>
    case FormulaNutritionFactLanguage.SPANISH:
      return <b>INGREDIENTES: </b>
    default:
      return <b>INGREDIENTS: </b>
  }
}

export const IngredientStatement: React.FC<IngredientStatementProps> = ({
  statement,
  suggestedStatement
}) => {
  return (
    <Box width="100%">
      {Object.keys(statement)
        .sort((a, b) => {
          return langDisplaySorter(
            a as FormulaNutritionFactLanguage,
            b as FormulaNutritionFactLanguage
          )
        })
        .map((language) => {
          const languageKey = language as FormulaNutritionFactLanguage
          if (!(statement[languageKey] || suggestedStatement[languageKey])) {
            return null
          }
          return (
            <Typography key={languageKey}>
              {translateIngredients(languageKey)}
              {statement[languageKey]?.toUpperCase() ||
                suggestedStatement[languageKey]?.toUpperCase() ||
                ''}
            </Typography>
          )
        })}
    </Box>
  )
}
