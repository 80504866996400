import React from 'react'
import { FormulaNutritionFactNutrient } from 'models/FormulaLabel'
import { NutrientType } from 'models/Nutrient'
import { Container } from '../../components/NutritionLabelStyles'
import { NutritionLabelText } from '../../components/NutritionLabelText'
import { NutrientsTableBottomContainer } from '../NutritionLabelTabularStyles'

const getBottomNutrientsText = (
  name: string,
  amount: string,
  unit: string,
  dvPercentage: string,
  nutrientType: NutrientType
) => {
  if (nutrientType === NutrientType.FOLATE_DFE) {
    return (
      `Folate ${amount}${unit} DFE` + (dvPercentage ? ` ${dvPercentage}%` : '')
    )
  }
  return `${name} ${amount}${unit}` + (dvPercentage ? ` ${dvPercentage}%` : '')
}

export const BottomNutrientsText: React.FC<{
  bottomNutrients: FormulaNutritionFactNutrient[]
}> = ({ bottomNutrients = [] }) => {
  const itemRefs = React.useRef<(HTMLDivElement | null)[]>([])
  const [yPositions, setYPositions] = React.useState<number[]>([])

  React.useEffect(() => {
    // Update yPositions with the actual y positions of the items
    const positions = itemRefs.current.map(
      (item) => item?.getBoundingClientRect().top || 0
    )
    setYPositions(positions)
  }, [bottomNutrients])

  const hasDotSeparator = (index: number) => {
    // Get the current and previous y positions.
    const currentY = yPositions[index]
    const previousY = yPositions[index - 1]

    // If there is no previous y position, do not add a dot.
    if (index === 0 || previousY === undefined) return false

    // Check if the y position of the previous item is the same as the current item.
    return currentY === previousY
  }

  return (
    <NutrientsTableBottomContainer>
      {bottomNutrients.map((fnfn, index) => (
        <div
          ref={(el) => (itemRefs.current[index] = el)}
          key={`bottomNutrients-${index}`}
        >
          <NutritionLabelText points={8}>
            {hasDotSeparator(index) && <>&nbsp;• </>}
            {getBottomNutrientsText(
              fnfn.displayNameOptions[0],
              fnfn.displayAmountOptions[0]?.amount,
              fnfn.nutrient.unit,
              fnfn.displayDvOptions[0]?.amount,
              fnfn.nutrient.type
            )}
          </NutritionLabelText>
        </div>
      ))}
    </NutrientsTableBottomContainer>
  )
}
