import React from 'react'
import { StyledButton } from './ButtonStyle'
import { SxProps } from '@mui/material'

export type ButtonSize = 'small' | 'medium' | 'large'
export type ButtonColor = 'primary' | 'secondary' | 'danger' | 'tertiary'
export type ButtonVariant = 'normal' | 'square' | 'circle' | 'text'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Color of the button. */
  color?: ButtonColor
  /** Size of the button. */
  size?: ButtonSize
  /** If the button should be disabled or not. */
  disabled?: boolean
  /** The content that would go inside the button. */
  children?: React.ReactNode
  /** Expends the button width to 100%. */
  fullWidth?: boolean
  /** Changes the variant type of the button. */
  variant?: ButtonVariant
  /** Styling for MUI button. */
  sx?: SxProps
}

/**
 * Button allows users to take actions, and make choices, with a single tap.
 */

export const Button = React.memo(
  React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
      {
        color = 'primary',
        size = 'medium',
        variant = 'normal',
        disabled = false,
        onClick,
        children,
        fullWidth = false,
        sx,
        ...props
      },
      ref
    ) => {
      return (
        <StyledButton
          bColor={color}
          bSize={size}
          sx={sx}
          onClick={onClick}
          disabled={disabled}
          disableRipple // Disables ripple for mui button.
          fullWidth={fullWidth}
          bVariant={variant}
          ref={ref}
          {...props}
        >
          {children}
        </StyledButton>
      )
    }
  )
)
