import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../CommonState'
import { Tag, SimpleTagsPaginated } from 'models/Tags'
import { TagsApi } from 'services/apis/tags/TagsApi'
import { GetTagsRequest } from './TagsRequest'

interface TagsState extends LoadingState {
  tags: Tag[]
}

const initialState: TagsState = {
  tags: [],
  loading: false,
  error: false
}

export const getCompanyTags = createAsyncThunk(
  'tags/company/get',
  async ({ companyId }: GetTagsRequest) => {
    return await TagsApi.getTags(companyId)
  }
)

export const tagsSlice = createSlice({
  name: 'tagsSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCompanyTags.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      getCompanyTags.fulfilled,
      (state, action: PayloadAction<SimpleTagsPaginated>) => {
        state.tags = [...action.payload.items]
        state.loading = false
      }
    )
    builder.addCase(getCompanyTags.rejected, (state) => {
      state.loading = false
      state.error = true
    })
  }
})

export default tagsSlice.reducer
