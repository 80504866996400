import React from 'react'
import { Modal } from './Modal'

export enum ModalType {
  INFO,
  CONFIRM,
  COMPLEX
}

interface ModalState {
  open: boolean
  title?: string
  type: ModalType
  message?: string | React.ReactNode
  onYesClicked?: () => void
  onNoClicked?: () => void
  yesText?: string
  noText?: string
  danger?: boolean
  content?: React.ReactNode
}

const initialModalState: ModalState = {
  open: false,
  title: '',
  type: ModalType.INFO,
  danger: false
}

interface ModalContextInterface {
  modal: ModalState
  showModal: (modalProps: Pick<ModalState, 'content'>) => void
  showConfirmationModal: (
    modalProps: Omit<ModalState, 'type' | 'open' | 'element'>
  ) => void
  showInfoModal: (
    modalProps: Omit<
      ModalState,
      'type' | 'onNoClicked' | 'noText' | 'open' | 'element'
    >
  ) => void
  closeModal: () => void
}

export const ModalContext = React.createContext<ModalContextInterface>({
  modal: initialModalState,
  showModal: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  showConfirmationModal: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  showInfoModal: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  closeModal: () => {} // eslint-disable-line @typescript-eslint/no-empty-function
})

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [modal, setModal] = React.useState<ModalState>(initialModalState)

  /**
   * Close the current active modal.
   */
  const closeModal = () => {
    setModal({
      ...modal,
      open: false,
      onNoClicked: undefined,
      onYesClicked: undefined
    })
  }

  /**
   * Show a confirmation modal on the screen.
   * @param modalProps The properties for the confirmation modal.
   */
  const showConfirmationModal = (
    modalProps: Omit<ModalState, 'type' | 'open'>
  ) => {
    setModal({
      open: true,
      type: ModalType.CONFIRM,
      ...modalProps
    })
  }

  /**
   * Show an info modal on the screen.
   * @param modalProps The properties for the info modal.
   */
  const showInfoModal = (
    modalProps: Omit<ModalState, 'type' | 'onNoClicked' | 'noText' | 'open'>
  ) => {
    setModal({
      open: true,
      type: ModalType.INFO,
      onNoClicked: undefined,
      noText: undefined,
      ...modalProps
    })
  }

  /**
   * Show a complex modal on the screen.
   * @param modalProps The properties for the complex modal.
   */
  const showModal = (modalProps: Pick<ModalState, 'content'>) => {
    setModal({
      open: true,
      type: ModalType.COMPLEX,
      ...modalProps
    })
  }

  return (
    <ModalContext.Provider
      value={{
        modal,
        showModal,
        showConfirmationModal,
        showInfoModal,
        closeModal
      }}
    >
      <Modal />
      {children}
    </ModalContext.Provider>
  )
}
