export interface BasicUserApiResponse {
  id: string
  firstName: string
  lastName: string
  imagePath: string
}
export interface UserApiResponse extends BasicUserApiResponse {
  companyId: string
  email: string
  createdAt: string
  authenticatorId: string
}

export enum RoleApiResponse {
  MEMBER = 'Member',
  ADMIN = 'Admin'
}

export interface UserRoleApiResponse {
  user: UserApiResponse
  role: RoleApiResponse
}

export interface BasicUserRoleApiResponse {
  user: BasicUserApiResponse
  role: RoleApiResponse
}

export interface UserRoleApiPaginatedResponse {
  content: UserRoleApiResponse[]
}

export interface UserApiPaginatedResponse {
  content: UserApiResponse[]
}
