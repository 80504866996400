import { Tabs } from '@mui/material'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import {
  ViewerBottomContentContainer,
  ViewerBottomContentWrap,
  ViewerBottomHeaderFooter,
  ViewerBottomTab
} from 'components/common'
import { DetailedSimpleIngredient } from 'models/SimpleIngredient'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { getSimpleIngredientNutrients } from 'state/simple_ingredients/SimpleIngredientsSlice'
import { IngredientsDocumentsPanel } from './components/DocumentsPanel/IngredientsDocumentsPanel'
import { Nutrients } from './components/NutrientsPanel/Nutrients'
import { IngredientMeasurementsPanel } from './components/MeasurementsPanel/IngredientMeasurementsPanel'

interface IngredientViewerPanelProps {
  simpleIngredient: DetailedSimpleIngredient
  readOnly?: boolean
}

export const IngredientViewerPanel: React.FC<IngredientViewerPanelProps> = ({
  simpleIngredient,
  readOnly = false
}) => {
  const dispatch = useAppDispatch()
  const { showError } = React.useContext(SnackbarContext)
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const [activeTab, setActiveTab] = React.useState(0)

  const handleTabChange = (e: React.SyntheticEvent, value: number) => {
    setActiveTab(value)
  }

  React.useEffect(() => {
    void dispatch(
      getSimpleIngredientNutrients({
        companyId: currentCompany.id,
        simpleIngredientId: simpleIngredient.id
      })
    )
      .unwrap()
      .catch(() =>
        showError(`Failed to load nutrient data for ${simpleIngredient.name}.`)
      )
  }, [simpleIngredient.id])

  return (
    <ViewerBottomContentContainer>
      <ViewerBottomContentWrap>
        <ViewerBottomHeaderFooter data-header>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            sx={{ minHeight: 'unset' }}
          >
            <ViewerBottomTab label="Nutrients" />
            <ViewerBottomTab label="Documents" />
            <ViewerBottomTab label="Measurements" />
          </Tabs>
        </ViewerBottomHeaderFooter>

        {activeTab === 0 && (
          <Nutrients ingredientId={simpleIngredient.id} disabled={readOnly} />
        )}

        {activeTab === 1 && (
          <IngredientsDocumentsPanel
            simpleIngredient={simpleIngredient}
            readOnly={readOnly}
          />
        )}

        {activeTab === 2 && (
          <IngredientMeasurementsPanel
            simpleIngredient={simpleIngredient}
            readOnly={readOnly}
          />
        )}
      </ViewerBottomContentWrap>
    </ViewerBottomContentContainer>
  )
}
