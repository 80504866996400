import React from 'react'
import {
  areNutrientRowPropsEqual,
  NutrientRow,
  NutrientRowProps
} from './NutrientRow'
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import isEqual from 'lodash.isequal'

export interface NutrientRowGroupProps {
  nutrientRows: NutrientRowProps[]
  placeholderRows?: number
  header?: boolean
}

const getPlaceholderRows = (rows: number) => {
  return (
    <>
      {Array.from({ length: rows }).map((_, idx) => (
        <TableRow key={`ng-placeholder-${idx}`}>
          <TableCell colSpan={6}>
            <Skeleton variant={'rectangular'} height={'19px'} />
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}

const arePropsEqual = (
  prevProps: NutrientRowGroupProps,
  nextProps: NutrientRowGroupProps
): boolean => {
  return (
    prevProps.nutrientRows.length === nextProps.nutrientRows.length &&
    prevProps.placeholderRows === nextProps.placeholderRows &&
    prevProps.header === nextProps.header &&
    prevProps.nutrientRows.every((nr, idx) => {
      const nextNr = nextProps.nutrientRows[idx]
      return areNutrientRowPropsEqual(nr, nextNr)
    })
  )
}

export const NutrientRowGroup = React.memo(
  ({
    nutrientRows,
    header = false,
    placeholderRows = 5
  }: NutrientRowGroupProps) => {
    return (
      <TableContainer
        sx={{ padding: '8px 0px', width: '100%', overflow: 'hidden' }}
      >
        <Table style={{ tableLayout: 'fixed' }}>
          <colgroup>
            <col style={{ width: '30%', minWidth: '75px' }} />
            <col style={{ width: '10%', minWidth: '150px' }} />
            <col style={{ width: '10%', minWidth: '150px' }} />
            <col style={{ width: '30%', minWidth: '100px' }} />
            <col style={{ width: '10%', minWidth: '150px' }} />
            <col style={{ width: '10%', minWidth: '150px' }} />
          </colgroup>
          {header && (
            <TableHead>
              <TableRow key="nutrient-table-header">
                <TableCell align="left">
                  <Typography color="secondary" variant="body1">
                    Name
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography color="secondary" variant="body1">
                    Amount
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography color="secondary" variant="body1">
                    DV %
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography color="secondary" variant="body1">
                    Name
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography color="secondary" variant="body1">
                    Amount
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography color="secondary" variant="body1">
                    DV %
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {nutrientRows &&
              nutrientRows.map((nr, index) => (
                <NutrientRow
                  key={`nr-${index}`}
                  nutrient={nr.nutrient}
                  level={nr.level}
                  overrides={nr.overrides}
                  disabledName={nr.disabledName}
                />
              ))}
            {nutrientRows.length === 0 && getPlaceholderRows(placeholderRows)}
          </TableBody>
        </Table>
      </TableContainer>
    )
  },
  arePropsEqual
)
