export enum MeasurementState {
  SOLID = 'solid',
  LIQUID = 'liquid'
}

export enum MeasurementType {
  GRAMS = 'grams',
  MILLILTERS = 'milliliters',
  TABLESPOON = 'tablespoon',
  TEASPOON = 'teaspoon',
  CUP = 'cup',
  FLUID_OUNCE = 'fluid_ounce',
  PINT = 'pint',
  QUART = 'quart',
  GALLON = 'gallon',
  POUND = "pound",
  KILOGRAM = "kilogram",
  OUNCE = "ounce"
}

export enum VolumetricMeasurementType {
  MILLILTERS = 'milliliters',
  TABLESPOON = 'tablespoon',
  TEASPOON = 'teaspoon',
  CUP = 'cup',
  FLUID_OUNCE = 'fluid_ounce',
  PINT = 'pint',
  QUART = 'quart',
  GALLON = 'gallon'
}

export enum WeightMeasurementType {
  KILOGRAM = "kilogram",
  GRAMS = 'grams',
  POUND = "pound",
  OUNCE = "ounce"
}

export interface Measurement {
  id: string
  name: string
  unit: string
  state: MeasurementState
  type?: MeasurementType
}
