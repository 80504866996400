import { SelectOption } from 'components/common'
import {
  FormulaNutritionFactLabelAgeGroup,
  FormulaNutritionFactLabelType
} from 'models/FormulaLabel'
import { RegulationName } from 'services/apis/regulation/RegulationApiResponse'

// TODO: This has to come from the backend.
export const Categories: Record<string, SelectOption<string>[]> = {
  [RegulationName.FDA]: Object.values(FormulaNutritionFactLabelAgeGroup).map(
    (value) => {
      return {
        label: value,
        value: value
      }
    }
  ),
  [RegulationName.CANADA]: [
    {
      label: FormulaNutritionFactLabelAgeGroup.ADULT,
      value: FormulaNutritionFactLabelAgeGroup.ADULT
    }
  ]
}

// TODO: This has to come from the backend.
export const Formats: Record<string, SelectOption<string>[]> = {
  [RegulationName.FDA]: Object.values(FormulaNutritionFactLabelType).map(
    (value) => {
      return {
        label: value,
        value: value
      }
    }
  ),
  [RegulationName.CANADA]: [
    {
      label: FormulaNutritionFactLabelType.VERTICAL,
      value: FormulaNutritionFactLabelType.VERTICAL
    },
    {
      label: FormulaNutritionFactLabelType.LINEAR,
      value: FormulaNutritionFactLabelType.LINEAR
    }
  ]
}
