import { Typography } from '@mui/material'
import React from 'react'
import { BoxRow } from '../common'

interface IProps {
  total: number
}

export const CountCell: React.FC<IProps> = ({ total }) => {
  const str = total ? total.toString() : 'None'

  return (
    <BoxRow alignItems="center">
      <Typography sx={{ fontSize: '14px', opacity: str === 'None' ? 0.3 : 1 }}>
        {str}
      </Typography>
    </BoxRow>
  )
}
