import { LoadingAnimation } from 'components/LoadingAnimation/LoadingAmination'
import React from 'react'
import { Document, Page, pdfjs, DocumentProps } from 'react-pdf'
import { useElementSize } from 'usehooks-ts'

import PDFPage from './PDFPage'
import { ZoomButtons } from 'components/ZoomButtons/ZoomButtons'
import { Box } from '@mui/material'

export interface PDFPreviewProps {
  fileSource?: string
}

type VisiblePages = Record<number, boolean>

export const PDFPreview: React.FC<PDFPreviewProps> = ({ fileSource }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const [numPages, setNumPages] = React.useState<number | null>(null)
  const [visiblePages, setVisiblePages] = React.useState<VisiblePages>({})
  const [loading, setLoading] = React.useState<boolean>(true)
  const [scale, setScale] = React.useState<number>(1)
  const MAX_SCALE = 5
  const MIN_SCALE = 0.5

  const [pageWidth, setPageWidth] = React.useState(0)
  const [pageHeight, setPageHeight] = React.useState(0)

  // Hook to measure the size of the container.
  const [ref, { width: wrapperWidth, height: wrapperHeight }] = useElementSize()

  // Determine whether to fit the page horizontally or vertically within the container.
  const fitHorizontal = React.useMemo(() => {
    if (wrapperWidth && wrapperHeight) {
      const wRatio = pageWidth / wrapperWidth
      const hRatio = pageHeight / wrapperHeight
      return wRatio > hRatio
    }
  }, [pageHeight, pageWidth, wrapperWidth, wrapperHeight])

  const handleOnDocumentLoadSuccess = ({
    numPages
  }: {
    numPages: number
  }): void => {
    setNumPages(numPages)
    setLoading(false)
  }

  const handleScaleChange = (newScale: number): void => {
    setScale(newScale)
  }

  const setPageVisibility = React.useCallback(
    (pageNumber: number, isIntersecting: boolean) => {
      setVisiblePages((prevVisiblePages) => ({
        ...prevVisiblePages,
        [pageNumber]: isIntersecting
      }))
    },
    []
  )

  const memoizedFile: DocumentProps['file'] = React.useMemo(() => {
    // Only return an object with a URL if fileSource is defined.
    // Reset the scale.
    setScale(1)
    setLoading(true)
    if (fileSource) {
      return { url: fileSource }
    }
    // If fileSource is undefined, return undefined to match the expected type.
    return undefined
  }, [fileSource])

  return (
    <>
      {fileSource && (
        <Box
          ref={ref}
          style={{
            width: '100%',
            height: '100%',
            overflow: 'auto',
            display: 'flex'
          }}
        >
          {loading && (
            <LoadingAnimation
              sx={{ alignSelf: 'center' }}
              text="Loading preview"
            />
          )}
          <Box style={{ margin: 'auto' }}>
            <Document
              file={memoizedFile}
              onLoadSuccess={handleOnDocumentLoadSuccess}
              loading={''}
              onLoadStart={() => {
                setLoading(true)
              }}
              error={''}
              onLoadError={() => {
                setLoading(false)
              }}
            >
              {numPages &&
                [...Array(numPages)].map((el, index) => (
                  <PDFPage
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    loading={''}
                    onLoadSuccess={({
                      pageNumber,
                      originalWidth,
                      originalHeight
                    }) => {
                      setPageWidth(originalWidth)
                      setPageHeight(originalHeight)
                      setLoading(false)
                    }}
                    scale={scale}
                    width={fitHorizontal ? wrapperWidth : undefined}
                    height={!fitHorizontal ? wrapperHeight : undefined}
                    setPageVisibility={setPageVisibility}
                  />
                ))}
            </Document>
            <ZoomButtons
              sx={{ bottom: '12px', left: '12px' }}
              onZoomInClicked={() => handleScaleChange(scale + 0.5)}
              onZoomOutClicked={() => handleScaleChange(scale - 0.5)}
              onZoomResetClicked={() => handleScaleChange(1)}
              zoomInDisabled={scale + 0.5 > MAX_SCALE}
              zoomOutDisabled={scale - 0.5 < MIN_SCALE}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

export default PDFPreview
