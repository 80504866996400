import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FormulaAllergens } from 'models/Formula'
import { FormulaApi } from 'services/apis/formula/FormulaApi'
import { AddingState, LoadingState, RemovingState } from '../../CommonState'
import {
  AddFormulaAllergensRequest,
  GetFormulaAllergensRequest,
  RemoveFormulaAllergensRequest
} from './FormulaAllergensRequest'

export interface FormulatorAllergensState
  extends LoadingState,
    AddingState,
    RemovingState {
  formulaAllergens: FormulaAllergens
  formulaPreviewAllergens: FormulaAllergens
}

const initialFormulaAllergen: FormulaAllergens = {
  allergens: [],
  suggestedAllergens: [],
  formula: {
    id: '',
    name: ''
  }
}

const initialState: FormulatorAllergensState = {
  loading: false,
  adding: false,
  removing: false,
  error: false,
  formulaAllergens: {
    ...initialFormulaAllergen
  },
  formulaPreviewAllergens: {
    ...initialFormulaAllergen
  }
}

export const getFormulaAllergens = createAsyncThunk(
  'formulas/allergens/get',
  async ({
    companyId,
    formulaId,
    regulationId
  }: GetFormulaAllergensRequest) => {
    console.log('Called with', regulationId)
    return await FormulaApi.getFormulaAllergens(companyId, formulaId, {
      withSuggestions: true,
      regulationId: regulationId || undefined
    })
  }
)

export const getFormulaPreviewAllergens = createAsyncThunk(
  'formulas/allergens/preview/get',
  async ({
    companyId,
    formulaId,
    regulationId
  }: GetFormulaAllergensRequest) => {
    return await FormulaApi.getFormulaAllergens(companyId, formulaId, {
      withSuggestions: true,
      regulationId: regulationId || undefined
    })
  }
)

export const addFormulaAllergens = createAsyncThunk(
  'formulas/allergens/add',
  async ({
    companyId,
    formulaId,
    allergenTypes
  }: AddFormulaAllergensRequest) => {
    return await FormulaApi.addFormulaAllergens(companyId, formulaId, {
      allergenTypes
    })
  }
)

export const removeFormulaAllergens = createAsyncThunk(
  'formulas/allergens/remove',
  async ({
    companyId,
    formulaId,
    allergenTypes
  }: RemoveFormulaAllergensRequest) => {
    return await FormulaApi.removeFormulaAllergens(companyId, formulaId, {
      allergenTypes
    })
  }
)

const formulatorAllergensSlice = createSlice({
  name: 'formulatorAllergensSlice',
  initialState,
  reducers: {
    resetState: (state) => {
      Object.assign(state, initialState)
    }
  },
  extraReducers(builder) {
    builder
      // get
      .addCase(getFormulaAllergens.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(
        getFormulaAllergens.fulfilled,
        (state, action: PayloadAction<FormulaAllergens>) => {
          state.formulaAllergens = action.payload.suggestedAllergens
            ? action.payload
            : { ...action.payload, suggestedAllergens: [] }
          state.loading = false
        }
      )
      .addCase(getFormulaAllergens.rejected, (state) => {
        state.loading = false
        state.error = true
      })
      // get preview
      .addCase(getFormulaPreviewAllergens.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(
        getFormulaPreviewAllergens.fulfilled,
        (state, action: PayloadAction<FormulaAllergens>) => {
          state.formulaPreviewAllergens = action.payload.suggestedAllergens
            ? action.payload
            : { ...action.payload, suggestedAllergens: [] }
          state.loading = false
        }
      )
      .addCase(getFormulaPreviewAllergens.rejected, (state) => {
        state.loading = false
        state.error = true
      })
      // add
      .addCase(addFormulaAllergens.pending, (state) => {
        state.adding = true
        state.error = false
      })
      .addCase(
        addFormulaAllergens.fulfilled,
        (state, action: PayloadAction<FormulaAllergens>) => {
          const formulaAllergens = state.formulaAllergens || {
            formula: action.payload.formula,
            allergens: [],
            suggestedAllergens: []
          }
          formulaAllergens.allergens.push(...action.payload.allergens)
          state.formulaAllergens = formulaAllergens
          state.adding = false
        }
      )
      .addCase(addFormulaAllergens.rejected, (state) => {
        state.loading = false
        state.error = true
      })
      // remove
      .addCase(removeFormulaAllergens.pending, (state) => {
        state.removing = true
        state.error = false
      })
      .addCase(
        removeFormulaAllergens.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          if (state.formulaAllergens) {
            const allergens = state.formulaAllergens.allergens.filter(
              (a) => !action.payload.includes(a.type)
            )
            state.formulaAllergens = { ...state.formulaAllergens, allergens }
          }
          state.removing = false
        }
      )
      .addCase(removeFormulaAllergens.rejected, (state) => {
        state.removing = false
        state.error = true
      })
  }
})

export const { resetState } = formulatorAllergensSlice.actions
export default formulatorAllergensSlice.reducer
