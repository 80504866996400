import { IconButton, Menu, MenuItem, Typography, useTheme } from '@mui/material'
import { BoxRow } from 'components/common'
import {
  MeasurementInput,
  UnstyledInput
} from 'pages/Formulator/FormulatorStyles'
import React, { KeyboardEvent } from 'react'
import { useDebouncedCallback } from 'use-debounce'

interface MeasurementProps {
  id: string
  unit: string
}

export interface IngredientQuantityEditorProps {
  amount: {
    value: number
    percentage: number
    onUpdate?: (amount: number) => void
  }
  measurement: {
    id: string
    unit: string
    onUpdate?: (measurementId: string) => void
    getAvailableMeasurements?: () => Promise<MeasurementProps[]>
  }
  showPercentages?: boolean
  disabled?: boolean
}

export const blockInvalidChar = (e: KeyboardEvent) =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

export const IngredientQuantityEditor: React.FC<
  IngredientQuantityEditorProps
> = ({ amount, measurement, showPercentages = true, disabled = false }) => {
  const theme = useTheme()
  const [unitMenuAnchorEl, setUnitMenuAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const [textAmount, setTextAmount] = React.useState<string>(
    amount.value.toString()
  )

  const [unit, setUnit] = React.useState<string>('g')
  const [availableMeasurements, setAvailableMeasurements] = React.useState<
    MeasurementProps[]
  >([])

  React.useEffect(() => {
    if (measurement) {
      setUnit(measurement.unit)
    }
  }, [measurement])

  const handleUnitMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setUnitMenuAnchorEl(event.currentTarget)
    if (measurement.getAvailableMeasurements) {
      void measurement.getAvailableMeasurements().then((measurements) => {
        setAvailableMeasurements(measurements)
      })
    }
  }

  const handleUnitMenuClose = () => {
    setUnitMenuAnchorEl(null)
  }

  const handleQuantityChange = (value: string) => {
    // Remove leading zeros.
    let newValue = value
    if (value && value[0] !== '.' && value[value.length - 1] !== '0') {
      newValue = parseFloat(value).toString()
    }

    // Limit to 7 digits.
    if (newValue.length > 7) {
      newValue = newValue.slice(0, 7)
    }

    if (newValue != textAmount) {
      setTextAmount(newValue)
      handleQuantityChangeDebounced(newValue)
    }
  }

  const handleQuantityChangeDebounced = useDebouncedCallback(
    (value: string) => {
      if (amount.onUpdate) {
        amount.onUpdate(parseFloat(value) || 0)
      }
    },
    500
  )

  const handleUnitClick = (measurementId: string) => {
    if (measurement.onUpdate) {
      measurement.onUpdate(measurementId)
    }
  }

  const handleOnBlur = (value: string) => {
    if (value === '') {
      setTextAmount('0')
    } else if (value[0] === '.') {
      setTextAmount('0' + value)
    }
  }

  return (
    <BoxRow>
      {showPercentages && (
        <UnstyledInput
          disabled={true}
          value={amount.percentage.toFixed(2)}
          sx={{
            borderRadius: '8px 0px 0px 8px',
            flex: 3
          }}
          endAdornment={
            <Typography
              marginLeft={'0.25em'}
              color={theme.palette.tertiary.main}
            >
              %
            </Typography>
          }
          inputProps={{ style: { textAlign: 'right' } }}
        />
      )}
      <UnstyledInput
        disabled={disabled}
        value={textAmount}
        onChange={(event) => {
          handleQuantityChange(event.target.value)
        }}
        inputProps={{
          style: { textAlign: 'right' },
          step: 0.00001
        }}
        onBlur={(event) => {
          handleOnBlur(event.target.value)
        }}
        type="number"
        sx={{
          borderLeft: showPercentages ? '2px solid rgba(0, 0, 0, 0.06)' : '0px',
          borderRadius: showPercentages ? '0px' : '8px 0px 0px 8px',
          flex: 2
        }}
        onKeyDown={(e) => blockInvalidChar(e)}
      />
      <MeasurementInput
        sx={{
          borderRadius: '0px 8px 8px 0px',
          flex: 1
        }}
      >
        <IconButton
          sx={{ padding: '4px 0px 5px 0px' }}
          disabled={disabled}
          disableRipple
          size="small"
          onClick={(e) => {
            handleUnitMenuClick(e)
          }}
          aria-label="select unit"
        >
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '23px',
              fontweight: '600'
            }}
          >
            {unit}
          </Typography>
        </IconButton>
        <Menu
          anchorEl={unitMenuAnchorEl}
          open={Boolean(unitMenuAnchorEl)}
          onClose={handleUnitMenuClose}
        >
          {availableMeasurements.map((option) => (
            <MenuItem
              key={option.id}
              selected={option.id === measurement?.id}
              onClick={() => {
                handleUnitClick(option.id)
                handleUnitMenuClose()
              }}
            >
              {option.unit}
            </MenuItem>
          ))}
        </Menu>
      </MeasurementInput>
    </BoxRow>
  )
}
