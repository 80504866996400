import { EntrTableFiltersV2 } from 'components/EntrTable/EntrTableFiltersV2'
import { getOptionsFromFilterApiResponse } from 'components/EntrTable/Filter/helpers'
import { FormulaStatus } from 'models/Formula'
import React from 'react'
import { FormulaApi } from 'services/apis/formula/FormulaApi'
import { useAppSelector } from 'state/hooks'
import {
  FilterSearchResponse,
  useFilter
} from '../../../../components/EntrTable/Filter/FiltersHook'
import { QueriesContext } from 'components/EntrTable/Filter/QueriesContext'

export const FormulasFiltersContainer: React.FC = () => {
  const { setSearch, setFilters, filters, search } =
    React.useContext(QueriesContext)
  const { createdBy, status, withArchived } = filters
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)

  // Search bar.
  const handleSearchUpdate = React.useCallback(
    (value: string) => {
      setSearch(value)
    },
    [setSearch]
  )

  const handleCreatedBySearchChange = React.useCallback(
    (search?: string, page = 1): Promise<FilterSearchResponse> => {
      return FormulaApi.getFormulaCreatedByFilter(companyId, {
        searchTerm: search,
        page: page,
        selectedUserIds: createdBy
      }).then((createdByFilter) => {
        return getOptionsFromFilterApiResponse(
          createdByFilter,
          (responseItem) => {
            return {
              label: responseItem.firstName + ' ' + responseItem.lastName,
              value: false,
              id: responseItem.id
            }
          },
          createdBy ?? [],
          Boolean(search)
        )
      })
    },
    [createdBy, companyId]
  )

  const handleStatusSearchChange =
    React.useCallback((): Promise<FilterSearchResponse> => {
      return Promise.resolve().then(() => {
        return {
          options: Object.values(FormulaStatus).map((fs) => ({
            label: fs,
            value: status?.includes(fs),
            id: fs
          })),
          page: 1,
          pages: 1
        } as FilterSearchResponse
      })
    }, [])

  const handleArchiveSearchChange =
    React.useCallback((): Promise<FilterSearchResponse> => {
      return Promise.resolve().then(() => {
        return {
          options: [
            {
              label: 'Archived',
              value: withArchived?.includes('true'),
              id: 'true'
            }
          ],
          page: 1,
          pages: 1
        } as FilterSearchResponse
      })
    }, [])

  const createdByFilter = useFilter({
    name: 'Created By',
    onSearchChange: handleCreatedBySearchChange,
    localSearch: false,
    onChange: (values) => setFilters('createdBy', values)
  })

  const statusFilter = useFilter({
    name: 'Status',
    onSearchChange: handleStatusSearchChange,
    localSearch: true,
    onChange: (values) => setFilters('status', values)
  })

  const archivedFilter = useFilter({
    name: 'Archived',
    onSearchChange: handleArchiveSearchChange,
    localSearch: true,
    onChange: (values) => setFilters('withArchived', values)
  })

  return (
    <EntrTableFiltersV2
      search={{
        placeholder: 'Search all formulas',
        onUpdate: handleSearchUpdate,
        initialSearch: search
      }}
      filters={[createdByFilter, statusFilter, archivedFilter]}
    />
  )
}
