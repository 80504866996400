import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  CreateTargetRequest,
  DeleteTargetRequest,
  GetTargetRequest,
  GetTargetsRequest,
  UpdateTargetRequest
} from '../TargetRequest'
import { TargetsState } from '../TargetsSlice'
import { TargetApi } from 'services/apis/target/TargetApi'
import { denormalizeTarget } from '../helpers'
import { Nutrient } from 'models/Nutrient'
import { NutrientsApi } from 'services/apis/nutrient/NutrientApi'

export const createTarget = createAsyncThunk(
  'targets/create',
  async ({ companyId, name, rootOperation, regulationId }: CreateTargetRequest) => {
    return await TargetApi.createTarget(companyId, {
      name,
      operation: rootOperation,
      regulationId
    })
  }
)

export const getTargets = createAsyncThunk(
  'targets/all/get',
  async ({ companyId, withPublic, categoriesIds }: GetTargetsRequest) => {
    return await TargetApi.getTargets(companyId, withPublic, categoriesIds)
  }
)

export const getTarget = createAsyncThunk(
  'targets/get',
  async ({ companyId, targetId }: GetTargetRequest) => {
    return await TargetApi.getTarget(companyId, targetId)
  }
)

export const updateTarget = createAsyncThunk(
  'targets/update',
  async ({ companyId, targetId }: UpdateTargetRequest, thunkAPI) => {
    const state = thunkAPI.getState() as { targets: TargetsState }
    const target = denormalizeTarget(
      state.targets.activeTarget,
      state.targets.activeOperations
    )

    return await TargetApi.updateTarget(companyId, targetId, {
      name: target.name,
      operation: target.operation,
      regulationId: target.regulation?.id
    })
  }
)

export const deleteTarget = createAsyncThunk(
  'targets/delete',
  async ({ companyId, targetId }: DeleteTargetRequest) => {
    return await TargetApi.deleteTarget(companyId, targetId)
  }
)

export const getAvailableNutrients = createAsyncThunk(
  'targets/available_nutrients/get',
  async (): Promise<Nutrient[]> => {
    return await NutrientsApi.getNutrients({})
  }
)
