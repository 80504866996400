import { styled, Typography } from '@mui/material'

export const HighlightedSearchNameTypography = styled(Typography)`
  font-size: 16px;
  text-wrap: nowrap;
  flex: 7;
`

export const FriendlyIdTypography = styled(Typography)`
  text-align: 'right';
  align-items: 'center';
`

export const SupplierNameTypography = styled(Typography)`
  text-align: 'right';
  align-items: 'center';
  text-wrap: 'nowrap';
  text-overflow: 'elipsis';
`
