import { InputAdornment, TextField } from '@mui/material'
import { InputWithAdornment } from 'components/InputWithAdornment/InputWithAdornment'
import { MenuButton } from 'components/MenuButton/MenuButton'
import { ModalContext } from 'components/Modal/ModalContext'
import { BoxRow } from 'components/common'
import { MeasurementType } from 'models/Measurement'
import { SimpleIngredientCost } from 'models/SimpleIngredient'
import React from 'react'
import { useAppSelector } from 'state/hooks'

interface IngredientCostRowProps {
  cost: SimpleIngredientCost
  handleCostUpdate: (
    id: string,
    values: {
      cost?: number
      unit?: string
      note?: string
    }
  ) => void
  handleDeleteCost: (id: string) => void
  disabled: boolean
}

export const IngredientCostRow: React.FC<IngredientCostRowProps> = ({
  cost,
  handleCostUpdate,
  handleDeleteCost,
  disabled
}) => {
  const [localNote, setLocalNote] = React.useState(cost.note || '')
  const measurements = useAppSelector(
    (state) => state.simpleIngredientMeasurementSlice.measurements
  )
  const { showConfirmationModal } = React.useContext(ModalContext)

  const handleDelete = () => {
    showConfirmationModal({
      title: 'Delete Cost',
      message: 'Are you sure you want to delete this cost?',
      yesText: 'Delete',
      noText: 'Cancel',
      onYesClicked: () => handleDeleteCost(cost.id)
    })
  }
  return (
    <BoxRow style={{ width: '100%', gap: '8px' }}>
      <InputWithAdornment
        initialValue={cost.cost || 0}
        initialUnit={cost.measurement.unit}
        unitDisplayFormatter={(unit) => `per ${unit}.`}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        unitOptions={
          measurements
            ? measurements
                .filter((measurement) =>
                  [MeasurementType.POUND, MeasurementType.KILOGRAM].includes(
                    measurement.type as MeasurementType
                  )
                )
                .map((measurement) => measurement.unit)
            : []
        }
        getValueAndUnit={(value, unit) =>
          handleCostUpdate(cost.id, { cost: value, unit })
        }
        disabled={disabled}
        containerStyle={{ width: '30%' }}
      />
      <TextField
        placeholder="Cost note"
        value={localNote}
        onChange={(e) => {
          setLocalNote(e.target.value)
          handleCostUpdate(cost.id, { note: e.target.value })
        }}
        disabled={disabled}
        style={{ width: '70%' }}
      />
      <MenuButton
        buttonStyle={{
          height: '100%'
        }}
        menuItems={[
          {
            itemTitle: 'Delete',
            itemCallback: () => handleDelete(),
            itemStyle: {
              color: 'var(--error, #D03838)'
            }
          }
        ]}
        showButton={!disabled}
      />
    </BoxRow>
  )
}
