import {
  Nutrient,
  NutrientClassification,
  NutrientRequirement,
  NutrientType,
  NutrientUnit
} from 'models/Nutrient'
import {
  NutrientApiResponse,
  NutrientClassificationApiResponse,
  NutrientRequirementApiResponse,
  NutrientTypeApiResponse,
  NutrientUnitApiResponse
} from './NutrientApiResponse'

const toNutrientClassification = (
  response: NutrientClassificationApiResponse
): NutrientClassification => {
  switch (response) {
    case NutrientClassificationApiResponse.MICRONUTRIENT:
      return NutrientClassification.MICRONUTRIENT
    case NutrientClassificationApiResponse.MACRONUTRIENT:
      return NutrientClassification.MACRONUTRIENT
    case NutrientClassificationApiResponse.OTHER:
      return NutrientClassification.OTHER
  }
}

const toNutrientRequirement = (
  response: NutrientRequirementApiResponse
): NutrientRequirement => {
  switch (response) {
    case NutrientRequirementApiResponse.OPTIONAL:
      return NutrientRequirement.OPTIONAL
    case NutrientRequirementApiResponse.MANDATORY:
      return NutrientRequirement.MANDATORY
    case NutrientRequirementApiResponse.OTHER:
      return NutrientRequirement.OTHER
  }
}

export const toNutrientUnit = (
  response: NutrientUnitApiResponse
): NutrientUnit => {
  switch (response) {
    case NutrientUnitApiResponse.KILOCALORIE:
      return NutrientUnit.KILOCALORIE
    case NutrientUnitApiResponse.GRAM:
      return NutrientUnit.GRAM
    case NutrientUnitApiResponse.POWER_OF_HYDROGEN:
      return NutrientUnit.POWER_OF_HYDROGEN
    case NutrientUnitApiResponse.SPECIFIC_GRAVITY:
      return NutrientUnit.SPECIFIC_GRAVITY
    case NutrientUnitApiResponse.MILLIGRAM:
      return NutrientUnit.MILLIGRAM
    case NutrientUnitApiResponse.KILOJOULE:
      return NutrientUnit.KILOJOULE
    case NutrientUnitApiResponse.MICROGRAM:
      return NutrientUnit.MICROGRAM
    case NutrientUnitApiResponse.INTERNATIONAL_UNIT:
      return NutrientUnit.INTERNATIONAL_UNIT
    case NutrientUnitApiResponse.MICROGRAM_RETINOL_EQUIVALENTS:
      return NutrientUnit.MICROGRAM_RETINOL_EQUIVALENTS
    case NutrientUnitApiResponse.MILLIGRAM_ALPHA_TOCOPHEROL_EQUIVALENTS:
      return NutrientUnit.MILLIGRAM_ALPHA_TOCOPHEROL_EQUIVALENTS
    case NutrientUnitApiResponse.MICROMOLE_TROLOX_EQUIVALENTS:
      return NutrientUnit.MICROMOLE_TROLOX_EQUIVALENTS
    case NutrientUnitApiResponse.MILLIGRAM_GALLIC_ACID_EQUIVALENTS:
      return NutrientUnit.MILLIGRAM_GALLIC_ACID_EQUIVALENTS
    case NutrientUnitApiResponse.MICROGRAM_MCG:
      return NutrientUnit.MICROGRAM_MCG
  }
}

export const fromNutrientUnit = (
  unit: NutrientUnit
): NutrientUnitApiResponse => {
  switch (unit) {
    case NutrientUnit.KILOCALORIE:
      return NutrientUnitApiResponse.KILOCALORIE
    case NutrientUnit.GRAM:
      return NutrientUnitApiResponse.GRAM
    case NutrientUnit.POWER_OF_HYDROGEN:
      return NutrientUnitApiResponse.POWER_OF_HYDROGEN
    case NutrientUnit.SPECIFIC_GRAVITY:
      return NutrientUnitApiResponse.SPECIFIC_GRAVITY
    case NutrientUnit.MILLIGRAM:
      return NutrientUnitApiResponse.MILLIGRAM
    case NutrientUnit.KILOJOULE:
      return NutrientUnitApiResponse.KILOJOULE
    case NutrientUnit.MICROGRAM:
      return NutrientUnitApiResponse.MICROGRAM
    case NutrientUnit.INTERNATIONAL_UNIT:
      return NutrientUnitApiResponse.INTERNATIONAL_UNIT
    case NutrientUnit.MICROGRAM_RETINOL_EQUIVALENTS:
      return NutrientUnitApiResponse.MICROGRAM_RETINOL_EQUIVALENTS
    case NutrientUnit.MILLIGRAM_ALPHA_TOCOPHEROL_EQUIVALENTS:
      return NutrientUnitApiResponse.MILLIGRAM_ALPHA_TOCOPHEROL_EQUIVALENTS
    case NutrientUnit.MICROMOLE_TROLOX_EQUIVALENTS:
      return NutrientUnitApiResponse.MICROMOLE_TROLOX_EQUIVALENTS
    case NutrientUnit.MILLIGRAM_GALLIC_ACID_EQUIVALENTS:
      return NutrientUnitApiResponse.MILLIGRAM_GALLIC_ACID_EQUIVALENTS
    case NutrientUnit.MICROGRAM_MCG:
      return NutrientUnitApiResponse.MICROGRAM_MCG
  }
}

export const toNutrientType = (
  response: NutrientTypeApiResponse
): NutrientType => {
  switch (response) {
    case NutrientTypeApiResponse.ADDED_SUGAR:
      return NutrientType.ADDED_SUGAR
    case NutrientTypeApiResponse.ALCOHOL:
      return NutrientType.ALCOHOL
    case NutrientTypeApiResponse.ASH:
      return NutrientType.ASH
    case NutrientTypeApiResponse.BETA_CAROTENE:
      return NutrientType.BETA_CAROTENE
    case NutrientTypeApiResponse.BIOTIN:
      return NutrientType.BIOTIN
    case NutrientTypeApiResponse.CAFFEINE:
      return NutrientType.CAFFEINE
    case NutrientTypeApiResponse.CALCIUM:
      return NutrientType.CALCIUM
    case NutrientTypeApiResponse.CALORIES:
      return NutrientType.CALORIES
    case NutrientTypeApiResponse.CARBOHYDRATES:
      return NutrientType.CARBOHYDRATES
    case NutrientTypeApiResponse.CAROTENOID_RE:
      return NutrientType.CAROTENOID_RE
    case NutrientTypeApiResponse.CHOLESTEROL:
      return NutrientType.CHOLESTEROL
    case NutrientTypeApiResponse.CHOLINE:
      return NutrientType.CHOLINE
    case NutrientTypeApiResponse.CHROMIUM:
      return NutrientType.CHROMIUM
    case NutrientTypeApiResponse.COPPER:
      return NutrientType.COPPER
    case NutrientTypeApiResponse.DISACCHARIDES:
      return NutrientType.DISACCHARIDES
    case NutrientTypeApiResponse.TOTAL_FAT:
      return NutrientType.TOTAL_FAT
    case NutrientTypeApiResponse.FLUORIDE:
      return NutrientType.FLUORIDE
    case NutrientTypeApiResponse.FOLATE:
      return NutrientType.FOLATE
    case NutrientTypeApiResponse.FOLATE_DFE:
      return NutrientType.FOLATE_DFE
    case NutrientTypeApiResponse.FOLATE_FOOD:
      return NutrientType.FOLATE_FOOD
    case NutrientTypeApiResponse.FOLIC_ACID:
      return NutrientType.FOLIC_ACID
    case NutrientTypeApiResponse.IODINE:
      return NutrientType.IODINE
    case NutrientTypeApiResponse.IRON:
      return NutrientType.IRON
    case NutrientTypeApiResponse.MAGNESIUM:
      return NutrientType.MAGNESIUM
    case NutrientTypeApiResponse.MANGANESE:
      return NutrientType.MANGANESE
    case NutrientTypeApiResponse.MOLYBDENUM:
      return NutrientType.MOLYBDENUM
    case NutrientTypeApiResponse.MONO_FAT:
      return NutrientType.MONO_FAT
    case NutrientTypeApiResponse.MONOSACCHARIDES:
      return NutrientType.MONOSACCHARIDES
    case NutrientTypeApiResponse.OMEGA_3_FATTY_ACID:
      return NutrientType.OMEGA_3_FATTY_ACID
    case NutrientTypeApiResponse.OMEGA_6_FATTY_ACID:
      return NutrientType.OMEGA_6_FATTY_ACID
    case NutrientTypeApiResponse.OTHER_CARBS:
      return NutrientType.OTHER_CARBS
    case NutrientTypeApiResponse.PANTOTHENIC_ACID:
      return NutrientType.PANTOTHENIC_ACID
    case NutrientTypeApiResponse.PHOSPHORUS:
      return NutrientType.PHOSPHORUS
    case NutrientTypeApiResponse.POLY_FAT:
      return NutrientType.POLY_FAT
    case NutrientTypeApiResponse.POTASSIUM:
      return NutrientType.POTASSIUM
    case NutrientTypeApiResponse.PROTEIN:
      return NutrientType.PROTEIN
    case NutrientTypeApiResponse.RETINOL_RE:
      return NutrientType.RETINOL_RE
    case NutrientTypeApiResponse.SATURATED_FAT:
      return NutrientType.SATURATED_FAT
    case NutrientTypeApiResponse.SELENIUM:
      return NutrientType.SELENIUM
    case NutrientTypeApiResponse.SODIUM:
      return NutrientType.SODIUM
    case NutrientTypeApiResponse.TOTAL_DIETARY_FIBER:
      return NutrientType.TOTAL_DIETARY_FIBER
    case NutrientTypeApiResponse.TOTAL_SOLUBLE_FIBER:
      return NutrientType.TOTAL_SOLUBLE_FIBER
    case NutrientTypeApiResponse.TOTAL_SUGARS:
      return NutrientType.TOTAL_SUGARS
    case NutrientTypeApiResponse.TRANS_FATTY_ACID:
      return NutrientType.TRANS_FATTY_ACID
    case NutrientTypeApiResponse.VITAMIN_A_IU:
      return NutrientType.VITAMIN_A_IU
    case NutrientTypeApiResponse.VITAMIN_A_RE:
      return NutrientType.VITAMIN_A_RE
    case NutrientTypeApiResponse.VITAMIN_B1_THIAMIN:
      return NutrientType.VITAMIN_B1_THIAMIN
    case NutrientTypeApiResponse.VITAMIN_B12:
      return NutrientType.VITAMIN_B12
    case NutrientTypeApiResponse.VITAMIN_B2_RIBOFLAVIN:
      return NutrientType.VITAMIN_B2_RIBOFLAVIN
    case NutrientTypeApiResponse.VITAMIN_B3_NIACIN:
      return NutrientType.VITAMIN_B3_NIACIN
    case NutrientTypeApiResponse.VITAMIN_B3_NIACIN_EQUIV:
      return NutrientType.VITAMIN_B3_NIACIN_EQUIV
    case NutrientTypeApiResponse.VITAMIN_B6:
      return NutrientType.VITAMIN_B6
    case NutrientTypeApiResponse.VITAMIN_C:
      return NutrientType.VITAMIN_C
    case NutrientTypeApiResponse.VITAMIN_D_IU:
      return NutrientType.VITAMIN_D_IU
    case NutrientTypeApiResponse.VITAMIN_D:
      return NutrientType.VITAMIN_D
    case NutrientTypeApiResponse.VITAMIN_E_ALPHA_TOCO:
      return NutrientType.VITAMIN_E_ALPHA_TOCO
    case NutrientTypeApiResponse.VITAMIN_E:
      return NutrientType.VITAMIN_E
    case NutrientTypeApiResponse.VITAMIN_E_IU:
      return NutrientType.VITAMIN_E_IU
    case NutrientTypeApiResponse.VITAMIN_K:
      return NutrientType.VITAMIN_K
    case NutrientTypeApiResponse.WATER:
      return NutrientType.WATER
    case NutrientTypeApiResponse.ZINC:
      return NutrientType.ZINC
    case NutrientTypeApiResponse.INSOLUBLE_FIBER:
      return NutrientType.INSOLUBLE_FIBER
    case NutrientTypeApiResponse.VITAMIN_A_RAE:
      return NutrientType.VITAMIN_A_RAE
    case NutrientTypeApiResponse.CHLORIDE:
      return NutrientType.CHLORIDE
    case NutrientTypeApiResponse.SUGAR_ALCOHOL:
      return NutrientType.SUGAR_ALCOHOL
    case NutrientTypeApiResponse.STARCH:
      return NutrientType.STARCH
  }
}

export const fromNutrientType = (
  type: NutrientType
): NutrientTypeApiResponse => {
  switch (type) {
    case NutrientType.ADDED_SUGAR:
      return NutrientTypeApiResponse.ADDED_SUGAR
    case NutrientType.ALCOHOL:
      return NutrientTypeApiResponse.ALCOHOL
    case NutrientType.ASH:
      return NutrientTypeApiResponse.ASH
    case NutrientType.BETA_CAROTENE:
      return NutrientTypeApiResponse.BETA_CAROTENE
    case NutrientType.BIOTIN:
      return NutrientTypeApiResponse.BIOTIN
    case NutrientType.CAFFEINE:
      return NutrientTypeApiResponse.CAFFEINE
    case NutrientType.CALCIUM:
      return NutrientTypeApiResponse.CALCIUM
    case NutrientType.CALORIES:
      return NutrientTypeApiResponse.CALORIES
    case NutrientType.CARBOHYDRATES:
      return NutrientTypeApiResponse.CARBOHYDRATES
    case NutrientType.CAROTENOID_RE:
      return NutrientTypeApiResponse.CAROTENOID_RE
    case NutrientType.CHOLESTEROL:
      return NutrientTypeApiResponse.CHOLESTEROL
    case NutrientType.CHOLINE:
      return NutrientTypeApiResponse.CHOLINE
    case NutrientType.CHROMIUM:
      return NutrientTypeApiResponse.CHROMIUM
    case NutrientType.COPPER:
      return NutrientTypeApiResponse.COPPER
    case NutrientType.DISACCHARIDES:
      return NutrientTypeApiResponse.DISACCHARIDES
    case NutrientType.TOTAL_FAT:
      return NutrientTypeApiResponse.TOTAL_FAT
    case NutrientType.FLUORIDE:
      return NutrientTypeApiResponse.FLUORIDE
    case NutrientType.FOLATE:
      return NutrientTypeApiResponse.FOLATE
    case NutrientType.FOLATE_DFE:
      return NutrientTypeApiResponse.FOLATE_DFE
    case NutrientType.FOLATE_FOOD:
      return NutrientTypeApiResponse.FOLATE_FOOD
    case NutrientType.FOLIC_ACID:
      return NutrientTypeApiResponse.FOLIC_ACID
    case NutrientType.IODINE:
      return NutrientTypeApiResponse.IODINE
    case NutrientType.IRON:
      return NutrientTypeApiResponse.IRON
    case NutrientType.MAGNESIUM:
      return NutrientTypeApiResponse.MAGNESIUM
    case NutrientType.MANGANESE:
      return NutrientTypeApiResponse.MANGANESE
    case NutrientType.MOLYBDENUM:
      return NutrientTypeApiResponse.MOLYBDENUM
    case NutrientType.MONO_FAT:
      return NutrientTypeApiResponse.MONO_FAT
    case NutrientType.MONOSACCHARIDES:
      return NutrientTypeApiResponse.MONOSACCHARIDES
    case NutrientType.OMEGA_3_FATTY_ACID:
      return NutrientTypeApiResponse.OMEGA_3_FATTY_ACID
    case NutrientType.OMEGA_6_FATTY_ACID:
      return NutrientTypeApiResponse.OMEGA_6_FATTY_ACID
    case NutrientType.OTHER_CARBS:
      return NutrientTypeApiResponse.OTHER_CARBS
    case NutrientType.PANTOTHENIC_ACID:
      return NutrientTypeApiResponse.PANTOTHENIC_ACID
    case NutrientType.PHOSPHORUS:
      return NutrientTypeApiResponse.PHOSPHORUS
    case NutrientType.POLY_FAT:
      return NutrientTypeApiResponse.POLY_FAT
    case NutrientType.POTASSIUM:
      return NutrientTypeApiResponse.POTASSIUM
    case NutrientType.PROTEIN:
      return NutrientTypeApiResponse.PROTEIN
    case NutrientType.RETINOL_RE:
      return NutrientTypeApiResponse.RETINOL_RE
    case NutrientType.SATURATED_FAT:
      return NutrientTypeApiResponse.SATURATED_FAT
    case NutrientType.SELENIUM:
      return NutrientTypeApiResponse.SELENIUM
    case NutrientType.SODIUM:
      return NutrientTypeApiResponse.SODIUM
    case NutrientType.TOTAL_DIETARY_FIBER:
      return NutrientTypeApiResponse.TOTAL_DIETARY_FIBER
    case NutrientType.TOTAL_SOLUBLE_FIBER:
      return NutrientTypeApiResponse.TOTAL_SOLUBLE_FIBER
    case NutrientType.TOTAL_SUGARS:
      return NutrientTypeApiResponse.TOTAL_SUGARS
    case NutrientType.TRANS_FATTY_ACID:
      return NutrientTypeApiResponse.TRANS_FATTY_ACID
    case NutrientType.VITAMIN_A_IU:
      return NutrientTypeApiResponse.VITAMIN_A_IU
    case NutrientType.VITAMIN_A_RE:
      return NutrientTypeApiResponse.VITAMIN_A_RE
    case NutrientType.VITAMIN_B1_THIAMIN:
      return NutrientTypeApiResponse.VITAMIN_B1_THIAMIN
    case NutrientType.VITAMIN_B12:
      return NutrientTypeApiResponse.VITAMIN_B12
    case NutrientType.VITAMIN_B2_RIBOFLAVIN:
      return NutrientTypeApiResponse.VITAMIN_B2_RIBOFLAVIN
    case NutrientType.VITAMIN_B3_NIACIN:
      return NutrientTypeApiResponse.VITAMIN_B3_NIACIN
    case NutrientType.VITAMIN_B3_NIACIN_EQUIV:
      return NutrientTypeApiResponse.VITAMIN_B3_NIACIN_EQUIV
    case NutrientType.VITAMIN_B6:
      return NutrientTypeApiResponse.VITAMIN_B6
    case NutrientType.VITAMIN_C:
      return NutrientTypeApiResponse.VITAMIN_C
    case NutrientType.VITAMIN_D_IU:
      return NutrientTypeApiResponse.VITAMIN_D_IU
    case NutrientType.VITAMIN_D:
      return NutrientTypeApiResponse.VITAMIN_D
    case NutrientType.VITAMIN_E_ALPHA_TOCO:
      return NutrientTypeApiResponse.VITAMIN_E_ALPHA_TOCO
    case NutrientType.VITAMIN_E:
      return NutrientTypeApiResponse.VITAMIN_E
    case NutrientType.VITAMIN_E_IU:
      return NutrientTypeApiResponse.VITAMIN_E_IU
    case NutrientType.VITAMIN_K:
      return NutrientTypeApiResponse.VITAMIN_K
    case NutrientType.WATER:
      return NutrientTypeApiResponse.WATER
    case NutrientType.ZINC:
      return NutrientTypeApiResponse.ZINC
    case NutrientType.INSOLUBLE_FIBER:
      return NutrientTypeApiResponse.INSOLUBLE_FIBER
    case NutrientType.VITAMIN_A_RAE:
      return NutrientTypeApiResponse.VITAMIN_A_RAE
    case NutrientType.CHLORIDE:
      return NutrientTypeApiResponse.CHLORIDE
    case NutrientType.SUGAR_ALCOHOL:
      return NutrientTypeApiResponse.SUGAR_ALCOHOL
    case NutrientType.STARCH:
      return NutrientTypeApiResponse.STARCH
  }
}

export const toNutrient = (response: NutrientApiResponse): Nutrient => {
  return {
    ...response,
    id: response.id,
    type: toNutrientType(response.type),
    classification: toNutrientClassification(response.classification),
    unit: toNutrientUnit(response.unit),
    requirement: toNutrientRequirement(response.requirement)
  }
}

export const toNutrients = (response: NutrientApiResponse[]): Nutrient[] => {
  return response.map((nutrient) => toNutrient(nutrient))
}
