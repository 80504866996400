import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AppRoutes } from 'pages/AppRoutes'
import { Auth0ProviderWithNavigate } from 'core/AuthProviderWithNavigate'
import { ModalProvider } from 'components/Modal/ModalContext'
import { SnackbarProvider } from 'components/Snackbar/SnackbarContext'
import { AbilityProvider } from 'core/Permissions/AbilityContext'
import { AnalyticsProvider } from 'core/Analytics/AnalyticsContext'
import { LoadingStateProvider } from 'components/LoadingUI/LoadingUIContext'
import { FilePreviewProvider } from 'components/FilePreview/FilePreviewContext'

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <AnalyticsProvider>
          <SnackbarProvider>
            <LoadingStateProvider>
              <AbilityProvider>
                <ModalProvider>
                  <FilePreviewProvider>
                    <AppRoutes />
                  </FilePreviewProvider>
                </ModalProvider>
              </AbilityProvider>
            </LoadingStateProvider>
          </SnackbarProvider>
        </AnalyticsProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  )
}
