import styled from 'styled-components'
import { NutritionLabelBox } from '../components/NutritionLabelStyles'

export const NutritionLabelLinearBox = styled(NutritionLabelBox)``

export const NutritionLabelLinearContainer = styled.div`
  display: inline-block;
  align-items: baseline;
`

export const NutritionLabelLinearFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`
