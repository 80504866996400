import { AxiosProgressEvent } from 'axios'
import { SupplierAllergen } from 'models/Allergen'
import {
  SimpleSupplier,
  DetailedSupplierPaginated,
  SupplierSimpleIngredient
} from 'models/Supplier'
import { SupplierTag } from 'models/Tags'
import { api } from '../config/AxiosConfig'
import { toSupplierSimpleIngredient } from '../simple_ingredient/SimpleIngredientApiMapper'
import { SupplierSimpleIngredientApiResponse } from '../simple_ingredient/SimpleIngredientApiResponse'
import {
  toSimpleAllergen,
  toSimpleAllergens,
  toDetailedSupplierPaginated,
  toSupplierTag,
  toSimpleSupplier
} from './SupplierApiMapper'
import {
  CreateSupplierApiData,
  GetSupplierApiParams,
  UpdateSupplierApiData
} from './SupplierApiRequest'
import {
  DetailedSupplierApiPaginatedResponse,
  SupplierTagApiResponse,
  SimpleSupplierApiResponse,
  SupplierAllergenApiResponse
} from './SupplierApiResponse'
import { FileApi } from '../document/DocumentApi'
import { DocumentApiResponse } from '../document/DocumentApiResponse'
import { BasicDocument } from 'models/Document'
import { toBasicDocuments } from '../document/DocumentApiMapper'

export const SupplierApi = {
  getSuppliers: (
    companyId: string,
    params: GetSupplierApiParams
  ): Promise<DetailedSupplierPaginated> => {
    return api
      .get<DetailedSupplierApiPaginatedResponse>(
        `/companies/${companyId}/suppliers`,
        {
          params,
          paramsSerializer: {
            indexes: null
          }
        }
      )
      .then((res) => {
        return toDetailedSupplierPaginated(res.data)
      })
  },

  createSupplier: (
    companyId: string,
    data: CreateSupplierApiData
  ): Promise<SimpleSupplier> => {
    return api
      .post<SimpleSupplierApiResponse>(
        `/companies/${companyId}/suppliers`,
        data
      )
      .then((res) => {
        return toSimpleSupplier(res.data)
      })
  },

  deleteSupplier: (companyId: string, supplierId: string): Promise<string> => {
    return api
      .delete(`/companies/${companyId}/suppliers/${supplierId}`)
      .then(() => supplierId)
  },

  updateSupplier: (
    companyId: string,
    supplierId: string,
    data: UpdateSupplierApiData
  ): Promise<SimpleSupplier> => {
    return api
      .put<SimpleSupplierApiResponse>(
        `/companies/${companyId}/supplier/${supplierId}`,
        data
      )
      .then((res) => {
        return toSimpleSupplier(res.data)
      })
  },

  addTag: (
    companyId: string,
    supplierId: string,
    name: string
  ): Promise<SupplierTag> => {
    return api
      .post<SupplierTagApiResponse>(
        `/companies/${companyId}/suppliers/${supplierId}/tags`,
        {
          name
        }
      )
      .then((res) => {
        return toSupplierTag(res.data)
      })
  },

  addAllergen: (
    companyId: string,
    supplierId: string,
    allergenType: string
  ): Promise<SupplierAllergen> => {
    return api
      .post<SupplierAllergenApiResponse>(
        `/companies/${companyId}/suppliers/${supplierId}/allergens`,
        {
          allergenType
        }
      )
      .then((res) => {
        return toSimpleAllergen(res.data)
      })
  },

  getAllergens: (
    companyId: string,
    supplierId: string
  ): Promise<SupplierAllergen[]> => {
    return api
      .get<SupplierAllergenApiResponse[]>(
        `/companies/${companyId}/suppliers/${supplierId}/allergens`
      )
      .then((res) => {
        return toSimpleAllergens(res.data)
      })
  },

  addIngredientToSupplier: (
    companyId: string,
    supplierId: string,
    simpleIngredientId: string
  ): Promise<SupplierSimpleIngredient> => {
    return api
      .post<SupplierSimpleIngredientApiResponse>(
        `/companies/${companyId}/suppliers/${supplierId}/simple_ingredients/${simpleIngredientId}`
      )
      .then((res) => {
        return toSupplierSimpleIngredient(res.data)
      })
  },

  deleteIngredientFromSupplier: (
    companyId: string,
    supplierId: string,
    simpleIngredientId: string
  ): Promise<string> => {
    return api
      .delete(
        `/companies/${companyId}/suppliers/${supplierId}/simple_ingredients/${simpleIngredientId}`
      )
      .then(() => {
        return supplierId
      })
  },

  uploadSupplierDocument: async (
    companyId: string,
    supplierId: string,
    file: File,
    uploadProgressListener?: (
      progressEvent: AxiosProgressEvent
    ) => ProgressEvent | void
  ): Promise<string> => {
    return api
      .post<string>(
        `/companies/${companyId}/suppliers/${supplierId}/documents/`,
        { fileName: file.name, contentType: file.type }
      )
      .then((res) => {
        return FileApi.uploadFile(file, res.data, uploadProgressListener)
      })
  },

  getSupplierDocument: async (
    companyId: string,
    supplierId: string,
    fileName: string,
    download = true
  ): Promise<string> => {
    const encodedFileName = encodeURIComponent(fileName)

    return api
      .get<string>(
        `/companies/${companyId}/suppliers/${supplierId}/documents/${encodedFileName}`
      )
      .then((res) => {
        return FileApi.downloadFile(res.data, fileName, download)
      })
  },

  deleteSupplierDocument: (
    companyId: string,
    supplierId: string,
    fileName: string
  ): Promise<string> => {
    return api
      .delete(`/companies/${companyId}/suppliers/${supplierId}/documents`, {
        params: { fileName }
      })
      .then(() => fileName)
  },

  getSupplierDocuments: (
    companyId: string,
    supplierId: string
  ): Promise<BasicDocument[]> => {
    return api
      .get<DocumentApiResponse[]>(
        `/companies/${companyId}/suppliers/${supplierId}/documents`
      )
      .then((res) => {
        return toBasicDocuments(res.data)
      })
  }
}
