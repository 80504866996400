import { Typography } from '@mui/material'
import { infoMessages } from 'common/constants'
import {
  BoxColumnBasic,
  BoxRow,
  BoxRowBasic,
  SwitchStyled
} from 'components/common'
import { InfoBubble } from 'components/InfoBubble/InfoBubble'
import { InputFieldLabel } from 'components/InputFieldLabel/InputFieldLabel'
import { MarkdownRenderer } from 'components/MarkdownRenderer/MarkdownRenderer'
import {
  IngredientSearch,
  IngredientSearchProps
} from 'pages/Formulator/components/FormulatorIngredients/components/Ingredients/components/search/IngredientSearch'
import React from 'react'
import { SelectTargetSwitchControlLabel } from '../../../TargetsStyles'
import { RegulationName } from 'services/apis/regulation/RegulationApiResponse'

export interface FormulaRegulationTargetParametersProps {
  regulationId: string
  regulationName: RegulationName
  racc: {
    value?: number
    onChange: (value: number) => void
  }
  mealMainDish: {
    value?: boolean
    onChange: (value: boolean) => void
  }
  referenceIngredient: {
    searchProps: IngredientSearchProps
    ingredient?: {
      id: string
      name: string
    }
  }
}

export interface TargetParametersProps {
  formulaRegulations: FormulaRegulationTargetParametersProps[]
}

export const TargetParameters: React.FC<TargetParametersProps> = ({
  formulaRegulations
}) => {
  const blockInvalidChar = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      return
    }

    return (
      (['+', '-', '='].includes(e.key) || /[a-zA-Z]/.test(e.key)) &&
      e.preventDefault()
    )
  }

  return (
    <BoxColumnBasic sx={{ gap: '32px' }}>
      {formulaRegulations.map((formulaRegulation) => (
        <BoxColumnBasic
          sx={{ gap: '10px' }}
          key={formulaRegulation.regulationId}
        >
          <Typography variant="subtitle1">
            {formulaRegulation.regulationName} settings
          </Typography>
          <BoxRow sx={{ justifyContent: 'space-between', gap: '10px' }}>
            <InputFieldLabel
              label="Formula RACC in grams"
              labelIcon={
                formulaRegulation.regulationName && (
                  <InfoBubble
                    text={
                      <MarkdownRenderer
                        markdownText={infoMessages.formula.targets.RACC[formulaRegulation.regulationName]}
                      />
                    }
                  />
                )
              }
              name="RACC"
              initialValue={formulaRegulation.racc.value?.toString() || ''}
              onChange={(value) =>
                formulaRegulation.racc.onChange(value ? parseFloat(value) : 0)
              }
              debounceTime={1000}
              onKeyDown={blockInvalidChar}
            />

            <IngredientSearch
              {...formulaRegulation.referenceIngredient.searchProps}
              sx={{ flex: '1' }}
              label="Reference Food"
              labelIcon={
                formulaRegulation.regulationName && (
                  <InfoBubble
                    text={
                      <MarkdownRenderer
                        markdownText={
                          infoMessages.formula.targets.REFERENCE_FOOD[formulaRegulation.regulationName]
                        }
                      />
                    }
                  />
                )
              }
            />
          </BoxRow>
          <SelectTargetSwitchControlLabel
            label={
              <BoxRowBasic
                style={{
                  gap: '4px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 'fit-content'
                }}
              >
                <Typography>Meal Or Main Dish</Typography>
                {formulaRegulation.regulationName === 'FDA' && (
                  <InfoBubble
                    text={
                      <MarkdownRenderer
                        markdownText={
                          infoMessages.formula.targets.MEAL_OR_MAIN_DISH
                        }
                      />
                    }
                  />
                )}
              </BoxRowBasic>
            }
            onClick={() =>
              formulaRegulation.mealMainDish.onChange(
                !formulaRegulation.mealMainDish.value
              )
            }
            control={
              <SwitchStyled checked={formulaRegulation.mealMainDish.value} />
            }
          />
        </BoxColumnBasic>
      ))}
    </BoxColumnBasic>
  )
}
