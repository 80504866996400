import { NutrientType } from 'models/Nutrient'
import AddIcon from '@mui/icons-material/Add'
import React from 'react'
import { SimpleAutoComplete } from '../../SimpleAutoComplete'
import { Box } from '@mui/material'
import { Delete } from '@mui/icons-material'

interface SecondNutrientFieldProps {
  initialValue: string
  options: string[]
  onSecondNutrientChange: (e: React.FocusEvent<HTMLInputElement>) => void
  removeSecondNutrient: () => void
  disableEdit: boolean
  show: boolean
}

export const SecondNutrientField: React.FC<SecondNutrientFieldProps> = ({
  initialValue,
  options,
  onSecondNutrientChange,
  removeSecondNutrient,
  disableEdit,
  show
}) => {
  return show ? (
    <>
      <AddIcon style={{ fontSize: '20px' }} />
      <Box style={{ position: 'relative' }}>
        <SimpleAutoComplete
          initialValue={initialValue}
          options={options}
          onBlur={(e) => onSecondNutrientChange(e)}
          disabled={disableEdit}
          showFullTextTooltip={true}
        />
        <Delete
          style={{
            position: 'absolute',
            right: '4',
            top: '6',
            cursor: 'pointer',
            fontSize: '20px'
          }}
          onClick={removeSecondNutrient}
          color={disableEdit ? 'disabled' : 'secondary'}
        />
      </Box>
    </>
  ) : null
}
