import { Box } from '@mui/material'
import DebouncedTextField from 'components/DebouncedTextField/DebouncedTextField'
import styled from 'styled-components'

export const NoteTextField = styled(DebouncedTextField)(() => ({
  '& .MuiInputBase-root': {
    background: '#F6F5F4',
    border: '0px',
    fontSize: '16px',
    fontWeight: 400,
    color: '#1A2C42BF',
    padding: '10px 10px 0px 10px',
    borderRadius: '8px 8px 0px 0px'
  }
}))

export const NotesBottom = styled(Box)(() => ({
  background: '#F6F5F4',
  padding: '10px',
  borderRadius: '0px 0px 8px 8px'
}))
