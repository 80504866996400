import { Autocomplete, TextField, Typography } from '@mui/material'
import { LoadingAnimation } from 'components/LoadingAnimation/LoadingAmination'
import { ViewerBottomHeaderFooter } from 'components/common'
import { Conversion } from 'models/Conversion'
import { DetailedIngredient } from 'models/Ingredient'
import { Measurement, MeasurementType } from 'models/Measurement'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  addConversion,
  getConversions,
  removeConversion,
  updateConversion
} from 'state/simple_ingredients/measurements/SimpleIngredientMeasurementsSlice'
import { ConversionItem } from './ConversionItem'
import {
  IngredientViewerPanelContent,
  IngredientViewerPanelDualGrid,
  IngredientViewerPanelHeaderLabel
} from '../../IngredientViewStyles'
import {
  getSimpleIngredient,
  setSelectedSimpleIngredient
} from 'state/simple_ingredients/SimpleIngredientsSlice'

interface IngredientMeasurementsPanelProps {
  simpleIngredient: DetailedIngredient
  readOnly?: boolean
}

export const IngredientMeasurementsPanel: React.FC<
  IngredientMeasurementsPanelProps
> = ({ simpleIngredient, readOnly = false }) => {
  const ref = React.useRef<HTMLDivElement>(null)

  const dispatch = useAppDispatch()

  const measurements = useAppSelector(
    (state) => state.simpleIngredientMeasurementSlice.measurements
  )
  const conversions = useAppSelector(
    (state) => state.simpleIngredientMeasurementSlice.conversions
  )

  const [measurementValue, setMeasurementValue] =
    React.useState<Measurement | null>(null)

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )

  React.useEffect(() => {
    void dispatch(
      getConversions({
        companyId: currentCompany.id,
        simpleIngredientId: simpleIngredient.id
      })
    )
  }, [simpleIngredient.id])

  const updateSelectedIngredient = () => {
    void dispatch(
      getSimpleIngredient({
        companyId: currentCompany.id,
        simpleIngredientId: simpleIngredient.id
      })
    )
      .unwrap()
      .then((res) => {
        dispatch(setSelectedSimpleIngredient(res))
      })
  }

  const onConversionChange = (
    conversion: Conversion,
    amount: number,
    amountInMeasurement: number,
    remove?: boolean
  ) => {
    if (remove) {
      void dispatch(
        removeConversion({
          companyId: currentCompany.id,
          simpleIngredientId: simpleIngredient.id,
          conversionIds: [conversion.id]
        })
      ).then(() => {
        updateSelectedIngredient()
      })
    } else {
      void dispatch(
        updateConversion({
          companyId: currentCompany.id,
          simpleIngredientId: simpleIngredient.id,
          conversionId: conversion.id,
          amount,
          amountInMeasurement
        })
      ).then(() => {
        updateSelectedIngredient()
      })
    }
  }

  const handleAutoCompleteChange = (
    e: React.SyntheticEvent<Element, Event>,
    newValue: Measurement | null
  ) => {
    if (measurements && newValue) {
      setMeasurementValue(newValue)
      void dispatch(
        addConversion({
          companyId: currentCompany.id,
          simpleIngredientId: simpleIngredient.id,
          measurementId: newValue.id,
          amountInMeasurement: 1,
          amount: 0
        })
      )
        .then(() => {
          updateSelectedIngredient()
        })
        .finally(() => {
          setMeasurementValue(null)
        })
      /* keep autocomplete in view */
      setTimeout(() => {
        const scrollDiv =
          ref.current?.parentElement?.parentElement?.parentElement
        if (scrollDiv) {
          scrollDiv.scrollTo({
            top: scrollDiv.scrollHeight,
            behavior: 'smooth'
          })
        }
      }, 400)
    }
  }

  return (
    <>
      {(conversions === undefined || measurements === undefined) && (
        <LoadingAnimation text="Loading measurements" />
      )}

      {conversions !== undefined && measurements !== undefined && (
        <>
          <IngredientViewerPanelContent ref={ref}>
            <IngredientViewerPanelDualGrid>
              <IngredientViewerPanelHeaderLabel sx={{ justifySelf: 'start' }}>
                <Typography>Name</Typography>
              </IngredientViewerPanelHeaderLabel>
              <IngredientViewerPanelHeaderLabel sx={{ justifySelf: 'right' }}>
                <Typography>Amount</Typography>
              </IngredientViewerPanelHeaderLabel>
            </IngredientViewerPanelDualGrid>

            <IngredientViewerPanelDualGrid>
              {conversions.map((c) => (
                <ConversionItem
                  key={c.id}
                  conversion={c}
                  onChange={onConversionChange}
                  debounceTime={1400}
                  removable={!readOnly}
                  disabled={readOnly}
                />
              ))}

              {!readOnly && (
                <Autocomplete
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  value={measurementValue}
                  options={measurements
                    .filter((m) =>
                      conversions.every((c) => c.measurement.id !== m.id)
                    )
                    .filter((m) => m.type !== MeasurementType.GRAMS)
                  }
                  onChange={handleAutoCompleteChange}
                  getOptionLabel={(option: Measurement) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="e.g., 100 mL = 100 g" />
                  )}
                  sx={{ gridColumn: '1/3' }}
                />
              )}
            </IngredientViewerPanelDualGrid>
          </IngredientViewerPanelContent>

          <ViewerBottomHeaderFooter data-footer />
        </>
      )}
    </>
  )
}
