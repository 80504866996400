import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { useDebouncedCallback } from 'use-debounce'

type DebouncedTextFieldProps = TextFieldProps & {
  debounceTime?: number
}

const DebouncedTextField: React.FC<DebouncedTextFieldProps> = ({
  onChange,
  debounceTime = 200, // default debounce time
  ...restProps
}) => {
  const handleInputChangeDebounced = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event)
      }
    },
    debounceTime
  )

  return <TextField onChange={handleInputChangeDebounced} {...restProps} />
}

export default DebouncedTextField
