import { Box, Typography } from '@mui/material'
import { TransformWrapper } from 'react-zoom-pan-pinch'
import styled from 'styled-components'

export const FilePreviewContainer = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
})

export const FilePreviewTopBarContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  flexDirection: 'row-reverse',
  background: '#FFF',
  boxShadow: '-2px 4px 5px 0px rgba(0, 0, 0, 0.08)',
  padding: '10px 14px',
  zIndex: 1
})

export const FilePreviewFileName = styled(Typography)({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)'
})

export const FilePreviewButtonGroup = styled(Box)({
  display: 'flex',
  gap: '5px'
})

export const FilePreviewContentContainer = styled(Box)({
  display: 'flex',
  flex: 1,
  overflow: 'auto',
  justifyContent: 'center'
})

export const FilePreviewWrapperInnerContainer = styled(Box)`
  height: 100vh;
`
