import { Nutrient } from 'models/Nutrient'
import { api } from '../config/AxiosConfig'
import { toNutrients } from './NutrientApiMapper'
import { GetNutrientsApiParams } from './NutrientApiRequest'
import { NutrientApiResponse } from './NutrientApiResponse'

export const NutrientsApi = {
  getNutrients: (params: GetNutrientsApiParams): Promise<Nutrient[]> => {
    return api
      .get<NutrientApiResponse[]>(`/nutrients`, {
        params: params,
        paramsSerializer: {
          indexes: null
        }
      })
      .then((res) => toNutrients(res.data))
  }
}
