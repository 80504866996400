import React from 'react'
import { useLoadNextPage, useQuery } from 'common/hooks'
import { CountCell } from 'components/Cells/CountCell'
import { FormulasCell } from 'components/Cells/FormulasCell'
import { NameCell } from 'components/Cells/NameCell'
import { ListCell } from 'components/Cells/ListCell'
import { ColumnConfiguration, EntrTable } from 'components/EntrTable/EntrTable'
import { EntrTableFilters } from 'components/EntrTable/EntrTableFilters'
import { TableFilter, TableOptions } from 'components/EntrTable/types'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { TitleActionsBar } from 'components/TitleActionsBar/TitleActionsBar'
import {
  EntrTableContainer,
  PageEntrTableContainer,
  PageEntrTableInnerContainer
} from 'components/common'
import { DetailedSupplier } from 'models/Supplier'
import { getTagsFilters } from 'state/filters/FiltersSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  createSupplier,
  getSupplierAllergens,
  getSuppliers
} from 'state/suppliers/SuppliersSlice'
import { getCompanyTags } from 'state/tags/TagsSlice'
import { SupplierViewer } from './components/SupplierViewer'
import { Button } from 'components/Button/Button'

export const Suppliers: React.FC = () => {
  const dispatch = useAppDispatch()
  const { items, page, pages } = useAppSelector(
    (state) => state.suppliers.suppliers
  )
  const deleting = useAppSelector((state) => state.suppliers.deleting)
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const [selectedSupplier, setSelectedSupplier] =
    React.useState<DetailedSupplier>()
  const [prevSupplier, setPrevSupplier] = React.useState<DetailedSupplier>()
  const [showViewer, setShowViewer] = React.useState(false)
  const { showError, showSuccess } = React.useContext(SnackbarContext)
  const tagsFilterOptions = useAppSelector(
    (state) => state.filters.tagFilterOptions
  )
  const [{ search, tags }] = useQuery()

  const setCurrentPageCallback = (page: number) => {
    void dispatch(
      getSuppliers({
        companyId: currentCompany.id,
        page: page,
        fuzzyName: search,
        tags: tags?.split(',')
      })
    )
  }

  React.useEffect(() => {
    void dispatch(
      getSuppliers({
        companyId: currentCompany.id,
        page: 1,
        fuzzyName: search,
        tags: tags?.split(',')
      })
    )
  }, [search, tags])

  const { nextPageCallback, allPagesLoaded } = useLoadNextPage({
    page,
    pages,
    setCurrentPageCallback
  })

  const options: TableOptions = {
    sorting: false,
    filtering: true
  }

  const filters: TableFilter[] = [
    {
      field: 'Tags',
      paramName: 'tags',
      options: tagsFilterOptions.map((tagsFilterOption) => {
        return {
          label: tagsFilterOption.name,
          value: tagsFilterOption.id
        }
      })
    }
  ]

  const columnConfigs: ColumnConfiguration<DetailedSupplier>[] = [
    {
      title: 'Name',
      fieldStyles: { width: '35%' },
      field: () => '',
      customRender: (supplier) => <NameCell name={supplier.name} />
    },
    {
      title: 'Ingredients',
      fieldStyles: { width: '21%' },
      field: () => '',
      customRender: (supplier) => (
        <CountCell total={supplier.totalIngredients} />
      )
    },
    // {
    //   title: 'Used In',
    //   fieldStyles: { width: '21%' },
    //   field: () => '',
    //   customRender: (supplier) => (
    //     /* path for link? */
    //     <FormulasCell total={supplier.totalFormulas} link={'#'} />
    //   )
    // },
    {
      title: 'Tags',
      field: () => '',
      customRender: (supplier) => (
        <ListCell list={(supplier.tags || []).map((t) => t.name)} />
      ),
      customFilterAndSearch: (
        term: string,
        rowData: DetailedSupplier
      ): boolean => {
        return rowData.tags.some((t) => t.id === term)
      }
    }
  ]

  const handleNewSupplier = () => {
    void dispatch(
      createSupplier({
        companyId: currentCompany.id,
        name: 'Untitled Supplier'
      })
    )
      .unwrap()
      .then((payload) => {
        showSuccess('New supplier has been created.')
        setSelectedSupplier({
          ...payload,
          tags: [],
          totalFormulas: 0,
          totalIngredients: 0
        })
      })
      .catch(() => {
        showError('There was a problem creating a new supplier.')
      })
  }

  const handleRowClicked = (sup: DetailedSupplier) => {
    if (selectedSupplier && selectedSupplier.id === sup.id) {
      setShowViewer(false)
    } else {
      setPrevSupplier(selectedSupplier)
      setSelectedSupplier(sup)
    }
  }

  React.useEffect(() => {
    void dispatch(getTagsFilters({ companyId: currentCompany.id }))
  }, [])

  React.useEffect(() => {
    if (
      selectedSupplier &&
      (!prevSupplier || selectedSupplier.id !== prevSupplier.id)
    ) {
      // get supplier allergens when new supplier is selected
      setPrevSupplier(selectedSupplier)
      void dispatch(
        getSupplierAllergens({
          companyId: selectedSupplier.company.id,
          supplierId: selectedSupplier.id
        })
      )
        .unwrap()
        .catch(() => {
          showError("Unable to load this supplier's allergens")
        })
      // get company tags
      void dispatch(
        getCompanyTags({
          companyId: selectedSupplier.company.id
        })
      )
        .unwrap()
        .catch(() => {
          showError('Unable to load company tags')
        })
    }
  }, [selectedSupplier])

  React.useEffect(() => {
    if (selectedSupplier) {
      const updatedSupplier = items.find((s) => s.id === selectedSupplier.id)
      if (updatedSupplier) {
        const newData = JSON.stringify({ ...updatedSupplier, updatedAt: '' })
        const oldData = JSON.stringify({ ...selectedSupplier, updatedAt: '' })
        if (newData !== oldData) {
          // refresh selected supplier data after update
          setSelectedSupplier(updatedSupplier)
        }
      }
    }
  }, [items])

  /* open viewer when supplier is selected */
  React.useEffect(() => {
    if (selectedSupplier) {
      setShowViewer(true)
    }
  }, [selectedSupplier])

  /* set selected supplier to undefined when viewer closes */
  React.useEffect(() => {
    if (!showViewer && selectedSupplier) {
      setSelectedSupplier(undefined)
      setPrevSupplier(undefined)
    }
  }, [showViewer])

  /* auto close viewer on delete */
  React.useEffect(() => {
    if (deleting) {
      setShowViewer(false)
    }
  }, [deleting])

  const action = (
    <Button onClick={handleNewSupplier} color="primary">
      New Supplier
    </Button>
  )

  return (
    <PageEntrTableContainer>
      <SupplierViewer show={showViewer} supplier={selectedSupplier} />
      <PageEntrTableInnerContainer>
        <EntrTableContainer onClick={() => setShowViewer(false)}>
          <TitleActionsBar title={'Suppliers'} action={action} />
          <EntrTableFilters
            searchPlaceholder="Search all suppliers"
            searchable
            searchParamName="search"
            filters={filters}
          />
          <EntrTable
            data={items}
            allPagesLoaded={allPagesLoaded}
            columnConfigs={columnConfigs}
            onRowClick={handleRowClicked}
            onLoadNextPage={nextPageCallback}
          />
        </EntrTableContainer>
      </PageEntrTableInnerContainer>
    </PageEntrTableContainer>
  )
}
