import { Skeleton, Typography } from '@mui/material'
import { BoxRow } from 'components/common'
import React from 'react'
import { ReactComponent as BackArrowIcon } from 'assets/BackArrow.svg'
import { Button } from 'components/Button/Button'
import { MenuButton } from 'components/MenuButton/MenuButton'
import {
  SavingStatus,
  SavingStatusIndicator
} from 'components/SavingStatusIndicator/SavingStatusIndicator'

interface BackButtonProps {
  onClick?: () => void
}

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  return (
    <BoxRow>
      <Button
        variant="square"
        color="primary"
        onClick={onClick}
        disabled={onClick === undefined}
        size="medium"
      >
        <BackArrowIcon />
      </Button>
      <Typography style={{ marginLeft: '10px' }} variant="subtitle1">
        Back to All Formulas
      </Typography>
    </BoxRow>
  )
}

const Placeholder = () => {
  return (
    <BoxRow justifyContent={'space-between'} alignContent={'center'}>
      <BackButton />
      <BoxRow gap="12px">
        <Skeleton variant="rectangular" height={25} width={125} />
        <Skeleton variant="rectangular" height={32} width={82} />
        <Skeleton variant="rectangular" height={32} width={38} />
      </BoxRow>
    </BoxRow>
  )
}

export interface FormulaTopBarProps {
  onBackButtonClick: () => void
  onDuplicateClick: () => void
  onDownloadClick: () => void
  onDeleteClick: () => void
  onArchiveClick: () => void
  saving: boolean
  loading: boolean
  formulaArchived?: boolean
}

export const FormulatorTopBar: React.FC<FormulaTopBarProps> = ({
  onBackButtonClick,
  onDuplicateClick,
  onDownloadClick,
  onArchiveClick,
  onDeleteClick,
  saving,
  loading,
  formulaArchived = false
}) => {
  return (
    <>
      {loading && <Placeholder />}
      {!loading && (
        <BoxRow justifyContent={'space-between'} alignContent={'center'}>
          <BackButton onClick={onBackButtonClick} />
          <BoxRow gap="12px">
            <SavingStatusIndicator
              status={saving ? SavingStatus.SAVING : SavingStatus.SAVED}
            />
            <Button color="secondary" onClick={onDuplicateClick}>
              Duplicate
            </Button>
            <MenuButton
              buttonStyle={{
                height: '100%'
              }}
              menuItems={[
                {
                  itemTitle: 'Download as CSV',
                  itemCallback: onDownloadClick
                },
                {
                  itemTitle: formulaArchived ? 'Unarchive' : 'Archive',
                  itemCallback: onArchiveClick
                },
                {
                  itemTitle: 'Delete',
                  itemStyle: {
                    color: 'var(--error, #D03838)'
                  },
                  itemCallback: onDeleteClick
                }
              ]}
            />
          </BoxRow>
        </BoxRow>
      )}
    </>
  )
}
