import React from 'react'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material'

interface TopBarLayoutProps {
  topBar: React.ReactNode
  children: React.ReactNode
}

export const TOPBAR_HEIGHT = '3.75em'

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    position: 'absolute',
    height: '100%',
    width: '100%'
  },
  topBar: {
    paddingTop: 20,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: 'white',
    zIndex: 1 // Drawer zIndex is 1200
  },
  outlet: {
    flex: 1,
    padding: 20,
    overflow: 'hidden'
  }
}))

export const TopBarLayout: React.FC<TopBarLayoutProps> = ({
  topBar,
  children
}) => {
  const classes = useStyles(useTheme())

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>{topBar}</div>
      <div className={classes.outlet}>{children}</div>
    </div>
  )
}
