import { FormulaStatus } from 'models/Formula'
import {
  FormulaHistoryModificationField,
  FormulaHistoryModification,
  FormulaHistoryActivitySubject,
  FormulaHistoryActivityAction,
  FormulaHistory,
  FormulaHistoryActivity,
  FormulaHistoryActivityPaginated
} from 'models/FormulaHistory'
import { toBasicUser } from '../user/UserApiMapper'
import { toBasicFormula } from './FormulaApiMapper'
import { FormulaApiStatus } from './FormulaApiResponse'
import {
  FormulaHistoryModificationApiField,
  FormulaHistoryModificationApiResponse,
  FormulaHistoryActivityApiSubject,
  FormulaHistoryActivityApiAction,
  FormulaHistoryActivityApiResponse,
  FormulaHistoryApiResponse,
  FormulaHistoryActivityApiPaginatedResponse
} from './FormulaHistoryApiResponse'

const toFormulaHistoryActivitySubject = (
  subject: FormulaHistoryActivityApiSubject
): FormulaHistoryActivitySubject => {
  switch (subject) {
    case FormulaHistoryActivityApiSubject.FORMULA:
      return FormulaHistoryActivitySubject.FORMULA
    case FormulaHistoryActivityApiSubject.INGREDIENT:
      return FormulaHistoryActivitySubject.INGREDIENT
    case FormulaHistoryActivityApiSubject.INGREDIENTS:
      return FormulaHistoryActivitySubject.INGREDIENTS
    case FormulaHistoryActivityApiSubject.ALLERGEN:
      return FormulaHistoryActivitySubject.ALLERGEN
    case FormulaHistoryActivityApiSubject.ALLERGENS:
      return FormulaHistoryActivitySubject.ALLERGENS
    default:
      return FormulaHistoryActivitySubject.FORMULA
  }
}

const toFormulaHistoryActivityAction = (
  action: FormulaHistoryActivityApiAction
): FormulaHistoryActivityAction => {
  switch (action) {
    case FormulaHistoryActivityApiAction.CREATED:
      return FormulaHistoryActivityAction.CREATED
    case FormulaHistoryActivityApiAction.DUPLICATED:
      return FormulaHistoryActivityAction.DUPLICATED
    case FormulaHistoryActivityApiAction.ADDED:
      return FormulaHistoryActivityAction.ADDED
    case FormulaHistoryActivityApiAction.REMOVED:
      return FormulaHistoryActivityAction.REMOVED
    case FormulaHistoryActivityApiAction.UPDATED:
      return FormulaHistoryActivityAction.UPDATED
    default:
      return FormulaHistoryActivityAction.CREATED
  }
}

const fromModificationStatusValue = (status: string): FormulaStatus => {
  switch (status) {
    case FormulaApiStatus.IN_REVIEW.toString():
      return FormulaStatus.IN_REVIEW
    case FormulaApiStatus.PRIVATE_DRAFT.toString():
      return FormulaStatus.PRIVATE_DRAFT
    case FormulaApiStatus.APPROVED.toString():
      return FormulaStatus.APPROVED
    case FormulaApiStatus.IN_MARKET.toString():
      return FormulaStatus.IN_MARKET
    default:
      return FormulaStatus.PRIVATE_DRAFT
  }
}

const toFormulaHistoryModificationField = (
  field: FormulaHistoryModificationApiField
): FormulaHistoryModificationField => {
  switch (field) {
    case FormulaHistoryModificationApiField.NAME:
      return FormulaHistoryModificationField.NAME
    case FormulaHistoryModificationApiField.STATUS:
      return FormulaHistoryModificationField.STATUS
    case FormulaHistoryModificationApiField.IMAGE_PATH:
      return FormulaHistoryModificationField.IMAGE_PATH
    case FormulaHistoryModificationApiField.NOTE:
      return FormulaHistoryModificationField.NOTE
    case FormulaHistoryModificationApiField.INGREDIENT_STATEMENT:
      return FormulaHistoryModificationField.INGREDIENT_STATEMENT
    case FormulaHistoryModificationApiField.AMOUNT:
      return FormulaHistoryModificationField.AMOUNT
    case FormulaHistoryModificationApiField.WASTE_PERCENTAGE:
      return FormulaHistoryModificationField.WASTE_PERCENTAGE
    case FormulaHistoryModificationApiField.SERVING_WEIGHT:
      return FormulaHistoryModificationField.SERVING_WEIGHT
    case FormulaHistoryModificationApiField.CONTAINER_WEIGHT:
      return FormulaHistoryModificationField.CONTAINER_WEIGHT
    case FormulaHistoryModificationApiField.MEASUREMENT:
      return FormulaHistoryModificationField.MEASUREMENT
    case FormulaHistoryModificationApiField.RACC:
      return FormulaHistoryModificationField.RACC
    case FormulaHistoryModificationApiField.RACC_UNIT:
      return FormulaHistoryModificationField.RACC_UNIT
    case FormulaHistoryModificationApiField.IS_MEAL_OR_MAIN_DISH:
      return FormulaHistoryModificationField.IS_MEAL_OR_MAIN_DISH
    case FormulaHistoryModificationApiField.REFERENCE_FORMULA:
      return FormulaHistoryModificationField.REFERENCE_FORMULA
    default:
      return FormulaHistoryModificationField.NAME
  }
}

const toFormulaHistoryModification = (
  modification: FormulaHistoryModificationApiResponse
): FormulaHistoryModification => {
  if (modification.field === FormulaHistoryModificationApiField.STATUS) {
    return {
      field: toFormulaHistoryModificationField(modification.field),
      oldValue: fromModificationStatusValue(modification.oldValue),
      newValue: fromModificationStatusValue(modification.newValue)
    }
  }
  return {
    ...modification,
    field: toFormulaHistoryModificationField(modification.field)
  }
}

const toFormulaHistoryModifications = (
  modifications: FormulaHistoryModificationApiResponse[]
): FormulaHistoryModification[] => {
  return modifications.map((modification) =>
    toFormulaHistoryModification(modification)
  )
}

const toFormulaHistoryActivity = (
  activity: FormulaHistoryActivityApiResponse
): FormulaHistoryActivity => {
  const formulaHistoryActivity = {
    ...activity,
    user: toBasicUser(activity.user),
    subject: toFormulaHistoryActivitySubject(activity.subject),
    action: toFormulaHistoryActivityAction(activity.action)
  } as FormulaHistoryActivity
  if (activity.modifications) {
    formulaHistoryActivity.modifications = toFormulaHistoryModifications(
      activity.modifications
    )
  }
  return formulaHistoryActivity
}

const toFormulaHistoryActivityPaginated = (
  activity: FormulaHistoryActivityApiPaginatedResponse
): FormulaHistoryActivityPaginated => {
  return {
    ...activity,
    items: activity.items.map((activity) => toFormulaHistoryActivity(activity))
  }
}

export const toFormulaHistory = (
  history: FormulaHistoryApiResponse
): FormulaHistory => {
  return {
    formula: toBasicFormula(history.formula),
    activity: toFormulaHistoryActivityPaginated(history.activity)
  }
}
