import { TableCell, TableRow, Typography, styled } from '@mui/material'
import { convertToFixedFloat } from 'common/utils'
import { BoxRowBasic } from 'components/common'
import { TextField } from 'components/TextField/TextField'
import React from 'react'
import isEqual from 'lodash.isequal'

const formatNumber = (value?: number) => {
  if (value === undefined || value === null) {
    return ''
  }
  return convertToFixedFloat(value, 2)
}

const UnitTypography = styled(Typography)`
  margin-left: 3px;
  font-size: 14px;
  color: ${(props) => props.theme.palette.tertiary.main};
`

export interface NutrientRowProps {
  nutrient: {
    id: string
    name: string
    amount: number
    unit: string
    dvPercentage?: number
  }
  overrides?: {
    amount?: string
    dvPercentage?: string
    name?: string
    onChange: (
      id: string,
      name?: string,
      amount?: string,
      dvPercentage?: string
    ) => void
  }
  disabledName?: boolean
  level?: number
}

export const areNutrientRowPropsEqual = (
  prevProps: NutrientRowProps,
  nextProps: NutrientRowProps
): boolean => {
  const isNutrientEqual = isEqual(prevProps.nutrient, nextProps.nutrient)
  const areOverridesEqual =
    prevProps.overrides?.amount === nextProps.overrides?.amount &&
    prevProps.overrides?.dvPercentage === nextProps.overrides?.dvPercentage &&
    prevProps.overrides?.name === nextProps.overrides?.name
  return (
    isNutrientEqual &&
    areOverridesEqual &&
    prevProps.disabledName === nextProps.disabledName &&
    prevProps.level === nextProps.level &&
    prevProps.overrides?.onChange === nextProps.overrides?.onChange
  )
}

export const NutrientRow = React.memo(
  ({
    nutrient,
    overrides,
    level = 0,
    disabledName = false
  }: NutrientRowProps) => {
    return (
      <TableRow key={nutrient.id + '-nutrient-row'}>
        {/* Original nutrients. */}
        <TableCell align="left" sx={{ padding: '4px' }}>
          <Typography
            sx={{
              fontWeight: level > 0 ? '400' : '500',
              fontSize: '14px',
              marginLeft: level > 0 ? `${level * 12}px` : '0px'
            }}
          >
            {nutrient.name}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ padding: '4px' }}>
          <BoxRowBasic>
            <Typography fontSize={'14px'}>
              {formatNumber(nutrient.amount)}
            </Typography>
            <UnitTypography>{nutrient.unit}</UnitTypography>
          </BoxRowBasic>
        </TableCell>
        <TableCell align="left">
          <BoxRowBasic>
            {nutrient.dvPercentage !== undefined &&
            nutrient.dvPercentage !== null ? (
              <>
                <Typography fontSize={'14px'}>
                  {formatNumber(nutrient.dvPercentage * 100)}
                </Typography>
                <UnitTypography>%</UnitTypography>
              </>
            ) : (
              ''
            )}
          </BoxRowBasic>
        </TableCell>

        {/* Overrides. */}
        <TableCell align="left" sx={{ padding: '4px' }}>
          {!disabledName && (
            <TextField
              scale="small"
              value={overrides?.name || ''}
              onChange={(value) =>
                overrides?.onChange(nutrient.id, value, undefined, undefined)
              }
            />
          )}
        </TableCell>
        <TableCell align="left" sx={{ padding: '4px' }}>
          <TextField
            scale="small"
            value={overrides?.amount || ''}
            onChange={(value) => {
              overrides?.onChange(nutrient.id, undefined, value, undefined)
            }}
            endAdornment={<UnitTypography>{nutrient.unit}</UnitTypography>}
          />
        </TableCell>
        <TableCell align="left" sx={{ padding: '4px' }}>
          {nutrient.dvPercentage !== null && (
            <TextField
              scale="small"
              value={overrides?.dvPercentage || ''}
              onChange={(value) =>
                overrides?.onChange(nutrient.id, undefined, undefined, value)
              }
              endAdornment={<UnitTypography>%</UnitTypography>}
            />
          )}
        </TableCell>
      </TableRow>
    )
  },
  areNutrientRowPropsEqual
)
