import React from 'react'
import { EditorBox } from './EditorPanelStyles'
import { Box } from '@mui/material'
import { Tabs } from 'components/Tabs/Tabs'
import { Tab } from 'components/Tabs/Tab/Tab'
import { TabPanel } from 'components/Tabs/TabPanel/TabPanel'
import { Separator } from 'pages/Formulator/components/FormulatorIngredients/components/FormulaDetails/components/AddController/AddControllerStyles'

interface EditorProps {
  general: React.ReactNode
  allergens: React.ReactNode
  overrides: React.ReactNode
}

export const EditorPanel: React.FC<EditorProps> = ({
  general,
  allergens,
  overrides
}) => {
  const [activeTab, setActiveTab] = React.useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  return (
    <EditorBox>
      <Tabs
        sx={{
          '& .MuiTabs-flexContainer': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around'
          },
          '& .MuiButtonBase-root': {
            flex: 1,
            padding: '4px 8px',
            fontSize: '14px'
          },
          minHeight: 'unset'
        }}
        value={activeTab}
        onChange={handleTabChange}
      >
        <Tab label="General" />
        <Tab label="Allergens" />
        <Tab label="Overrides" />
      </Tabs>
      <Separator />

      <Box
        sx={{ overflow: 'hidden auto', position: 'relative', height: '100%' }}
      >
        <Box sx={{ height: '100%', paddingRight: '5px' }}>
          <TabPanel value={activeTab} index={0} renderWhileHidden>
            {general}
          </TabPanel>
          <TabPanel value={activeTab} index={1} renderWhileHidden>
            {allergens}
          </TabPanel>
          <TabPanel value={activeTab} index={2} renderWhileHidden>
            {overrides}
          </TabPanel>
        </Box>
      </Box>
    </EditorBox>
  )
}
