import { Box } from '@mui/material'
import { BoxColumnBasic, BoxRowBasic } from 'components/common'
import { LoadingAnimation } from 'components/LoadingAnimation/LoadingAmination'
import { ZoomButtons } from 'components/ZoomButtons/ZoomButtons'
import { FormulaNutritionFactLabelType } from 'models/FormulaLabel'
import {
  NFPLabel,
  NFPLabelProps
} from 'pages/Formulator/components/FormulatorPanel/Label/components/NFPLabel'
import React, { useCallback, useRef, useState } from 'react'
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper
} from 'react-zoom-pan-pinch'

interface EditorPreviewProps {
  loading: boolean
  nfpLabel: NFPLabelProps
}

export const EditorPreview: React.FC<EditorPreviewProps> = ({
  loading,
  nfpLabel
}) => {
  const MAX_SCALE = 5
  const MIN_SCALE = 0.5
  const transformWrapperRef = useRef<ReactZoomPanPinchRef>(null)
  const [currentScale, setCurrentScale] = useState<number>(1)

  const handleOnTransformed = useCallback(
    (_: ReactZoomPanPinchRef, s: { scale: number }) => {
      setCurrentScale(s.scale)
    },
    [setCurrentScale]
  )

  React.useEffect(() => {
    if (transformWrapperRef.current && nfpLabel.nutritionFactsLabel?.type) {
      transformWrapperRef.current.resetTransform()
    }
  }, [nfpLabel.nutritionFactsLabel?.type, transformWrapperRef.current])

  return (
    <BoxRowBasic
      sx={{ overflow: 'hidden', padding: '0 16px', position: 'relative' }}
    >
      <TransformWrapper
        ref={transformWrapperRef}
        centerOnInit
        minScale={MIN_SCALE}
        maxScale={MAX_SCALE}
        centerZoomedOut
        onTransformed={handleOnTransformed}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent:
                  nfpLabel.nutritionFactsLabel?.type ===
                  FormulaNutritionFactLabelType.TABULAR
                    ? 'flex-start'
                    : 'center'
              }}
            >
              {loading && <LoadingAnimation text="Loading preview" />}
              {!loading && (
                <BoxColumnBasic
                  sx={{
                    alignItems: 'center',
                    gap: '12px',
                    transformOrigin: 'top'
                  }}
                >
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '5px',
                        padding: '10px',
                        width: '100%',
                        overflow: 'visible'
                      }}
                    >
                      <NFPLabel {...nfpLabel} scale={currentScale} />
                    </Box>
                  </>
                </BoxColumnBasic>
              )}
            </TransformComponent>
            <ZoomButtons
              sx={{ bottom: '12px', left: '12px' }}
              onZoomInClicked={() => zoomIn()}
              onZoomOutClicked={() => zoomOut()}
              onZoomResetClicked={() => {
                resetTransform()
              }}
              zoomInDisabled={currentScale >= MAX_SCALE || loading}
              zoomOutDisabled={currentScale <= MIN_SCALE || loading}
            />
          </>
        )}
      </TransformWrapper>
    </BoxRowBasic>
  )
}
