import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { YieldAdjustmentsApi } from 'services/apis/yield_adjustments/YieldAdjustmentsApi'
import { YieldAdjustment } from 'services/apis/yield_adjustments/YieldAdjustmentsApiResponse'
import { LoadingState } from '../CommonState'

interface YieldAdjustmentsState extends LoadingState {
  yieldAdjustments: YieldAdjustment[]
}

const initialState: YieldAdjustmentsState = {
  loading: false,
  error: false,
  yieldAdjustments: []
}

export const getYieldAdjustments = createAsyncThunk(
  'yield_adjustments/get',
  async () => {
    return await YieldAdjustmentsApi.getYieldAdjustments()
  }
)

export const yieldAdjustmentsSlice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getYieldAdjustments.pending,
      (state: YieldAdjustmentsState) => {
        state.loading = true
        state.error = false
      }
    )
    builder.addCase(
      getYieldAdjustments.fulfilled,
      (state: YieldAdjustmentsState, action) => {
        state.yieldAdjustments = action.payload
        state.loading = false
      }
    )
    builder.addCase(
      getYieldAdjustments.rejected,
      (state: YieldAdjustmentsState) => {
        state.error = true
      }
    )
  }
})

export default yieldAdjustmentsSlice.reducer
