import React from 'react'

import { searchIngredients } from 'state/ingredients/search/IngredientSearchSlice'

import { ModalContext } from 'components/Modal/ModalContext'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import { FormulatorContext } from 'pages/Formulator/components/FormulatorIngredients/FormulatorProvider'
import { getFormula } from 'state/formulator/FormulatorSlice'
import { getExecutedCompanyTargets } from 'state/formulator/formulaTargets/FormulaTargetsSlice'
import { getFormulaHistory } from 'state/formulator/history/FormulaHistorySlice'
import { addIngredientToFormula } from 'state/formulator/ingredients/FormulatorIngredientsSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { IngredientSearchRow } from './IngredientSearchRow'
import { SearchResult } from './IngredientSearch'
import { AdvancedIngredientSearchContainer } from './advanced/AdvancedIngredientSearchContainer'

export const IngredientSearchRowContainer: React.FC = () => {
  const { viewOptions } = React.useContext(FormulatorContext)

  const dispatch = useAppDispatch()
  const suggestions = useAppSelector(
    (state) => state.ingredientSearch.ingredientSuggestions
  )
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const analytics = React.useContext(AnalyticsContext)
  const loading = useAppSelector((state) => state.ingredientSearch.loading)
  const { showModal, closeModal } = React.useContext(ModalContext)

  const handleSearchChange = React.useCallback(
    (newSearchTerm: string) => {
      analytics.searchedIngredient(formulaId)
      void dispatch(
        searchIngredients({
          companyId: currentCompany.id,
          query: newSearchTerm,
          withPublicIngredients: true,
          requireAllTerms: true,
          size: 20
        })
      )
    },
    [currentCompany.id]
  )

  const handleIngredientClick = React.useCallback(
    (ingredientId: string, supplierId?: string) => {
      analytics.addedIngredientToFormula(formulaId)
      void dispatch(
        addIngredientToFormula({
          companyId: currentCompany.id,
          formulaId: formulaId,
          ingredientId: ingredientId,
          supplierId: supplierId
        })
      ).then(() => {
        void dispatch(
          getExecutedCompanyTargets({
            companyId: currentCompany.id,
            formulaId: formulaId
          })
        )
        void dispatch(
          getFormula({ companyId: currentCompany.id, formulaId: formulaId })
        )
        void dispatch(
          getFormulaHistory({
            companyId: currentCompany.id,
            formulaId: formulaId
          })
        )
      })
    },
    [currentCompany.id, formulaId]
  )

  const visibility = React.useMemo(() => {
    return {
      showSupplier: viewOptions[0].value,
      showWastePercentage: viewOptions[1].value,
      showQuantityPercentage: viewOptions[2].value
    }
  }, [viewOptions])

  const handleAdvancedSearchShowClick = React.useCallback(
    (searchTerm: string) => {
      showModal({
        content: (
          <AdvancedIngredientSearchContainer
            initialSearchTerm={searchTerm}
            onIngredientClick={(ingredientId: string) => {
              handleIngredientClick(ingredientId)
              closeModal()
            }}
          />
        )
      })
    },
    [formulaId, currentCompany.id]
  )

  const searchResults: SearchResult[] = React.useMemo(() => {
    return suggestions.items.map((sug) => {
      return {
        ingredient: {
          id: sug.id,
          friendlyId: sug.friendlyId,
          name: sug.name,
          highlightedName: sug.highlightedName
        },
        suppliers: sug.suppliers
      }
    })
  }, [suggestions])

  const span = React.useMemo(() => {
    return Object.values(visibility).filter((v) => v).length + 2
  }, [visibility])

  return (
    <IngredientSearchRow
      span={span}
      ingredientSearchProps={{
        onClick: handleIngredientClick,
        onAdvancedSearchClick: handleAdvancedSearchShowClick,
        onSearchChange: handleSearchChange,
        state: loading ? 'searching' : 'ready',
        searchResults: searchResults
      }}
    />
  )
}
