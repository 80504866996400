import React from 'react'
import NutrientRow, { NutrientRowProps } from './NutrientRow'
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

export interface NutrientRowGroupProps {
  nutrientRows: NutrientRowProps[]
  placeholderRows?: number
  header?: boolean
}

const getPlaceholderRows = (rows: number) => {
  return (
    <>
      {Array.from({ length: rows }).map((_, idx) => (
        <TableRow key={`ng-placeholder-${idx}`}>
          <TableCell colSpan={3}>
            <Skeleton variant={'rectangular'} height={'19px'} />
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}

export const NutrientRowGroup: React.FC<NutrientRowGroupProps> = ({
  nutrientRows,
  header = false,
  placeholderRows = 5
}) => {
  return (
    <TableContainer sx={{ padding: '20px' }}>
      <Table>
        <colgroup>
          <col style={{ width: '50%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
        </colgroup>
        {header && (
          <TableHead>
            <TableRow key="nutrient-table-header">
              <TableCell align="left">
                <Typography color="secondary" variant="body1">
                  Name
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography color="secondary" variant="body1">
                  Amount
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography color="secondary" variant="body1">
                  DV
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {nutrientRows &&
            nutrientRows.map((nr, index) => (
              <NutrientRow
                nutrient={nr.nutrient}
                level={nr.level}
                key={`nr-${index}`}
              />
            ))}
          {nutrientRows.length === 0 && getPlaceholderRows(placeholderRows)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default NutrientRowGroup
