import { InputAdornment, SxProps, TextField } from '@mui/material'
import { ReactComponent as SearchIcon } from 'assets/Search.svg'
import React from 'react'

interface SearchField {
  placeholder?: string
  defaultValue?: string
  onChange: (value: string) => void
  sx?: SxProps
}

export const SearchField: React.FC<SearchField> = ({
  placeholder = '',
  defaultValue,
  sx,
  onChange
}) => {
  return (
    <TextField
      placeholder={placeholder}
      defaultValue={defaultValue}
      sx={sx}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ marginLeft: '0.5rem' }}>
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  )
}
