import { ItemRowValues } from 'components/NutritionLabel/components/ItemRow'
import { NutritionLabelLinearContainer } from '../NutritionLabelLinearCanadianStyles'
import { NutritionLabelText } from 'components/NutritionLabel/components/NutritionLabelText'

export interface ItemLinearSettings {
  boldName?: boolean
  comma?: boolean
}

export interface ItemLinearValues {
  name: string
  amount: string | number
  unit: string
  dvPercentage?: string
}

export interface FatItemRowProps extends ItemLinearSettings {
  saturated: ItemRowValues
  trans: ItemRowValues
}

export const FatItemLinear: React.FC<FatItemRowProps> = ({
  saturated,
  trans,
  comma = true
}) => (
  <NutritionLabelLinearContainer>
    <NutritionLabelText bold points={7}>
      {saturated.name}
    </NutritionLabelText>
    <NutritionLabelText points={7}>
      {' '}
      {saturated.amount} {saturated.unit}
    </NutritionLabelText>
    <NutritionLabelText points={7}> + </NutritionLabelText>
    <NutritionLabelText bold points={7}>
      {trans.name}
    </NutritionLabelText>
    <NutritionLabelText points={7}>
      {' '}
      {trans.amount} {trans.unit}{' '}
    </NutritionLabelText>
    <NutritionLabelText points={8}>
      {trans.dvPercentage &&
        saturated.dvPercentage &&
        `(${(
          (Number(trans.dvPercentage) + Number(saturated.dvPercentage)) /
          2
        ).toFixed(0)} %)`}
    </NutritionLabelText>
    <NutritionLabelText points={7}>{comma && ',\u00A0'}</NutritionLabelText>
  </NutritionLabelLinearContainer>
)
