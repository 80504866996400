import React from 'react'

interface Patterns {
  TAB: RegExp
  NEWLINE: RegExp
  BOLD: RegExp
  ITALIC: RegExp
  LINK: RegExp
}

interface Templates {
  TAB: string
  NEWLINE: string
  BOLD: string
  ITALIC: string
  LINK: string
}

interface MarkdownRendererProps {
  markdownText: string
  style?: React.CSSProperties
  customTemplates?: Templates
}

const patterns: Patterns = {
  TAB: /\\t/g,
  NEWLINE: /\\n/g,
  BOLD: /\*\*(.*?)\*\*/g,
  ITALIC: /\*(.*?)\*/g,
  LINK: /\[([^\]]+?)\]\(((?:[^)(]+|\((?:[^)(]+|\([^)(]*\))*\))*)\)/g // [text](link)
}

const templates: Templates = {
  TAB: '&nbsp;&nbsp;&nbsp;&nbsp;',
  NEWLINE: '<br/>',
  BOLD: '<b>$1</b>',
  ITALIC: '<i>$1</i>',
  LINK: "<a href='$2' target='_blank' rel='noopener noreferrer'>$1</a>"
}

export const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({
  markdownText,
  style,
  customTemplates = templates
}) => {
  const rules = Object.entries(patterns).map(([key, value]) => [
    value,
    customTemplates[key as keyof Templates]
  ])
  const renderTextWithFormatting = () => {
    let formattedText = markdownText
    rules.forEach(([rule, template]) => {
      formattedText = formattedText.replace(rule, template)
    })
    return formattedText
  }

  return (
    <div
      dangerouslySetInnerHTML={{ __html: renderTextWithFormatting() }}
      onClick={(e) => e.stopPropagation()}
      style={{
        ...style,
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        maxWidth: '100%'
      }}
    />
  )
}
