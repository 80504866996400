import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  IngredientSearchResult,
  IngredientSearchResultsPaginated
} from 'models/Ingredient'
import { IngredientApi } from 'services/apis/ingredient/IngredientApi'
import { LoadingState } from '../../CommonState'
import {
  AdvancedSearchIngredientsRequest,
  SearchIngredientsRequest
} from './IngredientSearchRequest'

export interface IngredientSearchState extends LoadingState {
  ingredientSuggestions: IngredientSearchResultsPaginated
}

const initialState: IngredientSearchState = {
  loading: false,
  error: false,
  ingredientSuggestions: {
    items: [],
    page: 1,
    pages: 1
  }
}

export const searchIngredients = createAsyncThunk(
  'ingredients/search',
  async (request: SearchIngredientsRequest) => {
    return await IngredientApi.searchIngredients(request.companyId, request)
  },
  {
    getPendingMeta: (action) => {
      return {
        requestId: action.requestId,
        sequentialThunk: true
      }
    }
  }
)

export const advancedSearchIngredients = createAsyncThunk(
  'ingredients/advancedSearch',
  async (request: AdvancedSearchIngredientsRequest) => {
    return await IngredientApi.advancedSearchIngredients(
      request.companyId,
      request
    )
  },
  {
    getPendingMeta: (action) => {
      return {
        requestId: action.requestId,
        sequentialThunk: true
      }
    }
  }
)

const ingredientSearchSlice = createSlice({
  name: 'ingredientSearchSlice',
  initialState,
  reducers: {
    clearIngredientSuggestions: (state) => {
      state.ingredientSuggestions = { ...initialState.ingredientSuggestions }
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle searchIngredients actions
      .addCase(searchIngredients.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(
        searchIngredients.fulfilled,
        (state, action: PayloadAction<IngredientSearchResultsPaginated>) => {
          state = mergeIngredientSearchResults(state, action)
          state.loading = false
        }
      )
      .addCase(searchIngredients.rejected, (state) => {
        state.loading = false
        state.error = true
      })
      // Handle advancedSearchIngredients actions
      .addCase(advancedSearchIngredients.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(
        advancedSearchIngredients.fulfilled,
        (state, action: PayloadAction<IngredientSearchResultsPaginated>) => {
          state = mergeIngredientSearchResults(state, action)
          state.loading = false
        }
      )
      .addCase(advancedSearchIngredients.rejected, (state) => {
        state.loading = false
        state.error = true
      })
  }
})

// Utility function to merge search results
function mergeIngredientSearchResults(
  state: IngredientSearchState,
  action: PayloadAction<IngredientSearchResultsPaginated>
): IngredientSearchState {
  if (action.payload.page === 1) {
    state.ingredientSuggestions = {
      ...action.payload
    }
  } else {
    const arr = action.payload.page > 1 ? state.ingredientSuggestions.items : []
    const all = [...arr, ...action.payload.items]
    const ids = [...new Set([...all.map((o) => o.id)])]
    const updatedItems = ids.map(
      (id) => all.reverse().find((o) => o.id === id) as IngredientSearchResult
    )
    state.ingredientSuggestions = {
      ...action.payload,
      items: updatedItems
    }
  }

  return state
}

export const { clearIngredientSuggestions } = ingredientSearchSlice.actions

export default ingredientSearchSlice.reducer
