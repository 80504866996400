import { PATHS } from 'common/constants'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { pluralize } from 'common/utils'
import { ModalContext } from 'components/Modal/ModalContext'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import { Formula } from 'models/Formula'
import {
  deleteFormula,
  downloadFormulaNutrientsAsCSV,
  duplicateFormula,
  updateFormulaArchive
} from 'state/formulator/FormulatorSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { FormulatorTopBar } from './FormulatorTopBar'
import { FormulatorContext } from '../FormulatorIngredients/FormulatorProvider'

export const FormulatorTopBarContainer: React.FC = () => {
  const { showConfirmationModal } = React.useContext(ModalContext)
  const { showSuccess, showError } = React.useContext(SnackbarContext)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const loading = useAppSelector((state) => state.formulator.loading)
  const saving = useAppSelector((state) => state.formulator.saving)
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )

  const { selectedIngredientIdsMap, clearIngredientIdsSelection } =
    React.useContext(FormulatorContext)
  const formulaName = useAppSelector((state) => state.formulator.formula.name)
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const formulaArchived = useAppSelector(
    (state) => state.formulator.formula.isArchived
  )

  const scale = useAppSelector((state) => state.formulator.scale)
  const { id } = useParams()
  const analytics = React.useContext(AnalyticsContext)

  const handleArchive = () => {
    analytics.archivedFormula(formulaId, !formulaArchived)
    void dispatch(
      updateFormulaArchive({
        companyId: currentCompany.id,
        formulaId: formulaId,
        archive: !formulaArchived
      })
    )
  }

  const handleDelete = () => {
    showConfirmationModal({
      title: 'Delete Formula',
      danger: true,
      message: (
        <>
          Are you sure you want to permanently delete the formula{' '}
          <b>{formulaName}</b>?
        </>
      ),
      yesText: 'Delete',
      noText: 'Cancel',
      onYesClicked: () => {
        analytics.deletedFormula(formulaId)
        void dispatch(
          deleteFormula({
            companyId: currentCompany.id,
            formulaId: formulaId
          })
        )
          .then(() => {
            showSuccess(`${formulaName} has been deleted.`)
            navigate(PATHS.FORMULAS)
          })
          .catch(() => {
            showError(`Failed to delete ${formulaName}`)
          })
      }
    })
  }

  const selectedIngredientIds = React.useMemo(() => {
    return Array.from(selectedIngredientIdsMap)
      .filter(([_, value]) => value === true)
      .map(([key, _]) => key)
  }, [selectedIngredientIdsMap])

  const handleDuplicate = () => {
    const formulaIngredientIds = selectedIngredientIds
    const message =
      selectedIngredientIds.length > 0
        ? `Including ${selectedIngredientIds.length} ${pluralize(
            selectedIngredientIds.length,
            'ingredient'
          )}.`
        : `No ingredient selected. Please either select the ingredients you wish to duplicate or click duplicate to make a copy with all ingredients included.`
    showConfirmationModal({
      title: `Duplicating ${formulaName}`,
      message,
      yesText: 'Duplicate',
      noText: 'Cancel',
      onYesClicked: () => {
        void dispatch(
          duplicateFormula({
            companyId: currentCompany.id,
            formulaId: formulaId,
            formulaIngredientIds: formulaIngredientIds
          })
        )
          .then((res) => {
            analytics.duplicatedFormula(formulaId)
            showSuccess(`Duplicated ${formulaName}`)
            const newFormula: Formula = res.payload as Formula
            navigate(PATHS.EDIT_FORMULA.replace(':id', newFormula.id))
            clearIngredientIdsSelection()
          })
          .catch(() => {
            showError(`Failed duplicating ${formulaName}`)
          })
      }
    })
  }

  const handleDownloadCSV = () => {
    void dispatch(
      downloadFormulaNutrientsAsCSV({
        companyId: currentCompany.id,
        formulaId: formulaId,
        scale: scale
      })
    )
  }

  return (
    <FormulatorTopBar
      loading={loading && formulaId !== id}
      saving={saving}
      onBackButtonClick={() => navigate(PATHS.FORMULAS)}
      onDuplicateClick={handleDuplicate}
      onDownloadClick={handleDownloadCSV}
      onDeleteClick={handleDelete}
      onArchiveClick={handleArchive}
      formulaArchived={formulaArchived}
    />
  )
}
