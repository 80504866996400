import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { InView } from 'react-intersection-observer'
import { BoxRow } from '../common'

interface IId {
  id: string
}

export interface ColumnConfiguration<K extends IId> {
  title: string
  fieldStyles?: SxProps
  hidden?: boolean
  field: (rowData: K) => string
  customFilterAndSearch?: (term: string, rowData: K) => boolean
  customSort?: (a: K, b: K) => number
  customRender?: (rowData: K) => React.ReactNode
}

interface EntrTableProps<K extends IId> {
  data: K[]
  allPagesLoaded?: boolean
  columnConfigs: ColumnConfiguration<K>[]
  onRowClick?: (rowData: K) => void
  onRowEnter?: (rowData: K) => void
  onRowExit?: (rowData: K) => void
  onLoadNextPage?: () => void
  sx?: SxProps
  headerSx?: SxProps
  bodySx?: SxProps
}

export const EntrTable = <K extends IId>({
  data,
  allPagesLoaded = true,
  columnConfigs,
  onRowClick,
  onLoadNextPage,
  sx,
  headerSx,
  bodySx,
  onRowEnter,
  onRowExit
}: EntrTableProps<K>) => {
  const handleInViewChange = (inView: boolean) => {
    if (inView && data.length > 0 && onLoadNextPage) {
      onLoadNextPage()
    }
  }

  return (
    <Box sx={{ minHeight: 0, overflow: 'hidden auto', ...sx }}>
      <Table sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: '#fff',
              ...headerSx
            }}
          >
            {columnConfigs.map((item: ColumnConfiguration<K>) => {
              if (item.hidden) return null
              return (
                <TableCell key={item.title} sx={item.fieldStyles}>
                  <BoxRow sx={{ height: 24 }}>
                    <Typography>{item.title}</Typography>
                  </BoxRow>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody sx={{ paddingBottom: '1.5rem' }}>
          {data.map((item: K) => {
            return (
              <TableRow
                key={item.id ?? Math.random()}
                onClick={(e) => {
                  e.stopPropagation()
                  onRowClick?.(item)
                }}
                onMouseEnter={() => onRowEnter?.(item)}
                onMouseLeave={() => onRowExit?.(item)}
                hover
                sx={{ ...bodySx, cursor: onRowClick ? 'pointer' : 'default' }}
              >
                {columnConfigs.map((config) => {
                  if (config.hidden) return null
                  return (
                    <TableCell key={config.title} sx={config.fieldStyles}>
                      {config.customRender ? (
                        config.customRender(item)
                      ) : (
                        <Typography color="secondary" variant="body1">
                          {config.field(item)}
                        </Typography>
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

      {!allPagesLoaded && (
        <InView
          as="div"
          onChange={handleInViewChange}
          trackVisibility={true}
          delay={300}
        >
          <div
            style={{
              height: '2rem',
              display: 'block'
            }}
          ></div>
        </InView>
      )}
    </Box>
  )
}
