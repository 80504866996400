import React from 'react'
import { SxProps } from '@mui/material'
import { StyledTabs } from './TabsStyle'

export type TabsOrientation = 'horizontal' | 'vertical'

export interface TabsProps {
  /** Custom styling for MUI Tabs. */
  sx?: SxProps
  /** Callback function fired when the selected tab changes. */
  onChange?: (event: React.SyntheticEvent, newValue: number) => void
  /** The index of the currently selected tab. */
  value?: number
  /** The orientation of the tabs. */
  orientation?: TabsOrientation
  /** The children of Tabs. */
  children?: React.ReactNode
}

/**
 * Tabs component for navigating between different content areas.
 */
export const Tabs: React.FC<TabsProps> = ({
  value,
  onChange,
  orientation,
  sx,
  children
}) => {
  // Ensures that the onChange and value props are properly used for controlling the Tabs.
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (onChange) {
      onChange(event, newValue)
    }
  }

  return (
    <StyledTabs
      value={value}
      onChange={handleChange}
      sx={sx}
      orientation={orientation}
    >
      {children}
    </StyledTabs>
  )
}
