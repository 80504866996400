import React from 'react'
import { SelectOption } from '../SelectOptionsLabel/SelectOptionsLabel'
import { BoxRow } from '../common'
import { EntrTableFilterDropDown } from './EntrTableFilterDropDown'
import { EntrTableSearchInput } from './EntrTableSearchInput'
import { SxProps, Theme } from '@mui/system'

interface EntrTableFiltersProps {
  searchPlaceholder?: string
  searchable?: boolean
  initialSearch?: string
  onUpdateSearchCallback?: (value: string) => void
  searchParamName?: string
  filters?: {
    field: string
    options: (string | SelectOption)[]
    onUpdateSelectionCallback?: (values: string[]) => void
    paramName?: string
  }[]
  sx?: SxProps<Theme>
}

export const EntrTableFilters: React.FC<EntrTableFiltersProps> = ({
  searchPlaceholder,
  searchable,
  initialSearch,
  searchParamName,
  filters,
  onUpdateSearchCallback,
  sx
}) => {
  return (
    <>
      <BoxRow gap="1rem" marginBottom={'3.5vh'} sx={sx}>
        {searchable && (
          <EntrTableSearchInput
            placeholder={searchPlaceholder || ''}
            paramName={searchParamName}
            initialSearch={initialSearch}
            onUpdateSearchCallback={onUpdateSearchCallback}
          />
        )}
        {filters?.map(
          ({ field, options, onUpdateSelectionCallback, paramName }) => (
            <EntrTableFilterDropDown
              key={field}
              name={field}
              options={options}
              paramName={paramName}
              onUpdateSelectionCallback={onUpdateSelectionCallback}
            />
          )
        )}
      </BoxRow>
    </>
  )
}
