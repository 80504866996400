import {
  BasicUser,
  BasicUserRole,
  Role,
  User,
  UserRole
} from 'models/User'
import {
  BasicUserApiResponse,
  UserApiResponse,
  UserApiPaginatedResponse as UserApiPaginatedResponse,
  UserRoleApiPaginatedResponse,
  UserRoleApiResponse,
  BasicUserRoleApiResponse,
  RoleApiResponse
} from './UserApiResponse'

export const toBasicUser = (
  response: BasicUserApiResponse | UserApiResponse
): BasicUser => {
  return {
    ...response,
    fullName: [response.firstName, response.lastName].join(' ')
  }
}

export const toUser = (response: UserApiResponse): User => {
  return {
    ...response,
    fullName: [response.firstName, response.lastName].join(' ')
  }
}

export const toUsers = (response: UserApiPaginatedResponse): User[] => {
  return response.content.map(toUser)
}

export const toRole = (role: RoleApiResponse): Role => {
  switch (role) {
    case RoleApiResponse.ADMIN:
      return Role.ADMIN
    case RoleApiResponse.MEMBER:
      return Role.MEMBER
    default:
      return Role.MEMBER
  }
}

export const fromRole = (role: Role): RoleApiResponse => {
  switch (role) {
    case Role.ADMIN:
      return RoleApiResponse.ADMIN
    case Role.MEMBER:
      return RoleApiResponse.MEMBER
    default:
      return RoleApiResponse.MEMBER
  }
}

export const toUserRole = (response: UserRoleApiResponse): UserRole => {
  return {
    user: toUser(response.user),
    role: toRole(response.role)
  }
}

export const toUserRoles = (
  response: UserRoleApiPaginatedResponse
): UserRole[] => {
  return response.content.map(toUserRole)
}

export const toBasicUserRole = (
  response: BasicUserRoleApiResponse
): BasicUserRole => {
  return {
    user: toBasicUser(response.user),
    role: toRole(response.role)
  }
}
