import {
  Box,
  Button,
  Input,
  Switch,
  SwitchProps,
  TableCell,
  Typography,
  styled
} from '@mui/material'

export const FormulaContainer = styled(Box)`
  flex: 1 1 auto;
  margin: 1em;
  align-items: center;
`

export const FormButton = styled(Button)`
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 0px 14px;
`

export const FormButtonLabel = styled(Typography)`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.secondary.main};
`

export const DraftStatusIndicatorText = styled(Typography)`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.tertiary.main};
`

export const UnstyledInput = styled(Input)`
  background-color: ${({ theme }) => theme.palette.beige.main};
  border: none;
  box-shadow: none;
  padding: 0 0.5em;
  fontweight: 600;
  font-size: 16px;
  lineheight: 20px;
`

export const MeasurementInput = styled(Box)`
  background-color: ${({ theme }) => theme.palette.beige.main};
  border: none;
  box-shadow: none;
  padding: 0 0.5em;
  fontweight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid rgba(0, 0, 0, 0.06);
`

export const FormulaTableHeaderCell = styled(TableCell)`
  font-weight: 400;
  font-size: 14px;
  padding: 0 8px;
  color: ${({ theme }) => theme.palette.secondary.main};

  [data-sortable] {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
`

// Based on IOS switch from the mui docs: https://mui.com/material-ui/react-switch/
export const EntrSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, size }) => {
  // TODO: should take one size param (small, large, etc) and infer all sizes
  const width = size === 'small' ? 33 : 42
  const height = size === 'small' ? 18 : 26
  const thumbSize = size === 'small' ? 14 : 24
  const thumbBorder = size === 'small' ? 8 : 8

  return {
    width: width,
    height: height,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: `translateX(${thumbSize}px)`,
        color: theme.palette.background.default,
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.success.main,
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: theme.palette.success.main,
        border: `${thumbBorder}px solid ${theme.palette.background.default}`
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.beigeDark.main
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: thumbSize,
      height: thumbSize
    },
    '& .MuiSwitch-track': {
      borderRadius: (thumbSize + 2) / 2,
      backgroundColor: theme.palette.beigeDark.main,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  }
})
