import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { FormulaNutrientsScale } from 'models/Formula'
import { toTableNutrientsRowGroup } from './OverridesNutrientsMapper'
import {
  getFormulaNutrients,
  setNutritionFactLabelEdits
} from 'state/labels/nutritions/NutritionFactLabelsSlice'
import { FormulaNFPOverride } from 'models/FormulaLabel'
import { NutrientsOverrides } from './NutrientsOverides'

export const NutrientsOverridesContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const formulaId = useAppSelector((sate) => sate.formulator.formula.id)

  const [overridesMap, setOverridesMap] = React.useState<Record<
    string,
    FormulaNFPOverride
  > | null>(null)

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const actualFormulaNutrients = useAppSelector(
    (state) => state.formulator.formulaNutrients
  )

  const formulaNutrients = useAppSelector(
    (state) => state.nutritionFactLabels.formulaNutrients
  )

  const ageGroup = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.ageGroup
  )
  const regulationId = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.regulationId
  )

  const nutritionFactLabelEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits
  )

  const nutritionFactsPreviewNutrients = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.nutrients
  )

  const loadingNFPLabel = useAppSelector(
    (state) => state.nutritionFactLabels.loadingNFPLabel
  )

  const handleOverrideChange = React.useCallback(
    (id: string, name?: string, amount?: string, dv?: string) => {
      setOverridesMap((prevOverridesMap) => {
        const currentMap = prevOverridesMap ?? {}
        return {
          ...currentMap,
          [id]: {
            nutrientId: id,
            nutrientDisplayName: name ?? currentMap[id]?.nutrientDisplayName,
            amount: amount ?? currentMap[id]?.amount,
            dv: dv ?? currentMap[id]?.dv
          }
        }
      })
    },
    []
  )

  React.useEffect(() => {
    // If regulation Id changes, clear the overrides map.
    setOverridesMap(null)
  }, [regulationId])

  React.useEffect(() => {
    // Only populates it when it's empty.
    if (
      overridesMap === null &&
      !loadingNFPLabel &&
      nutritionFactsPreviewNutrients
    ) {
      setOverridesMap(
        nutritionFactsPreviewNutrients.reduce((acc, n) => {
          acc[n.nutrient.id] = {
            nutrientId: n.nutrient.id,
            nutrientDisplayName: n.overrideValues?.nutrientDisplayName,
            amount: n.overrideValues?.amount,
            dv: n.overrideValues?.dv
          }
          return acc
        }, {} as Record<string, FormulaNFPOverride>)
      )
    }
  }, [nutritionFactsPreviewNutrients])

  React.useEffect(() => {
    void dispatch(
      setNutritionFactLabelEdits({
        ...nutritionFactLabelEdits,
        nutritionFactLabelPreview: {
          ...nutritionFactLabelEdits.nutritionFactLabelPreview,
          nutrients:
            nutritionFactLabelEdits.nutritionFactLabelPreview.nutrients.map(
              (n) => {
                const overrides = overridesMap?.[n.nutrient.id]
                if (overrides) {
                  return {
                    ...n,
                    overrideValues: {
                      amount: overrides.amount,
                      dv: overrides.dv,
                      nutrientDisplayName: overrides.nutrientDisplayName
                    }
                  }
                }
                return n
              }
            )
        }
      })
    )
  }, [overridesMap])

  const nutrientTableRowGroups = React.useMemo(() => {
    if (formulaNutrients) {
      return toTableNutrientsRowGroup(
        formulaNutrients,
        overridesMap ?? {},
        handleOverrideChange
      )
    }
    return []
  }, [formulaNutrients, overridesMap, handleOverrideChange])

  React.useEffect(() => {
    if (formulaId && regulationId && ageGroup) {
      void dispatch(
        getFormulaNutrients({
          companyId: currentCompany.id,
          formulaId: formulaId,
          scale: FormulaNutrientsScale.SERVING_SIZE,
          ageGroup: ageGroup,
          regulationId: regulationId
        })
      )
    }
  }, [formulaId, regulationId, ageGroup, actualFormulaNutrients])

  return <NutrientsOverrides nutrientTableRowGroups={nutrientTableRowGroups} />
}
