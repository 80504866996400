import { InputAdornment } from '@mui/material'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { ReactComponent as SearchIcon } from 'assets/Search.svg'
import DebouncedTextField from '../DebouncedTextField/DebouncedTextField'

export interface EntrTableSearchInputProps {
  placeholder?: string
  paramName?: string
  initialSearch?: string
  onUpdateSearchCallback?: (value: string) => void
}

export const EntrTableSearchInput: React.FC<EntrTableSearchInputProps> = ({
  placeholder = '',
  paramName,
  onUpdateSearchCallback,
  initialSearch
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleInputChange = (value: string) => {
    if (onUpdateSearchCallback) {
      onUpdateSearchCallback(value)
    }

    if (paramName) {
      if (value) {
        searchParams.set(paramName, value)
      } else {
        searchParams.delete(paramName)
      }
      setSearchParams(searchParams)
    }
  }

  return (
    <DebouncedTextField
      placeholder={placeholder}
      defaultValue={initialSearch || searchParams.get('search')}
      sx={{ flexGrow: 2, maxWidth: '500px' }}
      onChange={(e) => handleInputChange(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ marginLeft: '0.5rem' }}>
            <SearchIcon />
          </InputAdornment>
        )
      }}
      debounceTime={500}
    />
  )
}
