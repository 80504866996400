import React, { useState } from 'react'
import { DownloadModalContent } from './DownloadModal'
import { Option } from 'components/common'
import html2canvas from '@cedar-kr/html2canvas'
import { useAppSelector } from 'state/hooks'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { ModalContext } from 'components/Modal/ModalContext'
import { LoadingStateContext } from 'components/LoadingUI/LoadingUIContext'

export const ImageDownloadModalContainer: React.FC = () => {
  const formulaName = useAppSelector((state) => state.formulator.formula.name)
  const { showError } = React.useContext(SnackbarContext)
  const { closeModal } = React.useContext(ModalContext)
  const { showLoading, hideLoading } = React.useContext(LoadingStateContext)

  enum OptionId {
    NUTRITION_FACTS = 'nutrition-facts',
    INGREDIENT_STATEMENT = 'ingredient-statement',
    ALLERGEN_STATEMENT = 'allergen-statement',
    LABEL_DESCRIPTION = 'label-description',
    TRANSPARENT_BACKGROUND = 'transparent-background'
  }

  const [options, setOptions] = useState([
    {
      id: OptionId.NUTRITION_FACTS,
      label: 'Nutrition Facts Panel',
      value: true,
      disabled: false
    },
    {
      id: OptionId.INGREDIENT_STATEMENT,
      label: 'Ingredient Statement',
      value: true,
      disabled: false
    },
    {
      id: OptionId.ALLERGEN_STATEMENT,
      label: 'Allergen Statement',
      value: true,
      disabled: false
    },
    {
      id: OptionId.LABEL_DESCRIPTION,
      label: 'Label Description',
      value: true,
      disabled: false
    },
    {
      id: OptionId.TRANSPARENT_BACKGROUND,
      label: 'Transparent Background',
      value: false,
      disabled: false
    }
  ])

  const handleChange = (updatedOption: Option<boolean>) => {
    setOptions((prevOptions) => {
      const updatedOptions = prevOptions.map((option) => {
        if (option.id === updatedOption.id) {
          return {
            ...option,
            value: updatedOption.value
          }
        }
        return option
      })
      return updatedOptions
    })
  }

  const handleDownload = (options: Option<boolean>[]) => {
    showLoading({ blockUI: true, message: 'Downloading image...' })
    const element = document.querySelector('#nutrition-fact-label')
    const ignoredElements = options.filter((o) => !o.value).map((o) => o.id)

    if (element !== null && element instanceof HTMLElement) {
      void html2canvas(element, {
        scale: 10,
        backgroundColor: options.find(
          (o) => o.id === OptionId.TRANSPARENT_BACKGROUND
        )?.value
          ? 'transparent'
          : 'white',
        ignoreElements: (el) => {
          return ignoredElements.includes(el.id)
        }
      })
        .then((dataUrl) => {
          const a = document.createElement('a')
          a.download = `${formulaName} - Nutrition Fact Label`
          a.href = dataUrl.toDataURL('image/png')
          a.click()
          a.remove()
          closeModal()
        })
        .catch(() => {
          showError('Could not download nutrition facts label')
        })
        .finally(() => {
          hideLoading()
        })
    } else {
      showError('Could not download nutrition fact label')
      hideLoading()
    }
  }

  const downloadDisabled = React.useMemo(() => {
    // Disable download if all the options are off excluding the transparent background option.
    return options
      .filter((o) => o.id !== OptionId.TRANSPARENT_BACKGROUND)
      .every((o) => !o.value)
  }, [options])

  return (
    <DownloadModalContent
      title="Image Download"
      options={options}
      onChange={handleChange}
      onDownloadClick={handleDownload}
      onCancelClick={closeModal}
      downloadDisabled={downloadDisabled}
    />
  )
}
