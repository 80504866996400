import { Typography } from '@mui/material'
import { DateWithTooltip } from 'components/DateWithTooltip/DateWithTooltip'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { BoxColumnBasic, BoxRow, ViewerRow } from 'components/common'
import { MeasurementType } from 'models/Measurement'
import { DetailedSimpleIngredient } from 'models/SimpleIngredient'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  createSimpleIngredientCost,
  deleteSimpleIngredientCost,
  updateSimpleIngredientCost
} from 'state/simple_ingredients/SimpleIngredientsSlice'
import { useDebouncedCallback } from 'use-debounce'
import { MenuButton } from 'components/MenuButton/MenuButton'
import { Button } from 'components/Button/Button'
import { IngredientCostRow } from './IngredientCostRow'

interface IngredientCostProps {
  simpleIngredientId: string
  disabled: boolean
}

export const IngredientCost: React.FC<IngredientCostProps> = ({
  simpleIngredientId,
  disabled
}) => {
  const dispatch = useAppDispatch()
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const simpleIngredient: DetailedSimpleIngredient | undefined = useAppSelector(
    (state) =>
      state.simpleIngredients.simpleIngredients.items.find(
        (simpleIngredient) => simpleIngredient.id === simpleIngredientId
      )
  )
  const measurements = useAppSelector(
    (state) => state.simpleIngredientMeasurementSlice.measurements
  )
  const { showError } = React.useContext(SnackbarContext)

  const getMeasurementIdFromUnit = (unit: string | undefined) => {
    if (!measurements) {
      return
    }
    const measurement = measurements.find((m) => m.unit === unit)
    return measurement?.id
  }

  const getMeasurementIdFromType = (type: MeasurementType) => {
    if (!measurements) {
      return ''
    }
    const measurement = measurements.find((m) => m.type === type)
    return measurement?.id || ''
  }

  const handleCostUpdate = useDebouncedCallback(
    (
      simpleIngredientCostId: string,
      values: {
        cost?: number
        unit?: string
        note?: string
      }
    ) => {
      if (disabled || !simpleIngredient) {
        return
      }
      const simpleIngredientCost = simpleIngredient.costs.find(
        (currentSimpleIngredientCost) =>
          currentSimpleIngredientCost.id === simpleIngredientCostId
      )
      if (!simpleIngredientCost) {
        return
      }
      const newCost =
        values.cost != simpleIngredientCost.cost ? values.cost : undefined
      const newUnit =
        values.unit != simpleIngredientCost.measurement.unit
          ? values.unit
          : undefined
      const newMeasurementId = getMeasurementIdFromUnit(newUnit)
      const newNote =
        values.note != simpleIngredientCost.note ? values.note : undefined
      if (
        newCost === undefined &&
        newMeasurementId === undefined &&
        newNote === undefined
      ) {
        return
      }
      void dispatch(
        updateSimpleIngredientCost({
          companyId: currentCompany.id,
          simpleIngredientId: simpleIngredient.id,
          simpleIngredientCostId,
          measurementId: newMeasurementId,
          cost: newCost,
          note: newNote
        })
      ).catch(() => {
        showError(`Failed to update cost for ${simpleIngredient.name}.`)
      })
    },
    500
  )

  const handleDeleteCost = (simpleIngredientCostId: string) => {
    if (disabled || !simpleIngredient) {
      return
    }
    void dispatch(
      deleteSimpleIngredientCost({
        companyId: currentCompany.id,
        simpleIngredientId: simpleIngredient.id,
        simpleIngredientCostId
      })
    ).catch(() => {
      showError(`Failed to delete cost for ${simpleIngredient.name}.`)
    })
  }

  const handleCreateSimpleIngredientCost = () => {
    if (disabled || !simpleIngredient) {
      return
    }
    void dispatch(
      createSimpleIngredientCost({
        companyId: currentCompany.id,
        simpleIngredientId: simpleIngredient.id,
        measurementId: getMeasurementIdFromType(MeasurementType.KILOGRAM),
        cost: 0
      })
    ).catch(() => {
      showError(`Failed to add cost for ${simpleIngredient.name}.`)
    })
  }

  return (
    <BoxColumnBasic
      style={{
        gap: '12px',
        padding: '12px',
        backgroundColor: '#F6F5F4',
        borderRadius: '8px'
      }}
    >
      <ViewerRow>
        <Typography
          style={{
            fontWeight: '500',
            fontSize: '16px',
            color: '#1A2C42BF'
          }}
        >
          Cost
        </Typography>
        <BoxRow gap="8px" justifyContent="flex-end">
          {simpleIngredient && simpleIngredient.costs.length > 0 ? (
            <>
              <Typography
                style={{
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#233C586E',
                  paddingBottom: '1px'
                }}
              >
                last updated{' '}
              </Typography>
              <DateWithTooltip
                date={
                  simpleIngredient.costs.reduce(
                    (max, cost) =>
                      new Date(cost.updatedAt) > new Date(max)
                        ? cost.updatedAt
                        : max,
                    simpleIngredient.costs[0]?.updatedAt || ''
                  ) || ''
                }
                style={{
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#233C586E',
                  paddingBottom: '1px'
                }}
              />
            </>
          ) : (
            <Button
              size="small"
              color="secondary"
              onClick={handleCreateSimpleIngredientCost}
              disabled={disabled}
            >
              Add new cost
            </Button>
          )}
        </BoxRow>
      </ViewerRow>
      {simpleIngredient && simpleIngredient.costs.length > 0 && (
        <BoxColumnBasic>
          {simpleIngredient?.costs.map((cost) => (
            <IngredientCostRow
              key={cost.id}
              cost={cost}
              handleCostUpdate={handleCostUpdate}
              handleDeleteCost={handleDeleteCost}
              disabled={disabled}
            />
          ))}
        </BoxColumnBasic>
      )}
    </BoxColumnBasic>
  )
}
