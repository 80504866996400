import { SimpleTagsPaginated } from 'models/Tags'
import { api } from '../config/AxiosConfig'
import { toSimpleTagsPaginated } from './TagsApiMapper'
import { SimpleTagApiPaginatedResponse } from './TagsApiResponse'

export const TagsApi = {
  getTags: (companyId: string): Promise<SimpleTagsPaginated> => {
    return api
      .get<SimpleTagApiPaginatedResponse>(`/companies/${companyId}/tags`)
      .then((res) => toSimpleTagsPaginated(res.data))
  }
}
