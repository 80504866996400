import { styled, Box, Typography } from '@mui/material'

export const SupplierViewerDivider = styled(Box)`
  grid-column: 1/3;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.05);
`

export const SupplierViewerTypography = styled(Typography)`
  font-size: 16px;
  lineheight: 20px;

  span {
    color: rgba(26, 44, 66, 0.75);
  }
`
