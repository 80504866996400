import React from 'react'
import { Chip, useTheme } from '@mui/material'
import { ReactComponent as DotIcon } from 'assets/Dot.svg'
import { ReactComponent as HollowDotIcon } from 'assets/DotOutline.svg'
import { FormulaStatus } from 'models/Formula'
import { BoxRow } from 'components/common'
import { ExpandMore } from '@mui/icons-material'

interface ChipColorConfig {
  textColor: string
  bgColor: string
  dotColor: string
}

export const StatusChip: React.FC<{
  status: FormulaStatus
  width?: string
  style?: React.CSSProperties
  showDropArrow?: boolean
}> = ({ status, width = '130px', style, showDropArrow = false }) => {
  const theme = useTheme()
  const getColor = (status: FormulaStatus): ChipColorConfig => {
    switch (status) {
      case FormulaStatus.IN_REVIEW:
        return {
          bgColor: theme.palette.statusColors.pink.main,
          textColor: theme.palette.statusTextColors.pink.main,
          dotColor: theme.palette.statusTextColors.pink.main
        }
      case FormulaStatus.PRIVATE_DRAFT:
        return {
          bgColor: theme.palette.statusColors.grey.main,
          textColor: theme.palette.statusTextColors.grey.main,
          dotColor: theme.palette.statusTextColors.grey.main
        }
      case FormulaStatus.APPROVED:
        return {
          bgColor: theme.palette.statusColors.green.main,
          textColor: theme.palette.statusTextColors.green.main,
          dotColor: theme.palette.success.main
        }
      case FormulaStatus.IN_MARKET:
        return {
          bgColor: theme.palette.statusColors.blue.main,
          textColor: theme.palette.statusTextColors.blue.main,
          dotColor: theme.palette.statusTextColors.blue.main
        }
      default:
        return {
          bgColor: theme.palette.statusColors.grey.main,
          textColor: theme.palette.statusTextColors.grey.main,
          dotColor: theme.palette.statusTextColors.grey.main
        }
    }
  }

  const { textColor, bgColor, dotColor } = getColor(status)
  const dotFillColor =
    dotColor === theme.palette.statusTextColors.grey.main
      ? 'transparent'
      : dotColor
  const DotMaker =
    dotColor === theme.palette.statusTextColors.grey.main
      ? HollowDotIcon
      : DotIcon
  const dot = (
    <DotMaker fill={dotFillColor} stroke={dotColor} height="8px" width="8px" />
  )

  return (
    <Chip
      label={
        <BoxRow justifyContent={'space-between'} gap={'5px'}>
          {dot}
          {status}
          {showDropArrow && (
            <ExpandMore
              style={{ color: textColor }}
              sx={{ width: '0.8em', height: '0.8em' }}
            />
          )}
        </BoxRow>
      }
      sx={{
        color: textColor,
        backgroundColor: bgColor,
        maxHeight: '21px',
        width: width,
        paddingLeft: '4px',
        paddingRight: '4px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
      }}
      style={{ ...style }}
    />
  )
}
