import { BasicAllergen } from 'models/Allergen'
import { api } from '../config/AxiosConfig'
import { toAllergens } from './AllergensApiMapper'
import { AllergenApiResponse } from './AllergensApiResponse'
import { GetAllergensApiParams } from './AllergensApiRequest'

export const AllergensApi = {
  getAllergens: (
    params: GetAllergensApiParams
  ): Promise<BasicAllergen[]> => {
    return api
      .get<AllergenApiResponse[]>(
        '/allergens',
        {
          params,
          paramsSerializer: {
            indexes: null
          }
        }
        )
      .then((res) => toAllergens(res.data))
  }
}
