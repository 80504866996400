import { Typography, Tooltip } from '@mui/material'
import { displayDate } from 'common/utils'
import React from 'react'

interface DateWithTooltipProps {
  date: string
  style?: React.CSSProperties
}

export const DateWithTooltip: React.FC<DateWithTooltipProps> = ({
  date,
  style
}) => {
  const fullDate = new Date(date).toLocaleString()
  const humanReadableDate = displayDate(date)

  return (
    <Tooltip title={fullDate} enterDelay={500} enterNextDelay={500}>
      <Typography variant="subtitle2" style={style} noWrap>
        {humanReadableDate}
      </Typography>
    </Tooltip>
  )
}
