import { BasicUser } from './User'
import { Nutrient } from './Nutrient'
import { BasicCompany } from './Company'
import { BasicSupplier } from './Supplier'
import { BasicFormula } from './Formula'

export enum IngredientType {
  SIMPLE = 'simple_ingredient',
  FORMULA = 'formula'
}

export interface BasicIngredient {
  id: string
  name: string
  friendlyId?: string
  type: IngredientType
  public?: boolean
}

export interface DetailedIngredient extends BasicIngredient {
  company: BasicCompany
  description?: string
  createdBy?: BasicUser
  createdAt: string
  updatedAt: string
  ingredientStatement?: string
  formulas: BasicFormula[]
  suppliers: BasicSupplier[]
  isSugar: boolean
}

export interface DetailedIngredientsPaginated {
  items: DetailedIngredient[]
  page: number
  pages: number
}
export interface IngredientNutrient {
  ingredient: BasicIngredient
  nutrient: Nutrient
  amount: number
  children: IngredientNutrient[]
  isCalculated: boolean
}

export interface IngredientSearchResult extends BasicIngredient {
  highlightedName: string
  suppliers: BasicSupplier[]
}

export interface IngredientSearchResultsPaginated {
  items: IngredientSearchResult[]
  page: number
  pages: number
}
