import { NutritionLabelText } from '../../components/NutritionLabelText'
import { NutritionLabelLinearContainer } from '../NutritionLabelLinearCanadianStyles'

export interface ItemLinearSettings {
  boldName?: boolean
  comma?: boolean
}

export interface ItemLinearValues {
  name: string
  amount: string | number
  unit: string
  dvPercentage?: string
  override?: React.ReactNode
}

export interface ItemRowProps extends ItemLinearSettings {
  name: string
  amount: string | number
  unit: string
  dvPercentage?: string
  override?: React.ReactNode
}

export const ItemLinear: React.FC<ItemRowProps> = ({
  name,
  amount,
  unit,
  dvPercentage,
  comma = true
}) => (
  <NutritionLabelLinearContainer>
    <NutritionLabelText bold points={7}>
      {name}
    </NutritionLabelText>
    <NutritionLabelText points={7}>
      {' '}
      {amount} {unit}
    </NutritionLabelText>
    <NutritionLabelText points={7}>
      {dvPercentage && ` (${dvPercentage} %)`}
    </NutritionLabelText>
    <NutritionLabelText points={7}>{comma && ',\u00A0'}</NutritionLabelText>
  </NutritionLabelLinearContainer>
)
