import { BoxColumnBasic } from 'components/common'
import React from 'react'
import { TabContent } from '../TabContent'
import { CommentEdit, CommentEditProps } from './components/CommentEdit'
import {
  CommentItem,
  CommentItemProps,
  CommentItemSkeleton
} from './components/CommentItem'

export const CommentItemsSkeleton: React.FC = () => {
  const numberOfItems = 5
  const skeletons = Array.from({ length: numberOfItems }, (_, index) => (
    <CommentItemSkeleton key={index} />
  ))

  return <>{skeletons}</>
}

export interface CommentsProps {
  commentItems?: CommentItemProps[]
  commentEditProps: CommentEditProps
  loading?: boolean
}

export const Comments: React.FC<CommentsProps> = ({
  commentEditProps,
  commentItems = [],
  loading = false
}) => {
  return (
    <TabContent
      scrollToBottom={true}
      footerElement={<CommentEdit {...commentEditProps} />}
    >
      <BoxColumnBasic gap="2rem" height="100%">
        {loading && <CommentItemsSkeleton />}
        {!loading &&
          commentItems.map((item, index) => (
            <CommentItem key={`comment-item-${index}`} {...item} />
          ))}
      </BoxColumnBasic>
    </TabContent>
  )
}
