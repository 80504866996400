import { Measurement } from 'models/Measurement'
import { MeasurementApiResponse } from './MeasurementApiResponse'

export const toMeasurement = (
  response: MeasurementApiResponse
): Measurement => {
  return {
    ...response
  }
}

export const toMeasurements = (
  response: MeasurementApiResponse[]
): Measurement[] => {
  return response.map((measurement) => toMeasurement(measurement))
}
