import React from 'react'
import { SettingSubPageLayout } from './components/SettingSubPageLayout'
import { Button, Grid, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  sendInvitation,
  withdrawInvitation,
  getInvitations
} from 'state/invitations/InvitationsSlice'
import { MembersRow } from './components/MembersRow'
import {
  getCompanyUsers,
  removeCompanyUser,
  updateCompanyUserRole
} from 'state/companies/users/CompanyUsersSlice'
import { emailValidator } from 'common/utils'
import dayjs from 'dayjs'
import { ModalContext } from 'components/Modal/ModalContext'
import { MembersSkeleton } from './components/MembersSkeleton'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { InputFieldLabel } from 'components/InputFieldLabel/InputFieldLabel'
import { Actions, Subjects } from 'core/Permissions/AbilityConstants'
import { AbilityContext, Can } from 'core/Permissions/AbilityContext'
import { Role } from 'models/User'

const Members: React.FC = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.users.currentUser)
  const userRoles = useAppSelector((state) => state.companyUsers.userRoles)
  const usersLoading = useAppSelector((state) => state.companyUsers.loading)
  const removing = useAppSelector((state) => state.companyUsers.removing)
  const updating = useAppSelector((state) => state.companyUsers.updating)

  const usersError = useAppSelector((state) => state.companyUsers.error)
  const invitations = useAppSelector(
    (state) => state.invitations.invitations || []
  )
  const invitationsLoading = useAppSelector(
    (state) => state.invitations.loading
  )
  const sending = useAppSelector((state) => state.invitations.sending)
  const withdrawing = useAppSelector((state) => state.invitations.withdrawing)
  const invitationsError = useAppSelector((state) => state.invitations.error)
  const [email, setEmail] = React.useState('')
  const [validEmail, setValidEmail] = React.useState(false)
  const [withdrawInvite, setWithdrawInvite] = React.useState(false)
  const [removeUser, setRemoveUser] = React.useState(false)
  const [updateUserRole, setUpdateUserRole] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const { showConfirmationModal } = React.useContext(ModalContext)
  const { showError, showSuccess } = React.useContext(SnackbarContext)
  const ability = React.useContext(AbilityContext)

  const handleNewInvite = () => {
    void dispatch(sendInvitation({ email, companyId: user.companyId }))
  }

  const handleRemoveCompanyUser = React.useCallback(
    (userId: string, companyId: string, name: string) => {
      showConfirmationModal({
        title: 'Remove Member',
        message: (
          <>
            Are you sure you want to remove <b>{name}</b> from the company?
          </>
        ),
        danger: true,
        yesText: 'Remove',
        noText: 'Cancel',
        onYesClicked: () => {
          setRemoveUser(true)
          void dispatch(removeCompanyUser({ userId, companyId }))
        }
      })
    },
    [showConfirmationModal]
  )

  const handleChangeRole = React.useCallback(
    (
      userId: string,
      fullName: string,
      companyId: string,
      role_value: string
    ) => {
      const roleKey = Object.keys(Role).find(
        (key) => Role[key as keyof typeof Role] === role_value
      )
      const role: Role | undefined = roleKey
        ? Role[roleKey as keyof typeof Role]
        : undefined
      if (role) {
        showConfirmationModal({
          title: 'Change Role',
          message: (
            <>
              Are you sure you want to change the role of <b>{fullName}</b> to{' '}
              <b>{role_value}</b>?
            </>
          ),
          yesText: 'Change',
          noText: 'Cancel',
          onYesClicked: () => {
            setUpdateUserRole(true)
            void dispatch(updateCompanyUserRole({ companyId, userId, role }))
          }
        })
      }
    },
    [showConfirmationModal]
  )

  const handleDeleteInvitation = React.useCallback(
    (email: string, companyId: string) => {
      showConfirmationModal({
        title: 'Withdraw Invitation',
        danger: true,
        message: (
          <>
            Are you sure you want to withdraw invitation of <b>{email}</b>?
          </>
        ),
        yesText: 'Withdraw',
        noText: 'Cancel',
        onYesClicked: () => {
          setWithdrawInvite(true)
          void dispatch(withdrawInvitation({ email, companyId }))
        }
      })
    },
    [showConfirmationModal]
  )

  const getDate = (dateString: string): string => {
    const day = dayjs(dateString)
    return `${day.format('LL')} at ${day.format('LT')}`
  }

  React.useEffect(() => {
    if (user.companyId !== '') {
      if (ability.can(Actions.READ, Subjects.USERS)) {
        void dispatch(getCompanyUsers({ companyId: user.companyId }))
      }
      if (ability.can(Actions.READ, Subjects.INVITATIONS)) {
        void dispatch(getInvitations({ companyId: user.companyId }))
      }
    }
  }, [user])

  React.useEffect(() => {
    setValidEmail(emailValidator(email))
  }, [email])

  React.useEffect(() => {
    if (!removing && removeUser && !usersError) {
      setRemoveUser(false)
      showSuccess('Member has been removed.')
      void dispatch(getCompanyUsers({ companyId: user.companyId }))
    }
  }, [removing])

  React.useEffect(() => {
    if (!updating && updateUserRole && !usersError) {
      setUpdateUserRole(false)
      showSuccess('Member role updated.')
      void dispatch(getCompanyUsers({ companyId: user.companyId }))
    }
  }, [updating])

  React.useEffect(() => {
    if (usersError) {
      if (removeUser) {
        setRemoveUser(false)
        showError('There was a problem removing user.')
      } else {
        showError('There was a problem loading members.')
      }
    }
  }, [usersError])

  React.useEffect(() => {
    if (!sending && validEmail && !invitationsError) {
      setEmail('')
      showSuccess('Invitation has been sent.')
      void dispatch(getInvitations({ companyId: user.companyId }))
    }
  }, [sending])

  React.useEffect(() => {
    if (!withdrawing && withdrawInvite && !invitationsError) {
      setWithdrawInvite(false)
      showSuccess('Invitation has been withdrawn.')
      void dispatch(getInvitations({ companyId: user.companyId }))
    }
  }, [withdrawing])

  React.useEffect(() => {
    if (invitationsError) {
      if (validEmail && !withdrawInvite) {
        setEmail('')
        showError('There was a problem sending invitation.')
      } else if (withdrawInvite) {
        setWithdrawInvite(false)
        showError('There was a problem withdrawing invitation.')
      } else {
        showError('There was a problem loading invitations.')
      }
    }
  }, [invitationsError])

  React.useEffect(() => {
    setLoading(usersLoading || invitationsLoading)
  }, [usersLoading, invitationsLoading])

  return (
    <>
      {loading && <MembersSkeleton />}

      {!loading && (
        <SettingSubPageLayout title="Members">
          <Can I={Actions.CREATE} a={Subjects.INVITATIONS}>
            <Grid container spacing={2} direction="column" width={'20vw'}>
              <Grid item>
                <InputFieldLabel
                  label="Add Member"
                  placeholder="Enter user email"
                  onChange={setEmail}
                  disabled={false}
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={!validEmail}
                  color="secondary"
                  onClick={handleNewInvite}
                  fullWidth
                >
                  Send Invite
                </Button>
              </Grid>
            </Grid>
          </Can>

          <Can I={Actions.READ} a={Subjects.USERS}>
            <Grid container spacing={2} marginTop={'20px'} width={'80vw'}>
              {userRoles.map((userRole) => (
                <MembersRow
                  key={userRole.user.id}
                  name={userRole.user.fullName}
                  photoPath={userRole.user.imagePath}
                  date={`Joined on ${getDate(userRole.user.createdAt)}`}
                  handleRemove={() =>
                    handleRemoveCompanyUser(
                      userRole.user.id,
                      userRole.user.companyId,
                      userRole.user.fullName
                    )
                  }
                  handleChangeRole={(role) =>
                    handleChangeRole(
                      userRole.user.id,
                      userRole.user.fullName,
                      userRole.user.companyId,
                      role
                    )
                  }
                  role={userRole.role}
                ></MembersRow>
              ))}
            </Grid>
          </Can>
          <Can I={Actions.READ} a={Subjects.INVITATIONS}>
            <Typography variant="h3" marginTop={'40px'} marginBottom={'20px'}>
              Invitations
            </Typography>
            <Grid>
              {invitations.map((invitation) => (
                <MembersRow
                  key={invitation.email}
                  name={invitation.email}
                  photoPath=""
                  date={`Invited on ${getDate(invitation.createdAt)}`}
                  handleRemove={() =>
                    handleDeleteInvitation(invitation.email, user.companyId)
                  }
                ></MembersRow>
              ))}
            </Grid>
          </Can>
        </SettingSubPageLayout>
      )}
    </>
  )
}

export default Members
