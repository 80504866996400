import { FormGroup, MenuItem, Popover } from '@mui/material'
import { ReactComponent as OpenArrowIcon } from 'assets/OpenArrow.svg'
import { Button } from 'components/Button/Button'
import { BoxRow, Option } from 'components/common'
import React from 'react'

interface MultibuttonProps<T> {
  onClick: (option: Option<string>) => void
  options: Option<string>[]
  label: string
}

export const Multibutton = <T,>({
  onClick,
  options,
  label
}: MultibuttonProps<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = React.useMemo(() => Boolean(anchorEl), [anchorEl])
  const id = React.useMemo(() => (open ? 'simple-popover' : undefined), [open])

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [anchorEl]
  )

  const handleClose = React.useCallback(() => {
    setAnchorEl(null)
  }, [anchorEl])

  const handleOnClick = React.useCallback(
    (option: Option<string>) => {
      onClick(option)
      handleClose()
    },
    [onClick, handleClose]
  )

  return (
    <BoxRow>
      <Button color="secondary" onClick={handleClick}>
        {label}
        <OpenArrowIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        sx={{
          borderRadius: '8px'
        }}
      >
        <FormGroup
          sx={{
            padding: '6px',
            minWidth: '200px'
          }}
        >
          {options.map((o) => (
            <MenuItem
              key={o.id}
              sx={{ padding: '6px', fontSize: '12px', fontWeight: '500' }}
              onClick={() => handleOnClick({ ...o })}
            >
              {o.label}
            </MenuItem>
          ))}
        </FormGroup>
      </Popover>
    </BoxRow>
  )
}
