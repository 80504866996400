import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DetailedIngredient } from 'models/Ingredient'
import { SupplierSimpleIngredient } from 'models/Supplier'
import { SimpleIngredientApi } from 'services/apis/simple_ingredient/SimpleIngredientApi'
import { GetIngredientSuppliersRequest } from './IngredientRequest'

interface IngredientState {
  ingredients: DetailedIngredient[]
  loading: boolean
  saving: boolean
  editingIngredient?: string
  supplierIngredients: SupplierSimpleIngredient[]
}

const initialState: IngredientState = {
  ingredients: [],
  loading: false,
  saving: false,
  supplierIngredients: []
}

export const getIngredientSuppliers = createAsyncThunk(
  'ingredients/suppliers/get',
  async ({ companyId, ingredientId }: GetIngredientSuppliersRequest) => {
    return await SimpleIngredientApi.getSimpleIngredientSuppliers(
      companyId,
      ingredientId
    )
  }
)

const ingredientSlice = createSlice({
  name: 'ingredientSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getIngredientSuppliers.pending, (state: IngredientState) => {
        state.loading = true
      })
      .addCase(
        getIngredientSuppliers.fulfilled,
        (state, action: PayloadAction<SupplierSimpleIngredient[]>) => {
          action.payload.forEach((supplierIngredient) => {
            if (
              !state.supplierIngredients.some(
                (si) => si.id === supplierIngredient.id
              )
            ) {
              state.supplierIngredients.push(supplierIngredient)
            }
          })
          state.loading = false
        }
      )
      .addCase(getIngredientSuppliers.rejected, (state: IngredientState) => {
        state.loading = false
      })
  }
})

export default ingredientSlice.reducer
