import { ChevronLeft } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import React from 'react'
import { BoxRow } from '../common'

const SIDEBAR_WIDTH = '45%'
const ANIMATION_TIME = '0.5s'

interface SideBarLayoutProps {
  sidebar: React.ReactNode
  children: React.ReactNode
  alwaysOpen?: boolean
  defaultOpen?: boolean
}

export const SideBarLayout: React.FC<SideBarLayoutProps> = ({
  sidebar,
  children,
  alwaysOpen = false,
  defaultOpen = true
}) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(
    alwaysOpen || defaultOpen
  )

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen || alwaysOpen)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          width: '65%',
          display: 'flex',
          flexGrow: 1,
          flexShrink: 1,
          transition: `all ${ANIMATION_TIME} ease-in-out`
        }}
      >
        {children}
        {!alwaysOpen && (
          <BoxRow>
            <IconButton
              color="inherit"
              aria-label="open sidebar"
              onClick={toggleSidebar}
              sx={{
                position: 'relative',
                width: '30px',
                height: '30px',
                transform: sidebarOpen ? `rotate(180deg)` : 'none',
                transition: `all ${ANIMATION_TIME} ease-in-out`
              }}
            >
              <ChevronLeft />
            </IconButton>
          </BoxRow>
        )}
      </Box>
      <Box
        sx={{
          width: sidebarOpen ? SIDEBAR_WIDTH : '0',
          transition: `all ${ANIMATION_TIME} ease-in-out`,
          border: '0.5px solid rgba(185, 185, 185, 0.41)',
          boxShadow:
            '47px 107px 47px rgba(0, 0, 0, 0.01), 27px 60px 40px rgba(0, 0, 0, 0.05), 12px 27px 29px rgba(0, 0, 0, 0.09), 3px 7px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          overflow: 'hidden'
        }}
      >
        {sidebar}
      </Box>
    </Box>
  )
}
