import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { SimpleTagsPaginated, Tag } from 'models/Tags'
import { BasicUser, UserRole } from 'models/User'
import { LoadingState } from '../CommonState'
import {
  GetCreatedByFiltersRequest,
  GetSuppliersFiltersRequest,
  GetTagsFiltersRequest
} from './FiltersRequest'
import { UserApi } from 'services/apis/user/UserApi'
import { TagsApi } from 'services/apis/tags/TagsApi'
import { BasicSupplier, DetailedSupplierPaginated } from 'models/Supplier'
import { SupplierApi } from 'services/apis/supplier/SupplierApi'

interface FiltersState extends LoadingState {
  createdByFilterOptions: BasicUser[]
  tagFilterOptions: Tag[]
  supplierFilterOptions: BasicSupplier[]
}

const initialState: FiltersState = {
  createdByFilterOptions: [],
  tagFilterOptions: [],
  supplierFilterOptions: [],
  loading: false,
  error: false
}

export const getCreatedByFilters = createAsyncThunk(
  'filters/createdBy/get',
  async ({ companyId }: GetCreatedByFiltersRequest) => {
    return await UserApi.getCompanyUsers(companyId)
  }
)

export const getTagsFilters = createAsyncThunk(
  'filters/tags/get',
  async ({ companyId }: GetTagsFiltersRequest) => {
    return await TagsApi.getTags(companyId)
  }
)

export const getSupplierFilters = createAsyncThunk(
  'filters/suppliers/get',
  async ({ companyId, withPublicSuppliers }: GetSuppliersFiltersRequest) => {
    return await SupplierApi.getSuppliers(companyId, {
      withPublicSuppliers
    })
  }
)

const filtersSlice = createSlice({
  name: 'filtersSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCreatedByFilters.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      getCreatedByFilters.fulfilled,
      (state, action: PayloadAction<UserRole[]>) => {
        state.createdByFilterOptions = action.payload.map((ur) => ur.user)
        state.loading = false
      }
    )
    builder.addCase(getCreatedByFilters.rejected, (state) => {
      state.loading = false
      state.error = true
    })
    builder.addCase(getTagsFilters.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      getTagsFilters.fulfilled,
      (state, action: PayloadAction<SimpleTagsPaginated>) => {
        state.tagFilterOptions = action.payload.items
        state.loading = false
      }
    )
    builder.addCase(getTagsFilters.rejected, (state) => {
      state.loading = false
      state.error = true
    })
    builder.addCase(getSupplierFilters.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      getSupplierFilters.fulfilled,
      (state, action: PayloadAction<DetailedSupplierPaginated>) => {
        state.supplierFilterOptions = action.payload.items.map((ds) => {
          return { id: ds.id, name: ds.name }
        })
        state.loading = false
      }
    )
    builder.addCase(getSupplierFilters.rejected, (state) => {
      state.loading = false
      state.error = true
    })
  }
})

export default filtersSlice.reducer
