import { BasicUser } from './User'
import { FormulaIngredientNutrient } from './FormulaIngredient'
import { Nutrient } from './Nutrient'
import { BasicAllergen, SuggestedAllergen } from './Allergen'

export enum FormulaStatus {
  PRIVATE_DRAFT = 'Private Draft',
  IN_REVIEW = 'In Review',
  APPROVED = 'Approved',
  IN_MARKET = 'In Market'
}

export enum FormulaNutrientsScale {
  TOTAL = 'Total',
  GRAMS_100 = '100g',
  SERVING_SIZE = 'Serving Size'
}

export interface BasicFormula {
  id: string
  name: string
}

export interface FormulaRegulation {
  formulaId: string
  regulationId: string
  isMealOrMainDish?: boolean
  racc?: number
  raccUnit?: string
  referenceFormula?: BasicFormula
}

export interface Formula extends BasicFormula {
  status: FormulaStatus
  imagePath?: string
  note: string
  servingWeight?: number
  containerWeight?: number
  createdAt: string
  updatedAt: string
  createdBy?: BasicUser
  isArchived: boolean
  friendlyId?: string
  cost: FormulaCost
  formulaRegulations: FormulaRegulation[]
}

export interface FormulaCost {
  totalCostPerServing: number | null
  totalCostPerWeight: number | null
}

export interface FormulasPaginated {
  items: Formula[]
  page: number
  total: number
  size: number
  pages: number
}

export interface FormulaNutrientModifier {
  name: string
  amount: number
  unit: string
}

export interface FormulaNutrient {
  formula: BasicFormula
  nutrient: Nutrient
  ingredients: FormulaIngredientNutrient[]
  total: number
  dvPercentage?: number
  children: FormulaNutrient[]
  modifiers: FormulaNutrientModifier[]
}

export interface FormulaAllergens {
  formula: BasicFormula
  allergens: BasicAllergen[]
  suggestedAllergens: SuggestedAllergen[]
}

export interface FormulaAllergen {
  formula: BasicFormula
  allergen: BasicAllergen
}
