import React from 'react'
import { PercentDailyValueContainer } from '../NutritionLabelDualStyles'
import { NutritionLabelText } from '../../components/NutritionLabelText'
import { NutritionLabelSeparator } from '../../components/NutritionLabelSeparator'

export interface NutritionLabelDualColumnProps {
  header: string
  rightHeader?: boolean
  preHeader?: string
  dvHeader?: string
  topNutrientsContent: React.ReactNode
  bottomNutrientsContent: React.ReactNode
  nutrientNameColumns?: boolean
}

export const NutritionLabelDualColumn: React.FC<
  NutritionLabelDualColumnProps
> = ({
  header,
  preHeader,
  dvHeader,
  topNutrientsContent,
  bottomNutrientsContent,
  rightHeader = false,
  nutrientNameColumns = false
}) => (
  <div style={{ display: 'flex', flexDirection: 'column', minWidth: '120px' }}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: rightHeader ? 'flex-end' : 'flex-start'
      }}
    >
      <NutritionLabelText bold points={6} leading={4}>
        {preHeader ? preHeader : '\u00A0'}
      </NutritionLabelText>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline'
        }}
      >
        <NutritionLabelText bold points={nutrientNameColumns ? 16 : 22}>
          {header}
        </NutritionLabelText>
        {nutrientNameColumns && (
          <NutritionLabelText bold points={22}>
            {'\u00A0'}
          </NutritionLabelText>
        )}
      </div>
    </div>
    <NutritionLabelSeparator type="normal" />
    <PercentDailyValueContainer>
      <NutritionLabelText bold points={6} leading={4}>
        {dvHeader ? dvHeader : '\u00A0'}
      </NutritionLabelText>
      <NutritionLabelText bold points={8} leading={4}>
        {'\u00A0'}
      </NutritionLabelText>
    </PercentDailyValueContainer>
    <NutritionLabelSeparator type="hairline" />
    {topNutrientsContent}
    <NutritionLabelSeparator type="thick" />
    {bottomNutrientsContent}
  </div>
)
