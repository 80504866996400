import { PATHS } from 'common/constants'
import { IngredientType } from 'models/Ingredient'
import { FormulatorContext } from 'pages/Formulator/components/FormulatorIngredients/FormulatorProvider'
import React from 'react'
import { getExecutedCompanyTargets } from 'state/formulator/formulaTargets/FormulaTargetsSlice'
import { getFormulaHistory } from 'state/formulator/history/FormulaHistorySlice'
import {
  getFormulaIngredientMeasurements,
  getFormulaIngredients,
  updateFormulaIngredientMeasurement,
  updateFormulaIngredientSupplier,
  updateIngredientAmount,
  updateIngredientWastePercentage
} from 'state/formulator/ingredients/FormulatorIngredientsSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { getIngredientSuppliers } from 'state/ingredients/IngredientsSlice'
import { IngredientsTable } from './IngredientsTable'
import { IngredientRowProps } from './components/row/IngredientRow'
import { IngredientSearchRowContainer } from './components/search/IngredientSearchRowContainer'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'

export const IngredientsTableContainer: React.FC = () => {
  const { viewOptions, updateIngredientIdSelection, selectedIngredientIdsMap } =
    React.useContext(FormulatorContext)

  const dispatch = useAppDispatch()
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const formulaIngredients =
    useAppSelector((state) => state.formulatorIngredients.formulaIngredients) ||
    []
  const ingredientsLoading = useAppSelector(
    (state) => state.formulatorIngredients.loading
  )
  const [initialLoading, setInitialLoading] = React.useState(true)
  const analytics = React.useContext(AnalyticsContext)

  React.useEffect(() => {
    if (formulaId) {
      void dispatch(
        getFormulaIngredients({
          companyId: currentCompany.id,
          formulaId: formulaId
        })
      ).finally(() => setInitialLoading(false))
    }
  }, [formulaId, currentCompany.id])

  const handleRowCheckedChange = React.useCallback(
    (checked: boolean, formulaIngredientId: string) => {
      updateIngredientIdSelection(formulaIngredientId, checked)
    },
    [selectedIngredientIdsMap]
  )

  const handleOnDetailNavigationClick = React.useCallback(
    (id: string, type: IngredientType) => {
      const windowPath =
        type === IngredientType.FORMULA
          ? `${PATHS.FORMULAS}/${id}`
          : `${PATHS.INGREDIENTS}?ingredientId=${id}`
      window.open(windowPath, '_blank', 'noreferrer')
    },
    []
  )

  const handleUpdateWastePercentage = React.useCallback(
    (formulaIngredientId: string, wastePercentage: number) => {
      analytics.updatedWastePercentage(formulaId)
      void dispatch(
        updateIngredientWastePercentage({
          companyId: currentCompany.id,
          formulaId: formulaId,
          formulaIngredientId: formulaIngredientId,
          wastePercentage: wastePercentage
        })
      ).then((action) => {
        if (updateIngredientWastePercentage.fulfilled.match(action)) {
          void dispatch(
            getFormulaHistory({
              companyId: currentCompany.id,
              formulaId: formulaId
            })
          )
        }
      })
    },
    [currentCompany.id, formulaId]
  )

  const handleGetIngredientSuppliers = React.useCallback(
    (ingredientId: string) => {
      return dispatch(
        getIngredientSuppliers({
          companyId: currentCompany.id,
          ingredientId: ingredientId
        })
      )
        .unwrap()
        .then((res) =>
          res.map((ssi) => {
            return {
              id: ssi.supplier.id,
              name: ssi.supplier.name
            }
          })
        )
    },
    [currentCompany.id]
  )

  const handleSelectSupplier = React.useCallback(
    (formulaIngredientId: string, supplierId: string) => {
      analytics.changedIngredientSupplier(formulaId)
      void dispatch(
        updateFormulaIngredientSupplier({
          companyId: currentCompany.id,
          formulaId: formulaId,
          formulaIngredientId: formulaIngredientId,
          supplierId: supplierId
        })
      )
    },
    [formulaId, currentCompany.id]
  )

  const handleIngredientAmountChange = React.useCallback(
    (formulaIngredientId: string, amount: number, measurementId: string) => {
      analytics.modifiedIngredientWeight(formulaId)
      void dispatch(
        updateIngredientAmount({
          companyId: currentCompany.id,
          formulaId: formulaId,
          formulaIngredientId: formulaIngredientId,
          amount_in_measurement: amount || 0,
          measurementId: measurementId
        })
      ).then((action) => {
        if (updateIngredientAmount.fulfilled.match(action)) {
          void dispatch(
            getExecutedCompanyTargets({
              companyId: currentCompany.id,
              formulaId: formulaId
            })
          )
          void dispatch(
            getFormulaHistory({
              companyId: currentCompany.id,
              formulaId: formulaId
            })
          )
        }
      })
    },
    [currentCompany.id, formulaId]
  )

  const handleUpdateIngredientMeasurement = React.useCallback(
    (formulaIngredientId: string, measurementId: string) => {
      analytics.modifiedIngredientMeasurement(formulaId)
      void dispatch(
        updateFormulaIngredientMeasurement({
          companyId: currentCompany.id,
          formulaId: formulaId,
          formulaIngredientId: formulaIngredientId,
          measurementId: measurementId
        })
      ).then((action) => {
        if (updateFormulaIngredientMeasurement.fulfilled.match(action)) {
          void dispatch(
            getFormulaHistory({
              companyId: currentCompany.id,
              formulaId: formulaId
            })
          )
        }
      })
    },
    [currentCompany.id, formulaId]
  )

  const handleGetAvailableMeasurements = React.useCallback(
    (ingredientId: string) => {
      return dispatch(
        getFormulaIngredientMeasurements({
          companyId: currentCompany.id,
          ingredientId: ingredientId
        })
      )
        .unwrap()
        .then((response) => {
          return response.map((r) => {
            return {
              id: r.id,
              unit: r.unit
            }
          })
        })
    },
    [currentCompany.id]
  )

  const visibility = React.useMemo(() => {
    return {
      showSupplier: viewOptions[0].value,
      showWastePercentage: viewOptions[1].value,
      showCost: viewOptions[2].value,
      showQuantityPercentage: viewOptions[3].value
    }
  }, [viewOptions])

  const rows = React.useMemo(() => {
    const output: IngredientRowProps[] = []
    formulaIngredients.forEach((fi) => {
      const row: IngredientRowProps = {
        formulaIngredientId: fi.id,
        cells: {
          ingredient: {
            name: fi.ingredient.name,
            friendlyId: fi.ingredient.friendlyId,
            detailsNavigation: {
              hoverMessage: `Go to ${
                fi.ingredient.type === IngredientType.FORMULA
                  ? 'formula'
                  : 'ingredient'
              } details`,
              onClick: () =>
                handleOnDetailNavigationClick(
                  fi.ingredient.id,
                  fi.ingredient.type
                )
            },
            verified: !fi.ingredient.public
          },
          supplier: {
            currentSupplierName: fi.supplier.name || 'No suppliers',
            getIngredientSuppliers: () =>
            handleGetIngredientSuppliers(fi.ingredient.id),
            onSelect: (supplierId: string) =>
            handleSelectSupplier(fi.id, supplierId),
            noSuppliers: !fi.supplier.name
          },
          cost: {
            totalCost: fi.totalCostForGrams
          },
          wastePercentage: {
            percentage: fi.wastePercentage,
            onUpdate: (percentage) =>
              handleUpdateWastePercentage(fi.id, percentage)
          },
          quantityEditor: {
            amount: {
              value: fi.amountInMeasurement,
              percentage: fi.amountInPercentage,
              onUpdate: (amount) =>
                handleIngredientAmountChange(fi.id, amount, fi.measurement.id)
            },
            showPercentages: visibility.showQuantityPercentage,
            measurement: {
              id: fi.measurement.id,
              unit: fi.measurement.unit,
              onUpdate: (measurementId) =>
                handleUpdateIngredientMeasurement(fi.id, measurementId),
              getAvailableMeasurements: () =>
                handleGetAvailableMeasurements(fi.ingredient.id)
            }
          }
        },
        checked: selectedIngredientIdsMap.get(fi.id) || false,
        onRowCheckedChange: (checked) => handleRowCheckedChange(checked, fi.id),
        visibility: {
          supplier: visibility.showSupplier,
          cost: visibility.showCost,
          wastePercentage: visibility.showWastePercentage
        }
      }
      output.push(row)
    })
    return output
  }, [
    formulaIngredients,
    visibility,
    selectedIngredientIdsMap,
    handleOnDetailNavigationClick,
    handleGetIngredientSuppliers,
    handleSelectSupplier,
    handleUpdateWastePercentage,
    handleIngredientAmountChange,
    handleUpdateIngredientMeasurement,
    handleGetAvailableMeasurements,
    handleRowCheckedChange
  ])

  const ingredientSearchRow = React.useMemo(
    () => <IngredientSearchRowContainer />,
    []
  )

  return (
    <IngredientsTable
      {...visibility}
      ingredientRows={rows}
      ingredientSearchRow={ingredientSearchRow}
      loading={initialLoading || ingredientsLoading}
    />
  )
}
