import React, { useState, useEffect, FC } from 'react'
import { Animate } from 'react-move'

type AnimatedProgressWrapperProps = {
  valueStart: number
  valueEnd: number
  duration: number
  repeat?: boolean
  easingFunction: (t: number) => number
  children: (value: number) => React.ReactElement
}

export const AnimatedProgressWrapper: FC<AnimatedProgressWrapperProps> = ({
  valueStart,
  valueEnd,
  duration,
  repeat = false,
  easingFunction,
  children
}) => {
  const [isAnimated, setIsAnimated] = useState(false)

  useEffect(() => {
    let interval: number | undefined

    if (repeat) {
      interval = window.setInterval(() => {
        setIsAnimated((prevIsAnimated) => !prevIsAnimated)
      }, duration * 1000)
    } else {
      setIsAnimated(!isAnimated)
    }

    return () => {
      if (interval) window.clearInterval(interval)
    }
  }, [repeat, duration])

  return (
    <Animate
      start={() => ({
        value: valueStart
      })}
      update={() => ({
        value: [isAnimated ? valueEnd : valueStart],
        timing: {
          duration: duration * 1000,
          ease: easingFunction
        }
      })}
    >
      {({ value }) => children(value)}
    </Animate>
  )
}
