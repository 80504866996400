import { Option } from 'components/common'
import { FilterApiResponse } from 'services/apis/filter/FilterApiResponse'
import { FilterSearchResponse } from './FiltersHook'

export const getOptionsFromFilterApiResponse = <T>(
  filterApiResponse: FilterApiResponse<T>,
  optionMapping: (responseItem: T) => Option<boolean>,
  selectedIds: string[],
  searching?: boolean
): FilterSearchResponse => {
  const options: Option<boolean>[] = []
  const selectedIdsSet = new Set(selectedIds)
  const page = filterApiResponse.options.page
  const pages = filterApiResponse.options.pages

  // Pass through the fetched options.
  filterApiResponse.options.items.forEach((fo) => {
    // Convert the response item to an option.
    const option = optionMapping(fo)

    // In the event it's a selected option and we're not searching, this will be handled in the next section.
    if (!searching && selectedIdsSet.has(option.id)) {
      return
    }

    // In the event it's a search, then the option should be included even if it's selected.
    if (searching) {
      option.value = selectedIdsSet.has(option.id)
    }

    options.push(option)
  })

  // Add the missing selected options if no search is being done and only on first page.
  if (page === 1 && !searching) {
    filterApiResponse.selected.forEach((so) => {
      const option = optionMapping(so)
      option.value = true
      if (selectedIdsSet.has(option.id)) {
        options.push(option)
      }
    })
  }

  return {
    options: options,
    page: page,
    pages: pages
  } as FilterSearchResponse
}
