import { Tag, SimpleTagsPaginated, BasicTag } from 'models/Tags'
import { toBasicCompany } from '../company/CompanyApiMapper'
import { toBasicUser } from '../user/UserApiMapper'
import {
  BasicTagApiResponse,
  SimpleTagApiPaginatedResponse,
  SimpleTagApiResponse
} from './TagsApiResponse'

export const toBasicTag = (response: BasicTagApiResponse): BasicTag => {
  return {
    ...response
  }
}

export const toSimpleTag = (response: SimpleTagApiResponse): Tag => {
  return {
    ...response,
    company: toBasicCompany(response.company),
    createdBy: response.createdBy ? toBasicUser(response.createdBy) : undefined
  }
}

export const toSimpleTagsPaginated = (
  response: SimpleTagApiPaginatedResponse
): SimpleTagsPaginated => {
  return {
    ...response,
    items: response.items.map((item) => toSimpleTag(item))
  }
}
