import { SxProps } from '@mui/system'
import React from 'react'
import { BoxRow } from '../common'
import { EntrTableFilter, EntrTableFilterProps } from './Filter/EntrTableFilter'
import { EntrTableSearchInput } from './EntrTableSearchInput'

export interface EntrTableFiltersV2Props {
  search?: {
    placeholder?: string
    paramName?: string
    initialSearch?: string
    onUpdate: (value: string) => void
  }
  filters?: EntrTableFilterProps[]
  sx?: SxProps
}

export const EntrTableFiltersV2: React.FC<EntrTableFiltersV2Props> = ({
  search,
  filters,
  sx
}) => {
  return (
    <BoxRow gap="1rem" marginBottom={'3.5vh'} sx={sx}>
      {search && (
        <EntrTableSearchInput
          placeholder={search.placeholder ?? ''}
          paramName={search.paramName}
          initialSearch={search.initialSearch}
          onUpdateSearchCallback={search.onUpdate}
        />
      )}
      {filters?.map((filter) => (
        <EntrTableFilter key={filter.name} {...filter} />
      ))}
    </BoxRow>
  )
}
