import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DeletingState,
  DownloadingState,
  LoadingState,
  UploadingState
} from '../../CommonState'
import {
  DeleteFormulaNoteDocumentRequest,
  DownloadFormulaNoteDocumentRequest,
  GetFormulaNoteDocumentsRequest,
  PreviewFormulaNoteDocumentRequest,
  UploadFormulaNoteDocumentRequest
} from './FormulaNoteDocumentsRequest'
import { BasicDocument } from 'models/Document'
import { SimpleIngredientApi } from 'services/apis/simple_ingredient/SimpleIngredientApi'
import { FormulaApi } from 'services/apis/formula/FormulaApi'

export interface FormulaNoteDocumentState
  extends LoadingState,
    DeletingState,
    UploadingState,
    DownloadingState {
  formulaNoteDocuments: BasicDocument[]
  previewedDocument?: BasicDocument
}

const initialState: FormulaNoteDocumentState = {
  formulaNoteDocuments: [],
  previewedDocument: undefined,
  loading: false,
  deleting: false,
  uploading: false,
  downloading: false,
  error: false
}

export const uploadFormulaNoteDocument = createAsyncThunk(
  'formulator/notes/documents/upload',
  async ({
    companyId,
    formulaId,
    file,
    uploadProgressListener
  }: UploadFormulaNoteDocumentRequest) => {
    return await FormulaApi.uploadFormulaNoteDocument(
      companyId,
      formulaId,
      file,
      uploadProgressListener
    )
  }
)

export const previewFormulaNoteDocument = createAsyncThunk(
  'formulator/notes/documents/preview',
  async ({
    companyId,
    formulaId,
    file
  }: PreviewFormulaNoteDocumentRequest): Promise<BasicDocument> => {
    const url = await FormulaApi.getFormulaNoteDocument(
      companyId,
      formulaId,
      file.name,
      false
    )
    return {
      ...file,
      path: url
    }
  }
)

export const downloadFormulaNoteDocument = createAsyncThunk(
  'formulator/notes/documents/download',
  async ({
    companyId,
    formulaId,
    fileName
  }: DownloadFormulaNoteDocumentRequest) => {
    return await FormulaApi.getFormulaNoteDocument(
      companyId,
      formulaId,
      fileName
    )
  }
)

export const deleteFormulaNoteDocument = createAsyncThunk(
  'formulator/notes/documents/delete',
  async ({
    companyId,
    formulaId,
    fileName
  }: DeleteFormulaNoteDocumentRequest) => {
    return await FormulaApi.deleteFormulaNoteDocument(
      companyId,
      formulaId,
      fileName
    )
  }
)

export const getFormulaNoteDocuments = createAsyncThunk(
  'formulator/notes/documents/get',
  async ({ companyId, formulaId }: GetFormulaNoteDocumentsRequest) => {
    return await FormulaApi.getFormulaNoteDocuments(companyId, formulaId)
  }
)

const FormulaNoteDocumentSlice = createSlice({
  name: 'formulaNoteDocumentsSlice',
  initialState,
  reducers: {
    resetPreviewedDocument: (state) => {
      state.previewedDocument = undefined
    }
  },
  extraReducers: (builder) => {
    // formula note documents

    // upload formula note document to url
    builder
      .addCase(
        uploadFormulaNoteDocument.pending,
        (state: FormulaNoteDocumentState) => {
          state.uploading = true
        }
      )
      .addCase(
        uploadFormulaNoteDocument.fulfilled,
        (state: FormulaNoteDocumentState) => {
          state.uploading = false
        }
      )
      .addCase(
        uploadFormulaNoteDocument.rejected,
        (state: FormulaNoteDocumentState) => {
          state.uploading = false
          state.error = true
        }
      )

    // download formula note document from url
    builder
      .addCase(
        downloadFormulaNoteDocument.pending,
        (state: FormulaNoteDocumentState) => {
          state.downloading = true
        }
      )
      .addCase(
        downloadFormulaNoteDocument.fulfilled,
        (state: FormulaNoteDocumentState) => {
          state.downloading = false
        }
      )
      .addCase(
        downloadFormulaNoteDocument.rejected,
        (state: FormulaNoteDocumentState) => {
          state.downloading = false
          state.error = true
        }
      )

    // preview formula note document from url
    builder
      .addCase(
        previewFormulaNoteDocument.pending,
        (state: FormulaNoteDocumentState) => {
          state.downloading = true
        }
      )
      .addCase(
        previewFormulaNoteDocument.fulfilled,
        (
          state: FormulaNoteDocumentState,
          action: PayloadAction<BasicDocument>
        ) => {
          state.downloading = false
          state.previewedDocument = action.payload
        }
      )
      .addCase(
        previewFormulaNoteDocument.rejected,
        (state: FormulaNoteDocumentState) => {
          state.downloading = false
          state.error = true
        }
      )

    // delete formula note document
    builder
      .addCase(
        deleteFormulaNoteDocument.pending,
        (state: FormulaNoteDocumentState) => {
          state.deleting = true
        }
      )
      .addCase(
        deleteFormulaNoteDocument.fulfilled,
        (state: FormulaNoteDocumentState, action: PayloadAction<string>) => {
          state.deleting = false
          state.formulaNoteDocuments = state.formulaNoteDocuments.filter(
            (document) => document.name !== action.payload
          )
        }
      )
      .addCase(
        deleteFormulaNoteDocument.rejected,
        (state: FormulaNoteDocumentState) => {
          state.deleting = false
          state.error = true
        }
      )

    // get formula note documents
    builder
      .addCase(
        getFormulaNoteDocuments.pending,
        (state: FormulaNoteDocumentState) => {
          state.loading = true
        }
      )
      .addCase(
        getFormulaNoteDocuments.fulfilled,
        (
          state: FormulaNoteDocumentState,
          action: PayloadAction<BasicDocument[]>
        ) => {
          state.loading = false
          state.formulaNoteDocuments = action.payload.map((basicDocument) => {
            return {
              ...basicDocument
            }
          })
        }
      )
      .addCase(
        getFormulaNoteDocuments.rejected,
        (state: FormulaNoteDocumentState) => {
          state.loading = false
          state.error = true
        }
      )
  }
})

export const { resetPreviewedDocument } = FormulaNoteDocumentSlice.actions
export default FormulaNoteDocumentSlice.reducer
