import { Skeleton } from '@mui/material'
import ArchivedChip from 'components/ArchivedChip/ArchivedChip'
import { EditableText } from 'components/EditableText/EditableText'
import { BoxColumnBasic, BoxRow } from 'components/common'
import React from 'react'
import {
  StatusDropdown,
  StatusDropdownProps
} from './components/StatusDropdown'

export interface StatusBarProps {
  title: {
    value?: string
    onChange: (newTitle: string) => void
  }
  friendlyId: {
    value?: string
    onChange: (newFriendlyId: string) => void
  }
  status: StatusDropdownProps
  loading?: boolean
  archived: boolean
}

/**
 * Checks if the props are equal and if a re-render is required.
 */
const arePropsEqual = (
  prevProps: StatusBarProps,
  nextProps: StatusBarProps
): boolean => {
  if (prevProps.loading !== nextProps.loading) {
    return false
  }
  if (prevProps.archived !== nextProps.archived) {
    return false
  }
  if (
    prevProps.title.value !== nextProps.title.value ||
    prevProps.title.onChange !== nextProps.title.onChange
  ) {
    return false
  }
  if (
    prevProps.friendlyId.value !== nextProps.friendlyId.value ||
    prevProps.friendlyId.onChange !== nextProps.friendlyId.onChange
  ) {
    return false
  }
  if (
    prevProps.status.value !== nextProps.status.value ||
    prevProps.status.onChange !== nextProps.status.onChange
  ) {
    return false
  }
  return true
}

export const StatusBar = React.memo(
  ({
    loading = false,
    title,
    status,
    archived,
    friendlyId
  }: StatusBarProps) => {
    return (
      <BoxColumnBasic>
        <BoxRow gap={'10px'}>
          {loading && (
            <Skeleton variant={'rectangular'} height={57} width={'50%'} />
          )}
          {!loading && (
            <>
              <EditableText
                initialValue={title.value}
                saveAction={title.onChange}
                placeholder="Untitled Formula"
                size="large"
              />
              <StatusDropdown value={status.value} onChange={status.onChange} />
            </>
          )}
        </BoxRow>
        <BoxRow gap={'5px'}>
          <EditableText
            initialValue={friendlyId.value}
            saveAction={friendlyId.onChange}
            placeholder="00000"
            size="small"
            prefix="#"
          />
          {archived && <ArchivedChip />}
        </BoxRow>
      </BoxColumnBasic>
    )
  },
  arePropsEqual
)
