import React from 'react'
import { NutrientType } from 'models/Nutrient'
import { NutritionLabelText } from '../../components/NutritionLabelText'
import { ItemLinear, ItemLinearSettings, ItemLinearValues } from './ItemLinear'

export interface ItemLinearContentProps {
  itemsLinearProps: ItemLinearProps[]
}

export interface ItemLinearProps {
  nutrientType: NutrientType
  itemLinearValues: ItemLinearValues
}

export const NUTRIENT_TYPE_ITEM_LABEL_SETTINGS: Partial<
  Record<NutrientType, ItemLinearSettings>
> = {
  [NutrientType.TOTAL_FAT]: {
    boldName: true
  },
  [NutrientType.CHOLESTEROL]: {
    boldName: true
  },
  [NutrientType.SODIUM]: {
    boldName: true
  },
  [NutrientType.CARBOHYDRATES]: {
    boldName: true
  },
  [NutrientType.PROTEIN]: {
    boldName: true
  }
}

const getItemLinearSettings = (
  nutrientType: NutrientType
): ItemLinearSettings => {
  return NUTRIENT_TYPE_ITEM_LABEL_SETTINGS[nutrientType] || {}
}

const getItemLabelOverride = (
  nutrientType: NutrientType,
  amount: string,
  unit: string,
  dvPercentage?: string
): React.ReactNode => {
  switch (nutrientType) {
    case NutrientType.ADDED_SUGAR:
      return (
        <NutritionLabelText points={8} wrapText>
          {' ('}
          {`Incl. ${amount}${unit} Added Sugars, `}
          {dvPercentage && `${dvPercentage}% DV`}
          {')'}
        </NutritionLabelText>
      )
    case NutrientType.TRANS_FATTY_ACID:
      return (
        <NutritionLabelText points={8} wrapText>
          , <i>Trans </i>
          {`Fat ${amount}${unit}`}
          {dvPercentage && `${dvPercentage}% DV`}
        </NutritionLabelText>
      )
    case NutrientType.FOLATE_DFE:
      return (
        <NutritionLabelText points={8} wrapText>
          {`Folate ${amount}${unit} DFE`}
          {dvPercentage && `${dvPercentage}% DV`}
        </NutritionLabelText>
      )
  }
  return undefined
}

export const ItemLinearContent: React.FC<ItemLinearContentProps> = ({
  itemsLinearProps = []
}) => {
  return (
    <>
      {itemsLinearProps.map((itemLinearProps, index) => {
        const itemRowSettings = getItemLinearSettings(
          itemLinearProps.nutrientType
        )

        return (
          <React.Fragment key={`item-linear.${index}`}>
            <ItemLinear
              {...itemRowSettings}
              {...itemLinearProps.itemLinearValues}
              comma={index !== 0}
              override={getItemLabelOverride(
                itemLinearProps.nutrientType,
                itemLinearProps.itemLinearValues.amount as string,
                itemLinearProps.itemLinearValues.unit,
                itemLinearProps.itemLinearValues.dvPercentage
              )}
            />
          </React.Fragment>
        )
      })}
      <NutritionLabelText points={8} wrapText>
        .
      </NutritionLabelText>
    </>
  )
}
