import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FormulaHistory } from 'models/FormulaHistory'
import { LoadingState } from '../../CommonState'
import { GetFormulaHistoryRequest } from './FormulaHistoryRequest'
import { FormulaApi } from 'services/apis/formula/FormulaApi'

export interface FormulaHistoryState extends LoadingState {
  history: FormulaHistory
}

const initialState: FormulaHistoryState = {
  history: {
    formula: {
      id: '',
      name: ''
    },
    activity: {
      items: [],
      page: 1,
      total: 0,
      size: 0,
      pages: 0
    }
  },
  loading: false,
  error: false
}

export const getFormulaHistory = createAsyncThunk(
  'formulas/history/get',
  async ({
    companyId,
    formulaId,
    page,
    size = 15
  }: GetFormulaHistoryRequest): Promise<FormulaHistory> => {
    return await FormulaApi.getHistory(companyId, formulaId, { page, size })
  }
)

const formulaHistorySlice = createSlice({
  name: 'formulaHistorySlice',
  initialState,
  reducers: {
    resetState: () => {
      return initialState
    }
  },
  extraReducers(builder) {
    builder.addCase(getFormulaHistory.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getFormulaHistory.fulfilled, (state, action) => {
      state.history = {
        ...action.payload,
        activity: {
          ...action.payload.activity,
          items: [
            ...state.history.activity.items,
            ...action.payload.activity.items.filter(
              (newItem) =>
                !state.history.activity.items.some(
                  (currentItem) =>
                    `${newItem.revisionId}${newItem.createdAt}` ===
                    `${currentItem.revisionId}${currentItem.createdAt}`
                )
            )
          ].sort((a, b) => {
            const aDate = new Date(a.createdAt)
            const bDate = new Date(b.createdAt)
            return bDate.getTime() - aDate.getTime()
          })
        }
      }
      state.loading = false
      state.error = false
    })
    builder.addCase(getFormulaHistory.rejected, (state) => {
      state.loading = false
      state.error = true
    })
  }
})

export const { resetState } = formulaHistorySlice.actions

export default formulaHistorySlice.reducer
