import React from 'react'
import { EditorContentBox, EditorViewer } from './NutritionFactsEditorStyles'
import {
  EditorTopBar,
  EditorTopBarProps
} from './components/EditorTopBar/EditorTopBar'

interface NutritionFactsEditorProps {
  topBarProps: EditorTopBarProps
  preview: React.ReactNode
  panel: React.ReactNode
  show: boolean
}

const NutritionFactsEditor: React.FC<NutritionFactsEditorProps> = ({
  topBarProps,
  preview,
  panel,
  show
}) => {
  return (
    <EditorViewer data-show={show}>
      <EditorTopBar {...topBarProps} />
      <EditorContentBox>
        {preview}
        {panel}
      </EditorContentBox>
    </EditorViewer>
  )
}

export default NutritionFactsEditor
