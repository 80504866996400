import { FormGroup, MenuItem, Popover, Typography } from '@mui/material'
import { ReactComponent as OpenArrowIcon } from 'assets/OpenArrow.svg'
import { BoxColumnBasic, BoxRow, Option, OptionGroup } from 'components/common'
import { FormButton, FormButtonLabel } from 'pages/Formulator/FormulatorStyles'
import React from 'react'
import { Separator } from './AddControllerStyles'

interface AddControllerProps {
  onChange: (option: Option<boolean>) => void
  optionGroups?: OptionGroup<boolean>[]
}

export const AddController: React.FC<AddControllerProps> = ({
  onChange,
  optionGroups = []
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = React.useMemo(() => Boolean(anchorEl), [anchorEl])
  const id = React.useMemo(() => (open ? 'simple-popover' : undefined), [open])

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [anchorEl]
  )

  const handleClose = React.useCallback(() => {
    setAnchorEl(null)
  }, [anchorEl])

  const handleOnClick = React.useCallback(
    (option: Option<boolean>) => {
      onChange(option)
      handleClose()
    },
    [onChange, handleClose]
  )

  return (
    <BoxRow>
      <FormButton aria-describedby={id} onClick={handleClick}>
        <FormButtonLabel>Add</FormButtonLabel>
        <OpenArrowIcon />
      </FormButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        sx={{
          borderRadius: '8px'
        }}
      >
        <FormGroup
          sx={{
            padding: '6px',
            minWidth: '200px'
          }}
        >
          {optionGroups.map((og) => (
            <BoxColumnBasic key={og.label}>
              <BoxColumnBasic
                sx={{ alignItems: 'flex-start', marginBottom: '3px' }}
              >
                <Typography sx={{ padding: '6px' }} variant="subtitle2">
                  {og.label}
                </Typography>
                <Separator />
              </BoxColumnBasic>
              {og.options.map((o) => (
                <MenuItem
                  key={o.id}
                  sx={{ padding: '6px' }}
                  onClick={() => handleOnClick({ ...o, value: true })}
                  disabled={o.value}
                >
                  {o.label}
                </MenuItem>
              ))}
            </BoxColumnBasic>
          ))}
        </FormGroup>
      </Popover>
    </BoxRow>
  )
}
