import { Delete } from '@mui/icons-material'
import React from 'react'
import { DropdownButton } from './DropdownButton'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { addActiveOperation } from 'state/targets'
import { BoxRow } from 'components/common'
import { isNormalizedLogicalOperation } from 'services/apis/target/TargetApiMapper'
import { CreateOperationActionType } from 'models/Target'
import { buttonStyle, buttonTextStyle } from '../../TargetsStyles'
import { ModalContext } from 'components/Modal/ModalContext'

export const CreateOperationButton: React.FC<{
  operationId: string
  disabled?: boolean
}> = ({ operationId, disabled = false }) => {
  const dispatch = useAppDispatch()
  const { showInfoModal } = React.useContext(ModalContext)
  const operation = useAppSelector((state) => {
    return state.targets.activeOperations[operationId]
  })
  const availableTags = useAppSelector(
    (state) => state.tags.tags,
    (a, b) => a.length === b.length
  )

  if (!operation || !isNormalizedLogicalOperation(operation)) {
    return <></>
  }

  return (
    <BoxRow>
      <Delete
        style={{
          visibility: 'hidden'
        }}
      />
      <DropdownButton
        buttonText={'Add condition'}
        buttonTextStyle={buttonTextStyle}
        buttonStyle={buttonStyle}
        disabled={disabled}
        MenuItems={[
          {
            groupName: 'Comparison',
            text: 'Per RACC',
            onClick: () => {
              dispatch(
                addActiveOperation({
                  operationId: operation.id,
                  operationType: CreateOperationActionType.COMPARISON_PER_RACC,
                  level: operation.level
                })
              )
            }
          },
          {
            groupName: 'Comparison',
            text: 'RDI Per RACC',
            onClick: () => {
              dispatch(
                addActiveOperation({
                  operationId: operation.id,
                  operationType:
                    CreateOperationActionType.COMPARISON_RDI_PER_RACC,
                  level: operation.level
                })
              )
            }
          },
          {
            groupName: 'Comparison',
            text: 'Per custom serving',
            onClick: () => {
              dispatch(
                addActiveOperation({
                  operationId: operation.id,
                  operationType: CreateOperationActionType.COMPARISON_SERVING,
                  level: operation.level
                })
              )
            }
          },
          {
            groupName: 'Comparison',
            text: 'Per labeled serving',
            onClick: () => {
              dispatch(
                addActiveOperation({
                  operationId: operation.id,
                  operationType:
                    CreateOperationActionType.COMPARISON_PER_LABELED_SERVING,
                  level: operation.level
                })
              )
            }
          },
          {
            groupName: 'Comparison',
            text: 'Cal from nutrient',
            onClick: () => {
              dispatch(
                addActiveOperation({
                  operationId: operation.id,
                  operationType:
                    CreateOperationActionType.COMPARISON_CAL_NUTRIENT,
                  level: operation.level
                })
              )
            }
          },
          {
            groupName: 'Comparison',
            text: 'Tags in formula',
            onClick: () => {
              if (availableTags.length === 0) {
                showInfoModal({
                  title: 'No tags available',
                  message:
                    'There are no tags available to use in operation. Please create a tag first.',
                  yesText: 'Dismiss'
                })
                return
              }
              dispatch(
                addActiveOperation({
                  operationId: operation.id,
                  operationType:
                    CreateOperationActionType.COMPARISON_TAG_IN_FORMULA,
                  level: operation.level
                })
              )
            }
          },
          {
            groupName: 'Comparison',
            text: 'Text in ingredients',
            onClick: () => {
              dispatch(
                addActiveOperation({
                  operationId: operation.id,
                  operationType:
                    CreateOperationActionType.COMPARISON_TEXT_IN_FORMULA,
                  level: operation.level
                })
              )
            }
          },
          {
            groupName: 'Conditions',
            text: 'Is RACC Small',
            onClick: () => {
              dispatch(
                addActiveOperation({
                  operationId: operation.id,
                  operationType:
                    CreateOperationActionType.CONDITIONAL_SMALL_RACC,
                  level: operation.level
                })
              )
            }
          },
          {
            groupName: 'Conditions',
            text: 'Is Meal And Main Dish',
            onClick: () => {
              dispatch(
                addActiveOperation({
                  operationId: operation.id,
                  operationType:
                    CreateOperationActionType.CONDITIONAL_MEAL_AND_MAIN_DISH,
                  level: operation.level
                })
              )
            }
          },
          {
            groupName: 'Conditions',
            text: 'Allergen free',
            onClick: () => {
              dispatch(
                addActiveOperation({
                  operationId: operation.id,
                  operationType: CreateOperationActionType.CONDITIONAL_ALLERGEN_FREE,
                  level: operation.level
                })
              )
            }
          },
          {
            groupName: 'Target',
            text: 'Another target',
            onClick: () => {
              dispatch(
                addActiveOperation({
                  operationId: operation.id,
                  operationType: CreateOperationActionType.TARGET,
                  level: operation.level
                })
              )
            }
          }
        ]}
      />
    </BoxRow>
  )
}
