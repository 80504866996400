import React from 'react'
import Grid from '@mui/material/Grid'
import { Skeleton } from '@mui/material'
import { SettingSubPageLayout } from './SettingSubPageLayout'

export const ProfileSkeleton: React.FC = () => {
  return (
    <SettingSubPageLayout title="Profile">
      <Grid container spacing={2} direction="column" width={'20vw'}>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <Skeleton variant={'circular'} width={55} height={55} />
          </Grid>
          <Grid item>
            <Skeleton
              variant={'rectangular'}
              width={143}
              height={26}
              sx={{
                marginTop: '4px',
                marginRight: '10px'
              }}
            />
          </Grid>
        </Grid>

        <Grid item sx={{ marginTop: '2px' }}>
          <Skeleton variant={'text'} height={20} width={163} />
          <Skeleton variant={'rectangular'} height={33} />
        </Grid>

        <Grid item sx={{ marginTop: '2px' }}>
          <Skeleton variant={'text'} height={20} width={163} />
          <Skeleton variant={'rectangular'} height={33} />
        </Grid>

        <Grid item sx={{ marginTop: '2px' }}>
          <Skeleton variant={'text'} height={20} width={163} />
          <Skeleton variant={'rectangular'} height={33} />
        </Grid>

        <Grid item sx={{ marginTop: '23px' }}>
          <Skeleton variant={'rectangular'} height={39} />
        </Grid>

        <Grid item sx={{ marginTop: '23px' }}>
          <Skeleton variant={'rectangular'} height={39} />
        </Grid>

        <Grid item sx={{ marginTop: '23px' }}>
          <Skeleton variant={'rectangular'} height={39} />
        </Grid>
      </Grid>
    </SettingSubPageLayout>
  )
}
