import axios, { AxiosProgressEvent } from 'axios'

export const FileApi = {
  uploadFile: async (
    file: File,
    url: string,
    uploadProgressListener?: (
      progressEvent: AxiosProgressEvent
    ) => ProgressEvent | void
  ): Promise<string> => {
    return axios
      .put<string>(url, file, {
        headers: {
          'Content-Type': file.type ? file.type : 'binary/octet-stream'
        },
        onUploadProgress: (progressEvent) => {
          if (uploadProgressListener) {
            uploadProgressListener(progressEvent)
          }
        }
      })
      .then((res) => {
        return res.data
      })
  },

  downloadFile: async (
    url: string,
    fileName: string,
    download = true
  ): Promise<string> => {
    return axios.get<string>(url, { responseType: 'blob' }).then((response) => {
      const blob = new Blob([response.data], {
        type: response.headers['content-type']
      })
      const url = window.URL.createObjectURL(blob)
      if (download) {
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        link.style.display = 'none'

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      return url
    })
  }
}
