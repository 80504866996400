import React from 'react'
import { SetURLSearchParams, useSearchParams } from 'react-router-dom'

export function useQuery(): [
  { [key: string]: string | undefined },
  SetURLSearchParams,
  URLSearchParams
] {
  const [searchParams, setSearchParams] = useSearchParams()
  const getValues = () => {
    const o: { [key: string]: string } = {}
    searchParams.forEach((v, k) => (o[k] = v))
    return o
  }
  const [obj, setObj] = React.useState(getValues())
  React.useEffect(() => {
    setObj(getValues())
  }, [searchParams])

  return [obj, setSearchParams, searchParams]
}

interface UseLoadNextPageArgs {
  page: number
  pages: number
  setCurrentPageCallback: (page: number) => void
}

export function useLoadNextPage({
  page,
  pages,
  setCurrentPageCallback
}: UseLoadNextPageArgs): {
  nextPageCallback: () => void
  allPagesLoaded: boolean
} {
  const [allPagesLoaded, setAllPagesLoaded] = React.useState(false)

  const nextPageCallback = React.useCallback(() => {
    if (page + 1 <= pages) {
      setCurrentPageCallback(page + 1)
    }
  }, [page, pages])

  React.useEffect(() => {
    setAllPagesLoaded(page === pages)
  }, [page, pages])

  return { nextPageCallback, allPagesLoaded }
}
