export enum AllergenDisplayNameLanguageApiResponse {
  ENGLISH = 'english',
  FRENCH = 'french',
  SPANISH = 'spanish'
}

export interface AllergenDisplayNameApiResponse {
  [AllergenDisplayNameLanguageApiResponse.ENGLISH]?: string
  [AllergenDisplayNameLanguageApiResponse.FRENCH]?: string
  [AllergenDisplayNameLanguageApiResponse.SPANISH]?: string
}
export interface AllergenApiResponse {
  name: string
  type: string
  displayNames?: AllergenDisplayNameApiResponse
  description?: string
}
