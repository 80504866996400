import { NutrientType, NutrientUnit } from 'models/Nutrient'
import {
  ComparisonOperator,
  ComparisonType,
  ComparisonUnit,
  ConditionalType,
  CreateOperationActionType,
  ExtendedNutrientUnitForTargets,
  LogicalOperator,
  NormalizedComparisonOperation,
  NormalizedConditionalOperation,
  NormalizedLogicalOperation,
  NormalizedOperation,
  NormalizedOperations,
  NormalizedTarget,
  NormalizedTargetOperation,
  Operation,
  OperationType,
  Target
} from 'models/Target'
import {
  isLogicalOperation,
  isNormalizedLogicalOperation
} from 'services/apis/target/TargetApiMapper'

export const normalizeTarget = (target: Target): NormalizedTarget => {
  return {
    id: target.id,
    name: target.name,
    company: target.company,
    operationId: target.operation?.id,
    updatedAt: target.updatedAt,
    formulaCount: target.formulaCount,
    isEditable: target.isEditable,
    regulation: target.regulation,
  } as NormalizedTarget
}

export const normalizeOperations = (target: Target): NormalizedOperations => {
  const normalizedTargetOperations = {} as NormalizedOperations

  const addOperation = (operation: Operation, parentOperationId = '') => {
    if (!isLogicalOperation(operation)) {
      normalizedTargetOperations[operation.id] = {
        ...operation,
        parentOperationId,
        parentTargetId: target.id
      }
      return
    }

    const { operations, ...operationWithoutChildren } = operation
    normalizedTargetOperations[operation.id] = {
      ...operationWithoutChildren,
      parentOperationId,
      parentTargetId: target.id,
      childOperationsIds: operation.operations.map((subOperation) => {
        return subOperation.id
      })
    }
    operation.operations.forEach((subOperation) => {
      addOperation(subOperation, operation.id)
    })
  }

  if (target.operation) {
    addOperation(target.operation)
  }

  return normalizedTargetOperations
}

export const denormalizeTarget = (
  normalizedTarget: NormalizedTarget,
  normalizedOperations: NormalizedOperations
): Target => {
  const denormalizeOperation = (operationId: string): Operation => {
    const normOp = normalizedOperations[operationId]
    if (isNormalizedLogicalOperation(normOp)) {
      const denormalizedOperation = {
        ...normOp,
        operations: normOp.childOperationsIds.map(denormalizeOperation)
      }
      return denormalizedOperation
    }
    return normOp
  }

  const target = normalizedTarget
  return {
    ...target,
    operation: target.operationId
      ? denormalizeOperation(target.operationId)
      : undefined
  }
}

export const createDefaultOperation = (
  operationCreateType: string,
  parentOperationId: string,
  parentTargetId: string,
  parentOperationLevel?: number
): NormalizedOperation => {
  const level =
    parentOperationLevel != undefined ? parentOperationLevel + 1 : undefined
  const baseOperation = {
    id: Math.random().toString(),
    parentOperationId,
    parentTargetId,
    level
  }
  switch (operationCreateType) {
    // Logical Operations
    case CreateOperationActionType.LOGICAL_AND:
      return {
        ...baseOperation,
        operationType: OperationType.LOGICAL,
        operator: LogicalOperator.AND,
        childOperationsIds: []
      } as NormalizedLogicalOperation
    case CreateOperationActionType.LOGICAL_OR:
      return {
        ...baseOperation,
        operationType: OperationType.LOGICAL,
        operator: LogicalOperator.OR,
        childOperationsIds: []
      } as NormalizedLogicalOperation

    // Comparison Operations
    case CreateOperationActionType.COMPARISON_PER_RACC:
      return {
        ...baseOperation,
        operationType: OperationType.COMPARISON,
        operator: ComparisonOperator.EQUAL,
        comparisonUnit: NutrientUnit.GRAM,
        value: 0,
        comparisonType: ComparisonType.PER_RACC,
        comparedToReferenceFormula: false,
        nutrient: NutrientType.PROTEIN
      } as NormalizedComparisonOperation
    case CreateOperationActionType.COMPARISON_RDI_PER_RACC:
      return {
        ...baseOperation,
        operationType: OperationType.COMPARISON,
        operator: ComparisonOperator.EQUAL,
        comparisonUnit: ExtendedNutrientUnitForTargets.PERCENTAGE,
        value: 0,
        comparisonType: ComparisonType.PER_RACC,
        comparedToReferenceFormula: false,
        comparedToRdi: true,
        nutrient: NutrientType.PROTEIN
      } as NormalizedComparisonOperation
    case CreateOperationActionType.COMPARISON_SERVING:
      return {
        ...baseOperation,
        operationType: OperationType.COMPARISON,
        operator: ComparisonOperator.EQUAL,
        comparisonUnit: NutrientUnit.GRAM,
        value: 0,
        comparisonType: ComparisonType.PER_TARGET_VALUE,
        comparedToReferenceFormula: false,
        nutrient: NutrientType.PROTEIN,
        targetValue: 0,
        targetUnit: ComparisonUnit.GRAM
      } as NormalizedComparisonOperation
    case CreateOperationActionType.COMPARISON_CAL_NUTRIENT:
      return {
        ...baseOperation,
        operationType: OperationType.COMPARISON,
        operator: ComparisonOperator.EQUAL,
        comparisonUnit: ExtendedNutrientUnitForTargets.PERCENTAGE,
        comparisonType: ComparisonType.CALORIES_FROM_NUTRIENT,
        comparedToReferenceFormula: false,
        nutrient: NutrientType.PROTEIN,
        value: 0
      } as NormalizedComparisonOperation
    case CreateOperationActionType.COMPARISON_PER_LABELED_SERVING:
      return {
        ...baseOperation,
        operationType: OperationType.COMPARISON,
        operator: ComparisonOperator.EQUAL,
        comparisonUnit: NutrientUnit.GRAM,
        comparisonType: ComparisonType.PER_LABELED_SERVING,
        comparedToReferenceFormula: false,
        nutrient: NutrientType.PROTEIN,
        value: 0
      } as NormalizedComparisonOperation
    case CreateOperationActionType.COMPARISON_TAG_IN_FORMULA:
      return {
        ...baseOperation,
        operationType: OperationType.COMPARISON,
        operator: ComparisonOperator.EQUAL,
        comparisonUnit: ExtendedNutrientUnitForTargets.TAG,
        comparisonType: ComparisonType.TAGS_IN_FORMULA,
        comparedToReferenceFormula: false,
        value: 0
      } as NormalizedComparisonOperation
    case CreateOperationActionType.COMPARISON_TEXT_IN_FORMULA:
      return {
        ...baseOperation,
        operationType: OperationType.COMPARISON,
        operator: ComparisonOperator.EQUAL,
        comparisonUnit: ExtendedNutrientUnitForTargets.TAG,
        comparisonType: ComparisonType.TEXT_IN_FORMULA,
        text: '',
        comparedToReferenceFormula: false,
        value: 0
      } as NormalizedComparisonOperation

    // Target Operations
    case CreateOperationActionType.TARGET:
      return {
        ...baseOperation,
        operationType: OperationType.TARGET,
        targetId: '',
        targetName: '',
        expectedExecutionReturn: true,
        executeOnReferenceFormula: false
      } as NormalizedTargetOperation

    // Conditional Operations
    case CreateOperationActionType.CONDITIONAL_TARGET:
      return {
        ...baseOperation,
        operationType: OperationType.CONDITIONAL,
        conditionalType: ConditionalType.TARGET,
        targetId: ''
      } as NormalizedConditionalOperation
    case CreateOperationActionType.CONDITIONAL_MEAL_AND_MAIN_DISH:
      return {
        ...baseOperation,
        operationType: OperationType.CONDITIONAL,
        conditionalType: ConditionalType.MEAL_AND_MAIN_DISH,
        isMealOrMainDish: true
      } as NormalizedConditionalOperation
    case CreateOperationActionType.CONDITIONAL_SMALL_RACC:
      return {
        ...baseOperation,
        operationType: OperationType.CONDITIONAL,
        conditionalType: ConditionalType.SMALL_RACC,
        isSmallRacc: true
      } as NormalizedConditionalOperation
    case CreateOperationActionType.CONDITIONAL_ALLERGEN_FREE:
      return {
        ...baseOperation,
        operationType: OperationType.CONDITIONAL,
        conditionalType: ConditionalType.ALLERGEN_FREE,
        allergenType: ''
      } as NormalizedConditionalOperation

    default:
      return {
        ...baseOperation,
        operationType: OperationType.LOGICAL,
        operator: LogicalOperator.AND,
        childOperationsIds: []
      } as NormalizedLogicalOperation
  }
}
