import { Box } from '@mui/material'
import styled from 'styled-components'

export const ActivitySentenceBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  whiteSpace: 'pre-wrap',
  width: '100%'
})
