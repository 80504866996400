import { Button } from 'components/Button/Button'
import { BoxRow } from 'components/common'
import {
  CreateOperationActionType,
  LogicalOperator
} from 'models/Target'
import React from 'react'
import { isNormalizedLogicalOperation } from 'services/apis/target/TargetApiMapper'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { addActiveOperation } from 'state/targets'

export const CreateBlockButton: React.FC<{
  operationId: string
  disabled?: boolean
}> = ({ operationId, disabled = false }) => {
  const dispatch = useAppDispatch()
  const operation = useAppSelector((state) => {
    return state.targets.activeOperations[operationId]
  })

  if (!operation || !isNormalizedLogicalOperation(operation)) {
    return <></>
  }

  const handleAddBlockClick = () => {
    dispatch(
      addActiveOperation({
        operationId: operation.id,
        operationType:
          operation.operator === LogicalOperator.OR
            ? CreateOperationActionType.LOGICAL_AND
            : CreateOperationActionType.LOGICAL_OR,
        level: operation.level
      })
    )
  }

  return (
    <BoxRow>
      <Button
        onClick={handleAddBlockClick}
        disabled={disabled}
        size={'small'}
        color={'secondary'}
      >
        Add condition block
      </Button>
    </BoxRow>
  )
}
