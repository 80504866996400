import { Box, Typography } from '@mui/material'
import React from 'react'

import { ReactComponent as CloseCircle } from 'assets/CloseCircle.svg'
import { convertToFixedFloat } from 'common/utils'
import { Conversion } from 'models/Conversion'
import { useDebouncedCallback } from 'use-debounce'
import {
  IngredientViewerPanelInput,
  IngredientViewerPanelLabel
} from '../../IngredientViewStyles'

interface ConversionItemProps {
  conversion: Conversion
  onChange?: (
    conversion: Conversion,
    amount: number,
    amountInMeasurement: number,
    remove?: boolean
  ) => void
  debounceTime?: number
  disabled?: boolean
  removable?: boolean
}

export const ConversionItem: React.FC<ConversionItemProps> = ({
  conversion,
  onChange,
  debounceTime = 0,
  disabled,
  removable
}) => {
  const [value, setValue] = React.useState<string>('0')
  const [amountInMeasurement, setAmountInMeasurement] = React.useState<string>(
    conversion.amountInMeasurement.toString() || '1'
  )
  const MAX_DECIMAL = 3

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
      ? convertToFixedFloat(e.target.value, MAX_DECIMAL)
      : ''
    setValue(inputValue)
    handleOnChangeDebounced(inputValue)
  }

  const handleOnChangeDebounced = useDebouncedCallback((inputValue: string) => {
    if (onChange && inputValue) {
      onChange(
        conversion,
        parseFloat(inputValue),
        parseFloat(amountInMeasurement)
      )
    }
  }, debounceTime)

  const handleRemove = () => {
    if (onChange) onChange(conversion, 0, parseFloat(amountInMeasurement), true)
  }

  const handleBlur = () => {
    if (value == '') {
      setValue('0')
      handleOnChangeDebounced('0')
    }
  }

  React.useEffect(() => {
    if (conversion) {
      setValue(
        convertToFixedFloat(conversion.equivalentInGrams, MAX_DECIMAL, true)
      )
    }
  }, [conversion])
  const callOnChangedDebounced = useDebouncedCallback(
    () =>
      onChange &&
      onChange(conversion, parseFloat(value), parseFloat(amountInMeasurement)),
    debounceTime
  )

  React.useEffect(() => {
    callOnChangedDebounced()
  }, [amountInMeasurement])

  const handleAmountInMeasurementChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value == amountInMeasurement) return
    setAmountInMeasurement(e.target.value)
  }
  return (
    <>
      <IngredientViewerPanelLabel>
        <Typography>
          <IngredientViewerPanelInput
            sx={{ textAlign: 'center' }}
            type="number"
            onChange={(e) => handleAmountInMeasurementChange(e)}
            value={amountInMeasurement}
            disabled={disabled}
          />{' '}
          <b>{conversion.measurement.name}</b>
        </Typography>
      </IngredientViewerPanelLabel>
      <Box
        sx={{
          '&&': {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            width: 'fit-content',
            padding: 0,
            ':not(:last-child)': {
              marginBottom: '8px'
            }
          },
          justifySelf: 'right'
        }}
      >
        =
        <IngredientViewerPanelInput
          data-value={value}
          type="number"
          inputProps={{ min: 0 }}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
          endAdornment={<Typography>g</Typography>}
        />
        {!!removable && (
          <CloseCircle
            style={{ cursor: 'pointer' }}
            onClick={handleRemove}
            role="button"
            title="remove"
            aria-label="remove"
          />
        )}
      </Box>
    </>
  )
}
