import React from 'react'
import { NutritionFactsProps } from '../NutritionLabel'
import { NutritionLabelVerticalBox } from '../NutritionLabelVertical/NutritionLabelVerticalStyles'
import { NutritionLabelSeparator } from '../components/NutritionLabelSeparator'
import {
  Container,
  FlexContainerColumn,
  FlexContainerColumnSpaced,
  FlexContainerRowSpacedTop
} from '../components/NutritionLabelStyles'
import { NutritionLabelText } from '../components/NutritionLabelText'
import { BottomNutrientsTable } from './components/BottomNutrientsTable'
import { TopNutrientsTable } from './components/TopNutrientsTable'
import { FormulaNutritionFactLanguage } from 'models/FormulaLabel'

export const NutritionLabelVerticalCanadian: React.FC<NutritionFactsProps> = ({
  servingSize,
  servingWeight,
  topNutrients,
  bottomNutrients,
  calories
}) => {
  // In Canadian Regulation, leading = stated leading - font size.

  return (
    <Container>
      <NutritionLabelVerticalBox>
        <FlexContainerColumnSpaced style={{ marginBottom: '3px' }}>
          <NutritionLabelText bold points={13}>
            Nutrition Facts
          </NutritionLabelText>
          <NutritionLabelText bold points={13} leading={1}>
            Valeur nutritive
          </NutritionLabelText>
          <NutritionLabelText points={9} leading={2}>
            Per{' '}
            {
              servingSize[
                FormulaNutritionFactLanguage.ENGLISH as keyof typeof servingSize
              ]
            }{' '}
            ({servingWeight})
          </NutritionLabelText>
          <NutritionLabelText points={9} leading={1}>
            pour{' '}
            {
              servingSize[
                FormulaNutritionFactLanguage.FRENCH as keyof typeof servingSize
              ]
            }{' '}
            ({servingWeight})
          </NutritionLabelText>
        </FlexContainerColumnSpaced>

        <NutritionLabelSeparator type="normal-canadian" />

        <FlexContainerRowSpacedTop>
          <Container>
            <NutritionLabelText bold points={10} leading={4.5}>
              Calories {calories}
            </NutritionLabelText>
            <NutritionLabelSeparator type="thick-canadian" />
          </Container>
          <FlexContainerColumn>
            <NutritionLabelText bold alignRight points={6}>
              % Daily Value
              <NutritionLabelText symbol={true} points={9}>
                *
              </NutritionLabelText>
            </NutritionLabelText>

            <NutritionLabelText bold alignRight points={6} leading={1}>
              % valeur quotidienne
              <NutritionLabelText symbol={true} points={9}>
                *
              </NutritionLabelText>
            </NutritionLabelText>
          </FlexContainerColumn>
        </FlexContainerRowSpacedTop>

        <TopNutrientsTable topNutrients={topNutrients} />
        <NutritionLabelSeparator type="thick-canadian" />

        <BottomNutrientsTable bottomNutrients={bottomNutrients} />

        <NutritionLabelSeparator type="thick-canadian" />
        <FlexContainerColumn>
          <NutritionLabelText points={6.5}>
            *5% or less is <b>a little</b>, 15% or more is <b>a lot</b>
          </NutritionLabelText>
          <NutritionLabelText points={6.5}>
            *5% ou moins c'est <b>peu</b>, 15% ou plus c'est <b>beaucoup</b>
          </NutritionLabelText>
        </FlexContainerColumn>
      </NutritionLabelVerticalBox>
    </Container>
  )
}
