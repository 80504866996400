import React from 'react'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { BasicDocument } from 'models/Document'
import { DetailedIngredient } from 'models/Ingredient'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  deleteSimpleIngredientDocument,
  downloadSimpleIngredientDocument,
  getSimpleIngredientDocuments,
  previewSimpleIngredientDocument,
  uploadSimpleIngredientDocument
} from 'state/simple_ingredients/documents/SimpleIngredientDocumentsSlice'

import { DocumentsPanel } from 'components/DocumentsPanel/DocumentsPanel'
import { LoadingStateContext } from 'components/LoadingUI/LoadingUIContext'

interface IngredientsDocumentsPanelProps {
  simpleIngredient: DetailedIngredient
  readOnly?: boolean
}

export const IngredientsDocumentsPanel: React.FC<
  IngredientsDocumentsPanelProps
> = ({ simpleIngredient, readOnly = true }) => {
  const dispatch = useAppDispatch()

  const [documents, setDocuments] = React.useState<BasicDocument[]>([])

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const simpleIngredientDocuments = useAppSelector(
    (state) => state.simpleIngredientDocuments.simpleIngredientDocuments
  )

  const documentsLoading = useAppSelector(
    (state) => state.simpleIngredientDocuments.loading
  )

  const selectedIngredient = useAppSelector(
    (state) => state.simpleIngredients.selectedSimpleIngredient
  )

  const { showError } = React.useContext(SnackbarContext)

  const { hideLoading } = React.useContext(LoadingStateContext)

  const getDocuments = () => {
    setDocuments([])
    void dispatch(
      getSimpleIngredientDocuments({
        companyId: currentCompany.id,
        simpleIngredientId: simpleIngredient.id
      })
    ).then((action) => {
      hideLoading()
      if (getSimpleIngredientDocuments.rejected.match(action)) {
        showError('There was a problem loading the documents')
      }
    })
  }

  const handleUploadDocument = (
    file: File,
    progressListener: (progress: number | undefined) => void
  ) => {
    return dispatch(
      uploadSimpleIngredientDocument({
        companyId: currentCompany.id,
        simpleIngredientId: simpleIngredient.id,
        file: file,
        uploadProgressListener: (progressEvent) => {
          progressListener(progressEvent.progress)
        }
      })
    ).then(() => {})
  }

  const handlePreviewDocument = (file: BasicDocument) => {
    if (selectedIngredient) {
      return dispatch(
        previewSimpleIngredientDocument({
          companyId: currentCompany.id,
          file: file,
          simpleIngredientId: selectedIngredient?.id
        })
      )
        .unwrap()
        .then((res) => {
          return res.path
        })
    } else {
      return Promise.resolve('')
    }
  }

  const handleDownloadDocument = (file: BasicDocument) => {
    return dispatch(
      downloadSimpleIngredientDocument({
        companyId: currentCompany.id,
        simpleIngredientId: simpleIngredient.id,
        fileName: file.name
      })
    ).then(() => {})
  }

  const handleDeleteDocument = (file: BasicDocument): Promise<void> => {
    return dispatch(
      deleteSimpleIngredientDocument({
        companyId: currentCompany.id,
        simpleIngredientId: simpleIngredient.id,
        fileName: file.name
      })
    ).then(() => {})
  }

  React.useEffect(() => {
    if (currentCompany.id && simpleIngredient.id) {
      getDocuments()
    } else {
      setDocuments([])
    }
  }, [currentCompany.id, simpleIngredient.id])

  React.useEffect(() => {
    setDocuments(simpleIngredientDocuments)
  }, [simpleIngredientDocuments])

  return (
    <DocumentsPanel
      documents={documentsLoading ? [] : documents}
      onDeleteDocument={handleDeleteDocument}
      onDownloadDocument={handleDownloadDocument}
      onPreviewDocument={handlePreviewDocument}
      onUploadDocument={handleUploadDocument}
      refresh={getDocuments}
      readOnly={readOnly}
    />
  )
}
