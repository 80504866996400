import { AbilityBuilder, createMongoAbility } from '@casl/ability'
import { Actions, Subjects } from './AbilityConstants'
import React from 'react'
import { createContextualCan } from '@casl/react'
import { useAuth0 } from '@auth0/auth0-react'

function parsePermissions(permissions: string[]) {
  return permissions?.map((permission) => {
    const [action, subject] = permission.split(':')
    return { action, subject }
  })
}

function defineRulesBasedOnPermissions(permissions: string[]) {
  const { can, rules } = new AbilityBuilder(createMongoAbility)

  const parsedPermissions = parsePermissions(permissions)

  // Get the dict of actions.
  const actionsDict: { [key: string]: keyof typeof Actions } = {}
  for (const key in Actions) {
    const value = Actions[key as keyof typeof Actions]
    actionsDict[value] = key as keyof typeof Actions
  }

  // Get the dict of subjects.
  const subjectsDict: { [key: string]: keyof typeof Subjects } = {}
  for (const key in Subjects) {
    const value = Subjects[key as keyof typeof Subjects]
    subjectsDict[value] = key as keyof typeof Subjects
  }
  parsedPermissions?.forEach(({ action, subject }) => {
    if (action in actionsDict && subject in subjectsDict) {
      can(action, subject)
    }
  })

  return createMongoAbility(rules)
}

export const AbilityContext = React.createContext(createMongoAbility())
export const Can = createContextualCan(AbilityContext.Consumer)

export const AbilityProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { user } = useAuth0()
  const permissions = (
    user ? user['https://entrtechnologies.com/permissions'] : []
  ) as string[]
  const ability = defineRulesBasedOnPermissions(permissions)

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  )
}
