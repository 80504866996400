import { Option } from 'components/common'
import React from 'react'
import { ViewController } from './ViewController'
import { FormulatorContext } from 'pages/Formulator/components/FormulatorIngredients/FormulatorProvider'

export const ViewControllerContainer: React.FC = () => {
  // Use context here from Formulator to fetch options and set them.
  const { viewOptions, setViewOptions } = React.useContext(FormulatorContext)

  const viewOptionsMap = React.useMemo(() => {
    return viewOptions.reduce(
      (acc: Record<string, Option<boolean>>, option) => {
        // Use option.id as the key and assign the option object itself as the value.
        acc[option.id] = option
        return acc
      },
      {}
    )
  }, viewOptions)

  const handleViewOptionChange = React.useCallback(
    (option: Option<boolean>) => {
      viewOptionsMap[option.id].value = option.value
      setViewOptions(Object.values(viewOptionsMap))
    },
    [viewOptions]
  )

  return (
    <ViewController options={viewOptions} onChange={handleViewOptionChange} />
  )
}
