import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DeletingState,
  DownloadingState,
  LoadingState,
  UploadingState
} from '../../CommonState'
import {
  DeleteSimpleIngredientDocumentRequest,
  DownloadSimpleIngredientDocumentRequest,
  GetSimpleIngredientDocumentsRequest,
  PreviewSimpleIngredientDocumentRequest,
  UploadSimpleIngredientDocumentRequest
} from './SimpleIngredientDocumentsRequest'
import { BasicDocument } from 'models/Document'
import { SimpleIngredientApi } from 'services/apis/simple_ingredient/SimpleIngredientApi'

export interface SimpleIngredientDocumentState
  extends LoadingState,
    DeletingState,
    UploadingState,
    DownloadingState {
  simpleIngredientDocuments: BasicDocument[]
  previewedDocument?: BasicDocument
}

const initialState: SimpleIngredientDocumentState = {
  simpleIngredientDocuments: [],
  previewedDocument: undefined,
  loading: false,
  deleting: false,
  uploading: false,
  downloading: false,
  error: false
}

export const uploadSimpleIngredientDocument = createAsyncThunk(
  'simpleIngredient/documents/upload',
  async ({
    companyId,
    simpleIngredientId,
    file,
    uploadProgressListener
  }: UploadSimpleIngredientDocumentRequest) => {
    return await SimpleIngredientApi.uploadSimpleIngredientDocument(
      companyId,
      simpleIngredientId,
      file,
      uploadProgressListener
    )
  }
)

export const previewSimpleIngredientDocument = createAsyncThunk(
  'simpleIngredient/documents/preview',
  async ({
    companyId,
    simpleIngredientId,
    file
  }: PreviewSimpleIngredientDocumentRequest): Promise<BasicDocument> => {
    const url = await SimpleIngredientApi.getSimpleIngredientDocument(
      companyId,
      simpleIngredientId,
      file.name,
      false
    )
    return {
      ...file,
      path: url
    }
  }
)

export const downloadSimpleIngredientDocument = createAsyncThunk(
  'simpleIngredient/documents/download',
  async ({
    companyId,
    simpleIngredientId,
    fileName
  }: DownloadSimpleIngredientDocumentRequest) => {
    return await SimpleIngredientApi.getSimpleIngredientDocument(
      companyId,
      simpleIngredientId,
      fileName
    )
  }
)

export const deleteSimpleIngredientDocument = createAsyncThunk(
  'simpleIngredient/documents/delete',
  async ({
    companyId,
    simpleIngredientId,
    fileName
  }: DeleteSimpleIngredientDocumentRequest) => {
    return await SimpleIngredientApi.deleteSimpleIngredientDocument(
      companyId,
      simpleIngredientId,
      fileName
    )
  }
)

export const getSimpleIngredientDocuments = createAsyncThunk(
  'simpleIngredient/documents/get',
  async ({
    companyId,
    simpleIngredientId
  }: GetSimpleIngredientDocumentsRequest) => {
    return await SimpleIngredientApi.getSimpleIngredientDocuments(
      companyId,
      simpleIngredientId
    )
  }
)

const SimpleIngredientDocumentSlice = createSlice({
  name: 'simpleIngredientDocumentsSlice',
  initialState,
  reducers: {
    resetPreviewedDocument: (state) => {
      state.previewedDocument = undefined
    }
  },
  extraReducers: (builder) => {
    // simple ingredient documents

    // upload simple ingredient document to url
    builder
      .addCase(
        uploadSimpleIngredientDocument.pending,
        (state: SimpleIngredientDocumentState) => {
          state.uploading = true
        }
      )
      .addCase(
        uploadSimpleIngredientDocument.fulfilled,
        (state: SimpleIngredientDocumentState) => {
          state.uploading = false
        }
      )
      .addCase(
        uploadSimpleIngredientDocument.rejected,
        (state: SimpleIngredientDocumentState) => {
          state.uploading = false
          state.error = true
        }
      )

    // download simple ingredient document from url
    builder
      .addCase(
        downloadSimpleIngredientDocument.pending,
        (state: SimpleIngredientDocumentState) => {
          state.downloading = true
        }
      )
      .addCase(
        downloadSimpleIngredientDocument.fulfilled,
        (state: SimpleIngredientDocumentState) => {
          state.downloading = false
        }
      )
      .addCase(
        downloadSimpleIngredientDocument.rejected,
        (state: SimpleIngredientDocumentState) => {
          state.downloading = false
          state.error = true
        }
      )

    // preview simple ingredient document from url
    builder
      .addCase(
        previewSimpleIngredientDocument.pending,
        (state: SimpleIngredientDocumentState) => {
          state.downloading = true
        }
      )
      .addCase(
        previewSimpleIngredientDocument.fulfilled,
        (
          state: SimpleIngredientDocumentState,
          action: PayloadAction<BasicDocument>
        ) => {
          state.downloading = false
          state.previewedDocument = action.payload
        }
      )
      .addCase(
        previewSimpleIngredientDocument.rejected,
        (state: SimpleIngredientDocumentState) => {
          state.downloading = false
          state.error = true
        }
      )

    // delete simple ingredient document
    builder
      .addCase(
        deleteSimpleIngredientDocument.pending,
        (state: SimpleIngredientDocumentState) => {
          state.deleting = true
        }
      )
      .addCase(
        deleteSimpleIngredientDocument.fulfilled,
        (
          state: SimpleIngredientDocumentState,
          action: PayloadAction<string>
        ) => {
          state.deleting = false
          state.simpleIngredientDocuments =
            state.simpleIngredientDocuments.filter(
              (document) => document.name !== action.payload
            )
        }
      )
      .addCase(
        deleteSimpleIngredientDocument.rejected,
        (state: SimpleIngredientDocumentState) => {
          state.deleting = false
          state.error = true
        }
      )

    // get simple ingredient documents
    builder
      .addCase(
        getSimpleIngredientDocuments.pending,
        (state: SimpleIngredientDocumentState) => {
          state.loading = true
        }
      )
      .addCase(
        getSimpleIngredientDocuments.fulfilled,
        (
          state: SimpleIngredientDocumentState,
          action: PayloadAction<BasicDocument[]>
        ) => {
          state.loading = false
          state.simpleIngredientDocuments = action.payload.map(
            (basicDocument) => {
              return {
                ...basicDocument
              }
            }
          )
        }
      )
      .addCase(
        getSimpleIngredientDocuments.rejected,
        (state: SimpleIngredientDocumentState) => {
          state.loading = false
          state.error = true
        }
      )
  }
})

export const { resetPreviewedDocument } = SimpleIngredientDocumentSlice.actions
export default SimpleIngredientDocumentSlice.reducer
