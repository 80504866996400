import { Divider, Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles } from '@mui/styles'
import { BoxRowBasic } from 'components/common'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    display: 'flex',
    padding: '12px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '6px',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    background: '#FFF',
    boxShadow:
      '0px 0px 8px 0px rgba(0, 0, 0, 0.09), 0px 0px 2px 0px rgba(0, 0, 0, 0.03)'
  },
  customArrow: {
    color: '#FFF',
    '&::before': {
      border: '1px solid rgba(0, 0, 0, 0.05)',
      boxShadow:
        '0px 0px 8px 0px rgba(0, 0, 0, 0.09), 0px 0px 2px 0px rgba(0, 0, 0, 0.03)'
    }
  }
}))
interface TargetResultProgressProps {
  success?: boolean
  disabled: boolean
  onHoverMessages?: string[][]
  children: React.ReactElement
}

export const TargetResultProgress: React.FC<TargetResultProgressProps> = ({
  children,
  success,
  disabled,
  onHoverMessages = []
}) => {
  const classes = useStyles()

  const formatMessages = (messages: string[][]): JSX.Element => {
    return (
      <>
        {messages.map((group, groupIndex) => (
          <React.Fragment key={groupIndex}>
            {group.map((message, messageIndex) => (
              <Typography
                style={{
                  color: 'var(--Body, #14202E)',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal'
                }}
                key={messageIndex}
              >
                {'○ '} {message}
              </Typography>
            ))}
            {groupIndex < messages.length - 1 && (
              <BoxRowBasic
                style={{
                  alignItems: 'center',
                  gap: '8px',
                  margin: '4px 0',
                  width: '100%'
                }}
              >
                <Divider style={{ flexGrow: 1 }} />
                <Typography
                  style={{
                    padding: '0 8px',
                    color: 'var(--Body, #14202E)',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal'
                  }}
                >
                  OR
                </Typography>
                <Divider style={{ flexGrow: 1 }} />
              </BoxRowBasic>
            )}
          </React.Fragment>
        ))}
      </>
    )
  }
  const getTooltipMessage = (onHoverMessages: string[][]): JSX.Element => {
    if (success) {
      return (
        <Typography
          style={{
            color: 'var(--Body, #14202E)',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
          }}
        >
          Target reached
        </Typography>
      )
    } else {
      return formatMessages(onHoverMessages)
    }
  }
  return (
    <Tooltip
      title={getTooltipMessage(onHoverMessages)}
      arrow
      disableHoverListener={disabled}
      classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
    >
      {children}
    </Tooltip>
  )
}
