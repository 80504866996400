import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  GetCompanyUsersRequest,
  RemoveCompanyUserRequest,
  UpdateCompanyUserRoleRequest
} from './CompanyUsersRequest'
import { UserApi } from 'services/apis/user/UserApi'
import { LoadingState, RemovingState, UpdatingState } from '../../CommonState'
import { User, UserRole } from 'models/User'
import { fromRole } from 'services/apis/user/UserApiMapper'

export const removeCompanyUser = createAsyncThunk(
  'companies/users/remove',
  async ({ userId, companyId }: RemoveCompanyUserRequest) => {
    return await UserApi.deleteCompanyUser(userId, companyId)
  }
)

export const getCompanyUsers = createAsyncThunk(
  'companies/users/get',
  async ({ companyId }: GetCompanyUsersRequest) => {
    return await UserApi.getCompanyUsers(companyId)
  }
)

export const updateCompanyUserRole = createAsyncThunk(
  'companies/users/roles/update',
  async ({ companyId, userId, role }: UpdateCompanyUserRoleRequest) => {
    return await UserApi.updateUserRole(companyId, userId, {
      role: fromRole(role)
    })
  }
)

interface CompanyUsersState extends LoadingState, RemovingState, UpdatingState {
  userRoles: UserRole[]
}

const initialState: CompanyUsersState = {
  userRoles: [],
  loading: false,
  removing: false,
  error: false,
  updating: false
}

export const companyUsersSlice = createSlice({
  name: 'companyUsersSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCompanyUsers.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      getCompanyUsers.fulfilled,
      (state, action: PayloadAction<UserRole[]>) => {
        state.userRoles = action.payload
        state.loading = false
      }
    )
    builder.addCase(getCompanyUsers.rejected, (state) => {
      state.userRoles = []
      state.loading = false
      state.error = true
    })

    builder.addCase(removeCompanyUser.pending, (state) => {
      state.removing = true
      state.error = false
    })
    builder.addCase(removeCompanyUser.fulfilled, (state) => {
      state.removing = false
    })
    builder.addCase(removeCompanyUser.rejected, (state) => {
      state.removing = false
      state.error = true
    })

    builder.addCase(updateCompanyUserRole.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(updateCompanyUserRole.fulfilled, (state, action) => {
      const updatedUserRole = action.payload
      const userIndex = state.userRoles.findIndex(
        (userRole) => userRole.user.id === updatedUserRole.user.id
      )
      state.userRoles[userIndex].role = updatedUserRole.role
      state.updating = false
    })
    builder.addCase(updateCompanyUserRole.rejected, (state) => {
      state.updating = false
      state.error = true
    })
  }
})

export default companyUsersSlice.reducer
