import { Typography, styled } from '@mui/material'
import React from 'react'
import SmallDropDown from 'components/SmallDropdown/SmallDropdown'
import { BoxRow } from 'components/common'
import { FormulaNutrientsScale } from 'models/Formula'

const dictionaryFromEnum: Record<string, FormulaNutrientsScale> =
  Object.entries(FormulaNutrientsScale).reduce((acc, [key, value]) => {
    acc[value as string] =
      FormulaNutrientsScale[key as keyof typeof FormulaNutrientsScale]
    return acc
  }, {} as Record<string, FormulaNutrientsScale>)

const BasedOnTypography = styled(Typography)`
  font-size: 16px;
  color: ${(props) => props.theme.palette.tertiary.main};
`

export interface NutrientsBasedOnProps {
  initialScale?: FormulaNutrientsScale
  onScaleChangedCallback: (scale: FormulaNutrientsScale) => void
}

export const NutrientsBasedOn: React.FC<NutrientsBasedOnProps> = ({
  initialScale,
  onScaleChangedCallback
}) => {
  const handleOnDropDownOptionSelected = (option: string) => {
    onScaleChangedCallback(dictionaryFromEnum[option])
  }

  return (
    <BoxRow paddingTop={'20px'} paddingBottom={'20px'} paddingLeft={'24px'}>
      <BasedOnTypography marginRight={'5px'}>Based on</BasedOnTypography>
      <SmallDropDown
        options={Object.values(dictionaryFromEnum)}
        initialOption={initialScale}
        onDropDownOptionSelected={handleOnDropDownOptionSelected}
      />
    </BoxRow>
  )
}

export default NutrientsBasedOn
