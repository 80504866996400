import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { SxProps } from '@mui/material'
import React from 'react'
import { BoxRowBasic } from '../common'
import { ZoomButton } from './ZoomButtonsStyles'
import { CenterFocusStrong } from '@mui/icons-material'

interface ZoomButtonsProps {
  onZoomInClicked: () => void
  onZoomOutClicked: () => void
  onZoomResetClicked?: () => void
  zoomInDisabled?: boolean
  zoomOutDisabled?: boolean
  sx?: SxProps
}

export const ZoomButtons: React.FC<ZoomButtonsProps> = ({
  onZoomInClicked,
  onZoomOutClicked,
  onZoomResetClicked,
  zoomInDisabled = false,
  zoomOutDisabled = false,
  sx
}) => {
  return (
    <BoxRowBasic sx={{ gap: '5px', position: 'absolute', zIndex: 3, ...sx }}>
      <ZoomButton onClick={onZoomInClicked} disabled={zoomInDisabled}>
        <AddIcon />
      </ZoomButton>
      {onZoomResetClicked && (
        <ZoomButton onClick={onZoomResetClicked}>
          <CenterFocusStrong />
        </ZoomButton>
      )}
      <ZoomButton onClick={onZoomOutClicked} disabled={zoomOutDisabled}>
        <RemoveIcon />
      </ZoomButton>
    </BoxRowBasic>
  )
}
