import { NutrientType } from 'models/Nutrient'
import React from 'react'
import { NutritionLabelText } from '../../components/NutritionLabelText'
import { ItemLinear, ItemLinearValues } from './ItemLinear'
import { FatItemLinear } from './FatItemLinear'

export interface ItemLinearContentProps {
  itemsLinearProps: ItemLinearProps[]
}

export interface ItemLinearProps {
  nutrientType: NutrientType
  itemLinearValues: ItemLinearValues
}

export const ItemLinearContent: React.FC<ItemLinearContentProps> = ({
  itemsLinearProps = []
}) => {
  // Create the fat item row by extracting the saturated and trans fat.
  const fatItemRow = React.useMemo(() => {
    const saturated = itemsLinearProps.find(
      (itemRowProps) => itemRowProps.nutrientType === NutrientType.SATURATED_FAT
    )
    const trans = itemsLinearProps.find(
      (itemRowProps) =>
        itemRowProps.nutrientType === NutrientType.TRANS_FATTY_ACID
    )

    return saturated && trans ? (
      <FatItemLinear
        saturated={saturated?.itemLinearValues}
        trans={trans?.itemLinearValues}
      />
    ) : (
      <></>
    )
  }, [itemsLinearProps])

  return (
    <>
      {itemsLinearProps.map((itemLinearProps, index) => {
        return (
          <React.Fragment key={`item-linear.${index}`}>
            {![
              NutrientType.SATURATED_FAT,
              NutrientType.TRANS_FATTY_ACID
            ].includes(itemLinearProps.nutrientType) && (
              <ItemLinear
                {...itemLinearProps.itemLinearValues}
                comma={index < itemsLinearProps.length - 1}
              />
            )}

            {NutrientType.TRANS_FATTY_ACID === itemLinearProps.nutrientType &&
              fatItemRow}
          </React.Fragment>
        )
      })}
      <NutritionLabelText points={8} wrapText>
        .
      </NutritionLabelText>
    </>
  )
}
