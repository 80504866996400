import { Typography } from '@mui/material'
import React from 'react'
import { BoxRow } from '../common'

interface IProps {
  list: string[]
}

export const ListCell: React.FC<IProps> = ({ list }) => {
  const title = list.join(', ')
  const first2 = list.slice(0, 2).join(', ')
  const remainder = list.slice(2).length
  const str = list.length
    ? `${first2}${remainder ? `, +${remainder}` : ''}`
    : 'None'

  return (
    <BoxRow alignItems="center" title={title}>
      <Typography
        sx={{
          fontSize: '14px',
          opacity: str === 'None' ? 0.3 : 0.5,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '100%'
        }}
      >
        {str}
      </Typography>
    </BoxRow>
  )
}
