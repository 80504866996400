import { Option } from 'components/common'
import React from 'react'
import { EditorSwitch } from '../../../common/EditorSwitch'
import { InputFieldLabel } from 'components/InputFieldLabel/InputFieldLabel'
import { InfoBubble } from 'components/InfoBubble/InfoBubble'
import { MarkdownRenderer } from 'components/MarkdownRenderer/MarkdownRenderer'
import { infoMessages } from 'common/constants'
import { APPLY_PDCAAS, PDCAAS_SCORE } from './NutrientsSettingsContainer'

export interface NutrientsSettingsProps {
  showProteinPercentage: {
    label: string
    value: boolean
    hidden: boolean
    disabled: boolean
    onChange: (value: boolean) => void
  }
  pdcaas: {
    applyPdcaas: {
      label: string
      value: boolean
      hidden: boolean
      disabled: boolean
      onChange: (value: boolean) => void
    }
    pdcaasScore: {
      label: string
      value: string
      hidden: boolean
      disabled: boolean
      onChange: (value: string) => void
      validator: (value: string) => boolean
    }
  }
}

export const NutrientsSettings: React.FC<NutrientsSettingsProps> = ({
  showProteinPercentage,
  pdcaas
}) => {
  return (
    <>
      {/* show protein percentage settings */}
      {!showProteinPercentage.hidden && (
        <EditorSwitch
          label={showProteinPercentage.label}
          checked={showProteinPercentage.value}
          onChange={showProteinPercentage.onChange}
          disabled={showProteinPercentage.disabled}
        />
      )}

      {/* pdcaas settings */}
        {/* apply pdcaas */}
        {!pdcaas.applyPdcaas.hidden && (
          <EditorSwitch
            label={pdcaas.applyPdcaas.label}
            checked={pdcaas.applyPdcaas.value}
            onChange={pdcaas.applyPdcaas.onChange}
            disabled={pdcaas.applyPdcaas.disabled}
            labelIcon={
              <InfoBubble
                text={
                  <MarkdownRenderer
                    markdownText={infoMessages.formula.label.PDCAAS}
                  />
                }
              />
            }
          />
        )}
        {/* pdcaas score */}
        {!pdcaas.pdcaasScore.hidden && pdcaas.applyPdcaas.value && (
          <InputFieldLabel
            label={pdcaas.pdcaasScore.label}
            initialValue={pdcaas.pdcaasScore.value}
            onChange={(v) => {
              pdcaas.pdcaasScore.validator(v) && pdcaas.pdcaasScore.onChange(v)
            }}
            debounceTime={500}
            disabled={pdcaas.pdcaasScore.disabled}
          />
        )}
    </>
  )
}
