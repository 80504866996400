import { Box, Typography } from '@mui/material'
import {
  AllergenDisplayNameLanguage,
  AllergensPerLanguage
} from 'models/Allergen'
import React from 'react'

export interface AllergensStatementProps {
  allergens: AllergensPerLanguage
}

const translateContains = (
  language: AllergenDisplayNameLanguage
): JSX.Element => {
  switch (language) {
    case AllergenDisplayNameLanguage.ENGLISH:
      return <b>CONTAINS</b>
    case AllergenDisplayNameLanguage.FRENCH:
      return <b>CONTIENT</b>
    case AllergenDisplayNameLanguage.SPANISH:
      return <b>CONTIENE</b>
    default:
      return <b>CONTAINS</b>
  }
}

export const AllergensStatement: React.FC<AllergensStatementProps> = ({
  allergens
}) => {
  const renderAllergensStatement = (language: AllergenDisplayNameLanguage) => {
    const allergenList = allergens[language]
    if (allergenList && allergenList.length > 0) {
      const allergenStatement = (
        <>
          {translateContains(language)}: {allergenList.join(', ').toUpperCase()}
        </>
      )
      return (
        <Typography key={language} component="div">
          {allergenStatement}
        </Typography>
      )
    }
    return null
  }
  return (
    <Box width="100%">
      {Object.keys(allergens).map((languageKey) => {
        const language = languageKey as AllergenDisplayNameLanguage
        return renderAllergensStatement(language)
      })}
    </Box>
  )
}
