import { Option } from 'components/common'
import React, { createContext, useState } from 'react'

export interface FormulatorContextInterface {
  selectedIngredientIdsMap: Map<string, boolean>
  updateIngredientIdSelection: (id: string, checked: boolean) => void
  clearIngredientIdsSelection: () => void
  viewOptions: Option<boolean>[]
  setViewOptions: React.Dispatch<React.SetStateAction<Option<boolean>[]>>
}

// Create the context outside of the provider to be used for consuming.
export const FormulatorContext = createContext<FormulatorContextInterface>({
  selectedIngredientIdsMap: new Map(),
  updateIngredientIdSelection: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  clearIngredientIdsSelection: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  viewOptions: [],
  setViewOptions: () => {} // eslint-disable-line @typescript-eslint/no-empty-function
})

export const FormulatorProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  // State for managing selected ingredient IDs.
  const [selectedIngredientIdsMap, setSelectedIngredientIdsMap] = useState<
    Map<string, boolean>
  >(new Map())
  // State for managing view options.
  const [viewOptions, setViewOptions] = useState<Option<boolean>[]>([
    { id: 'suppliers', label: 'Suppliers', value: false },
    { id: 'wastePercentage', label: 'Waste %', value: false },
    { id: 'cost', label: 'Cost ($)', value: false },
    { id: 'quantityPercentage', label: 'Quantity Percentage', value: true }
  ])

  const updateIngredientIdSelection = React.useCallback(
    (id: string, checked: boolean) => {
      // Use a functional update to ensure we're always working with the latest state.
      setSelectedIngredientIdsMap((prevSelectedIngredientIdsMap) => {
        const updatedMap = new Map(prevSelectedIngredientIdsMap)
        updatedMap.set(id, checked)
        return updatedMap
      })
    },
    []
  )

  const clearIngredientIdsSelection = React.useCallback(() => {
    setSelectedIngredientIdsMap(() => {
      return new Map()
    })
  }, [])

  // Providing context values including state and its setters.
  return (
    <FormulatorContext.Provider
      value={{
        selectedIngredientIdsMap,
        updateIngredientIdSelection,
        clearIngredientIdsSelection,
        viewOptions,
        setViewOptions
      }}
    >
      {children}
    </FormulatorContext.Provider>
  )
}
