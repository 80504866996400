import { User, useAuth0 } from '@auth0/auth0-react'
import { Navigate, Outlet, useLocation } from 'react-router'
import Loading from 'pages/Loading/Loading'
import { PATHS } from 'common/constants'
import React from 'react'
import { AnalyticsContext } from './Analytics/AnalyticsContext'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { getUser } from 'state/users/UsersSlice'
import { getCompany } from 'state/companies/CompaniesSlice'

const PrivateRoutes = () => {
  const analytics = React.useContext(AnalyticsContext)
  const { isAuthenticated, isLoading, user } = useAuth0()
  const currentUser = useAppSelector((state) => state.users.currentUser)
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )

  const location = useLocation()
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (user && user.sub && user.name) {
      analytics.setUserId(user.sub)
      analytics.identify(user.name)
      // Get the user the first time to use across the app.
      void dispatch(getUser())
    }
  }, [user])

  React.useEffect(() => {
    if (currentUser.companyId !== '') {
      void dispatch(getCompany({ companyId: currentUser.companyId }))
    }
  }, [currentUser])

  const navigateIfRequired = (path: string) => {
    if (path !== location.pathname) {
      return <Navigate to={path} state={{ from: 'private' }} />
    } else {
      return <Outlet />
    }
  }

  const userHasCompany = (user: User) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const userCompanies: string[] =
      user['https://entrtechnologies.com/company_ids'] || []
    return userCompanies.length > 0
  }

  if (isLoading) {
    return <Loading />
  } else if (!isAuthenticated) {
    return <Navigate to={PATHS.LOGIN} />
  } else if (!user?.email_verified) {
    return navigateIfRequired(PATHS.ACTIVATION)
  } else if (user && !userHasCompany(user)) {
    return navigateIfRequired(PATHS.NO_COMPANY)
  } else if (!currentUser.id || !currentCompany.id) {
    return <Loading />
  } else {
    return <Outlet />
  }
}

export default PrivateRoutes
