import { api } from '../config/AxiosConfig'
import { RegulationApiResponse } from './RegulationApiResponse'

// New api that doesn't map to a model.
export const RegulationApi = {
  getRegulations: (): Promise<RegulationApiResponse[]> => {
    return api
      .get<RegulationApiResponse[]>(`/regulations`)
      .then((res) => res.data)
  }
}
