import { NutritionLabelText } from '../../components/NutritionLabelText'
import { NutritionLabelLinearContainer } from '../NutritionLabelLinearStyles'

export interface ItemLinearSettings {
  boldName?: boolean
  comma?: boolean
}

export interface ItemLinearValues {
  name: string
  amount: string | number
  unit: string
  dvPercentage?: string
  override?: React.ReactNode
}

export interface ItemRowProps extends ItemLinearSettings {
  name: string
  amount: string | number
  unit: string
  dvPercentage?: string
  override?: React.ReactNode
}

export const ItemLinear: React.FC<ItemRowProps> = ({
  name,
  amount,
  unit,
  dvPercentage,
  boldName = false,
  comma = true,
  override
}) => (
  <NutritionLabelLinearContainer>
    {!override && (
      <>
        <NutritionLabelText points={8} wrapText>
          {comma && ', '}
        </NutritionLabelText>
        <NutritionLabelText bold={boldName} points={8} wrapText>
          {name}
        </NutritionLabelText>
        <NutritionLabelText points={8} wrapText>
          {' '}
          {amount}
          {unit}
        </NutritionLabelText>
        <NutritionLabelText points={8} wrapText>
          {dvPercentage && ` (${dvPercentage}% DV)`}
        </NutritionLabelText>
      </>
    )}
    {override}
  </NutritionLabelLinearContainer>
)
