import { Box, Typography } from '@mui/material'
import React from 'react'
import { SimpleAutoComplete } from '../SimpleAutoComplete'
import { useAppSelector } from 'state/hooks'
import { NormalizedTargetOperation } from 'models/Target'
import { isTargetOperation } from 'services/apis/target/TargetApiMapper'
import { grayedOutInputStyle } from '../../../TargetsStyles'

interface TargetOperationProps {
  operationId: string
  setNewOperation: (newOperation: any) => void
  disableEdit?: boolean
}

export const TargetOperation: React.FC<TargetOperationProps> = ({
  operationId,
  setNewOperation,
  disableEdit = false
}) => {
  const operation = useAppSelector(
    (state) => state.targets.activeOperations[operationId]
  )
  const activeTarget = useAppSelector((state) => state.targets.activeTarget)
  const targets = useAppSelector((state) => state.targets.targets)

  const handleTargetChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const newTargetName = e.target.value
    const newTarget = Object.values(targets).find(
      (target) => target.name === newTargetName
    )
    if (isTargetOperation(operation) && newTarget?.id !== operation.targetId) {
      setNewOperation({
        ...operation,
        targetId: newTarget?.id
      } as NormalizedTargetOperation)
    }
  }

  const handleExpectedExecutionReturnChange = (
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    const newExpectedExecutionReturn = e.target.value === 'true' ? true : false
    if (
      isTargetOperation(operation) &&
      newExpectedExecutionReturn !== operation.expectedExecutionReturn
    ) {
      setNewOperation({
        ...operation,
        expectedExecutionReturn: newExpectedExecutionReturn
      } as NormalizedTargetOperation)
    }
  }

  const handleExecuteOnReferenceFormulaChange = (
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    const newExecuteOnReferenceFormula =
      e.target.value === 'reference food' ? true : false
    if (
      isTargetOperation(operation) &&
      newExecuteOnReferenceFormula !== operation.executeOnReferenceFormula
    ) {
      setNewOperation({
        ...operation,
        executeOnReferenceFormula: newExecuteOnReferenceFormula
      } as NormalizedTargetOperation)
    }
  }

  if (!operationId || !isTargetOperation(operation)) {
    return <></>
  }

  return (
    <>
      <Box style={grayedOutInputStyle}>
        <Typography>Target</Typography>
      </Box>
      <SimpleAutoComplete
        initialValue={
          Object.values(targets).find(
            (target) => target.id === operation.targetId
          )?.name
        }
        options={Object.values(targets).flatMap((target) =>
          target.id === activeTarget.id ? [] : [target.name]
        )}
        onBlur={handleTargetChange}
        disabled={disableEdit}
      />
      <Box style={grayedOutInputStyle}>
        <Typography>is</Typography>
      </Box>
      <SimpleAutoComplete
        initialValue={operation.expectedExecutionReturn ? 'true' : 'false'}
        options={['true', 'false']}
        onBlur={handleExpectedExecutionReturnChange}
        disabled={disableEdit}
      />
      <Box style={grayedOutInputStyle}>
        <Typography>on</Typography>
      </Box>
      <SimpleAutoComplete
        initialValue={
          operation.executeOnReferenceFormula
            ? 'reference food'
            : 'current formula'
        }
        options={['reference food', 'current formula']}
        onBlur={handleExecuteOnReferenceFormulaChange}
        disabled={disableEdit}
      />
    </>
  )
}
