import React from 'react'
import { EntrAnalytics } from './EntrAnalytics'

const analyticsKey = process.env.REACT_APP_SEGMENT_KEY || ''
const analyticsEnabled = process.env.REACT_APP_ANALYTICS_ENABLED == 'true'
const analytics = new EntrAnalytics(analyticsKey, analyticsEnabled)

export const AnalyticsContext = React.createContext(analytics)

interface AnalyticsProviderProps {
  children: React.ReactNode
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children
}) => {
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}
