import {
  Avatar,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import React from 'react'
import { Can } from 'core/Permissions/AbilityContext'
import { Actions, Subjects } from 'core/Permissions/AbilityConstants'
import { Role } from 'models/User'
import { Button } from 'components/Button/Button'

interface MembersRow {
  name: string
  photoPath: string
  date: string
  handleRemove: () => void
  handleChangeRole?: (role: string) => void
  role?: Role
}

export const MembersRow: React.FC<MembersRow> = ({
  name,
  photoPath,
  date,
  handleRemove,
  handleChangeRole,
  role
}) => {
  return (
    <Grid item container alignItems="center">
      <Grid item sx={{ marginRight: '11px' }}>
        <Avatar src={photoPath} sx={{ width: '20px', height: '20px' }} />
      </Grid>
      <Grid item sx={{ marginRight: '11px' }}>
        <Typography
          variant="body2"
          sx={{
            fontSize: '16px',
            lineHeight: '19px'
          }}
        >
          {name}
        </Typography>
      </Grid>
      <Grid item sx={{ marginRight: '11px' }}>
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: '14px',
            lineHeight: '17px',
            fontWeight: 400
          }}
        >
          {date}
        </Typography>
      </Grid>
      <Can I={Actions.UPDATE} a={Subjects.ROLES}>
        {role && handleChangeRole && (
          <Grid item sx={{ marginRight: '11px' }}>
            <Select
              value={role}
              onChange={(event) => handleChangeRole(event.target.value)}
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                letterSpacing: '-0.28px',
                fontFamily: 'poppins',
                color: 'rgba(26, 44, 66, 0.85)',
                textAlign: 'center',
                height: '40px'
              }}
            >
              {Object.values(Role)?.map((roleOption) => (
                <MenuItem
                  key={roleOption}
                  value={roleOption}
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    letterSpacing: '-0.28px',
                    fontFamily: 'poppins',
                    color: 'rgba(26, 44, 66, 0.85)',
                    textAlign: 'center'
                  }}
                >
                  {roleOption}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
      </Can>

      <Can I={Actions.DELETE} a={Subjects.USERS}>
        <Grid item sx={{ marginRight: '11px' }}>
          <Button color="danger" onClick={handleRemove}>
            Remove
          </Button>
        </Grid>
      </Can>
    </Grid>
  )
}
