import React from 'react'
import { useNavigate, Outlet, useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import { TitleActionsLayout } from 'components/TitleActionsLayout/TitleActionsLayout'
import { PATHS } from 'common/constants'
import { AbilityContext } from 'core/Permissions/AbilityContext'
import { Actions, Subjects } from 'core/Permissions/AbilityConstants'
import { Tabs } from 'components/Tabs/Tabs'
import { Tab } from 'components/Tabs/Tab/Tab'

const Settings: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const ability = React.useContext(AbilityContext)

  const tabs = [
    {
      name: 'Profile',
      path: PATHS.PROFILE,
      accessible: ability.can(Actions.READ, Subjects.CURRENT_USER)
    },
    {
      name: 'Company',
      path: PATHS.COMPANY,
      accessible: ability.can(Actions.READ, Subjects.COMPANIES)
    },
    {
      name: 'Members',
      path: PATHS.MEMBERS,
      accessible:
        ability.can(Actions.READ, Subjects.INVITATIONS) &&
        ability.can(Actions.READ, Subjects.USERS)
    }
  ]

  const activeTab = tabs.findIndex((tab) => tab.path === location.pathname) || 0

  const [activeOption, setActiveOption] = React.useState<number>(activeTab)

  React.useEffect(() => {
    const tabIndex =
      tabs.findIndex((tab) => tab.path === location.pathname) || 0
    if (tabs[tabIndex].accessible) {
      setActiveOption(
        tabs.findIndex((tab) => tab.path === location.pathname) || 0
      )
    }
  }, [location])

  const handleOptionChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setActiveOption(newValue)
    navigateToPage(newValue)
  }

  const navigateToPage = (option: number) => {
    navigate(tabs[option].path)
  }

  return (
    <TitleActionsLayout title="Settings">
      <Tabs value={activeOption} onChange={handleOptionChange}>
        {tabs.map(
          (tab, index) => tab.accessible && <Tab key={index} label={tab.name} />
        )}
      </Tabs>
      <Box
        sx={{
          margin: '10px 0px 20px 0px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.06)'
        }}
      />
      <Outlet />
    </TitleActionsLayout>
  )
}

export default Settings
