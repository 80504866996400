import { useQuery } from 'common/hooks'
import React, { createContext } from 'react'

export interface QueriesContextProps {
  setSearch: (value: string) => void
  setFilters: (key: string, values: string[]) => void
  search?: string
  filters: { [key: string]: string[] | undefined }
}

export const QueriesContext = createContext<QueriesContextProps>({
  setSearch: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  setFilters: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  filters: {}
})

export const QueriesProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [queries, setSearchParams, searchParams] = useQuery()

  const search = React.useMemo(() => {
    return searchParams.get('search') || undefined
  }, [queries])

  const filters = React.useMemo(() => {
    const f: { [key: string]: string[] | undefined } = {}
    searchParams.forEach((v, k) => {
      if (k !== 'search') {
        f[k] = v.split(',')
      }
    })
    return f
  }, [queries])

  const setFilters = (key: string, values: string[]) => {
    if (values.length > 0) {
      searchParams.set(key, values.join(','))
    } else {
      searchParams.delete(key)
    }
    setSearchParams(searchParams)
  }

  const setSearch = (value: string) => {
    if (value) {
      searchParams.set('search', value)
    } else {
      searchParams.delete('search')
    }
    setSearchParams(searchParams)
  }

  return (
    <QueriesContext.Provider
      value={{
        setSearch,
        setFilters,
        search,
        filters
      }}
    >
      {children}
    </QueriesContext.Provider>
  )
}
