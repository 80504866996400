import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import React from 'react'

interface CustomWidthTooltipProps extends TooltipProps {
  width?: string
}

const CustomWidthTooltip = styled(
  ({ className, width, ...props }: CustomWidthTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme, width }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    maxWidth: width || '400px', // Use width from props directly here.
    overflowY: 'auto',
    maxHeight: '300px',
    padding: '10px'
  }
}))

interface InfoBubbleProps {
  text: string | React.ReactNode
  type?: 'info' | 'warning'
  width?: string
}

export const InfoBubble: React.FC<InfoBubbleProps> = ({
  text,
  width,
  type = 'info'
}) => {
  return (
    <CustomWidthTooltip title={text} width={width}>
      {type === 'info' ? (
        <InfoOutlinedIcon
          sx={{
            width: '16px',
            height: '16px',
            color: 'rgba(35, 60, 88, 0.43)'
          }}
        />
      ) : (
        <WarningAmberRoundedIcon
          sx={{
            width: '16px',
            height: '16px',
            color: 'rgba(35, 60, 88, 0.43)'
          }}
        />
      )}
    </CustomWidthTooltip>
  )
}
