import { MenuItem, Select } from '@mui/material'
import { BoxRow } from 'components/common'
import { FormulaStatus } from 'models/Formula'
import { StatusChip } from 'pages/Formulas/components/StatusChip'
import React from 'react'

export interface StatusDropdownProps {
  /** The currently selected status. */
  value: FormulaStatus
  /** Callback to notify of status change. */
  onChange: (newStatus: FormulaStatus) => void
}

export const StatusDropdown: React.FC<StatusDropdownProps> = ({
  value,
  onChange
}) => {
  return (
    <BoxRow>
      <label style={{ position: 'relative', display: 'inline-block' }}>
        <StatusChip status={value} showDropArrow={true} width="fit-content" />
        <Select
          value={value}
          onChange={(event) => onChange(event.target.value as FormulaStatus)}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            }
          }}
          style={{
            position: 'absolute',
            top: '10px',
            left: 0,
            opacity: 0,
            width: '100%',
            height: '100%'
          }}
        >
          {Object.values(FormulaStatus).map((val) => (
            <MenuItem value={val} key={val}>
              <StatusChip
                status={val}
                style={{ width: '100%', cursor: 'pointer' }}
              />
            </MenuItem>
          ))}
        </Select>
      </label>
    </BoxRow>
  )
}
