import React from 'react'
import { Divider } from '@mui/material'
import { Button } from 'components/Button/Button'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import {
  clearActiveTarget,
  deleteTarget,
  getTargets,
  setActiveTarget,
  updateActiveTargetName,
  updateTarget
} from 'state/targets'
import { ModalContext } from 'components/Modal/ModalContext'
import { pluralize } from 'common/utils'
import { EditableText } from 'components/EditableText/EditableText'
import { BoxRow } from 'components/common'

interface TargetViewerHeaderProps {
  setShow: (show: boolean) => void
}

export const TargetViewerHeader: React.FC<TargetViewerHeaderProps> = ({
  setShow
}) => {
  const dispatch = useAppDispatch()
  const { showConfirmationModal } = React.useContext(ModalContext)
  const { showError, showSuccess } = React.useContext(SnackbarContext)

  const targets = useAppSelector((state) => state.targets.targets)
  const normalizedTarget = useAppSelector(
    (state) => state.targets.normalizedTarget
  )
  const activeTarget = useAppSelector((state) => state.targets.activeTarget)
  const deleting = useAppSelector((state) => state.targets.deleting)
  const loading = useAppSelector((state) => state.targets.loading)
  const isActiveTargetUpdated = useAppSelector(
    (state) => state.targets.isActiveTargetUpdated
  )

  const disabled = loading || deleting || !activeTarget.isEditable

  const handleConfirmedDelete = () => {
    if (!activeTarget.isEditable) {
      return
    }
    if (!activeTarget.company) {
      return
    }
    dispatch(
      deleteTarget({
        companyId: activeTarget.company.id,
        targetId: activeTarget.id
      })
    ).then((action) => {
      if (deleteTarget.fulfilled.match(action)) {
        setShow(false)
        showSuccess(`Target "${activeTarget.name}" deleted successfully`)
        dispatch(clearActiveTarget())
        if (activeTarget.company) {
          dispatch(getTargets({ companyId: activeTarget.company.id }))
        }
      } else if (deleteTarget.rejected.match(action)) {
        showError(`Failed to delete target "${activeTarget.name}"`)
      }
    })
  }

  const getActiveTargetFormulaCount = () => {
    const target = targets.find((t) => t.id === activeTarget.id)
    return target ? target.formulaCount : 0
  }

  const handleDeleteTarget = () => {
    if (!activeTarget.isEditable) {
      return
    }
    const activeTargetFormulaCount = getActiveTargetFormulaCount()
    showConfirmationModal({
      title: 'Delete Target',
      message: `Are you sure you want to delete the target "${
        activeTarget.name
      }"? It is used in ${activeTargetFormulaCount} ${pluralize(
        activeTargetFormulaCount,
        'formula'
      )}.`,
      danger: true,
      yesText: 'Delete',
      noText: 'Cancel',
      onYesClicked: handleConfirmedDelete
    })
  }

  const handleConfirmedReset = () => {
    if (!activeTarget.isEditable) {
      return
    }
    dispatch(setActiveTarget(normalizedTarget))
  }

  const handleDiscardChanges = () => {
    if (!activeTarget.isEditable) {
      return
    }
    showConfirmationModal({
      title: 'Discard Changes',
      message: `Are you sure you want to discard the changes made to "${activeTarget.name}"?`,
      danger: true,
      yesText: 'Discard',
      noText: 'Cancel',
      onYesClicked: handleConfirmedReset
    })
  }

  const handleConfirmedSave = () => {
    if (!activeTarget.isEditable) {
      return
    }
    if (!activeTarget.company) {
      return
    }
    dispatch(
      updateTarget({
        companyId: activeTarget.company.id,
        targetId: activeTarget.id,
        normalizedTarget: activeTarget
      })
    ).then((action) => {
      if (updateTarget.fulfilled.match(action)) {
        if (activeTarget.company) {
          dispatch(getTargets({ companyId: activeTarget.company.id }))
        }
        showSuccess(`Target "${activeTarget.name}" saved successfully`)
      } else if (updateTarget.rejected.match(action)) {
        showError(`Failed to save target "${activeTarget.name}"`)
      }
    })
  }

  const handleSaveTarget = () => {
    if (!activeTarget.isEditable) {
      return
    }
    showConfirmationModal({
      title: 'Save Target',
      message: `Are you sure you want to save the changes made to "${activeTarget.name}"?`,
      yesText: 'Save',
      noText: 'Cancel',
      onYesClicked: handleConfirmedSave
    })
  }

  const handleNameUpdate = (value: string) => {
    if (!activeTarget.isEditable) {
      return
    }
    dispatch(updateActiveTargetName(value))
  }

  return (
    <BoxRow justifyContent={'space-between'} padding="16px">
      <BoxRow gap="8px">
        <EditableText
          initialValue={activeTarget.name || ''}
          saveAction={handleNameUpdate}
          placeholder="Untitled Target"
        />
      </BoxRow>
      <BoxRow gap="16px" justifyContent="flex-end">
        <Button
          color="danger"
          size="small"
          disabled={disabled}
          onClick={handleDeleteTarget}
        >
          Delete Target
        </Button>

        <Divider orientation="vertical" flexItem />

        <BoxRow gap="8px">
          <Button
            onClick={handleDiscardChanges}
            color="secondary"
            size="small"
            disabled={!isActiveTargetUpdated || disabled}
          >
            Discard Changes
          </Button>

          <Button
            onClick={handleSaveTarget}
            color="primary"
            size="small"
            disabled={!isActiveTargetUpdated || disabled}
          >
            Save Target
          </Button>
        </BoxRow>
      </BoxRow>
    </BoxRow>
  )
}
