import { useAuth0 } from '@auth0/auth0-react'
import { Box, Link, Typography } from '@mui/material'
import { useLocation } from 'react-router'

export const CompanyCheck = () => {
  const { user, logout } = useAuth0()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const email = queryParams.get('email') || user?.email

  const handleLogOut = () => {
    void logout()
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        position: 'relative'
      }}
    >
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          width: '50vw',
          gap: '20px'
        }}
      >
        <Typography variant="h1">Company Required</Typography>
        <Typography>
          We're sorry, but the email <b>{email}</b> does not belong to a company
          that is currently registered with ENTR. If you're interested in using
          ENTR for your company, please contact{' '}
          <Link href="mailto:accounts@entrtechnologies.com">
            accounts@entrtechnologies.com
          </Link>{' '}
          and we'll be happy to help you get started.
        </Typography>
        <Typography>
          Want to change your account? Click{' '}
          <Link onClick={handleLogOut}>here</Link> to log out.
        </Typography>
      </Box>
    </Box>
  )
}
