import { TextField } from '@mui/material'
import { Button } from 'components/Button/Button'
import { BoxColumnBasic, BoxRowBasic } from 'components/common'
import React from 'react'

export interface CommentEditProps {
  onSubmit: (comment: string) => Promise<void>
  disabled?: boolean
}

export const CommentEdit: React.FC<CommentEditProps> = ({
  onSubmit,
  disabled
}) => {
  const [text, setText] = React.useState('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    setText(inputValue)
  }

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLDivElement>
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    if (text.trim()) {
      void onSubmit(text).then(() => setText(''))
    }
  }

  return (
    <BoxRowBasic sx={{ gap: '10px' }}>
      <BoxColumnBasic sx={{ flex: 1 }} padding="5px">
        <TextField
          multiline
          placeholder="Add a message"
          rows="4"
          sx={{ '& .MuiInput-root': { border: 0 } }}
          value={text}
          onChange={handleChange}
          disabled={disabled}
          onKeyDown={handleKeyPress}
        ></TextField>
      </BoxColumnBasic>
      <BoxColumnBasic
        sx={{ display: 'inline-block', alignSelf: 'flex-end', padding: '15px' }}
      >
        <Button
          color="secondary"
          disabled={!text.trim() || disabled}
          onClick={handleSubmit}
        >
          Send
        </Button>
      </BoxColumnBasic>
    </BoxRowBasic>
  )
}
