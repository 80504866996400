import { Conversion, IngredientConversions } from 'models/Conversion'
import { api } from '../config/AxiosConfig'
import {
  toConversion,
  toConversions,
  toIngredientConversions
} from './ConversionApiMapper'
import {
  CreateConversionApiData,
  DeleteConversionsApiParams,
  UpdateConversionApiData
} from './ConversionApiRequest'
import {
  ConversionApiResponse,
  IngredientConversionsApiResponse
} from './ConversionApiResponse'

export const ConversionsApi = {
  getConversions: (
    companyId: string,
    ingredientId: string
  ): Promise<IngredientConversions> => {
    return api
      .get<IngredientConversionsApiResponse>(
        `/companies/${companyId}/ingredients/${ingredientId}/conversions/`
      )
      .then((res) => toIngredientConversions(res.data))
  },
  createConversion: (
    companyId: string,
    ingredientId: string,
    data: CreateConversionApiData
  ): Promise<Conversion> => {
    return api
      .post<ConversionApiResponse>(
        `/companies/${companyId}/ingredients/${ingredientId}/conversions`,
        data
      )
      .then((res) => toConversion(res.data))
  },
  updateConversion: (
    companyId: string,
    ingredientId: string,
    conversionId: string,
    data: UpdateConversionApiData
  ): Promise<Conversion> => {
    return api
      .put<ConversionApiResponse>(
        `/companies/${companyId}/ingredients/${ingredientId}/conversions/${conversionId}`,
        data
      )
      .then((res) => toConversion(res.data))
  },
  deleteConversions: (
    companyId: string,
    ingredientId: string,
    params: DeleteConversionsApiParams
  ): Promise<void> => {
    return api.delete(
      `/companies/${companyId}/ingredients/${ingredientId}/conversions`,
      {
        params: params,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }
}
