import { Tab } from '@mui/material'
import { SxProps, styled } from '@mui/material/styles'
import { TabVariant } from './Tab'

interface StyledTabProps {
  tVariant?: TabVariant
  sx?: SxProps
}

const getSize = (variant: TabVariant) => {
  return {
    width: variant === 'normal' ? 'inherit' : '40px',
    height: variant === 'normal' ? 'inherit' : '40px'
  }
}

export const StyledTab = styled(Tab, {
  shouldForwardProp: (prop: string) => !['tVariant'].includes(prop)
})<StyledTabProps>(({ tVariant = 'normal' }) => ({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '19px',
  letterSpacing: '0.01em',
  minWidth: 'unset',
  minHeight: 'unset',
  padding: '8px 16px',
  borderRadius: '8px',
  marginRight: '4px',
  transition: 'color 0.2s ease-in-out',
  backgroundColor: 'transparent',
  fontFamily: 'Inter',
  textTransform: 'none',
  '&.Mui-selected': {
    fontWeight: 'bold',
    color: '#000',
    backgroundColor: '#EAE7E6',
    borderBottom: 'none',
    '&:hover': {
      backgroundColor: '#EAE7E6'
    }
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.03)'
  },
  ...getSize(tVariant)
}))
