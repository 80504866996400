import { Box, Typography } from '@mui/material'
import styled from 'styled-components'

export const GroupTitleTypography = styled(Typography)`
  font-size: 16px;
  color: ${(props) => props.theme.palette.tertiary.main};
`

export const Separator = styled(Box)`
  height: 2px;
  background: var(--Beige, #f6f5f4);
  width: 100%;
`
