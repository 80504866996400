import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { addAccessTokenInterceptor } from 'services/apis/config/AxiosConfig'

/**
 * The Auth0TokenIntercepot is used to update the access token to every api call.
 * As useAuth0 has to be used as part of a component, this component will wrap
 * other components and make sure a token is always available.
 */
export const Auth0TokenInterceptor = ({
  children
}: {
  children: React.ReactNode
}) => {
  const { getAccessTokenSilently } = useAuth0()

  React.useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently)
  }, [getAccessTokenSilently])

  return <>{children}</>
}
