import React from 'react'
import { Formulas } from './Formulas'
import { FormulasTableContainer } from './components/FormulasTable/FormulasTableContainer'
import { FormulasFiltersContainer } from './components/FormulasSearchFilter/FormulasSearchFiltersContainer'
import { useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import { createFormula } from 'state/formulas/FormulasSlice'
import { PATHS } from 'common/constants'
import { Formula } from 'models/Formula'
import { QueriesProvider } from '../../components/EntrTable/Filter/QueriesContext'

export const FormulasContainer: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const analytics = React.useContext(AnalyticsContext)

  const companyId = useAppSelector((state) => state.companies.currentCompany.id)

  const handleNewFormulaClick = React.useCallback(() => {
    analytics.createdNewFormula()
    void dispatch(createFormula({ companyId: companyId })).then((action) => {
      const formula = action.payload as Formula
      navigate(PATHS.EDIT_FORMULA.replace(':id', formula.id))
    })
  }, [companyId])

  return (
    <QueriesProvider>
      <Formulas
        onNewFormulaClick={handleNewFormulaClick}
        filterAndSearch={<FormulasFiltersContainer />}
        table={<FormulasTableContainer />}
      />
    </QueriesProvider>
  )
}
