import { Measurement } from 'models/Measurement'
import { api } from '../config/AxiosConfig'
import { toMeasurements } from './MeasurementApiMapper'
import { GetMeasurementssApiParams } from './MeasurementApiRequest'
import { MeasurementApiResponse } from './MeasurementApiResponse'

export const MeasurementsApi = {
  getMeasurements: (
    companyId: string,
    params: GetMeasurementssApiParams
  ): Promise<Measurement[]> => {
    return api
      .get<MeasurementApiResponse[]>(`/companies/${companyId}/measurements`, {
        params: params
      })
      .then((res) => toMeasurements(res.data))
  }
}
