import React from 'react'
import LoadingCircle from 'assets/LoadingCircle.png'
import { Box, Typography } from '@mui/material'

interface LoadingAnimationProps {
  text?: string
  sx?: React.CSSProperties
}

export const LoadingAnimation: React.FC<LoadingAnimationProps> = ({
  text,
  sx = {}
}) => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '15px',
      '@keyframes rotate': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' }
      },
      img: {
        animation: 'rotate 0.5s infinite linear normal'
      },
      ...sx
    }}
  >
    <img
      src={LoadingCircle}
      alt="loading"
      width="24"
      height="24"
      loading="lazy"
    />

    {!!text && (
      <Typography color="secondary" fontSize={16}>
        {text}
      </Typography>
    )}
  </Box>
)
