import axios, { AxiosProgressEvent } from 'axios'


export const simulateClickToDownload = (url: string, fileName: string) => {
  const a = document.createElement('a')
  a.style.display = 'none'
  a.download = fileName
  a.href = url
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

interface DownloadConfig {
  url: string,
  fileName: string
  fileType: string
  onDownloadProgress?: (progressEvent: AxiosProgressEvent & { percentCompleted: number | undefined }) => void
  onError?: (error: unknown) => void
  onCancel?: (reason?: string) => void
  onFinished?: () => void
  timeout?: number
}

interface DownloadOperation {
  execute: () => Promise<void>
  cancel: (reason?: string) => void
}

export const createDownloadFileOperation = (
  config: DownloadConfig
): DownloadOperation => {
  const cancelToken = axios.CancelToken.source()
  let isCancelled = false

  const download = async (): Promise<void> => {
    try {
      const response = await axios
        .get(config.url, {
          cancelToken: cancelToken.token,
          responseType: 'blob',
          timeout: config.timeout || 30000,
          onDownloadProgress: (progressEvent) => {
            const percentCompleted = progressEvent.total
              ? Math.floor((progressEvent.loaded / progressEvent.total) * 100)
              : undefined
            config.onDownloadProgress?.({
              ...progressEvent,
              percentCompleted
            })
          }
        })

      const downloadUrl = URL.createObjectURL(new Blob([response.data], { type: config.fileType }))
      simulateClickToDownload(downloadUrl, config.fileName)
      URL.revokeObjectURL(downloadUrl)
    } catch (error) {
      if (axios.isCancel(error)) {
        config.onCancel?.(error.message)
      } else {
        config.onError?.(error)
      }
    } finally {
      cancelToken.cancel()
      config.onFinished?.()
    }
  }

  return {
    execute: download,
    cancel: (reason) => {
      if (!isCancelled) {
        isCancelled = true
        cancelToken.cancel(reason)
      }
    }
  }
}

