import React from 'react'
import { POINT_TO_PIXEL_RATIO as POINT_TO_PIXELS_RATIO } from '../components/NutritionLabelStyles'

type NutritionLabelTextProps = React.PropsWithChildren<{
  bold?: boolean
  points: number
  wrapText?: boolean
  leading?: number
  alignRight?: boolean
  symbol?: boolean
}>

export const NutritionLabelText: React.FC<NutritionLabelTextProps> = ({
  children,
  bold = false,
  points,
  wrapText = false,
  leading = 0,
  alignRight = false,
  symbol = false
}) => {
  const fontSize = `${Math.ceil(points * POINT_TO_PIXELS_RATIO)}px`
  const lineHeight = `${Math.ceil(
    (points + leading) * POINT_TO_PIXELS_RATIO
  )}px`

  return !symbol ? (
    <div
      style={{
        fontFamily: bold ? 'Helvetica Black' : 'Helvetica',
        fontSize: fontSize,
        whiteSpace: wrapText ? 'normal' : 'nowrap',
        lineHeight: lineHeight,
        textAlign: alignRight ? 'right' : 'left',
        color: 'black',
        display: 'inline'
      }}
    >
      {children}
    </div>
  ) : (
    <span
      style={{
        fontFamily: bold ? 'Helvetica Black' : 'Helvetica',
        fontSize: fontSize,
        top: '2px',
        position: 'relative',
        color: 'black'
      }}
    >
      {children}
    </span>
  )
}
