import { Typography } from '@mui/material'
import { BoxRow } from 'components/common'
import React from 'react'

interface EditableTextProps {
  initialValue?: string
  saveAction: (title: string) => void
  placeholder?: string
  size?: 'small' | 'large'
  prefix?: string
}

export const EditableText: React.FC<EditableTextProps> = ({
  initialValue,
  saveAction,
  placeholder,
  size = 'large',
  prefix
}) => {
  const [value, setValue] = React.useState(initialValue)
  const [isEditing, setIsEditing] = React.useState(false)
  const [isHovered, setIsHovered] = React.useState(false)
  const titleRef = React.useRef<HTMLSpanElement>(null)

  React.useEffect(() => {
    if (initialValue) {
      setValue(initialValue)
    }
  }, [initialValue])

  const handleNewTitle = () => {
    setIsEditing(false)
    if (titleRef.current) {
      const updatedTitle = titleRef.current.textContent || placeholder || ''
      setValue(updatedTitle.trim())
      saveAction(updatedTitle)
    }
  }

  const handleKeyInput = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      titleRef.current?.blur()
    }
  }

  const handleClick = () => {
    setIsEditing(true)
  }

  const handleMouseOut = () => {
    setIsHovered(false)
  }

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleFocus = () => {
    const el = titleRef.current
    if (el) {
      const range = document.createRange()
      const sel = window.getSelection()
      range.setStart(el, 0)
      range.collapse(true)
      sel?.removeAllRanges()
      sel?.addRange(range)
    }
  }

  return (
    <BoxRow>
      <Typography
        variant={size === 'small' ? 'body2' : 'h1'}
        sx={{ color: value === placeholder && !isEditing ? 'gray' : 'black' }}
      >
        {prefix}
      </Typography>
      <Typography
        ref={titleRef}
        suppressContentEditableWarning={true}
        contentEditable={true}
        onBlur={handleNewTitle}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseOut={handleMouseOut}
        onKeyDown={handleKeyInput}
        onFocus={handleFocus}
        variant={size === 'small' ? 'body2' : 'h1'}
        sx={{
          border: `1px solid ${
            isHovered || isEditing ? 'rgba(0, 0, 0, 0.1)' : 'transparent'
          }`,
          borderRadius: '8px',
          boxShadow: 'none',
          padding: '4px 0px',
          outline: 'none',
          color: value === placeholder && !isEditing ? 'gray' : 'black',
          minWidth: '20px'
        }}
      >
        {isEditing ? value : value || placeholder}
      </Typography>
    </BoxRow>
  )
}
