import { Box, Typography } from '@mui/material'
import { BoxColumnBasic, BoxRowBasic } from 'components/common'
import { TruthSetExecutionResult } from 'models/FormulaTargets'
import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import { InfoBubble } from 'components/InfoBubble/InfoBubble'
import { MarkdownRenderer } from 'components/MarkdownRenderer/MarkdownRenderer'
import { easeQuadInOut } from 'd3-ease'
import { AnimatedProgressWrapper } from './components/AnimatedProgressWrapper'
import { TargetResultProgress } from './components/TargetResultProgress'

export const executionResultColor = {
  success: 'rgb(144,200,52)',
  warning: 'rgb(255,199,0)',
  error: 'rgb(208,56,56)',
  disabled: 'rgb(217,221,226)'
}

export interface TargetResultProps {
  progressPercentage?: number
  matched?: boolean
  active?: boolean
  name: string
  description?: string
  executionTracePerTruthSet?: TruthSetExecutionResult[]
}

export const TargetResult: React.FC<TargetResultProps> = ({
  description,
  active,
  matched,
  name,
  progressPercentage,
  executionTracePerTruthSet
}) => {
  const value = React.useMemo(() => {
    if (matched) {
      return 100
    }

    return progressPercentage ?? 0
  }, [progressPercentage, matched])

  const hasError: boolean = React.useMemo(() => {
    const isTruthSetExecutedWithError = (
      truthSet: TruthSetExecutionResult
    ): boolean =>
      truthSet.truthSetOperationsExecutionResults.some(
        (operationResult) => operationResult.error !== undefined
      )

    return (
      executionTracePerTruthSet?.every((truthSet) =>
        isTruthSetExecutedWithError(truthSet)
      ) ?? false
    )
  }, [executionTracePerTruthSet])

  const targetReached = React.useMemo(() => {
    return matched && !hasError
  }, [matched, hasError])

  const targetNotReached: boolean = React.useMemo(() => {
    return !targetReached && !hasError
  }, [targetReached, hasError])

  const resultMessage = React.useMemo(() => {
    if (hasError) {
      return 'Not enough data'
    }
    if (targetReached) {
      return 'Target reached'
    }
    if (targetNotReached) {
      return 'Not on target'
    }
    return 'Not enough data'
  }, [hasError, targetReached, targetNotReached])

  const messages = React.useMemo(() => {
    const getTruthSetMessages = (
      truthSet: TruthSetExecutionResult
    ): string[] => {
      const messages: string[] = []
      truthSet.truthSetOperationsExecutionResults.forEach((operationResult) => {
        if (operationResult.error) {
          messages.push(operationResult.error.errorMessage)
        } else if (operationResult.suggestionMessage) {
          messages.push(operationResult.suggestionMessage)
        }
      })
      return messages
    }

    const messagesPerTruthSet: string[][] = []
    executionTracePerTruthSet?.forEach((truthSetExecutionTrace) => {
      const truthSetMessages = getTruthSetMessages(truthSetExecutionTrace)
      if (truthSetMessages.length > 0) {
        messagesPerTruthSet.push(truthSetMessages)
      }
    })
    return messagesPerTruthSet
  }, [executionTracePerTruthSet])

  const getColor = (
    value: number,
    selected = false,
    error: boolean
  ): string => {
    if (!selected) {
      return executionResultColor.disabled
    }
    if (error || value < 30) {
      return executionResultColor.error
    }
    if (value < 100) {
      return executionResultColor.warning
    }
    if (value == 100) {
      return executionResultColor.success
    }
    return executionResultColor.error
  }

  const getValue = (value: number): number => {
    if (value > 95 && value < 100) {
      return 95
    }
    return Math.round(value)
  }

  return (
    <>
      <BoxRowBasic
        style={{
          padding: '4px 24px 4px 0px',
          alignItems: 'center',
          gap: '12px'
        }}
      >
        <BoxColumnBasic
          style={{
            width: '28px',
            height: '28px',
            minWidth: '28px',
            minHeight: '28px'
          }}
        >
          <AnimatedProgressWrapper
            valueStart={0}
            valueEnd={value}
            duration={0.8}
            easingFunction={easeQuadInOut}
          >
            {(value) => {
              const roundedValue = getValue(value)
              const color = getColor(roundedValue, active, !progressPercentage)
              return (
                <TargetResultProgress
                  success={targetReached}
                  disabled={!active}
                  onHoverMessages={messages}
                >
                  <Box>
                    {roundedValue > 0 ? (
                      <CircularProgressbar
                        value={roundedValue}
                        strokeWidth={50}
                        background={true}
                        styles={{
                          path: {
                            stroke: color,
                            strokeLinecap: 'butt'
                          },
                          trail: {
                            stroke: '#fff',
                            strokeLinecap: 'butt',
                            strokeWidth: 45
                          },
                          background: {
                            fill: color
                          }
                        }}
                      />
                    ) : (
                      <Box
                        style={{
                          width: '28px',
                          height: '28px',
                          border: `1px solid ${color}`,
                          borderRadius: '50%'
                        }}
                      ></Box>
                    )}
                  </Box>
                </TargetResultProgress>
              )
            }}
          </AnimatedProgressWrapper>
        </BoxColumnBasic>
        <BoxColumnBasic
          style={{
            alignItems: 'flex-start',
            gap: '4px'
          }}
        >
          <BoxRowBasic style={{ gap: '2px' }}>
            <Typography
              style={{
                color: !active
                  ? 'var(--Body, #D9DDE2)'
                  : 'var(--Body, #14202E)',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '14px',
                textDecorationLine: !active ? 'line-through' : 'none'
              }}
            >
              {name}
            </Typography>
            {description && (
              <InfoBubble
                text={<MarkdownRenderer markdownText={description} />}
              />
            )}
          </BoxRowBasic>
          <BoxRowBasic
            style={{
              alignItems: 'center',
              gap: '2px'
            }}
          >
            <Typography
              style={{
                color: 'var(--Tertiary, rgba(35, 60, 88, 0.43))',
                fontFamily: 'Inter',
                fontSize: '11px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '14px',
                letterSpacing: '0.33px'
              }}
            >
              {resultMessage}
            </Typography>
          </BoxRowBasic>
        </BoxColumnBasic>
      </BoxRowBasic>
    </>
  )
}
