interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  renderWhileHidden?: boolean // Does not destroy the content of the tab.
}

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  index,
  value,
  renderWhileHidden = false
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      style={{
        height: '100%'
      }}
    >
      {(renderWhileHidden || value === index) && children}
    </div>
  )
}
