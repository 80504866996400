import { api } from '../config/AxiosConfig'
import { YieldAdjustment } from './YieldAdjustmentsApiResponse'

export const YieldAdjustmentsApi = {
  getYieldAdjustments: (): Promise<YieldAdjustment[]> => {
    return api.get<YieldAdjustment[]>(`/yield_adjustments`).then((res) => {
      return res.data
    })
  }
}
