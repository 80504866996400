import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BasicAllergen } from 'models/Allergen'
import { AllergensApi } from 'services/apis/allergens/AllergensApi'
import { LoadingState } from '../CommonState'
import isEqual from 'lodash.isequal'

interface AllergensState extends LoadingState {
  allergens: BasicAllergen[]
}

const initialState: AllergensState = {
  allergens: [],
  loading: false,
  error: false
}

export const getAllergens = createAsyncThunk(
  'allergens/all/get',
  async (regulationId?: string) => {
    return await AllergensApi.getAllergens({ 
      regulationId: regulationId || undefined
    })
  }
)

export const allergensSlice = createSlice({
  name: 'allergensSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllergens.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      getAllergens.fulfilled,
      (state, action: PayloadAction<BasicAllergen[]>) => {
        if (!isEqual(state.allergens, action.payload)) {
          state.allergens = action.payload
        }
        state.loading = false
      }
    )
    builder.addCase(getAllergens.rejected, (state) => {
      state.loading = false
      state.error = true
    })
  }
})

export default allergensSlice.reducer
