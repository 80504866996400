import { EntrTableFiltersV2 } from 'components/EntrTable/EntrTableFiltersV2'
import { getOptionsFromFilterApiResponse } from 'components/EntrTable/Filter/helpers'
import { QueriesContext } from 'components/EntrTable/Filter/QueriesContext'
import React from 'react'
import { SimpleIngredientApi } from 'services/apis/simple_ingredient/SimpleIngredientApi'
import { useAppSelector } from 'state/hooks'
import {
  FilterSearchResponse,
  useFilter
} from '../../../../components/EntrTable/Filter/FiltersHook'

export const IngredientsSearchFiltersContainer: React.FC = () => {
  const { setSearch, setFilters, filters, search } =
    React.useContext(QueriesContext)
  const { suppliers, tags } = filters
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)

  // Search bar.
  const handleSearchUpdate = React.useCallback(
    (value: string) => {
      setSearch(value)
    },
    [setSearch]
  )

  const handleSuppliersSearchChange = React.useCallback(
    (search?: string, page = 1): Promise<FilterSearchResponse> => {
      return SimpleIngredientApi.getSimpleIngredientSuppliersFilter(companyId, {
        searchTerm: search,
        page: page,
        selectedSupplierIds: suppliers
      }).then((suppliersFilter) => {
        return getOptionsFromFilterApiResponse(
          suppliersFilter,
          (responseItem) => {
            return {
              label: responseItem.name,
              value: false,
              id: responseItem.id
            }
          },
          suppliers ?? [],
          Boolean(search)
        )
      })
    },
    [suppliers, companyId]
  )

  const handleTagsSearchChange = React.useCallback(
    (search?: string, page = 1): Promise<FilterSearchResponse> => {
      return SimpleIngredientApi.getSimpleIngredientTagsFilter(companyId, {
        searchTerm: search,
        page: page,
        selectedTagIds: tags
      }).then((tagsFilter) => {
        return getOptionsFromFilterApiResponse(
          tagsFilter,
          (responseItem) => {
            return {
              label: responseItem.name,
              value: false,
              id: responseItem.id
            }
          },
          tags ?? [],
          Boolean(search)
        )
      })
    },
    [tags, companyId]
  )

  const suppliersFilter = useFilter({
    name: 'Suppliers',
    onSearchChange: handleSuppliersSearchChange,
    localSearch: false,
    onChange: (values) => setFilters('suppliers', values)
  })

  const tagsFilter = useFilter({
    name: 'Tags',
    onSearchChange: handleTagsSearchChange,
    localSearch: false,
    onChange: (values) => setFilters('tags', values)
  })

  return (
    <EntrTableFiltersV2
      search={{
        placeholder: 'Search all ingredients',
        onUpdate: handleSearchUpdate,
        initialSearch: search
      }}
      filters={[suppliersFilter, tagsFilter]}
    />
  )
}
