import { Chip, Typography } from '@mui/material'
import DebouncedTextField from 'components/DebouncedTextField/DebouncedTextField'
import styled from 'styled-components'
import { BoxRow } from '../../common'

export const FilterSearchTextField = styled(DebouncedTextField)`
  padding: 10px 8px;
`

export const ChipCount = styled(Chip)`
  height: 18px;
`

export const FilterNameTypography = styled(Typography)``

export const FilterNameBox = styled(BoxRow)`
  gap: 4px;
`
