import { FormulaNutritionFactNutrient } from 'models/FormulaLabel'
import { ItemTableContent } from './ItemTableContent'

export const BottomNutrientsTable: React.FC<{
  bottomNutrients: FormulaNutritionFactNutrient[]
}> = ({ bottomNutrients = [] }) => (
  <>
    <ItemTableContent
      tableItemRowsProps={bottomNutrients.map((fnfn) => {
        return {
          nutrientType: fnfn.nutrient.type,
          itemRowValues: {
            name:
              fnfn.displayNameOptions.length > 1
                ? `${fnfn.displayNameOptions[0]} / ${fnfn.displayNameOptions[1]}`
                : `${fnfn.displayNameOptions[0]}`,
            amount: fnfn.displayAmountOptions[0]?.amount,
            unit: fnfn.nutrient.unit,
            dvPercentage: fnfn.displayDvOptions[0]?.amount
          }
        }
      })}
    />
  </>
)
