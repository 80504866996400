import { Button } from '@mui/material'
import { SxProps, styled } from '@mui/material/styles'
import {
  ButtonHoverColors,
  ButtonTextColors,
  MainColors
} from '../../styles/colors'
import { ButtonColor, ButtonSize, ButtonVariant } from './Button'

interface StyledButtonProps {
  bColor: ButtonColor
  bSize: ButtonSize
  bVariant: ButtonVariant
  sx?: SxProps
}

const sizeStyle = {
  normal: {
    small: {
      fontSize: '12px',
      padding: '4px 10px',
      lineHeight: '18px'
    },
    medium: {
      fontSize: '14px',
      padding: '8px 14px',
      lineHeight: '21px'
    },
    large: {
      fontSize: '18px',
      padding: '10px 20px',
      height: '38px'
    }
  },
  square: {
    small: {
      padding: '7px',
      height: '20px',
      width: '20px',
      borderRadius: '6px'
    },
    medium: {
      padding: '7px',
      height: '24px',
      width: '24px',
      borderRadius: '6px'
    },
    large: {
      padding: '7px',
      height: '40px',
      width: '40px',
      borderRadius: '8px'
    }
  }
}

const colorStyle = {
  normal: {
    primary: {
      boxShadow: '0px 2px 4px rgba(0, 20, 50, 0.09)',
      color: ButtonTextColors.primary,
      backgroundColor: MainColors.primary,
      '&:hover': {
        backgroundColor: ButtonHoverColors.primary,
        color: ButtonTextColors.primary
      }
    },
    secondary: {
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08)',
      color: ButtonTextColors.secondary,
      backgroundColor: MainColors.secondary,
      border: '1px solid rgba(0, 0, 0, 0.06)',
      '&:hover': {
        backgroundColor: ButtonHoverColors.secondary,
        border: '1px solid rgba(0, 0, 0, 0.1)',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
        color: ButtonTextColors.secondary
      },
      fontWeight: '500'
    },
    tertiary: {
      boxShadow: '0px 2px 4px rgba(0, 20, 50, 0.09)',
      color: ButtonTextColors.tertiary,
      backgroundColor: MainColors.tertiary,
      '&:hover': {
        backgroundColor: ButtonHoverColors.tertiary,
        color: ButtonTextColors.tertiary
      }
    },
    danger: {
      boxShadow: '0px 2px 4px rgba(0, 20, 50, 0.09)',
      color: ButtonTextColors.danger,
      backgroundColor: MainColors.danger,
      '&:hover': {
        backgroundColor: ButtonHoverColors.danger,
        color: ButtonTextColors.danger
      }
    }
  },
  text: {
    primary: {
      color: MainColors.primary,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: ButtonHoverColors.primaryLight,
        color: MainColors.primary
      },
      fontWeight: '400'
    },
    secondary: {
      color: ButtonTextColors.secondary,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: ButtonHoverColors.secondaryLight,
        color: MainColors.secondary
      },
      fontWeight: '400'
    },
    danger: {
      color: MainColors.danger,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: ButtonHoverColors.dangerLight,
        color: MainColors.danger
      },
      fontWeight: '400'
    }
  }
}

const getColorStyle = (variant: ButtonVariant, color: ButtonColor) => {
  const colorVariant = variant === 'text' ? 'text' : 'normal'
  const styles = colorStyle[colorVariant] as Record<ButtonColor, any>

  // Return color style based on the button color.
  return styles[color]
}

const getSizeStyle = (bVariant: ButtonVariant, bSize: ButtonSize) => {
  switch (bVariant) {
    case 'normal':
    case 'text':
      return sizeStyle['normal'][bSize]
    case 'square':
      return sizeStyle['square'][bSize]
    case 'circle':
      return { ...sizeStyle['square'][bSize], borderRadius: '100%' }
  }
}

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop: string) =>
    !['bColor', 'bSize', 'bVariant'].includes(prop)
})<StyledButtonProps>(
  ({ bColor = 'primary', bSize = 'medium', bVariant = 'normal' }) => ({
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '-0.01em',
    minWidth: 'unset',
    minHeight: 'unset',
    borderRadius: '8px',
    textTransform: 'none',
    ...getColorStyle(bVariant, bColor),
    ...getSizeStyle(bVariant, bSize)
  })
)
