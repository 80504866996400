import {
  Conversion,
  IngredientConversions,
  SuggestedConversion
} from 'models/Conversion'
import { toBasicIngredient } from '../ingredient/IngredientApiMapper'
import {
  ConversionApiResponse,
  IngredientConversionsApiResponse,
  SuggestedConversionApiResponse
} from './ConversionApiResponse'

export const toConversion = (response: ConversionApiResponse): Conversion => {
  return {
    ...response
  }
}

export const toSuggestedConversion = (
  response: SuggestedConversionApiResponse
): SuggestedConversion => {
  return {
    ...response
  }
}

export const toConversions = (
  response: ConversionApiResponse[]
): Conversion[] => {
  return response.map((conversion) => toConversion(conversion))
}

export const toSuggestedConversions = (
  response: SuggestedConversionApiResponse[]
): SuggestedConversion[] => {
  return response.map((conversion) => toSuggestedConversion(conversion))
}

export const toIngredientConversions = (
  response: IngredientConversionsApiResponse
): IngredientConversions => {
  return {
    ingredient: toBasicIngredient(response.ingredient),
    conversions: toConversions(response.conversions),
    suggestedConversions: toSuggestedConversions(response.suggestedConversions)
  }
}
