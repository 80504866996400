import React from 'react'
import Chip from '@mui/material/Chip'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'

type ArchivedChipProps = {
  iconOnly?: boolean
}
const ArchivedChip: React.FC<ArchivedChipProps> = ({ iconOnly }) => {
  return (
    <>
      {!iconOnly && (
        <Chip
          icon={
            <Inventory2OutlinedIcon sx={{ width: '15px', height: '15px' }} />
          }
          label={'Archived'}
          sx={{
            height: 'fit-content',
            padding: '2px',
            width: 'fit-content'
          }}
        />
      )}

      {iconOnly && (
        <Inventory2OutlinedIcon sx={{ width: '15px', height: '15px' }} />
      )}
    </>
  )
}

export default ArchivedChip
