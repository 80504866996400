import { BasicFormula } from './Formula'
import { BasicTarget, BasicTargetCategory, Operation } from './Target'

export interface BasicFormulaTargetCategory {
  id: string
  name: string
  rank?: number
}

export enum OperationExecutionErrorType {
  COMPARISON_FAILURE = 'Comparison Failure',
  DATA_NOT_FOUND = 'Data Not Found',
  LOGIC_ERROR = 'Logic Error',
  OTHER = 'Other'
}

export interface OperationExecutionError {
  errorType: OperationExecutionErrorType
  errorMessage: string
}

export interface OperationExecutionResult {
  operation: Operation
  matched: boolean
  error?: OperationExecutionError
  actualValue?: number
  expectedValue?: number
  percentScore?: number
  suggestionMessage?: string
}

export interface TruthSetExecutionResult {
  truthSetMatched: boolean
  truthSetPercentScore?: number
  truthSetOperationsExecutionResults: OperationExecutionResult[]
}

export interface FormulaTarget {
  formula: BasicFormula
  targetDefinition: BasicTarget
  targetDefinitionCategory?: BasicFormulaTargetCategory
  isTargetSelectedForFormula?: boolean
  matched?: boolean
  averagePercentScore?: number
  targetExecutionTracePerTruthSet?: TruthSetExecutionResult[]
}
