import React from 'react'
import {
  TargetSelection,
  TargetSelectionProps
} from './components/TargetSelection'
import { BoxColumnBasic } from 'components/common'
import {
  TargetParameters,
  TargetParametersProps
} from './components/TargetParameters'
import styled from 'styled-components'
import { Box } from '@mui/material'

interface TargetsSettingsProps {
  targetSelectionProps: TargetSelectionProps
  targetParametersProps: TargetParametersProps
}

const Separator = styled(Box)({
  width: '100%',
  height: '1px',
  margin: '10px 0px',
  opacity: 0.08,
  backgroundColor: '#000',
  flexShrink: 0
})

export const TargetsSettings: React.FC<TargetsSettingsProps> = ({
  targetParametersProps,
  targetSelectionProps
}) => {
  return (
    <BoxColumnBasic sx={{ gap: '10px' }}>
      <TargetParameters {...targetParametersProps} />
      <Separator />
      <TargetSelection {...targetSelectionProps} />
    </BoxColumnBasic>
  )
}
