import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box
} from '@mui/material'
import { ModalContext, ModalType } from './ModalContext' // Adjust the path based on your file structure
import { Button } from '../Button/Button'

export const Modal = () => {
  const { modal, closeModal } = React.useContext(ModalContext)

  const {
    open,
    title,
    type,
    message,
    onYesClicked,
    onNoClicked,
    yesText,
    noText,
    danger,
    content: element
  } = modal

  const handleYes = () => {
    if (onYesClicked) {
      onYesClicked()
    }
    closeModal()
  }

  const handleNo = () => {
    if (onNoClicked) {
      onNoClicked()
    }
    closeModal()
  }

  if (type === ModalType.COMPLEX) {
    return (
      <Dialog
        open={open}
        onClose={handleNo}
        PaperProps={{
          sx: {
            width: 'fit-content',
            height: 'fit-content',
            maxHeight: '80vh',
            maxWidth: '80vw',
            padding: '0px'
          }
        }}
      >
        {element}
      </Dialog>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={type === ModalType.CONFIRM ? handleNo : handleYes}
    >
      <DialogTitle>{title}</DialogTitle>
      {message && (
        <DialogContent>
          <Typography color={'secondary'}>{message}</Typography>
        </DialogContent>
      )}
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Box>
          {type === ModalType.CONFIRM && (
            <Button
              color="secondary"
              style={{ minWidth: '80px' }}
              onClick={handleNo}
            >
              {noText || 'No'}
            </Button>
          )}
        </Box>
        <Button
          color={danger ? 'danger' : 'primary'}
          style={{ minWidth: '80px' }}
          onClick={handleYes}
        >
          {yesText || 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
