import { Button } from 'components/Button/Button'
import styled from 'styled-components'

export const StyledSupplierSelectButton = styled(Button)`
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 4px;

  svg {
    flex-shrink: 0;
  }
`
