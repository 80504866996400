import { BasicFormula } from './Formula'
import { BasicUser } from './User'

export enum FormulaHistoryModificationField {
  NAME = 'name',
  STATUS = 'status',
  IMAGE_PATH = 'image path',
  NOTE = 'note',
  INGREDIENT_STATEMENT = 'ingredient statement',
  AMOUNT = 'amount',
  WASTE_PERCENTAGE = 'waste percentage',
  SERVING_WEIGHT = 'serving weight',
  CONTAINER_WEIGHT = 'container weight',
  MEASUREMENT = 'measurement',
  RACC = 'RACC',
  RACC_UNIT = 'RACC unit',
  IS_MEAL_OR_MAIN_DISH = '',
  REFERENCE_FORMULA = 'reference food'
}

export interface FormulaHistoryModification {
  field: FormulaHistoryModificationField
  oldValue: string
  newValue: string
}

export enum FormulaHistoryActivitySubject {
  FORMULA = 'formula',
  INGREDIENT = 'ingredient',
  INGREDIENTS = 'ingredients',
  ALLERGEN = 'allergen',
  ALLERGENS = 'allergens'
}

export enum FormulaHistoryActivityAction {
  CREATED = 'created',
  DUPLICATED = 'duplicated',
  ADDED = 'added',
  REMOVED = 'removed',
  UPDATED = 'changed'
}

export interface FormulaHistoryActivity {
  revisionId: string
  user: BasicUser
  subject: FormulaHistoryActivitySubject
  action: FormulaHistoryActivityAction
  createdAt: string
  name?: string
  names?: string[]
  modifications?: FormulaHistoryModification[]
}

export interface FormulaHistoryActivityPaginated {
  items: FormulaHistoryActivity[]
  page: number
  total: number
  size: number
  pages: number
}

export interface FormulaHistory {
  formula: BasicFormula
  activity: FormulaHistoryActivityPaginated
}
