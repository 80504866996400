import { Box, Typography } from '@mui/material'
import React from 'react'

interface TitleActionsBarProps {
  title: string
  action?: React.ReactNode
}

export const TitleActionsBar: React.FC<TitleActionsBarProps> = ({
  title,
  action
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '5px',
        fill: 'transparent',
        marginBottom: '40px'
      }}
    >
      <Typography variant="h1">{title}</Typography>
      <Box>{action}</Box>
    </Box>
  )
}
