import React, { createContext } from 'react'

export interface SearchFiltersContextProps {
  setSearch: (value: string) => void
  setFilters: (key: string, values: string[]) => void
  search?: string
  filters: { [key: string]: string[] | undefined }
}

export const SearchFiltersContext = createContext<SearchFiltersContextProps>({
  setSearch: () => {},
  setFilters: () => {},
  filters: {}
})

export const SearchFiltersProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [search, setSearch] = React.useState<string | undefined>(undefined)
  const [filters, _setFilters] = React.useState<{
    [key: string]: string[] | undefined
  }>({})

  const setFilters = (key: string, values: string[]) => {
    if (values.length > 0) {
      _setFilters((prevFilters) => ({
        ...prevFilters,
        [key]: values
      }))
    } else {
      _setFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters }
        delete updatedFilters[key]
        return updatedFilters
      })
    }
  }

  return (
    <SearchFiltersContext.Provider
      value={{
        setSearch,
        setFilters,
        search,
        filters
      }}
    >
      {children}
    </SearchFiltersContext.Provider>
  )
}
