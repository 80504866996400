import { ArrowOutward } from '@mui/icons-material'
import { InputAdornment, Tooltip, Typography } from '@mui/material'
import { Button } from 'components/Button/Button'
import React from 'react'
import { UnstyledInput } from '../../../../../../../FormulatorStyles'
import { useDebouncedCallback } from 'use-debounce'
import { InfoBubble } from 'components/InfoBubble/InfoBubble'

export interface IngredientNameProps {
  name: string
  friendlyId?: string
  detailsNavigation: {
    hoverMessage: string
    onClick: () => void
  }
  verified?: boolean
}

export const IngredientName: React.FC<IngredientNameProps> = ({
  name,
  friendlyId,
  detailsNavigation,
  verified = true
}) => {
  const [hovered, setHovered] = React.useState<boolean>(false)
  const [buttonHovered, setButtonHovered] = React.useState<boolean>(false)

  const [nameTooltipOpen, setNameTooltipOpen] = React.useState<boolean>(false)
  const [buttonTooltipOpen, setButtonTooltipOpen] =
    React.useState<boolean>(false)

  const handleTooltip = useDebouncedCallback(() => {
    // If the name is hovered and the button is not hovered, show the name tooltip.
    if (hovered && !buttonHovered) {
      setNameTooltipOpen(true)
    }
    // If the button is hovered, show the button tooltip.
    if (buttonHovered) {
      setButtonTooltipOpen(true)
    }
  }, 1000)

  React.useEffect(() => {
    // If the name is not hovered or the button is hovered, hide the name tooltip.
    if (!hovered || buttonHovered) {
      setNameTooltipOpen(false)
    }
    // If the button is not hovered, hide the button tooltip.
    if (!buttonHovered) {
      setButtonTooltipOpen(false)
    }
    // If the name is hovered or the button is hovered, show the tooltip after a delay.
    if (buttonHovered || hovered) {
      handleTooltip()
    }
  }, [hovered, buttonHovered])

  return (
    <Tooltip title={name} open={nameTooltipOpen}>
      <UnstyledInput
        fullWidth
        inputProps={{
          style: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontFamily: 'Inter',
            fontSize: 16,
            fontWeight: 600
          }
        }}
        onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onFocus={(e) => e.target.blur()}
        value={name}
        startAdornment={
          !verified && (
            <InputAdornment position="start">
              <InfoBubble
                type="warning"
                text="This ingredient is sourced from the USDA database and may have incomplete data or allergen information."
                width="300px"
              />
            </InputAdornment>
          )
        }
        endAdornment={
          <>
            {!hovered && friendlyId && (
              <InputAdornment position="end">
                <Typography variant="subtitle2" marginRight="4px">
                  #{friendlyId}
                </Typography>
              </InputAdornment>
            )}

            {hovered && (
              <Button
                variant="square"
                color="secondary"
                onClick={detailsNavigation.onClick}
                onMouseEnter={() => setButtonHovered(true)}
                onMouseLeave={() => setButtonHovered(false)}
                size="medium"
              >
                <Tooltip
                  title={detailsNavigation.hoverMessage}
                  open={buttonTooltipOpen}
                >
                  <ArrowOutward style={{ fontSize: '16' }} />
                </Tooltip>
              </Button>
            )}
          </>
        }
      />
    </Tooltip>
  )
}
