import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material'
import { AvatarInitials } from 'components/AvatarInitials/AvatarInitials'

interface UserCellProps {
  name: string
  avatarSrc: string
}

export const UserCell: React.FC<UserCellProps> = ({ name, avatarSrc }) => {
  const theme = useTheme()
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <AvatarInitials name={name} avatarSrc={avatarSrc} size={24} />
      <Typography
        color={theme.palette.secondary.main}
        sx={{ marginLeft: '8px' }}
      >
        {name}
      </Typography>
    </Box>
  )
}
