import React from 'react'
import { ReactComponent as CircleCheckIcon } from 'assets/CheckCircle.svg'
import { BoxRow } from '../common'
import { Typography, useTheme } from '@mui/material'
import CloudCircleOutlinedIcon from '@mui/icons-material/CloudCircleOutlined'
import { Save } from '@mui/icons-material'

export enum SavingStatus {
  SAVED,
  UNSAVED,
  SAVING
}

interface SavingStatusIndicatorProps {
  status: SavingStatus
}

export const SavingStatusIndicator: React.FC<SavingStatusIndicatorProps> = ({
  status
}) => {
  const theme = useTheme()
  const [label, setLabel] = React.useState<string>('All changes saved')
  const [icon, setIcon] = React.useState<React.ReactElement>(
    <CircleCheckIcon stroke={theme.palette.tertiary.main} />
  )

  React.useEffect(() => {
    switch (status) {
      case SavingStatus.SAVED:
        setLabel('All changes saved')
        setIcon(<CircleCheckIcon stroke={theme.palette.tertiary.main} />)
        break
      case SavingStatus.SAVING:
        setLabel('Saving changes')
        setIcon(<Save stroke={theme.palette.tertiary.main} />)
        break
      case SavingStatus.UNSAVED:
        setLabel('Unsaved changes')
        setIcon(
          <CloudCircleOutlinedIcon
            sx={{ color: theme.palette.tertiary.main }}
          />
        )
        break
      default:
        break
    }
  }, [status])

  return (
    <BoxRow style={{ marginRight: '20px' }}>
      {icon}
      <Typography variant="subtitle2" style={{ marginLeft: '5px' }}>
        {label}
      </Typography>
    </BoxRow>
  )
}
