import styled from 'styled-components'
import {
  FlexContainerRowSpaced,
  NutritionLabelBox
} from '../components/NutritionLabelStyles'

export const NutritionLabelDualBox = styled(NutritionLabelBox)`
  display: table-caption;
`

export const AmountPerServingContainer = styled(FlexContainerRowSpaced)`
  align-items: flex-end;
`

export const PercentDailyValueContainer = styled(FlexContainerRowSpaced)`
  flex-direction: row-reverse;
  align-items: center;
`
