import React from 'react'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import { SettingSubPageLayout } from './components/SettingSubPageLayout'
import { useTheme } from '@mui/material'
import {
  deleteCompany,
  getCompany,
  updateCompany
} from 'state/companies/CompaniesSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { ModalContext } from 'components/Modal/ModalContext'
import { CompanySkeleton } from './components/CompanySkeleton'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { InputFieldLabel } from 'components/InputFieldLabel/InputFieldLabel'
import { Can } from 'core/Permissions/AbilityContext'
import { Actions, Subjects } from 'core/Permissions/AbilityConstants'
import { Button } from 'components/Button/Button'

const Company: React.FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.users.currentUser)
  const userLoading = useAppSelector((state) => state.users.loading)
  const company = useAppSelector((state) => state.companies.currentCompany)
  const updating = useAppSelector((state) => state.companies.updating)
  const companiesLoading = useAppSelector((state) => state.companies.loading)
  const error = useAppSelector((state) => state.companies.error)
  const [companyName, setCompanyName] = React.useState('')
  const [initCompanyName, setInitCompanyName] = React.useState<string>()
  const [companyLogo, setCompanyLogo] = React.useState<string | null>('')
  const [initCompanyLogo, setInitCompanyLogo] = React.useState<string | null>()
  const [hasChanges, setHasChanges] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const { showConfirmationModal } = React.useContext(ModalContext)
  const { showError, showSuccess } = React.useContext(SnackbarContext)

  const handleDeleteCompany = () => {
    showConfirmationModal({
      title: 'Delete Company',
      danger: true,
      message: (
        <>
          Are you sure you want to permanently delete company{' '}
          <b>{company.name}</b>?
        </>
      ),
      yesText: 'Delete',
      noText: 'Cancel',
      onYesClicked: () =>
        void dispatch(deleteCompany({ companyId: company.id }))
    })
  }

  const handleSaveChanges = () => {
    void dispatch(
      updateCompany({
        companyId: company.id,
        data: { name: companyName, logoPath: companyLogo || undefined }
      })
    )
  }

  React.useEffect(() => {
    if (company.id !== '') {
      setInitCompanyName(company.name)
      setInitCompanyLogo(company.logoPath)
      setCompanyName(company.name)
      setCompanyLogo(company.logoPath)
    }
  }, [company])

  React.useEffect(() => {
    if (initCompanyName && initCompanyLogo !== undefined) {
      const arr = [
        companyName === initCompanyName,
        companyLogo === initCompanyLogo
      ]
      setHasChanges(!arr.reduce((prev, cur) => (!prev ? prev : cur), true))
    }
  }, [companyName, companyLogo, initCompanyName, initCompanyLogo])

  React.useEffect(() => {
    if (!updating && hasChanges && !error) {
      setInitCompanyName(companyName)
      setInitCompanyLogo(companyLogo)
      showSuccess('Company has been updated.')
    }
  }, [updating])

  React.useEffect(() => {
    if (error && hasChanges) {
      if (initCompanyName) setCompanyName(initCompanyName)
      if (initCompanyLogo !== undefined) setCompanyLogo(initCompanyLogo)
      showError('There was a problem updating company information.')
      void dispatch(getCompany({ companyId: user.companyId }))
    }
  }, [error])

  React.useEffect(() => {
    setLoading(userLoading || companiesLoading)
  }, [userLoading, companiesLoading])

  return (
    <>
      {loading && <CompanySkeleton />}

      {!loading && (
        <SettingSubPageLayout title="Company">
          <Grid container spacing={2} direction="column" width={'20vw'}>
            <Grid item container spacing={2} alignItems="center">
              <Grid item>
                <Avatar
                  src={companyLogo || undefined}
                  sx={{
                    width: '43px',
                    height: '42px',
                    borderRadius: '10px'
                  }}
                />
              </Grid>

              <Can I={Actions.UPDATE} a={Subjects.COMPANIES}>
                <Grid item>
                  <Button
                    color="secondary"
                    size="small"
                    disabled={updating}
                  >
                    Change Profile Photo
                  </Button>
                </Grid>
              </Can>
            </Grid>

            <Can I={Actions.UPDATE} a={Subjects.COMPANIES} passThrough>
              {(can) => (
                <Grid item sx={{ marginTop: '7px' }}>
                  <InputFieldLabel
                    label="Company Name"
                    initialValue={initCompanyName}
                    disabled={updating || !can}
                    onChange={(value: string) => setCompanyName(value)}
                  />
                </Grid>
              )}
            </Can>

            <Can I={Actions.UPDATE} a={Subjects.COMPANIES}>
              <Grid item sx={{ marginTop: '23px' }}>
                <Button
                  color="secondary"
                  disabled={updating || !hasChanges}
                  onClick={handleSaveChanges}
                  fullWidth
                >
                  Save Changes
                </Button>
              </Grid>
            </Can>

            <Can I={Actions.DELETE} a={Subjects.COMPANIES}>
              <Grid item sx={{ marginTop: '23px' }}>
                <Button
                  color="danger"
                  onClick={handleDeleteCompany}
                  disabled={true} // Currently, no one should be able to delete the company.
                  fullWidth
                >
                  Delete Company
                </Button>
              </Grid>
            </Can>
          </Grid>
        </SettingSubPageLayout>
      )}
    </>
  )
}

export default Company
