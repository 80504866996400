import { Box, Typography } from '@mui/material'
import { langDisplaySorter } from 'common/utils'
import {
  FormulaNutritionFactLabelLangSpecificFields,
  FormulaNutritionFactLanguage
} from 'models/FormulaLabel'
import React from 'react'

export interface LabelDescriptionProps {
  description: FormulaNutritionFactLabelLangSpecificFields
}

export const LabelDescription: React.FC<LabelDescriptionProps> = ({
  description
}) => {
  return (
    <Box width="100%">
      {Object.keys(description)
        .sort((a, b) =>
          langDisplaySorter(
            a as FormulaNutritionFactLanguage,
            b as FormulaNutritionFactLanguage
          )
        )
        .map((language) => (
          <Typography
            key={language}
            paragraph={true}
            sx={{ whiteSpace: 'pre-wrap' }}
          >
            {description[language as FormulaNutritionFactLanguage] || ''}
          </Typography>
        ))}
    </Box>
  )
}
