export interface BasicUser {
  id: string
  firstName: string
  lastName: string
  imagePath: string
  fullName: string
}

export interface User extends BasicUser {
  companyId: string
  email: string
  createdAt: string
  authenticatorId: string
}

export enum Role {
  MEMBER = 'Member',
  ADMIN = 'Admin'
}

export interface UserRole {
  user: User
  role: Role
}

export interface BasicUserRole {
  user: BasicUser
  role: Role
}
