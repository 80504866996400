import { ReactComponent as OpenArrowIcon } from 'assets/OpenArrow.svg'
import { BoxRow, Option } from 'components/common'
import {
  EntrSwitch,
  FormButton,
  FormButtonLabel
} from 'pages/Formulator/FormulatorStyles'

import { FormControlLabel, FormGroup, Popover } from '@mui/material'
import React from 'react'
import { SwitchLabel } from './ViewControllerStyle'

export interface ViewControllerProps {
  onChange: (option: Option<boolean>) => void
  options?: Option<boolean>[]
}

const arePropsEqual = (
  prevProps: ViewControllerProps,
  nextProps: ViewControllerProps
): boolean => {
  if (prevProps.options?.length !== nextProps.options?.length) {
    return false
  }
  if (prevProps.onChange !== nextProps.onChange) {
    return false
  }
  return true
}

const FormulatorControlSwitch: React.FC<{
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
}> = ({ label, checked, onChange }) => {
  return (
    <FormControlLabel
      sx={{ padding: '4px' }}
      control={
        <EntrSwitch
          size={'small'}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
      }
      label={<SwitchLabel>{label}</SwitchLabel>}
    />
  )
}

export const ViewController = React.memo(
  ({ onChange, options = [] }: ViewControllerProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null
    )
    const open = React.useMemo(() => Boolean(anchorEl), [anchorEl])
    const id = React.useMemo(
      () => (open ? 'simple-popover' : undefined),
      [open]
    )

    const handleClick = React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
      },
      [anchorEl]
    )

    const handleClose = React.useCallback(() => {
      setAnchorEl(null)
    }, [anchorEl])

    return (
      <BoxRow>
        <FormButton aria-describedby={id} onClick={handleClick}>
          <FormButtonLabel>View Options</FormButtonLabel>
          <OpenArrowIcon />
        </FormButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          sx={{
            borderRadius: '8px'
          }}
        >
          <FormGroup sx={{ margin: '6px 18px' }}>
            {options.map((o) => (
              <FormulatorControlSwitch
                key={o.id}
                label={o.label}
                checked={o.value}
                onChange={(c) => onChange({ ...o, value: c })}
              />
            ))}
          </FormGroup>
        </Popover>
      </BoxRow>
    )
  },
  arePropsEqual
)
