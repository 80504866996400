import { Box, Typography } from '@mui/material'
import { BoxColumnBasic } from 'components/common'
import { TargetResult, TargetResultProps } from './TargetResult/TargetResult'

export interface TargetCategoryProps {
  categoryName: string
  targetsResultsProps: TargetResultProps[]
}

export const TargetCategory: React.FC<TargetCategoryProps> = ({
  categoryName,
  targetsResultsProps
}) => {
  return (
    <BoxColumnBasic
      style={{
        alignItems: 'flex-start',
        gap: '10px'
      }}
    >
      <Typography
        style={{
          color: 'var(--Tertiary, rgba(35, 60, 88, 0.43))',
          fontFamily: 'Inter',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '13px',
          letterSpacing: '0.6px',
          textTransform: 'uppercase'
        }}
      >
        {categoryName}
      </Typography>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap={2}
        alignItems={'center'}
      >
        {targetsResultsProps
          ? targetsResultsProps.map((targetResultProps, index) => (
              <Box gridColumn="span 4" key={`tr-${index}`}>
                <TargetResult {...targetResultProps} />
              </Box>
            ))
          : null}
      </Box>
    </BoxColumnBasic>
  )
}
