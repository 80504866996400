import {
  Autocomplete,
  AutocompleteProps,
  FilterOptionsState,
  TextField,
  createFilterOptions
} from '@mui/material'

import React, { useState } from 'react'
import { LabelTypography } from '../common'
import { useDebouncedCallback } from 'use-debounce'
import { SelectOption } from '../SelectOptionsLabel/SelectOptionsLabel'

export type SelectionAction = 'select' | 'deselect'

interface AutoCompleteLabelProps<T>
  extends Omit<
    AutocompleteProps<T, boolean, boolean, boolean>,
    | 'renderInput'
    | 'onChange'
    | 'value'
    | 'renderOption'
    | 'renderTags'
    | 'defaultValue'
    | 'limitTags'
    | 'freeSolo'
    | 'onBlur'
    | 'onFocus'
  > {
  label: string
  labelIcon?: React.ReactNode
  required?: boolean
  debounceTime?: number
  initialValue?: SelectOption[]
  limitTags?: number
  freeSolo?: boolean
  onChange?: (
    value: SelectOption | null,
    action: SelectionAction,
    selectedOptions?: SelectOption[]
  ) => void
  onFocus?: React.FocusEventHandler<HTMLDivElement>
  onBlur?: React.FocusEventHandler<HTMLDivElement>
}

export const AutoCompleteLabel: React.FC<
  AutoCompleteLabelProps<SelectOption>
> = ({
  label,
  labelIcon,
  required = false,
  debounceTime = 0,
  initialValue = [],
  freeSolo,
  onChange,
  onFocus,
  onBlur,
  disabled,
  filterOptions,
  ...rest
}) => {
    const ref = React.useRef<HTMLDivElement | null>(null)
    const [internalValue, setInternalValue] =
      useState<SelectOption[]>([])
    const [tagLimit, setTagLimit] = useState<number>()
    const [blur, setBlur] = useState(true)
    const [force, setForce] = useState<number>()
    const filter = createFilterOptions<SelectOption>()

    React.useEffect(() => {
      if (initialValue !== undefined) {
        setInternalValue(initialValue)
      }
    }, [initialValue])

    const handleChangeDebounced = useDebouncedCallback(
      (newSelectedOptions: SelectOption[]) => {
        let changedOption: SelectOption | null = null
        let action: 'select' | 'deselect' = 'select'
        const updatedSelectOptions = newSelectedOptions.map((opt) =>
          opt.label.startsWith('Add "')
            ? { ...opt, label: opt.value.toString() }
            : opt
        )

        // Determine which option was selected or deselected
        if (Array.isArray(updatedSelectOptions)) {
          if (updatedSelectOptions.length > initialValue.length) {
            changedOption =
              updatedSelectOptions.find((opt) => !initialValue.includes(opt)) ??
              null // Selected
          } else {
            changedOption =
              initialValue.find((opt) => !updatedSelectOptions.includes(opt)) ??
              null // Deselected
          }
        }

        // Determine the action (select or deselect)
        action =
          Array.isArray(updatedSelectOptions) &&
            updatedSelectOptions.length > initialValue.length
            ? 'select'
            : 'deselect'

        // Update the previous selected options state
        setInternalValue(updatedSelectOptions)
        // Call the onChange prop if it's provided
        if (onChange && changedOption !== undefined) {
          onChange(changedOption, action, updatedSelectOptions)
        }
      },
      debounceTime
    )

    const handleFilters = (
      options: SelectOption[],
      params: FilterOptionsState<SelectOption>
    ) => {
      const filtered = filter(options, params)
      const { inputValue } = params
      const isExisting = options.some((o) => inputValue === o.label)
      //
      if (freeSolo && inputValue !== '' && !isExisting) {
        filtered.push({
          value: inputValue,
          label: `Add "${inputValue}"`
        })
      }
      return filtered
    }

    const isOptionEqualToValue = (option: SelectOption, v: SelectOption) => {
      return option && v && option.value === v.value
    }

    const handleBlur = () => {
      setBlur(true)
    }

    const handleFocus = () => {
      setBlur(false)
      setTagLimit(undefined)
    }

    React.useLayoutEffect(() => {
      if (blur) {
        const height = (ref.current as HTMLDivElement).clientHeight
        if (height > 33) {
          const updateLimit =
            (tagLimit ? tagLimit : internalValue.length) - 1 || 1
          setTagLimit(updateLimit)
        }
      }
    }, [blur, tagLimit, internalValue, force])

    React.useEffect(() => {
      const onResize = () => {
        setTagLimit(undefined)
        setForce(Math.random())
      }
      window.addEventListener('resize', onResize)
      return () => window.removeEventListener('resize', onResize)
    }, [])

    return (
      <div onFocus={onFocus} onBlur={onBlur}>
        <LabelTypography variant="subtitle2">
          {label} {required && '*'} {labelIcon}
        </LabelTypography>

        <Autocomplete
          ref={ref}
          disabled={disabled}
          {...rest}
          value={internalValue}
          multiple
          freeSolo={freeSolo}
          autoHighlight={true}
          limitTags={disabled ? undefined : tagLimit}
          onFocus={handleFocus}
          onBlur={handleBlur}
          filterOptions={(o, s) => {
            const f = filterOptions ? filterOptions(o, s) : []
            return handleFilters(f, s)
          }}
          onChange={(event, newSelectedOptions) => {
            handleChangeDebounced(newSelectedOptions as SelectOption[])
          }}
          isOptionEqualToValue={isOptionEqualToValue}
          renderOption={(props, option) => <li {...props}>{option?.label}</li>}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: null
              }}
            />
          )}
          sx={{
            '&&': {
              '.MuiAutocomplete-inputRoot': {
                padding: '1px',
                minHeight: 33
              },
              '.MuiInputBase-input': {
                height: blur ? 0 : 'initial'
              }
            }
          }}
        />
      </div>
    )
  }
