export const MainColors = {
  primary: '#057DEB',
  secondary: '#FFFFFF',
  danger: '#EB5757',
  warning: '#FFC70080',
  info: '#007FC7',
  success: '#90C834',
  tertiary: '#EAE7E6'
}

export const ButtonHoverColors = {
  primary: '#0E64E6',
  secondary: '#FCFCFC',
  danger: '#F43E52',
  tertiary: '#D2CFCF',
  primaryLight: 'rgba(14, 100, 230, 0.05)',
  secondaryLight: 'rgba(26, 44, 66, 0.05)',
  dangerLight: 'rgba(244, 62, 82, 0.05)'
}

export const ButtonTextColors = {
  primary: '#FFFFFF',
  secondary: 'rgba(26, 44, 66, 0.85)',
  danger: '#FFFFFF',
  tertiary: 'rgba(26, 44, 66, 0.85)'
}

export const PageColors = {
  formulas: '#8E59FF1A',
  ingredients: '#029D811A',
  suppliers: '#A352071A',
  targets: '#B234611A',
  settings: '#14202E1A'
}
