import {
  Autocomplete,
  Box,
  ClickAwayListener,
  Popper,
  SxProps,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import React from 'react'

import { ReactComponent as ArrowRightIcon } from 'assets/OpenArrow.svg'
import { pluralize } from 'common/utils'
import { Button } from 'components/Button/Button'
import { BoxRow, LabelTypography } from 'components/common'
import { useDebouncedCallback } from 'use-debounce'
import { IngredientSupplierPopper } from '../common/IngredientSupplierPopper'
import {
  FriendlyIdTypography,
  HighlightedSearchNameTypography,
  SupplierNameTypography
} from './IngredientSearchRowStyle'

export interface SupplierProps {
  id: string
  name: string
}

export interface SearchResult {
  ingredient: {
    id: string
    friendlyId?: string
    name: string
    highlightedName: string
  }
  suppliers: SupplierProps[]
}

export interface IngredientSearchProps {
  searchResults: SearchResult[]
  onSearchChange: (searchTerm: string, regulationId?: string) => void
  onClick: (ingredientId: string, supplierId?: string, regulationId?: string) => void
  onAdvancedSearchClick: (searchTerm: string, regulationId?: string) => void
  state: 'searching' | 'ready'
  label?: string
  labelIcon?: React.ReactNode
  sx?: SxProps
  placeholder?: string
  value?: string
}

export const IngredientSearch: React.FC<IngredientSearchProps> = ({
  searchResults,
  onSearchChange,
  onClick,
  onAdvancedSearchClick,
  state,
  label,
  labelIcon,
  sx,
  placeholder = 'Add an ingredient...',
  value
}) => {
  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const [anchorEl, setAnchorEl] = React.useState<HTMLLIElement | null>(null)
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false)
  const [popperContent, setPopperContent] = React.useState<JSX.Element | null>(
    null
  )

  const handleNewSearchTermDebounced = useDebouncedCallback(
    (newSearchTerm: string) => {
      onSearchChange(newSearchTerm)
    },
    300
  )

  const handleNewSearchTerm = React.useCallback(
    (newSearchTerm: string) => {
      if (searchTerm != newSearchTerm) {
        setSearchTerm(newSearchTerm)
        setAnchorEl(null)
        handleNewSearchTermDebounced.cancel()
        handleNewSearchTermDebounced(newSearchTerm)
      }
    },
    [searchTerm]
  )

  const handleHoverIngredient = (
    newAnchor: HTMLLIElement,
    newPopperContent: JSX.Element
  ) => {
    setAnchorEl(newAnchor)
    setPopperContent(newPopperContent)
  }

  const handleOnClick = (ingredientId: string, supplierId?: string) => {
    setAnchorEl(null)
    setSearchTerm('')
    setAutocompleteOpen(false)
    onClick(ingredientId, supplierId)
  }

  const handleAdvancedSearchClick = () => {
    setAnchorEl(null)
    setSearchTerm('')
    setAutocompleteOpen(false)
    onAdvancedSearchClick(searchTerm)
  }

  const optionsWithButton =
    state === 'searching' ||
    handleNewSearchTermDebounced.isPending() ||
    searchResults.length === 0
      ? []
      : [...searchResults, 'addButton']

  const AdvancedSearchButton: React.FC = () => {
    return (
      <Button
        fullWidth
        color="secondary"
        size="small"
        sx={{ pointerEvents: 'auto' }}
        onClick={(e) => {
          e.stopPropagation()
          handleAdvancedSearchClick()
        }}
      >
        Advanced Search
      </Button>
    )
  }

  React.useEffect(() => {
    setSearchTerm(value || '')
  }, [value])

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setAutocompleteOpen(false)
          setAnchorEl(null)
          setPopperContent(null)
        }}
      >
        <Box sx={sx}>
          <LabelTypography variant="subtitle2">
            {label} {labelIcon}
          </LabelTypography>
          <Autocomplete
            filterOptions={(x) => x}
            open={autocompleteOpen && searchTerm.length > 0}
            clearOnBlur={false}
            clearOnEscape={false}
            disableClearable
            onOpen={() => {
              setAutocompleteOpen(true)
            }}
            options={searchTerm ? optionsWithButton : []}
            getOptionLabel={(option) => {
              if (option === 'addButton') {
                return ''
              }
              return (option as SearchResult).ingredient.highlightedName
            }}
            value={undefined}
            inputValue={searchTerm}
            noOptionsText={
              state === 'searching' ||
              handleNewSearchTermDebounced.isPending() ? (
                'Searching...'
              ) : (
                <>
                  <Typography sx={{ marginBottom: '8px' }}>
                    No ingredients found
                  </Typography>
                  <AdvancedSearchButton />
                </>
              )
            }
            onInputChange={(event, newInputValue) => {
              handleNewSearchTerm(newInputValue)
            }}
            renderInput={(params) => {
              delete params.InputProps.endAdornment

              const inputStyle = {
                padding: '0 0.5em',
                borderRadius: '8px',
                border: '1px solid rgba(161, 171, 183, 0.30)',
                background: '#FDFDFD',
                boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.02) inset',
                fontSize: '16px'
              }

              return (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: inputStyle,
                    onKeyDown: (e) => {
                      if (e.key === 'Enter') {
                        // Prevent users from using the Enter key to add ingredients because a supplier might need to be picked.
                        e.stopPropagation()
                      }
                    }
                  }}
                  placeholder={placeholder}
                />
              )
            }}
            renderOption={(props, multiOption) => {
              if (multiOption === 'addButton') {
                return (
                  <li
                    {...props}
                    key="addButton"
                    style={{
                      pointerEvents: 'none',
                      backgroundColor: 'transparent'
                    }}
                  >
                    <AdvancedSearchButton />
                  </li>
                )
              } else {
                const option = multiOption as SearchResult
                // Generate list of suppliers for popper
                const popperContent = (
                  <IngredientSupplierPopper
                    ingredient={{
                      friendlyId: option.ingredient.friendlyId
                    }}
                    suppliers={option.suppliers}
                    onClick={(supplierId) =>
                      handleOnClick(option.ingredient.id, supplierId)
                    }
                  />
                )

                // Render the ingredient itself
                return (
                  <li
                    style={{
                      cursor:
                        option.suppliers.length > 1 ? 'default' : 'pointer',
                      paddingRight: '0.5em',
                      paddingLeft: '0.5em'
                    }}
                    {...props}
                    key={option.ingredient.id}
                    onMouseEnter={(e) => {
                      if (option.suppliers.length > 1) {
                        handleHoverIngredient(e.currentTarget, popperContent)
                      } else {
                        setPopperContent(null)
                        setAnchorEl(null)
                      }
                    }}
                    onClick={() => {
                      if (option.suppliers.length <= 1) {
                        handleOnClick(option.ingredient.id)
                      }
                    }}
                  >
                    <BoxRow
                      flexGrow={1}
                      flexShrink={1}
                      flexBasis={'70%'}
                      textOverflow={'ellipsis'}
                      whiteSpace={'nowrap'}
                      maxWidth={'100%'}
                      justifyContent={'space-between'}
                      gap={'5px'}
                    >
                      <Tooltip
                        title={option.ingredient.name}
                        enterDelay={500}
                        enterNextDelay={500}
                      >
                        <HighlightedSearchNameTypography
                          noWrap
                          dangerouslySetInnerHTML={{
                            __html: option.ingredient.highlightedName
                          }}
                        />
                      </Tooltip>

                      <BoxRow
                        flexGrow={0}
                        flexShrink={1}
                        maxWidth={'30%'}
                        flexBasis={'30%'}
                        textOverflow={'ellipsis'}
                        justifyContent={'flex-end'}
                        gap={'5px'}
                      >
                        {option.suppliers.length > 1 && (
                          <>
                            <Typography
                              color="secondary"
                              style={{
                                textAlign: 'right',
                                alignItems: 'center'
                              }}
                            >
                              {option.suppliers.length}{' '}
                              {pluralize(option.suppliers.length, 'supplier')}
                            </Typography>
                            <ArrowRightIcon
                              style={{ transform: 'rotate(-90deg)' }}
                            />
                          </>
                        )}
                        {option.suppliers.length <= 1 && (
                          <>
                            {option.ingredient.friendlyId && (
                              <FriendlyIdTypography
                                color="secondary"
                                style={{
                                  textAlign: 'right',
                                  alignItems: 'center'
                                }}
                              >
                                #{option.ingredient.friendlyId}
                              </FriendlyIdTypography>
                            )}
                            {option.suppliers.length > 0 && (
                              <Tooltip
                                title={option.suppliers[0].name}
                                enterDelay={500}
                                enterNextDelay={500}
                              >
                                <SupplierNameTypography
                                  color="secondary"
                                  noWrap
                                >
                                  {option.suppliers[0].name}
                                </SupplierNameTypography>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </BoxRow>
                    </BoxRow>
                  </li>
                )
              }
            }}
          />
        </Box>
      </ClickAwayListener>

      {/* Popper for ingredient suppliers */}
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement={'right-start'}
        sx={{ zIndex: '1' }}
      >
        {popperContent}
      </Popper>
    </>
  )
}
