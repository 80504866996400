import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Comment } from 'models/Comment'
import { FormulaApi } from 'services/apis/formula/FormulaApi'
import {
  AddFormulaCommentRequest,
  GetFormulaCommentsRequest
} from './CommentsRequest'
import { AddingState, LoadingState } from '../CommonState'

export interface CommentsState extends LoadingState, AddingState {
  comments: Comment[]
}

const initialState: CommentsState = {
  comments: [],
  loading: false,
  adding: false,
  error: false
}

export const getComments = createAsyncThunk(
  'formulas/comments/get',
  async ({
    companyId,
    formulaId
  }: GetFormulaCommentsRequest): Promise<Comment[]> => {
    return await FormulaApi.getComments(companyId, formulaId)
  }
)

export const addComment = createAsyncThunk(
  'formulas/comments/add',
  async ({
    companyId,
    formulaId,
    comment
  }: AddFormulaCommentRequest): Promise<Comment> => {
    return await FormulaApi.addComment(companyId, formulaId, { comment })
  }
)

const commentsSlice = createSlice({
  name: 'commentsSlice',
  initialState,
  reducers: {
    resetState: (state) => {
      Object.assign(state, initialState)
    }
  },
  extraReducers(builder) {
    builder.addCase(getComments.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getComments.fulfilled, (state, action) => {
      state.comments = action.payload
      state.loading = false
    })
    builder.addCase(getComments.rejected, (state) => {
      state.loading = false
      state.error = true
    })

    builder.addCase(addComment.pending, (state) => {
      state.adding = true
      state.error = false
    })
    builder.addCase(
      addComment.fulfilled,
      (state, action: PayloadAction<Comment>) => {
        state.comments = [...state.comments, action.payload]
        state.adding = false
      }
    )
    builder.addCase(addComment.rejected, (state) => {
      state.adding = false
      state.error = true
    })
  }
})

export const { resetState } = commentsSlice.actions

export default commentsSlice.reducer
