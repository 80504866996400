import { createSlice } from '@reduxjs/toolkit'
import { CreatingState, DeletingState, LoadingState } from '../CommonState'
import {
  BasicTarget,
  NormalizedOperations,
  NormalizedTarget
} from 'models/Target'
import {
  createTarget,
  deleteTarget,
  getAvailableNutrients,
  getTarget,
  getTargets,
  updateTarget
} from './thunks/TargetThunks'
import {
  handleCreateTargetFulfilled,
  handleCreateTargetPending,
  handleCreateTargetRejected,
  handleDeleteTargetFulfilled,
  handleDeleteTargetPending,
  handleDeleteTargetRejected,
  handleGetAvailableNutrientsFulfilled,
  handleGetAvailableNutrientsPending,
  handleGetAvailableNutrientsRejected,
  handleGetTargetFulfilled,
  handleGetTargetPending,
  handleGetTargetRejected,
  handleGetTargetsFulfilled,
  handleGetTargetsPending,
  handleGetTargetsRejected,
  handleUpdateTargetFulfilled,
  handleUpdateTargetPending,
  handleUpdateTargetRejected
} from './thunks/targetThunkHandlers'
import {
  addActiveOperationReducer,
  clearActiveTargetReducer,
  deleteActiveOperationReducer,
  filterTargetsReducer,
  resetTargetsStateReducer,
  setActiveTargetReducer,
  updateActiveOperationReducer,
  updateActiveTargetNameReducer,
  updateActiveTargetRegulationReducer
} from './reducers'
import { Nutrient } from 'models/Nutrient'

export interface TargetsState
  extends LoadingState,
    CreatingState,
    DeletingState {
  targets: BasicTarget[]
  filteredTargets: BasicTarget[]
  availableNutrients: Nutrient[]
  activeTarget: NormalizedTarget
  activeOperations: NormalizedOperations
  normalizedTarget: NormalizedTarget
  normalizedOperations: NormalizedOperations
  isActiveTargetUpdated: boolean
}

export const initialState: TargetsState = {
  targets: [],
  filteredTargets: [],
  availableNutrients: [],
  activeTarget: {} as NormalizedTarget,
  activeOperations: {} as NormalizedOperations,
  normalizedTarget: {} as NormalizedTarget,
  normalizedOperations: {} as NormalizedOperations,
  isActiveTargetUpdated: false,
  loading: false,
  creating: false,
  deleting: false,
  error: false
}

export const targetsSlice = createSlice({
  name: 'targetsSlice',
  initialState,
  reducers: {
    setActiveTarget: setActiveTargetReducer,
    clearActiveTarget: clearActiveTargetReducer,
    updateActiveTargetName: updateActiveTargetNameReducer,
    updateActiveTargetRegulation: updateActiveTargetRegulationReducer,
    deleteActiveOperation: deleteActiveOperationReducer,
    addActiveOperation: addActiveOperationReducer,
    updateActiveOperation: updateActiveOperationReducer,
    resetTargetsState: resetTargetsStateReducer,
    filterTargets: filterTargetsReducer
  },
  extraReducers(builder) {
    // get targets
    builder
      .addCase(getTargets.pending, handleGetTargetsPending)
      .addCase(getTargets.fulfilled, handleGetTargetsFulfilled)
      .addCase(getTargets.rejected, handleGetTargetsRejected)

    // get target
    builder
      .addCase(getTarget.pending, handleGetTargetPending)
      .addCase(getTarget.fulfilled, handleGetTargetFulfilled)
      .addCase(getTarget.rejected, handleGetTargetRejected)

    // create target
    builder
      .addCase(createTarget.pending, handleCreateTargetPending)
      .addCase(createTarget.fulfilled, handleCreateTargetFulfilled)
      .addCase(createTarget.rejected, handleCreateTargetRejected)

    // update target
    builder
      .addCase(updateTarget.pending, handleUpdateTargetPending)
      .addCase(updateTarget.fulfilled, handleUpdateTargetFulfilled)
      .addCase(updateTarget.rejected, handleUpdateTargetRejected)

    // delete target
    builder
      .addCase(deleteTarget.pending, handleDeleteTargetPending)
      .addCase(deleteTarget.fulfilled, handleDeleteTargetFulfilled)
      .addCase(deleteTarget.rejected, handleDeleteTargetRejected)

    // get available nutrients
    builder
      .addCase(
        getAvailableNutrients.pending,
        handleGetAvailableNutrientsPending
      )
      .addCase(
        getAvailableNutrients.fulfilled,
        handleGetAvailableNutrientsFulfilled
      )
      .addCase(
        getAvailableNutrients.rejected,
        handleGetAvailableNutrientsRejected
      )
  }
})

export const {
  setActiveTarget,
  clearActiveTarget,
  updateActiveTargetName,
  updateActiveTargetRegulation,
  deleteActiveOperation,
  addActiveOperation,
  updateActiveOperation,
  resetTargetsState,
  filterTargets
} = targetsSlice.actions

export const TargetsSlice = targetsSlice.reducer
