import {
  BasicIngredient,
  IngredientNutrient,
  IngredientSearchResult,
  IngredientSearchResultsPaginated
} from 'models/Ingredient'
import { toNutrient } from '../nutrient/NutrientApiMapper'
import { toBasicSuppliers } from '../supplier/SupplierApiMapper'

import {
  BasicIngredientApiResponse,
  IngredientApiResponse,
  IngredientNutrientApiResponse,
  IngredientSearchApiPaginatedResponse,
  IngredientSearchApiResponse
} from './IngredientApiResponse'

export const toBasicIngredient = (
  response: BasicIngredientApiResponse
): BasicIngredient => {
  return {
    ...response
  }
}

export const toBasicIngredientFromSimpleIngredient = (
  response: IngredientApiResponse
): BasicIngredient => {
  return {
    ...response,
    public: response.company.public
  }
}

export const toIngredientNutrient = (
  response: IngredientNutrientApiResponse
): IngredientNutrient => {
  return {
    ingredient: toBasicIngredient(response.ingredient),
    nutrient: toNutrient(response.nutrient),
    amount: response.amount,
    children: response.children?.map((c) => toIngredientNutrient(c)) || [],
    isCalculated: response.isCalculated
  }
}

export const toIngredientNutrients = (
  response: IngredientNutrientApiResponse[][]
): IngredientNutrient[][] => {
  return response.map((ingredientNutrientGroup) =>
    ingredientNutrientGroup.map((ingredientNutrient) => {
      return toIngredientNutrient(ingredientNutrient)
    })
  )
}

export const toIngredientSearchResult = (
  response: IngredientSearchApiResponse
): IngredientSearchResult => {
  return {
    ...response,
    suppliers: toBasicSuppliers(response.suppliers)
  }
}

export const toIngredientSearchResultsPaginated = (
  response: IngredientSearchApiPaginatedResponse
): IngredientSearchResultsPaginated => {
  return {
    items: response.items.map((ingredientResult) =>
      toIngredientSearchResult(ingredientResult)
    ),
    page: response.page,
    pages: response.pages
  }
}
