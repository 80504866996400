import React from 'react'
import { UnstyledInput } from '../../../../../../../FormulatorStyles'
import { convertToFixedFloat } from 'common/utils'

export interface IngredientCostProps {
  totalCost: number | null
}

export const IngredientCost: React.FC<IngredientCostProps> = ({
  totalCost
}) => {
  return (
    <UnstyledInput
      disabled={true}
      value={
        totalCost || totalCost === 0 ? convertToFixedFloat(totalCost, 2) : ''
      }
      sx={{
        borderRadius: '8px',
        flex: 2
      }}
      inputProps={{ style: { textAlign: 'right' } }}
    />
  )
}
