import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import React from 'react'
import { addComment, getComments } from 'state/comments/CommentsSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { Comments } from './Comments'

export const CommentsContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const loading = useAppSelector((state) => state.comments.loading)
  const adding = useAppSelector((state) => state.comments.adding)
  const comments = useAppSelector((state) => state.comments.comments)
  const analytics = React.useContext(AnalyticsContext)

  const handleSendComment = React.useCallback(
    async (comment: string): Promise<void> => {
      analytics.addedComment(formulaId)
      await dispatch(
        addComment({
          companyId: companyId,
          formulaId: formulaId,
          comment: comment
        })
      )
    },
    [analytics, companyId, dispatch, formulaId]
  )

  React.useEffect(() => {
    if (formulaId) {
      analytics.viewedFormulatorCommentsTab(formulaId)
      void dispatch(getComments({ companyId, formulaId }))
    }
  }, [companyId, formulaId])

  const commentsLoading = React.useMemo(() => {
    return loading && comments.length === 0
  }, [loading, comments])

  const commentEditDisabled = React.useMemo(() => {
    return adding || commentsLoading
  }, [adding, commentsLoading])

  const commentItems = React.useMemo(() => {
    return comments.map((comment) => {
      return {
        createdBy: {
          name: comment.createdBy.fullName,
          avatarSrc: comment.createdBy.imagePath
        },
        timestamp: comment.createdAt,
        comment: comment.comment
      }
    })
  }, [comments])

  return (
    <Comments
      loading={commentsLoading}
      commentEditProps={{
        onSubmit: handleSendComment,
        disabled: commentEditDisabled
      }}
      commentItems={commentItems}
    />
  )
}
