import { BasicUserRole, Role, User, UserRole } from 'models/User'
import { toBasicUserRole, toUser, toUserRoles } from './UserApiMapper'
import { api } from '../config/AxiosConfig'
import {
  BasicUserRoleApiResponse,
  UserApiResponse,
  UserRoleApiPaginatedResponse
} from './UserApiResponse'
import { UpdateUserApiData, UpdateUserRoleApiData } from './UserApiRequest'

export const UserApi = {
  getUser: (): Promise<User> => {
    return api.get<UserApiResponse>(`/users`).then((res) => {
      return toUser(res.data)
    })
  },

  updateUser: (data: UpdateUserApiData): Promise<User> => {
    return api.put<UserApiResponse>(`/users`, data).then((res) => {
      return toUser(res.data)
    })
  },

  deleteUser: () => {
    return api.delete<void>(`/users`)
  },

  getCompanyUsers: (companyId: string): Promise<UserRole[]> => {
    return api
      .get<UserRoleApiPaginatedResponse>(`/companies/${companyId}/users`)
      .then((res) => {
        return toUserRoles(res.data)
      })
  },

  updateUserRole: (
    companyId: string,
    userId: string,
    data: UpdateUserRoleApiData
  ): Promise<BasicUserRole> => {
    return api
      .put<BasicUserRoleApiResponse>(
        `/companies/${companyId}/users/${userId}/roles`,
        data
      )
      .then((res) => {
        return toBasicUserRole(res.data)
      })
  },

  deleteCompanyUser: (companyId: string, userID: string) => {
    return api.delete<void>(`/companies/${companyId}/users/${userID}`)
  }
}
