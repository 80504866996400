import { Select } from 'components/Select/Select'
import { BoxColumnBasic } from 'components/common'
import React from 'react'
import { RegulationApiResponse } from 'services/apis/regulation/RegulationApiResponse'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { getAvailableRegulations } from 'state/labels/nutritions/NutritionFactLabelsSlice'
import { updateActiveTargetRegulation } from 'state/targets'

export const TargetSettings: React.FC = () => {
  const dispatch = useAppDispatch()
  const regulations = useAppSelector(
    (state) => state.nutritionFactLabels.availableRegulations
  )
  const activeTarget = useAppSelector((state) => state.targets.activeTarget)
  const deleting = useAppSelector((state) => state.targets.deleting)
  const loading = useAppSelector((state) => state.targets.loading)

  const disabled = loading || deleting || !activeTarget.isEditable

  React.useEffect(() => {
    if (regulations.length === 0) {
      void dispatch(getAvailableRegulations())
    }
  }, [regulations.length])
  const handleRegulationUpdate = (regulationId: string) => {
    if (!activeTarget.isEditable) {
      return
    }
    const regulation = regulations.find((reg) => reg.id === regulationId)
    dispatch(updateActiveTargetRegulation(regulation as RegulationApiResponse))
  }

  const getRegulationsMenuOptions = () => {
    return regulations
      .map((regulation) => {
        return {
          label: regulation.name,
          value: regulation.id
        }
      })
      .concat({
        label: 'Default (FDA)',
        value: ''
      })
  }
  return (
    <BoxColumnBasic style={{ gap: '16px', padding: '20px 30px' }}>
      <Select
        options={getRegulationsMenuOptions()}
        value={activeTarget.regulation?.id || ''}
        onChange={(v) => handleRegulationUpdate(v)}
        label="Regulation"
        disabled={disabled}
      />
    </BoxColumnBasic>
  )
}
