import { TableCell, TableRow } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import React from 'react'
import {
  IngredientName,
  IngredientNameProps
} from './components/IngredientName'
import {
  IngredientQuantityEditor,
  IngredientQuantityEditorProps
} from './components/IngredientQuantityEditor'
import {
  IngredientSupplierDropdown,
  IngredientSupplierDropdownProps
} from './components/IngredientSupplierDropdown'
import {
  IngredientWastePercentage,
  IngredientWastePercentageProps
} from './components/IngredientWastePercentage'
import { IngredientCost, IngredientCostProps } from './components/IngredientCost'

export interface IngredientRowProps {
  formulaIngredientId: string
  cells: {
    ingredient: IngredientNameProps
    supplier: IngredientSupplierDropdownProps
    cost: IngredientCostProps
    wastePercentage: IngredientWastePercentageProps
    quantityEditor: IngredientQuantityEditorProps
  }
  onRowCheckedChange: (checked: boolean) => void
  checked: boolean
  visibility: {
    supplier: boolean
    cost: boolean
    wastePercentage: boolean
  }
}

export const IngredientRow: React.FC<IngredientRowProps> = ({
  cells,
  formulaIngredientId,
  onRowCheckedChange,
  checked,
  visibility
}) => {
  const tableCellPadding = { padding: '0px 8px' }

  return (
    <TableRow key={formulaIngredientId}>
      <TableCell sx={{ ...tableCellPadding }}>
        <Checkbox
          size="small"
          checked={checked}
          onChange={(_, checked) => onRowCheckedChange(checked)}
        />
      </TableCell>
      <TableCell sx={tableCellPadding}>
        <IngredientName {...cells.ingredient} />
      </TableCell>
      {visibility.supplier && (
        <TableCell sx={{ ...tableCellPadding }}>
          <IngredientSupplierDropdown {...cells.supplier} />
        </TableCell>
      )}
      {visibility.cost && (
        <TableCell sx={{ ...tableCellPadding }}>
          <IngredientCost totalCost={cells.cost.totalCost} />
        </TableCell>
      )}
      {visibility.wastePercentage && (
        <TableCell sx={{ ...tableCellPadding }}>
          <IngredientWastePercentage {...cells.wastePercentage} />
        </TableCell>
      )}
      <TableCell sx={{ ...tableCellPadding }}>
        <IngredientQuantityEditor {...cells.quantityEditor} />
      </TableCell>
    </TableRow>
  )
}
