import { api } from '../config/AxiosConfig'
import { Company } from 'models/Company'
import { toCompany } from './CompanyApiMapper'
import { CompanyApiResponse } from './CompanyApiResponse'
import { UpdateCompanyApiData } from './CompanyApiRequest'

export const CompanyApi = {
  getCompany: (companyId: string): Promise<Company> => {
    return api
      .get<CompanyApiResponse>(`/companies/${companyId}`)
      .then((res) => {
        return toCompany(res.data)
      })
  },

  updateCompany: (
    companyId: string,
    data: UpdateCompanyApiData
  ): Promise<Company> => {
    return api
      .put<CompanyApiResponse>(`/companies/${companyId}`, data)
      .then((res) => {
        return toCompany(res.data)
      })
  },

  deleteCompany: (companyId: string) => {
    return api.delete<void>(`/companies/${companyId}`)
  }
}
