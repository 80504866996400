import React, { useState } from 'react'
import {
  InputAdornment,
  TextField,
  MenuItem,
  IconButton,
  Menu,
  Box,
  Divider,
  Typography
} from '@mui/material'

interface InputWithAdornmentProps {
  initialValue: number | undefined
  initialUnit: string | undefined
  unitOptions: string[]
  containerStyle?: React.CSSProperties
  inputStyle?: React.CSSProperties
  getValueAndUnit?: (value: number, unit: string) => void
  disabled?: boolean
  unitDisplayFormatter?: (unit: string) => string
  startAdornment?: React.ReactNode
}

export const InputWithAdornment: React.FC<InputWithAdornmentProps> = React.memo(
  ({
    initialValue,
    initialUnit,
    unitOptions,
    containerStyle,
    inputStyle,
    getValueAndUnit,
    unitDisplayFormatter,
    disabled = false,
    startAdornment
  }) => {
    const [unitMenuAnchorEl, setUnitMenuAnchorEl] =
      useState<null | HTMLElement>(null)
    const [unit, setUnit] = useState<string>(initialUnit || unitOptions[0])
    const [value, setValue] = useState<string>(initialValue?.toString() || '0')
    const [unitOrValueUpdated, setUnitOrValueUpdated] = useState<boolean>(false)
    const [isBlurred, setIsBlurred] = useState<boolean>(true)

    const handleUnitMenuClick = (event: React.MouseEvent<HTMLElement>) => {
      setUnitMenuAnchorEl(event.currentTarget)
    }

    const handleUnitMenuClose = () => {
      setUnitMenuAnchorEl(null)
    }

    const handleUnitSelect = (newUnit: string) => {
      if (newUnit !== unit) {
        setUnit(newUnit)
      }
      handleUnitMenuClose()
    }

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value
      if (inputValue == value.toString()) {
        return
      }
      if (/^(\d*\.?\d*)$/.test(inputValue)) {
        setValue(inputValue)
      }
    }

    React.useEffect(() => {
      if (getValueAndUnit && unitOrValueUpdated && !isBlurred) {
        getValueAndUnit(parseFloat(value) || 0, unit)
        setUnitOrValueUpdated(false)
      }
    }, [unitOrValueUpdated, isBlurred])

    React.useEffect(() => {
      setUnitOrValueUpdated(true)
    }, [value, unit])

    React.useEffect(() => {
      if (initialValue && initialValue.toString() !== value) {
        setValue(initialValue.toString())
      }
    }, [initialValue])

    React.useEffect(() => {
      setUnit(initialUnit || unitOptions[0])
    }, [initialUnit, unitOptions])

    return (
      <TextField
        type="text"
        value={value}
        disabled={disabled}
        onChange={handleAmountChange}
        onClick={() => setIsBlurred(false)}
        onBlur={() => setIsBlurred(true)}
        style={containerStyle}
        InputProps={{
          style: inputStyle,
          startAdornment: startAdornment,
          endAdornment: (
            <InputAdornment position="end">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Divider orientation="vertical" flexItem style={{}} />
                <IconButton
                  disabled={disabled}
                  size="small"
                  onClick={(e) => {
                    handleUnitMenuClick(e)
                    setIsBlurred(false)
                  }}
                  aria-label="select unit"
                >
                  <Typography>
                    {unitDisplayFormatter ? unitDisplayFormatter(unit) : unit}
                  </Typography>
                </IconButton>
              </Box>
              <Menu
                anchorEl={unitMenuAnchorEl}
                open={Boolean(unitMenuAnchorEl)}
                onClose={handleUnitMenuClose}
              >
                {unitOptions.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === unit}
                    onClick={() => {
                      handleUnitSelect(option)
                      setIsBlurred(true)
                    }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </InputAdornment>
          )
        }}
      />
    )
  }
)
