import { createTheme, Theme } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const entrPurple = '#8e59ff'
const entrGreen = '#029D81'
const entrOrange = '#A35207'
const entrRed = '#B23461'
const entrWhite = '#FFFFFF'
const black = '#14202E'
export const textBlack = '#16191C'
const lightBlack = 'rgba(26, 44, 66, 0.75);'
const lightGray = 'rgba(35, 60, 88, 0.43)'
const entrBlue = '#057DEB'
const entrError = '#EB5757'

const {
  palette: { augmentColor }
} = createTheme()

const augment = (colorString: string) =>
  augmentColor({ color: { main: colorString } })

export const entrTheme: Theme = createTheme({
  palette: {
    body: augment(black),
    heading: augment(black),
    secondary: augment(lightBlack),
    error: augment(entrError),
    tertiary: augment(lightGray),
    highlight: augment('rgba(0, 0, 0, 0.06)'),
    accentHighlight: augment('rgba(6, 179, 234, 0.12)'),
    borderSelected: augment('rgba(6, 179, 234, 0.4)'),
    primary: augment('#057DEB'),
    entrPurple: augment(entrPurple),
    entrGreen: augment(entrGreen),
    entrOrange: augment(entrOrange),
    entrRed: augment(entrRed),
    beige: augment('#F6F5F4'),
    beigeDark: augment('#EAE7E6'),
    brandSwitcher: {
      orange: augment('#EA986E'),
      teal: augment('#32BCAC'),
      purple: augment('#9F67E6')
    },
    formulaColors: {
      green: augment('#93C91E'),
      teal: augment('#008080'),
      purple: augment('#8A67F0'),
      magenta: augment('#800080'),
      pink: augment('#FF93A6'),
      orange: augment('#FFA500'),
      red: augment('#E26E4A')
    },
    statusColors: {
      pink: augment('#FFE9F5'),
      grey: augment('rgba(18, 25, 50, 0.05)'),
      green: augment('rgba(232, 245, 211, 0.7)'),
      blue: augment('rgba(9, 132, 199, 0.08)')
    },
    statusTextColors: {
      pink: augment('#BD3582'),
      grey: augment('rgba(19, 33, 69, 0.58)'),
      green: augment('#375800'),
      blue: augment('#007FC7')
    },
    success: augment('#90C834'),
    warning: augment('#FFA500'),
    info: augment('#007FC7')
  },
  typography: {
    fontFamily: 'Poppins',
    body1: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px'
    },
    body2: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px'
    },
    h1: {
      fontSize: '40px',
      fontWeight: 700,
      lineHeight: '57px',
      letterSpacing: '-0.03em',
      textAlign: 'left',
      color: augment(black).main
    },
    h2: {
      fontWeight: 500,
      fontSize: 19,
      lineHeight: '28px',
      letterSpacing: '-0.02em',
      color: augment(lightBlack).main
    },
    h3: {
      fontFamily: 'Inter',
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: 600,
      color: '#14202E',
      letterSpacing: '-0.012em'
    },
    h4: {
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '14px',
      fontWeight: 500,
      color: '#14202E'
    },
    h5: {
      color: 'var(--Body, #14202E)',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: '-0.192px'
    },
    subtitle1: {
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 500
    },
    subtitle2: {
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '15px',
      fontWeight: 500,
      color: augment(lightGray).main
    },
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '21px',
          letterSpacing: '-0.01em',
          minWidth: 'unset',
          minHeight: 'unset',
          padding: '8px 8px',
          borderRadius: '8px'
        },
        containedPrimary: {
          background: entrBlue,
          boxShadow: '0px 2px 4px rgba(0, 20, 50, 0.09)',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#0E64E6'
          },
          paddingLeft: '14px',
          paddingRight: '14px'
        },
        containedSecondary: {
          background: entrWhite,
          border: '1px solid rgba(0, 0, 0, 0.06)',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08)',
          color: 'rgba(26, 44, 66, 0.85)',
          '&:hover': {
            backgroundColor: '#FCFCFC',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)'
          },
          fontWeight: 500,
          paddingLeft: '14px',
          paddingRight: '14px'
        },
        containedSizeSmall: {
          borderRadius: '8px',
          fontSize: '12px',
          fontWeight: 500,
          padding: '4px 10px'
        },
        containedError: {
          background: entrError,
          boxShadow: '0px 2px 4px rgba(0, 20, 50, 0.09)',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#F43E52'
          },
          paddingLeft: '14px',
          paddingRight: '14px'
        },
        textSecondary: {
          color: 'secondary'
        },
        startIcon: {
          marginRight: '8px',
          marginLeft: '-8px'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 7px 0px rgba(0, 0, 0, 0.25)',
          borderRadius: '30px',
          minWidth: '220px',
          maxWidth: '300px',
          background: '#FFF',
          padding: '0px 12px'
        },
        message: {
          color: lightBlack,
          padding: '10px 0px'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          background: '#FFFFFF',
          borderRadius: '8px',
          boxShadow: 'none',
          '& .MuiInput-root.MuiInputBase-sizeSmall .MuiInput-input': {
            padding: '4px 4px 4px 0px'
          }
        },
        input: {
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            display: 'none'
          }
        },
        tag: {
          borderRadius: '40px'
        }
      }
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true
      },
      styleOverrides: {
        root: {
          background: '#FFFFFF',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          boxShadow: 'none',
          padding: '4px 8px'
        },
        input: {
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            display: 'none'
          }
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        ::-webkit-scrollbar {
          width: 5px;  /* Width of the vertical scrollbar */
          height: 5px; /* Height of the horizontal scrollbar */
        }
        ::-webkit-scrollbar-track {
          background: transparent; /* Color of the track (scrollbar background) */
        }
        ::-webkit-scrollbar-thumb {
          background: #ddd; /* Color of the scroll thumb */
          border-radius: 20px; /* Rounded corners on the thumb */
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #ccc; /* Color of the thumb when hovering */
        }
      `
    },

    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        InputLabelProps: {
          style: {
            position: 'relative',
            fontFamily: 'Inter',
            fontWeight: 400,
            lineHeight: '15px',
            transform: 'none',
            color: lightGray
          }
        },
        inputProps: {
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            color: textBlack,
            marginLeft: '9px'
          }
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        IconComponent: ExpandMoreIcon,
        style: {
          borderRadius: '8px',
          background: '#FFFFFF',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08)'
        },
        inputProps: {
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: textBlack,
            marginLeft: '9px'
          }
        }
      },
      styleOverrides: {
        icon: {
          '&.MuiSelect-icon': {
            color: lightGray
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
          width: '360px',
          padding: '24px'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0px'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0px',
          marginBottom: '26px'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: '#14202E',
          fontFamily: 'Inter',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
          letterSpacing: '-0.192px',
          padding: '0px',
          marginBottom: '12px'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '17px'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '& td:first-of-type': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px'
          },
          '& td:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px'
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '17px'
        },
        displayedRows: {
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '17px'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            fontFamily: 'Inter, sans-serif',
            fontSize: '12px',
            fontWeight: 400
          },
          '& .MuiBox-root': {
            marginBottom: '20px'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontFamily: 'Inter, sans-serif',
          fontSize: '12px',
          fontWeight: 400
        },
        root: {
          padding: '8px',
          borderBottom: 'none',
          fontFamily: 'Inter',
          fontSize: '14px',
          '& .MuiTypography-root': {
            fontFamily: 'Inter, sans-serif'
          }
        }
      }
    },

    MuiTab: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '19px',
          letterSpacing: '0.01em',
          minWidth: 'unset',
          minHeight: 'unset',
          padding: '8px 16px',
          borderRadius: '8px',
          marginRight: '4px',

          transition: 'color 0.2s ease-in-out',
          backgroundColor: 'transparent',
          fontFamily: 'Inter',
          '&.Mui-selected': {
            fontWeight: 'bold',
            color: black,
            backgroundColor: '#EAE7E6',
            borderBottom: 'none'
          }
        }
      }
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          borderBottom: 'none',
          overflow: 'unset',
          '&.Mui-selected': {
            fontWeight: 'bold',

            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            borderBottom: 'none'
          }
        },
        indicator: {
          display: 'none'
        }
      }
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '8px'
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        rectangular: {
          borderRadius: '8px'
        }
      }
    }
  }
})
