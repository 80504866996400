import {
  FormulaNutritionFact,
  FormulaNutritionFactLabelAgeGroup,
  FormulaNutritionFactLabelLangSpecificFields,
  FormulaNutritionFactLabelType,
  FormulaNutritionFactNutrient
} from 'models/FormulaLabel'
import {
  FDA_CUTOFF_RANK_TOP_BOTTOM,
  HEALTH_CANADA_CUTOFF_RANK_TOP_BOTTOM,
  NutrientRequirement,
  NutrientType
} from 'models/Nutrient'
import React from 'react'
import { RegulationName } from 'services/apis/regulation/RegulationApiResponse'
import { NutritionLabelDual } from './NutritionLabelDual/NutritionLabelDual'
import { NutritionLabelLinear } from './NutritionLabelLinear/NutritionLabelLinear'
import { NutritionLabelLinearCanadian } from './NutritionLabelLinearCanadian/NutritionLabelLinearCanadian'
import { NutritionLabelTabular } from './NutritionLabelTabular/NutritionLabelTabular'
import { NutritionLabelVertical } from './NutritionLabelVertical/NutritionLabelVertical'
import { NutritionLabelVerticalCanadian } from './NutritionLabelVerticalCanadian/NutritionLabelVerticalCanadian'

export interface NutritionFactsPerContainerProps {
  calories: string
  topNutrients: FormulaNutritionFactNutrient[]
  bottomNutrients: FormulaNutritionFactNutrient[]
}
export interface NutritionFactsProps {
  servingsPerContainer: number
  servingSize: FormulaNutritionFactLabelLangSpecificFields
  servingWeight: string
  type: FormulaNutritionFactLabelType
  ageGroup: FormulaNutritionFactLabelAgeGroup
  calories: string
  topNutrients: FormulaNutritionFactNutrient[]
  bottomNutrients: FormulaNutritionFactNutrient[]
  perContainer?: NutritionFactsPerContainerProps
}

export interface NutritionLabelProps extends FormulaNutritionFact {
  regulationName: RegulationName
}

export const NutritionLabel: React.FC<NutritionLabelProps> = ({
  servingsPerContainer,
  servingSize,
  servingWeight,
  servingWeightOverride,
  nutrients,
  type,
  ageGroup,
  calories,
  optionalNutrientsType: optionalNutrients,
  perContainer,
  showProteinPercentage,
  regulationName
}) => {
  const optionalNutrientsType = optionalNutrients

  const caloriesOverrideAmount = React.useMemo(() => {
    return nutrients.find((n) => n.nutrient.type === NutrientType.CALORIES)
      ?.overrideValues?.amount
  }, [nutrients])

  const cutOffRank = React.useMemo(() => {
    switch (regulationName) {
      case RegulationName.FDA:
        return FDA_CUTOFF_RANK_TOP_BOTTOM
      case RegulationName.CANADA:
        return HEALTH_CANADA_CUTOFF_RANK_TOP_BOTTOM
      default:
        return FDA_CUTOFF_RANK_TOP_BOTTOM
    }
  }, [regulationName])

  const getBottomNutrients = (
    formulaNutritionFactNutrients: FormulaNutritionFactNutrient[]
  ): FormulaNutritionFactNutrient[] => {
    return formulaNutritionFactNutrients
      .filter(
        (fnfn) =>
          fnfn.nutrient.rank > cutOffRank &&
          (fnfn.nutrient.requirement === NutrientRequirement.MANDATORY ||
            (fnfn.nutrient.requirement === NutrientRequirement.OPTIONAL &&
              optionalNutrientsType?.includes(fnfn.nutrient.type)))
      )
      .map((a) => {
        return {
          nutrient: a.nutrient,
          displayDvOptions: a.overrideValues?.dv
            ? [{ amount: a.overrideValues?.dv }]
            : a.displayDvOptions,
          displayAmountOptions: a.overrideValues?.amount
            ? [{ amount: a.overrideValues?.amount }]
            : a.displayAmountOptions,
          displayNameOptions: a.overrideValues?.nutrientDisplayName
            ? [a.overrideValues?.nutrientDisplayName]
            : a.displayNameOptions
        }
      })
      .sort((a, b) => a.nutrient.rank - b.nutrient.rank)
  }

  const getServingWeight = (
    servingWeight: number,
    servingSizeOverride: string
  ): string => {
    return servingSizeOverride
      ? servingSizeOverride
      : servingWeight.toString() + 'g'
  }

  const getTopNutrients = (
    formulaNutritionFactNutrients: FormulaNutritionFactNutrient[],
    showProteinPercentage = false
  ): FormulaNutritionFactNutrient[] => {
    return formulaNutritionFactNutrients
      .filter(
        (fnfn) =>
          fnfn.nutrient.type != NutrientType.CALORIES &&
          fnfn.nutrient.rank <= cutOffRank &&
          (fnfn.nutrient.requirement === NutrientRequirement.MANDATORY ||
            (fnfn.nutrient.requirement === NutrientRequirement.OPTIONAL &&
              optionalNutrientsType?.includes(fnfn.nutrient.type)))
      )
      .map((a) => {
        // Remove the dv % for protein if not specified.
        return {
          nutrient: a.nutrient,
          displayDvOptions:
            a.nutrient.type === NutrientType.PROTEIN && !showProteinPercentage
              ? []
              : a.overrideValues?.dv
              ? [{ amount: a.overrideValues?.dv }]
              : a.displayDvOptions,
          displayAmountOptions: a.overrideValues?.amount
            ? [{ amount: a.overrideValues?.amount }]
            : a.displayAmountOptions,
          displayNameOptions: a.overrideValues?.nutrientDisplayName
            ? [a.overrideValues?.nutrientDisplayName]
            : a.displayNameOptions
        }
      })
      .sort((a, b) => a.nutrient.rank - b.nutrient.rank)
  }

  const props: NutritionFactsProps = {
    servingsPerContainer,
    servingSize,
    servingWeight: getServingWeight(servingWeight, servingWeightOverride),
    topNutrients: getTopNutrients(nutrients, showProteinPercentage),
    bottomNutrients: getBottomNutrients(nutrients),
    calories: caloriesOverrideAmount ? caloriesOverrideAmount : calories,
    type,
    ageGroup,
    perContainer: perContainer
      ? {
          calories: perContainer.calories,
          topNutrients: getTopNutrients(
            perContainer?.nutrients,
            showProteinPercentage
          ),
          bottomNutrients: getBottomNutrients(perContainer?.nutrients)
        }
      : {
          calories: calories,
          topNutrients: getTopNutrients(nutrients, showProteinPercentage),
          bottomNutrients: getBottomNutrients(nutrients)
        }
  }

  if (regulationName === RegulationName.CANADA) {
    switch (type) {
      case FormulaNutritionFactLabelType.VERTICAL:
        return <NutritionLabelVerticalCanadian {...props} />
      case FormulaNutritionFactLabelType.LINEAR:
        return <NutritionLabelLinearCanadian {...props} />
      default:
        return <NutritionLabelVerticalCanadian {...props} />
    }
  } else {
    switch (type) {
      case FormulaNutritionFactLabelType.VERTICAL:
        return <NutritionLabelVertical {...props} />
      case FormulaNutritionFactLabelType.LINEAR:
        return <NutritionLabelLinear {...props} />
      case FormulaNutritionFactLabelType.TABULAR:
        return <NutritionLabelTabular {...props} />
      case FormulaNutritionFactLabelType.DUAL:
        return <NutritionLabelDual {...props} />
      default:
        return <NutritionLabelVertical {...props} />
    }
  }
}
