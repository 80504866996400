import { Button } from 'components/Button/Button'
import {
  EntrTableContainer,
  PageEntrTableInnerContainer
} from 'components/common'
import { TitleActionsBar } from 'components/TitleActionsBar/TitleActionsBar'
import React from 'react'

interface FormulasProps {
  onNewFormulaClick: () => void
  filterAndSearch: React.ReactNode
  table: React.ReactNode
}

export const Formulas: React.FC<FormulasProps> = ({
  onNewFormulaClick,
  filterAndSearch,
  table
}) => {
  return (
    <PageEntrTableInnerContainer>
      <EntrTableContainer>
        <TitleActionsBar
          title={'Formulas'}
          action={<Button onClick={onNewFormulaClick}>New Formula</Button>}
        />
        {filterAndSearch}
        {table}
      </EntrTableContainer>
    </PageEntrTableInnerContainer>
  )
}
