import { Box } from '@mui/material'
import styled from 'styled-components'

export const EditorTopBarBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  alignItems: 'center',
  boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.06)',
  padding: '12px 18px',
  position: 'relative',
  zIndex: 3
})

export const EditorTopBarSeparator = styled(Box)({
  width: '1px',
  height: '32px',
  opacity: 0.08,
  backgroundColor: '#000',
  flexShrink: 0
})
