import React from 'react'
import { AutoCompleteLabel } from 'components/AutoCompleteLabel/AutoCompleteLabel'
import { InputFieldLabel } from 'components/InputFieldLabel/InputFieldLabel'
import { ModalContext } from 'components/Modal/ModalContext'
import {
  SavingStatus,
  SavingStatusIndicator
} from 'components/SavingStatusIndicator/SavingStatusIndicator'
import {
  SelectOption,
  SelectOptionsLabel
} from 'components/SelectOptionsLabel/SelectOptionsLabel'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import {
  BoxRow,
  ViewerContainer,
  ViewerContent,
  ViewerRow
} from 'components/common'
import { DetailedSupplier } from 'models/Supplier'
import { getAllergens } from 'state/allergens/AllergensSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  addSupplierAllergen,
  addSupplierTag,
  deleteSupplier,
  updateSupplier
} from 'state/suppliers/SuppliersSlice'
import { Documents } from './Documents'
import {
  SupplierViewerDivider,
  SupplierViewerTypography
} from './SupplierViewerStyles'
import { Button } from 'components/Button/Button'

interface SupplierViewerProps {
  show: boolean
  supplier?: DetailedSupplier
}

export const SupplierViewer: React.FC<SupplierViewerProps> = ({
  show = false,
  supplier
}) => {
  const dispatch = useAppDispatch()
  const { showConfirmationModal } = React.useContext(ModalContext)
  const { showError, showSuccess } = React.useContext(SnackbarContext)
  const currentUser = useAppSelector((state) => state.users.currentUser)
  const updating = useAppSelector((state) => state.suppliers.updating)
  const deleting = useAppSelector((state) => state.suppliers.deleting)
  const adding = useAppSelector((state) => state.suppliers.adding)
  const allergens = useAppSelector((state) => state.allergens.allergens)
  const tags = useAppSelector((state) => state.tags.tags)
  const [name, setName] = React.useState<string>('')
  const [description, setDescription] = React.useState<string>('')
  const [allergenOptions, setAllergenOptions] = React.useState<SelectOption[]>(
    []
  )
  const [tagOptions, setTagOptions] = React.useState<SelectOption[]>([])
  const [verified, setVerified] = React.useState(false)
  const [supplierAllergens, setSupplierAllergens] = React.useState<
    SelectOption[]
  >([])
  const [supplierTags, setSupplierTags] = React.useState<SelectOption[]>([])
  const saving = updating || adding
  const disabled = saving || deleting

  const handleDeleteSupplier = () => {
    if (supplier)
      showConfirmationModal({
        title: 'Delete Supplier',
        message: 'Are you sure you want to delete this supplier?',
        danger: true,
        onYesClicked: () => {
          void dispatch(
            deleteSupplier({
              companyId: currentUser.companyId,
              supplierId: supplier.id
            })
          )
            .unwrap()
            .then(() => {
              showSuccess(`${supplier.name} has been deleted.`)
            })
            .catch(() => {
              showError(`Failed to delete ${supplier.name}.`)
            })
        }
      })
  }

  React.useEffect(() => {
    if (allergens.length) {
      setAllergenOptions(
        allergens.map((a) => ({ label: a.name, value: a.type }))
      )
    } else {
      void dispatch(getAllergens())
    }
  }, [allergens])

  React.useEffect(() => {
    if (tags.length) {
      setTagOptions(tags.map((a) => ({ value: a.id, label: a.name })))
    }
  }, [tags])

  React.useEffect(() => {
    if (supplier) {
      setName(supplier.name)
      setVerified(supplier.verified)
      setSupplierAllergens(
        (supplier.allergens || []).map((a) => ({
          label: a.allergen.name,
          value: a.allergen.type
        }))
      )
      setSupplierTags(
        supplier.tags.map((t) => ({ label: t.name, value: t.id }))
      )
    }
  }, [supplier])

  React.useEffect(() => {
    if (supplier && name !== supplier.name) {
      // update name
      void dispatch(
        updateSupplier({
          companyId: currentUser.companyId,
          supplierId: supplier.id,
          name,
          verified,
          description
        })
      ).catch(() => {
        showError('Failed to update suppliers name.')
        setName(supplier.name)
      })
    }
  }, [name])

  React.useEffect(() => {
    if (supplier && description !== supplier.description) {
      // update description
      void dispatch(
        updateSupplier({
          companyId: currentUser.companyId,
          supplierId: supplier.id,
          name,
          verified,
          description
        })
      ).catch(() => {
        showError('Failed to update suppliers description.')
        setDescription(supplier.description || '')
      })
    }
  }, [description])

  React.useEffect(() => {
    if (supplier && verified !== supplier.verified) {
      // update verified
      void dispatch(
        updateSupplier({
          companyId: currentUser.companyId,
          supplierId: supplier.id,
          name,
          verified,
          description
        })
      ).catch(() => {
        showError(`Failed to update suppliers status.`)
        setVerified(supplier.verified)
      })
    }
  }, [verified])

  React.useEffect(() => {
    if (supplierTags.length && supplier) {
      const currentNames = supplier.tags.map((t) => t.name)
      // get difference
      const dif = supplierTags.filter(
        (t) => currentNames.indexOf(t.label) === -1
      )
      if (dif[0]) {
        // add tag
        void dispatch(
          addSupplierTag({
            companyId: supplier.company.id,
            supplierId: supplier.id,
            name: dif[0].label
          })
        )
          .unwrap()
          .then((payload) => {
            showSuccess(`${payload.tag.name} tag has been added.`)
          })
          .catch(() => {
            showError('Failed to add tag.')
            setSupplierTags(
              supplier.tags.map((t) => ({ label: t.name, value: t.id }))
            )
          })
      }
    }
  }, [supplierTags])

  React.useEffect(() => {
    if (supplierAllergens.length && supplier) {
      const currentNames = supplier.allergens?.map((a) => a.allergen.name) || []
      // get difference
      const dif = supplierAllergens.filter(
        (t) => currentNames.indexOf(t.label) === -1
      )
      //
      if (dif[0]) {
        // add allergen
        void dispatch(
          addSupplierAllergen({
            companyId: supplier.company.id,
            supplierId: supplier.id,
            allergenType: dif[0].value as string
          })
        )
          .unwrap()
          .then((payload) => {
            const aName =
              allergens.find((a) => a.type === payload.allergen.type)?.name ||
              ''
            showSuccess(`${aName} allergen has been added.`)
          })
          .catch(() => {
            showError('Failed to add allergen.')
            setSupplierAllergens(
              (supplier.allergens || []).map((a) => ({
                label: a.allergen.name,
                value: a.allergen.type
              }))
            )
          })
      }
    }
  }, [supplierAllergens])

  return (
    <ViewerContainer data-show={show} onClick={(e) => e.stopPropagation()}>
      <ViewerRow sx={{ '&&': { gridTemplateColumns: 'auto auto' } }}>
        <BoxRow>
          {/* <FormControlLabelStyled
          label={'Show Spec Sheet'}
          control={<SwitchStyled onChange={handleShowSpecSheetChange} />}
        /> */}
        </BoxRow>
        <BoxRow gap="8px" justifyContent="flex-end">
          <SavingStatusIndicator
            status={saving ? SavingStatus.SAVING : SavingStatus.SAVED}
          />
          <Button
            size="small"
            color="danger"
            disabled={disabled}
            onClick={handleDeleteSupplier}
          >
            Delete Supplier
          </Button>
        </BoxRow>
      </ViewerRow>

      <ViewerContent>
        <InputFieldLabel
          label="Name"
          initialValue={name}
          disabled={disabled}
          onChange={(value: string) => setName(value)}
          debounceTime={2000}
        />

        <SelectOptionsLabel
          label="Status"
          options={[
            { value: 1, label: 'Approved' },
            { value: 0, label: 'Not Approved' }
          ]}
          initialValue={verified ? 1 : 0}
          disabled={disabled}
          onChange={(value: string | number) => setVerified(value == 1)}
        />

        <ViewerRow sx={{ '&&': { alignItems: 'start' } }}>
          <AutoCompleteLabel
            label="Tags"
            options={tagOptions}
            initialValue={supplierTags}
            disabled={disabled}
            onChange={(value: SelectOption[]) => setSupplierTags(value)}
            allowRemoval={false}
            freeSolo={true}
          />

          <AutoCompleteLabel
            label="Facility Allergens"
            options={allergenOptions}
            initialValue={supplierAllergens}
            disabled={disabled}
            onChange={(value: SelectOption[]) => setSupplierAllergens(value)}
            allowRemoval={false}
          />
        </ViewerRow>

        <InputFieldLabel
          label="Description"
          name="description"
          initialValue={supplier?.description}
          onChange={(value: string) => setDescription(value)}
          debounceTime={2000}
          multiline
          rows={4}
        />

        <ViewerRow sx={{ '&&': { gridTemplateColumns: 'auto auto' } }}>
          <SupplierViewerTypography
            sx={{ opacity: !supplier?.totalIngredients ? 0.5 : 1 }}
          >
            {!!supplier?.totalIngredients && (
              <>
                {`${supplier.totalIngredients} ingredients`}
                <span> added for this supplier</span>
              </>
            )}
            {!supplier?.totalIngredients && (
              <>
                No Ingredients
                <span> from this supplier</span>
              </>
            )}
          </SupplierViewerTypography>

          <SupplierViewerDivider />

          <SupplierViewerTypography
            sx={{ opacity: !supplier?.totalFormulas ? 0.5 : 1 }}
          >
            {!!supplier?.totalFormulas && (
              <>
                {`${supplier.totalFormulas} formulas`}
                <span> added for this supplier</span>
              </>
            )}
            {!supplier?.totalFormulas && (
              <>
                No formulas
                <span> use this supplier</span>
              </>
            )}
          </SupplierViewerTypography>
        </ViewerRow>
        {!!supplier?.id && <Documents supplier={supplier} />}
      </ViewerContent>
    </ViewerContainer>
  )
}
