import React from 'react'
import {
  TargetCategory,
  TargetCategoryProps
} from './components/TargetCategory'
import { BoxColumnBasic } from 'components/common'
import { Box, Typography } from '@mui/material'
import { SearchField } from 'components/SearchField/SearchField'

export interface TargetsResultsProps {
  targetCategoriesProps: TargetCategoryProps[]
}

export const TargetsResults: React.FC<TargetsResultsProps> = ({
  targetCategoriesProps
}) => {
  const [searchQuery, setSearchQuery] = React.useState<string>('')
  const [filteredTargetCategoriesProps, setFilteredTargetCategoriesProps] =
    React.useState<TargetCategoryProps[]>([])

  const onSearchChange = (search: string) => {
    setSearchQuery(search)
  }

  React.useEffect(() => {
    if (searchQuery) {
      const filteredCategories = targetCategoriesProps
        .map((category) => {
          const filteredTargets = category.targetsResultsProps.filter(
            (target) => {
              return target.name
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
            }
          )

          return {
            ...category,
            targetsResultsProps: filteredTargets
          }
        })
        .filter((category) => category.targetsResultsProps.length > 0)

      setFilteredTargetCategoriesProps(filteredCategories)
    } else {
      setFilteredTargetCategoriesProps(targetCategoriesProps)
    }
  }, [searchQuery, targetCategoriesProps])

  return (
    <BoxColumnBasic sx={{ gap: '20px' }}>
      <Box>
        <SearchField
          placeholder="Search targets"
          onChange={onSearchChange}
          sx={{ width: '100%' }}
        />
        {searchQuery && filteredTargetCategoriesProps.length === 0 && (
          <Typography sx={{ margin: '10px 0px', textAlign: 'center' }}>
            No targets matched your search
          </Typography>
        )}
      </Box>
      {filteredTargetCategoriesProps.map((targetCategoryProps, index) => {
        return (
          <TargetCategory
            categoryName={targetCategoryProps.categoryName}
            targetsResultsProps={targetCategoryProps.targetsResultsProps}
            key={`tc-${index}`}
          />
        )
      })}
    </BoxColumnBasic>
  )
}
