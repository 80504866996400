import { Tabs } from '@mui/material'
import React from 'react'
import {
  ViewerBottomContentContainer,
  ViewerBottomContentWrap,
  ViewerBottomHeaderFooter,
  ViewerBottomTab
} from 'components/common'
import { DetailedSupplier } from 'models/Supplier'
import { SuppliersDocumentsPanel } from './SuppliersDocumentsPanel'

interface DocumentsProps {
  supplier: DetailedSupplier
}

export const Documents: React.FC<DocumentsProps> = ({ supplier }) => {
  const [activeTab, setActiveTab] = React.useState(0)

  const handleTabChange = (e: React.SyntheticEvent, value: number) => {
    setActiveTab(value)
  }

  return (
    <ViewerBottomContentContainer>
      <ViewerBottomContentWrap>
        <ViewerBottomHeaderFooter data-header>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            sx={{ minHeight: 'unset' }}
          >
            <ViewerBottomTab label="Documents" />
          </Tabs>
        </ViewerBottomHeaderFooter>

        {activeTab === 0 && <SuppliersDocumentsPanel supplier={supplier} />}
      </ViewerBottomContentWrap>
    </ViewerBottomContentContainer>
  )
}
