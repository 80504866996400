import React from 'react'
import {
  Box,
  ButtonProps,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material'
import { Info } from '@mui/icons-material'
import styled from 'styled-components'
import { grey } from '@mui/material/colors'
import { Button } from 'components/Button/Button'

interface ButtonMenuItem {
  text: string
  onClick?: () => void
  itemStyle?: React.CSSProperties
  itemTextStyle?: React.CSSProperties
  disabled?: boolean
  groupName?: string
  disableReasonTooltipText?: string
}

interface GroupedButtonMenuItem {
  groupName: string
  items: ButtonMenuItem[]
}

interface DropdownButtonProps {
  buttonText?: string
  buttonTextStyle?: React.CSSProperties
  buttonStyle?: React.CSSProperties
  MenuItems?: ButtonMenuItem[]
  muiButtonProps?: ButtonProps
  menuStyle?: React.CSSProperties
  disabled?: boolean
}

interface MenuSectionProps {
  children: React.ReactNode
  label: string
}

const MenuSectionRoot = styled('li')`
  list-style: none;

  & > ul {
    padding-left: 1em;
  }
`

const MenuSectionLabel = styled('span')`
  display: block;
  padding: 10px 0 5px 10px;
  font-size: 0.75em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${grey[600]};
`

function MenuSection({ children, label }: MenuSectionProps) {
  return (
    <MenuSectionRoot role="group">
      <MenuSectionLabel>{label}</MenuSectionLabel>
      <ul>{children}</ul>
    </MenuSectionRoot>
  )
}

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  buttonText,
  buttonTextStyle,
  buttonStyle,
  muiButtonProps,
  MenuItems,
  menuStyle,
  disabled = false
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [groupedMenuItems, setGroupedMenuItems] = React.useState<
    GroupedButtonMenuItem[]
  >([])
  const open = Boolean(anchorEl)

  React.useEffect(() => {
    if (MenuItems) {
      const tempGroupedMenuItems: GroupedButtonMenuItem[] = []
      MenuItems.forEach((item) => {
        if (item.groupName) {
          const existingGroup = tempGroupedMenuItems.find(
            (group) => group.groupName === item.groupName
          )
          if (existingGroup) {
            existingGroup.items.push(item)
          } else {
            tempGroupedMenuItems.push({
              groupName: item.groupName,
              items: [item]
            })
          }
        }
      })
      setGroupedMenuItems(tempGroupedMenuItems)
    }
  }, [MenuItems])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (value: string) => {
    handleClose()
  }

  return (
    <div>
      <Button
        size={'small'}
        color={'secondary'}
        aria-controls={open ? 'simple-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={disabled}
      >
        {buttonText}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={menuStyle}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {groupedMenuItems.length === 0
          ? MenuItems?.map((item, index) => (
            <div style={{ display: 'inline-block' }} key={index}>
              <MenuItem
                key={index}
                onClick={() => {
                  item.onClick && item.onClick()
                  handleMenuItemClick(item.text)
                }}
                style={item.itemStyle}
                disabled={item.disabled}
              >
                <Typography
                  variant="subtitle2"
                  style={
                    item.itemTextStyle || {
                      color: 'var(--secondary, rgba(26, 44, 66, 0.75))',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 500
                    }
                  }
                >
                  {item.text}
                </Typography>
              </MenuItem>
              {item.disabled && (
                <Tooltip title={item.disableReasonTooltipText}>
                  <Info
                    style={{
                      color: 'var(--secondary, rgba(26, 44, 66, 0.75))',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      marginLeft: 'auto'
                    }}
                  />
                </Tooltip>
              )}
            </div>
          ))
          : groupedMenuItems.map((group, index) => (
            <MenuSection key={index} label={group.groupName}>
              {group.items.map((item, index) => (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                  key={index}
                >
                  <MenuItem
                    key={index}
                    onClick={() => {
                      item.onClick && item.onClick()
                      handleMenuItemClick(item.text)
                    }}
                    style={item.itemStyle}
                    disabled={item.disabled}
                  >
                    <Typography
                      variant="subtitle2"
                      style={
                        item.itemTextStyle || {
                          color: 'var(--secondary, rgba(26, 44, 66, 0.75))',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 500
                        }
                      }
                    >
                      {item.text}
                    </Typography>
                  </MenuItem>
                  {item.disabled && (
                    <Tooltip title={item.disableReasonTooltipText}>
                      <Info
                        style={{
                          color: 'var(--secondary, rgba(26, 44, 66, 0.75))',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          justifySelf: 'start'
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>
              ))}
            </MenuSection>
          ))}
      </Menu>
    </div>
  )
}
