import { Option } from 'components/common'
import React from 'react'
import { EditorSection } from '../common/EditorSection'
import { AllergenSwitch } from './AllergenSwitch'

export interface AllergenOption extends Option<boolean> {
  ingredientsCount: number
  description?: string
}

interface AllergensProps {
  allergens: AllergenOption[]
  onChange: (id: string, checked: boolean) => void
}

export const Allergens: React.FC<AllergensProps> = ({
  allergens,
  onChange
}) => {
  return (
    <EditorSection
      title="Allergens"
      withSeparator={false}
      content={allergens.map((allergen) => (
        <AllergenSwitch
          key={allergen.id}
          label={allergen.label}
          checked={allergen.value}
          ingredientsCount={allergen.ingredientsCount}
          onChange={(c) => onChange(allergen.id, c)}
          description={allergen.description}
        />
      ))}
    />
  )
}
