import React from 'react'
import { Snackbar, Backdrop, Alert, Slide } from '@mui/material'
import { LoadingAnimation } from '../LoadingAnimation/LoadingAmination'

interface LoadingStateContextInterface {
  showLoading: (options: LoadingStateOptions) => void
  hideLoading: () => void
  updateLoadingMessage: (message: string) => void
}

export const LoadingStateContext =
  React.createContext<LoadingStateContextInterface>({
    showLoading: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    hideLoading: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    updateLoadingMessage: () => {} // eslint-disable-line @typescript-eslint/no-empty-function
  })

interface LoadingStateOptions {
  message?: string
  blockUI?: boolean
  forceDisplay?: boolean
}

const defaultLoadingStateOptions: LoadingStateOptions = {
  message: 'Loading...',
  blockUI: false,
  forceDisplay: false
}

interface LoadingStateProviderProps {
  children: React.ReactNode
}

export const LoadingStateProvider: React.FC<LoadingStateProviderProps> = ({
  children
}) => {
  const [currentLoadingStateOptions, setCurrentLoadingStateOptions] =
    React.useState<LoadingStateOptions>(defaultLoadingStateOptions)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  return (
    <LoadingStateContext.Provider
      value={{
        showLoading: (options: LoadingStateOptions) => {
          if (isLoading && !options.forceDisplay) {
            return
          }
          setCurrentLoadingStateOptions(options)
          setIsLoading(true)
        },
        hideLoading: () => {
          setCurrentLoadingStateOptions(defaultLoadingStateOptions)
          setIsLoading(false)
        },
        updateLoadingMessage: (message: string) => {
          setCurrentLoadingStateOptions((prev) => ({ ...prev, message }))
        }
      }}
    >
      <Backdrop
        open={isLoading && !!currentLoadingStateOptions?.blockUI}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9998 }}
      />
      <Snackbar
        open={isLoading}
        autoHideDuration={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Slide}
        style={{ zIndex: 9999 }}
      >
        <Alert
          severity="info"
          style={{ backgroundColor: 'white', maxWidth: '40vw' }}
          icon={false}
        >
          <LoadingAnimation
            text={currentLoadingStateOptions?.message}
            sx={{ flexDirection: 'row', padding: '0px 10px' }}
          />
        </Alert>
      </Snackbar>
      {children}
    </LoadingStateContext.Provider>
  )
}
