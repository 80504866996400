import React from 'react'
import { Typography, Box } from '@mui/material'

interface TitleActionsLayoutProps {
  title: string
  action?: React.ReactNode
  children: React.ReactNode
}

export const TitleActionsLayout: React.FC<TitleActionsLayoutProps> = ({
  title,
  action,
  children
}) => {
  return (
    <Box sx={{ height: '100vh', overflow: 'auto' }}>
      <Box sx={{ padding: '1.5rem 2.875rem 3.3rem 2.5rem' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '5px',
            fill: 'transparent',
            marginBottom: '40px'
          }}
        >
          <Typography variant="h1">{title}</Typography>
          <Box>{action}</Box>
        </Box>
        {children}
      </Box>
    </Box>
  )
}
