import { Page, PageProps } from 'react-pdf'
import { useIntersectionObserver } from '@wojtekmaj/react-hooks'
import React from 'react'

interface PDFPageProps extends PageProps {
  pageNumber: number
  setPageVisibility: (pageNumber: number, isIntersecting: boolean) => void
}

const observerConfig = {
  threshold: 0
}

const PDFPage: React.FC<PDFPageProps> = ({
  pageNumber,
  setPageVisibility,
  ...props
}) => {
  const [page, setPage] = React.useState<HTMLCanvasElement | null>(null)

  const onIntersectionChange = React.useCallback(
    ([entry]: IntersectionObserverEntry[]) => {
      setPageVisibility(pageNumber, entry.isIntersecting)
    },
    [pageNumber, setPageVisibility]
  )

  useIntersectionObserver(page, observerConfig, onIntersectionChange)

  return <Page canvasRef={setPage} pageNumber={pageNumber} {...props} />
}

export default PDFPage
