import { Box, Typography } from '@mui/material'
import styled from 'styled-components'

export const UploadDateTitleTypographyStyled = styled(Typography)({
  color: 'var(--Tertiary, rgba(35, 60, 88, 0.43))',
  fontFamily: 'Inter',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  width: '40%'
})

export const DocumentLinearItemContentBoxStyled = styled(Box)({
  display: 'flex',
  padding: '8px',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
  borderRadius: '8px',
  backgroundColor: 'transparent',
  maxWidth: '250px',
  background: '#FFF',
  '&:hover': {
    backgroundColor: '#f0f0f0'
  }
})

export const DocumentTypeLinearItemViewBoxStyled = styled(Box)({
  width: '30px',
  height: '30px'
})

export const FileNameTypographyStyled = styled(Typography)({
  color: 'var(--Body, #14202E)',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
  flexGrow: 1
})

export const uploadDateStyle: React.CSSProperties = {
  color: 'var(--Tertiary, rgba(35, 60, 88, 0.43))',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  whiteSpace: 'nowrap',
  flexShrink: 1
}
