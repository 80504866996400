import { LoadingStateContext } from 'components/LoadingUI/LoadingUIContext'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import { BasicDocument } from 'models/Document'
import React from 'react'
import { updateFormulaNote } from 'state/formulator/FormulatorSlice'
import {
  deleteFormulaNoteDocument,
  downloadFormulaNoteDocument,
  getFormulaNoteDocuments,
  previewFormulaNoteDocument,
  uploadFormulaNoteDocument
} from 'state/formulator/documents/FormulaNoteDocumentsSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { Notes } from './Notes'

export const NotesContainer: React.FC = () => {
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const formulaNote = useAppSelector((state) => state.formulator.formula.note)
  const formulaNoteDocuments = useAppSelector(
    (state) => state.formulaNoteDocuments.formulaNoteDocuments
  )
  const dispatch = useAppDispatch()

  const analytics = React.useContext(AnalyticsContext)

  const { showError } = React.useContext(SnackbarContext)
  const { hideLoading } = React.useContext(LoadingStateContext)

  const handleChangeNote = React.useCallback(
    (newNote: string) => {
      analytics.modifiedNotes(formulaId)
      void dispatch(
        updateFormulaNote({
          companyId: currentCompany.id,
          formulaId: formulaId,
          note: newNote
        })
      )
    },
    [currentCompany.id, formulaId]
  )

  const getDocuments = React.useCallback(() => {
    void dispatch(
      getFormulaNoteDocuments({
        companyId: currentCompany.id,
        formulaId: formulaId
      })
    ).then((action) => {
      hideLoading()
      if (getFormulaNoteDocuments.rejected.match(action)) {
        showError('There was a problem loading the documents')
      }
    })
  }, [currentCompany.id, formulaId])

  const handleUploadDocument = React.useCallback(
    (file: File, progressListener: (progress: number | undefined) => void) => {
      analytics.uploadedDocument(formulaId)
      return dispatch(
        uploadFormulaNoteDocument({
          companyId: currentCompany.id,
          formulaId: formulaId,
          file: file,
          uploadProgressListener: (progressEvent) => {
            progressListener(progressEvent.progress)
          }
        })
      ).then(() => {})
    },
    [currentCompany.id, formulaId]
  )

  const handlePreviewDocument = React.useCallback(
    (file: BasicDocument) => {
      analytics.previewedDocument(formulaId)
      if (formulaId) {
        return dispatch(
          previewFormulaNoteDocument({
            companyId: currentCompany.id,
            file: file,
            formulaId: formulaId
          })
        )
          .unwrap()
          .then((res) => {
            return res.path
          })
      } else {
        return Promise.resolve('')
      }
    },
    [currentCompany.id, formulaId]
  )

  const handleDownloadDocument = React.useCallback(
    (file: BasicDocument) => {
      analytics.downloadedDocument(formulaId)
      return dispatch(
        downloadFormulaNoteDocument({
          companyId: currentCompany.id,
          formulaId: formulaId,
          fileName: file.name
        })
      ).then(() => {})
    },
    [currentCompany.id, formulaId]
  )

  const handleDeleteDocument = React.useCallback(
    (file: BasicDocument): Promise<void> => {
      analytics.deletedDocument(formulaId)
      return dispatch(
        deleteFormulaNoteDocument({
          companyId: currentCompany.id,
          formulaId: formulaId,
          fileName: file.name
        })
      ).then(() => {})
    },
    [currentCompany.id, formulaId]
  )

  React.useEffect(() => {
    if (formulaId) {
      getDocuments()
    }
  }, [formulaId])

  return (
    <Notes
      note={{
        value: formulaNote,
        onChange: handleChangeNote
      }}
      documents={{
        documents: formulaNoteDocuments,
        onDeleteDocument: handleDeleteDocument,
        onDownloadDocument: handleDownloadDocument,
        onPreviewDocument: handlePreviewDocument,
        onUploadDocument: handleUploadDocument,
        refresh: getDocuments
      }}
    />
  )
}
