import React from 'react'

import { Tooltip } from '@mui/material'
import { displayDate } from 'common/utils'
import { BasicDocument } from 'models/Document'
import { DocumentThumbnail } from '../../DocumentThumbnail/DocumentThumbnail'
import { MenuButton } from '../../MenuButton/MenuButton'
import {
  DocumentLinearItemContentBoxStyled,
  FileNameTypographyStyled
} from './DocumentsPanelLinearStyles'

interface DocumentsPanelLinearItemProps {
  file: BasicDocument
  onPreviewDocument: (document: BasicDocument) => void
  onDownloadDocument: (document: BasicDocument) => void
  onDeleteDocument: (document: BasicDocument) => void
}

export const DocumentsPanelLinearItem: React.FC<
  DocumentsPanelLinearItemProps
> = ({ file, onPreviewDocument, onDownloadDocument, onDeleteDocument }) => {
  return (
    <DocumentLinearItemContentBoxStyled
      onClick={() => void onPreviewDocument(file)}
    >
      <DocumentThumbnail type={file.type} size={32} />

      {/* Document Name */}
      <Tooltip
        title={file.name + ' - ' + displayDate(file.lastModified)}
        followCursor
        enterDelay={1000}
      >
        <FileNameTypographyStyled noWrap>{file.name}</FileNameTypographyStyled>
      </Tooltip>

      {/* Document Menu */}
      <MenuButton
        menuItems={[
          {
            itemTitle: 'Download',
            itemCallback: () => onDownloadDocument(file)
          },
          {
            itemTitle: 'Delete',
            itemCallback: () => onDeleteDocument(file),
            itemStyle: {
              color: 'var(--error, #D03838)'
            }
          }
        ]}
        showButton
      />
    </DocumentLinearItemContentBoxStyled>
  )
}
