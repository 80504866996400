import React from 'react'
import { BoxColumnBasic } from 'components/common'
import { useAppSelector } from 'state/hooks'
import { LogicOperation } from './LogicOperation/LogicOperation'
import { isNormalizedLogicalOperation } from 'services/apis/target/TargetApiMapper'

export const TargetRequirements: React.FC = () => {
  const activeTarget = useAppSelector((state) => state.targets.activeTarget)

  const rootOperation = useAppSelector((state) =>
    activeTarget.operationId
      ? state.targets.activeOperations[activeTarget.operationId]
      : undefined
  )

  return (
    <BoxColumnBasic style={{ gap: '16px', paddingTop: '20px' }}>
      {rootOperation &&
        (isNormalizedLogicalOperation(rootOperation) ? (
          <LogicOperation
            operationId={rootOperation.id}
            isRootOperation={true}
          />
        ) : (
          <></>
        ))}
    </BoxColumnBasic>
  )
}
