import { Button } from 'components/Button/Button'
import {
  EntrTableContainer,
  PageEntrTableContainer,
  PageEntrTableInnerContainer
} from 'components/common'
import { TitleActionsBar } from 'components/TitleActionsBar/TitleActionsBar'
import React from 'react'

interface IngredientsProps {
  ingredientViewer: React.ReactNode
  filterAndSearch: React.ReactNode
  table: React.ReactNode
  onClickAway: () => void
  onNewIngredientClick: () => void
}

export const Ingredients: React.FC<IngredientsProps> = ({
  ingredientViewer,
  filterAndSearch,
  table,
  onClickAway,
  onNewIngredientClick
}) => {
  const handleNewIngredientClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation()
    onNewIngredientClick()
  }

  return (
    <PageEntrTableContainer>
      {ingredientViewer}
      <PageEntrTableInnerContainer>
        <EntrTableContainer onClick={onClickAway}>
          <TitleActionsBar
            title={'Ingredients'}
            action={
              <Button onClick={handleNewIngredientClick}>New Ingredient</Button>
            }
          />
          {filterAndSearch}
          {table}
        </EntrTableContainer>
      </PageEntrTableInnerContainer>
    </PageEntrTableContainer>
  )
}
