import { useLoadNextPage } from 'common/hooks'
import { Option } from 'components/common'
import { EntrTableFilterProps } from 'components/EntrTable/Filter/EntrTableFilter'
import React from 'react'

export interface FilterSearchResponse {
  options: Option<boolean>[]
  page: number
  pages: number
}

interface FilterOptions {
  name: string
  localSearch?: boolean
  onSearchChange: (
    search?: string,
    page?: number
  ) => Promise<FilterSearchResponse>
  onChange: (values: string[]) => void
}

export const useFilter = (
  filterOptions: FilterOptions
): EntrTableFilterProps => {
  const [options, setOptions] = React.useState<Option<boolean>[]>([])
  const [filteredOptions, setFilteredOptions] = React.useState<
    Option<boolean>[] | null
  >(null)
  const [search, setSearch] = React.useState('')
  const [page, setPage] = React.useState(1)
  const [pages, setPages] = React.useState(1)

  const setCurrentPageCallback = React.useCallback(
    (newPage: number) => {
      void filterOptions
        .onSearchChange(search, newPage)
        .then(({ options: newOptions, page, pages }) => {
          if (search) {
            setFilteredOptions((prevFilteredOptions) => [
              ...(prevFilteredOptions || []),
              ...newOptions
            ])
          } else {
            setOptions((prevOptions) => [...prevOptions, ...newOptions])
          }
          setPage(page)
          setPages(pages)
        })
    },
    [filterOptions.onSearchChange, search, options, filteredOptions]
  )

  const { nextPageCallback, allPagesLoaded } = useLoadNextPage({
    page,
    pages,
    setCurrentPageCallback
  })

  React.useEffect(() => {
    // If it's not a local search or we are in the initial state.
    if (!filterOptions.localSearch || (options.length === 0 && search === '')) {
      void filterOptions
        .onSearchChange(search, 1)
        .then(({ options, page, pages }) => {
          // If the search is not empty, then this should go into the filteredOptions.
          if (search) {
            setFilteredOptions(options)
          } else {
            setOptions(options)
            setFilteredOptions(null)
          }
          setPage(page)
          setPages(pages)
        })
    } else {
      setFilteredOptions(
        options.filter((option) =>
          option.label.toLowerCase().includes(search.toLowerCase())
        )
      )
    }
  }, [search])

  const handleSearchChange = React.useCallback(
    (search: string) => {
      setSearch(search)
    },
    [setSearch]
  )

  const onSelected = React.useCallback(
    (option: Option<boolean>) => {
      // Update the options list to include the new selected option.
      // This case handles if the option is in the filter but not in the original.
      let optionFound = false
      const newOptions = options.map((o) => {
        if (o.id === option.id) {
          optionFound = true
          return { ...o, value: option.value }
        }
        return o
      })

      if (!optionFound) {
        newOptions.push(option)
      }

      // Reflect the changes in the filteredOptions if needed.
      const newFilteredOptions =
        filteredOptions?.map((o) => {
          if (o.id === option.id) {
            return { ...o, value: option.value }
          }
          return o
        }) || null

      try {
        filterOptions.onChange(
          newOptions.filter((o) => o.value).map((o) => o.id)
        )
        setOptions(newOptions)
        setFilteredOptions(newFilteredOptions)
      } catch (e) {
        console.error(e)
      }
    },
    [options, filteredOptions, filterOptions.onChange]
  )

  return {
    name: filterOptions.name,
    options,
    onSelected,
    allPagesLoaded,
    onLoadNextPage: nextPageCallback,
    search: {
      value: search,
      onChange: handleSearchChange,
      placeholder: `Filter by ${filterOptions.name}`
    },
    filteredOptions
  }
}
