import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'
import { Button } from 'components/Button/Button'
import {
  BoxColumnBasic,
  BoxRow,
  Option,
  SwitchControlLabelStyled
} from 'components/common'
import { EntrSwitch } from 'pages/Formulator/FormulatorStyles'
import React from 'react'

interface DownloadModalProps {
  title: string
  options: Option<boolean>[]
  onChange: (option: Option<boolean>) => void
  onDownloadClick: (options: Option<boolean>[]) => void
  onCancelClick: () => void
  downloadDisabled?: boolean
}

export const DownloadModalContent: React.FC<DownloadModalProps> = ({
  title,
  options,
  onChange,
  onDownloadClick,
  onCancelClick,
  downloadDisabled = false
}) => {
  return (
    <Box sx={{ padding: '24px', width: '360px' }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <BoxColumnBasic sx={{ gap: '10px' }}>
          {options.map((option, index) => (
            <SwitchControlLabelStyled
              key={`${option.label}-${index}`}
              sx={{ padding: '4px' }}
              control={
                <EntrSwitch
                  size={'small'}
                  checked={option.value}
                  onChange={(e) =>
                    onChange({ ...option, value: e.target.checked })
                  }
                  disabled={option.disabled}
                />
              }
              label={
                <BoxRow sx={{ gap: '4px' }}>
                  <Typography>{option.label}</Typography>
                </BoxRow>
              }
            />
          ))}
        </BoxColumnBasic>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Box>
          <Button
            color="secondary"
            style={{ minWidth: '80px' }}
            onClick={onCancelClick}
          >
            Cancel
          </Button>
        </Box>
        <Button
          style={{ minWidth: '80px' }}
          onClick={() => onDownloadClick(options)}
          disabled={downloadDisabled}
        >
          Download
        </Button>
      </DialogActions>
    </Box>
  )
}
