import { List, ListItem, Paper, Typography } from '@mui/material'
import { BoxRow } from 'components/common'

export interface SupplierProps {
  id: string
  name: string
}

export interface IngredientSupplierPopperProps {
  ingredient: {
    friendlyId?: string
  }
  suppliers: SupplierProps[]
  onClick: (supplierId: string) => void
}

export const IngredientSupplierPopper: React.FC<
  IngredientSupplierPopperProps
> = ({ ingredient, suppliers, onClick }) => {
  return (
    <Paper>
      <List>
        {suppliers.map((s) => (
          <ListItem key={s.id} sx={{ cursor: 'pointer' }}>
            <div>
              <BoxRow
                onClick={() => onClick(s.id)}
                width="15em"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '8px'
                }}
              >
                <Typography
                  variant="body1"
                  color="body"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'clip',
                    textOverflow: 'ellipsis',
                    color: 'inherit',
                    flexGrow: 1,
                    textAlign: 'left'
                  }}
                >
                  {s.name}
                </Typography>
                {ingredient.friendlyId && (
                  <Typography
                    color="tertiary"
                    variant="body2"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'clip',
                      textOverflow: 'ellipsis',
                      color: 'rgba(35, 60, 88, 0.43)',
                      maxWidth: '46px',
                      textAlign: 'right'
                    }}
                  >
                    #{ingredient.friendlyId}
                  </Typography>
                )}
              </BoxRow>
            </div>
          </ListItem>
        ))}
      </List>
    </Paper>
  )
}
