import { Grid, Skeleton } from '@mui/material'
import React from 'react'

export const MembersTableSkeleton: React.FC = () => {
  return (
    <Grid item container alignItems="center">
      <Grid item sx={{ marginRight: '11px' }}>
        <Skeleton variant={'circular'} height={20} width={20} />
      </Grid>
      <Grid item sx={{ marginRight: '11px' }}>
        <Skeleton variant={'text'} height={20} width={171} />
      </Grid>
      <Grid item sx={{ marginRight: '11px' }}>
        <Skeleton variant={'text'} height={20} width={195} />
      </Grid>
      <Grid item sx={{ marginRight: '11px' }}>
        <Skeleton variant={'rectangular'} height={32} width={93} />
      </Grid>

      <Grid item sx={{ marginRight: '11px' }}>
        <Skeleton variant={'rectangular'} height={32} width={93} />
      </Grid>
    </Grid>
  )
}
