import { Typography } from '@mui/material'
import { Button } from 'components/Button/Button'
import { BoxColumnBasic, BoxRow } from 'components/common'
import React from 'react'

export interface TargetsHeaderProps {
  selected: number
  total: number
  editMode: boolean
  onEditClick: () => void
  onDoneClick: () => void
}

export const TargetsHeader: React.FC<TargetsHeaderProps> = ({
  selected,
  total,
  editMode,
  onEditClick,
  onDoneClick
}) => {
  return (
    <BoxRow sx={{ justifyContent: 'space-between' }}>
      <BoxColumnBasic sx={{ gap: '5px' }}>
        <Typography variant="h4">Targets</Typography>
        <Typography
          sx={{ color: '#1A2C42BF' }}
        >{`${selected} of ${total} selected for this formula`}</Typography>
      </BoxColumnBasic>
      <Button
        color={editMode ? 'primary' : 'secondary'}
        onClick={editMode ? onDoneClick : onEditClick}
        sx={{ minHeight: '39px' }}
      >
        {editMode ? 'Done' : 'Edit Targets'}
      </Button>
    </BoxRow>
  )
}
