import styled from 'styled-components'
import {
  BoxColumnBasic,
  BoxRowBasic,
  SwitchControlLabelStyled
} from 'components/common'
import { textBlack } from 'entrTheme'

export const ConfigSwitchTargetControlLabel = styled(SwitchControlLabelStyled)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center'
})

export const SelectTargetSwitchControlLabel = styled(SwitchControlLabelStyled)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& .MuiFormControlLabel-label': {
    color: textBlack
  },
  width: 'fit-content'
})

export const SelectTargetRowTarget = styled(BoxRowBasic)({
  padding: '5px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#f0f0f0'
  }
})

export const SelectTargetColumnContent = styled(BoxColumnBasic)({
  gap: '8px',
  width: '100%'
})
