import { BoxColumnBasic } from 'components/common'
import { NutritionLabel } from 'components/NutritionLabel/NutritionLabel'
import React, { useEffect, useState } from 'react'
import {
  IngredientStatement,
  IngredientStatementProps
} from './IngredientStatement/IngredientStatement'

import {
  LabelDescription,
  LabelDescriptionProps
} from './LabelDescription/LabelDescription'
import {
  FormulaNutritionFact,
  FormulaNutritionFactLabelType
} from 'models/FormulaLabel'
import {
  AllergensStatement,
  AllergensStatementProps
} from './AllergensStatement/AllergensStatement'
import { RegulationName } from 'services/apis/regulation/RegulationApiResponse'
import { Box } from '@mui/material'
import { LoadingAnimation } from 'components/LoadingAnimation/LoadingAmination'

export interface NFPLabelProps {
  nutritionFactsLabel?: FormulaNutritionFact
  ingredientStatement: IngredientStatementProps
  allergenStatement: AllergensStatementProps
  labelDescription: LabelDescriptionProps
  regulationName: RegulationName
  scale?: number
}

export const NFPLabel: React.FC<NFPLabelProps> = ({
  nutritionFactsLabel,
  ingredientStatement,
  allergenStatement,
  labelDescription,
  regulationName,
  scale = 1
}) => {
  const [width, setWidth] = useState(0)
  const nutritionLabelRef = React.useRef<HTMLDivElement>(null)

  // Function to recompute width considering the scale
  const computeWidth = () => {
    if (nutritionLabelRef.current) {
      const newWidth = nutritionLabelRef.current.getBoundingClientRect().width
      setWidth(newWidth / scale) // Adjust width by scale
    }
  }

  // Use ResizeObserver to monitor changes in the element size
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === nutritionLabelRef.current) {
          computeWidth()
        }
      }
    })

    if (nutritionLabelRef.current) {
      observer.observe(nutritionLabelRef.current)
    }

    return () => {
      if (nutritionLabelRef.current) {
        observer.unobserve(nutritionLabelRef.current)
      }
    }
  }, [scale])

  // Effect to recompute width when nutritionFactsLabel changes
  useEffect(() => {
    computeWidth()
  }, [nutritionFactsLabel, scale])

  return (
    <BoxColumnBasic
      display="flex"
      m="8px"
      gap="8px"
      overflow="scroll"
      alignItems={
        nutritionFactsLabel?.type === FormulaNutritionFactLabelType.TABULAR
          ? 'flex-start'
          : 'center'
      }
      flexGrow={1}
      position="relative"
    >
      {!nutritionFactsLabel && !ingredientStatement && (
        <LoadingAnimation text="Loading label" />
      )}

      {!!nutritionFactsLabel && !!ingredientStatement && (
        <BoxColumnBasic
          id="nutrition-fact-label"
          sx={{
            alignItems: 'left',
            gap: '20px',
            padding: '10px'
          }}
        >
          <Box
            ref={nutritionLabelRef}
            width={'fit-content'}
            id="nutrition-facts"
          >
            <NutritionLabel
              {...nutritionFactsLabel}
              regulationName={regulationName}
            />
          </Box>
          <BoxColumnBasic
            gap="10px"
            sx={{ maxWidth: `${width}px`, width: '100%' }}
          >
            <Box id="ingredient-statement">
              <IngredientStatement {...ingredientStatement} />
            </Box>
            <Box id="allergen-statement">
              <AllergensStatement {...allergenStatement} />
            </Box>
            <Box id="label-description">
              <LabelDescription {...labelDescription} />
            </Box>
          </BoxColumnBasic>
        </BoxColumnBasic>
      )}
    </BoxColumnBasic>
  )
}
