import { Typography, useTheme } from '@mui/material'
import React from 'react'
import { BoxColumnBasic } from '../common'
import { ReactComponent as ClipboardIcon } from 'assets/Clipboard.svg'

interface NoPreviewProps {
  title?: string
  text?: string
}

export const NoPreview: React.FC<NoPreviewProps> = ({
  title = 'No preview yet',
  text
}) => {
  const theme = useTheme()

  return (
    <BoxColumnBasic
      sx={{
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '48px',
        width: '100%'
      }}
    >
      <ClipboardIcon />
      <BoxColumnBasic
        sx={{
          width: '50%',
          textAlign: 'center',
          height: 'fit-content',
          gap: '8px'
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: '20px',
            color: theme.palette.secondary.main
          }}
        >
          {title}
        </Typography>
        {!!text && (
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '20px',
              color: theme.palette.tertiary.main
            }}
          >
            {text}
          </Typography>
        )}
      </BoxColumnBasic>
    </BoxColumnBasic>
  )
}
