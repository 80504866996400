import React from 'react'
import { Typography } from '@mui/material'

interface SettingSubPageLayoutProps {
  title: string
  children: React.ReactNode
}

export const SettingSubPageLayout: React.FC<SettingSubPageLayoutProps> = ({
  title,
  children
}) => {
  return (
    <div>
      <Typography variant="h3">{title}</Typography>
      <div style={{ marginTop: '23px' }} />
      {children}
    </div>
  )
}
