import React from 'react'
import { SelectOption } from '../SelectOptionsLabel/SelectOptionsLabel'
import { FormControlStyled, LabelTypography } from '../common'
import {
  Autocomplete,
  TextField,
  createFilterOptions,
  FilterOptionsState,
  Chip
} from '@mui/material'
import { AutoCompleteLabelWrapper } from './AutoCompleteLabelStyles'
import { MultiSelectAddIcon } from '../MultiSelectLabel/MultiSelectAddIcon'
import { render } from '@testing-library/react'

interface AutoCompleteLabelProps {
  label: string
  labelIcon?: React.ReactNode
  options: SelectOption[]
  disabled?: boolean
  onChange?: (value: SelectOption[]) => void
  onChangeSingle?: (value: SelectOption) => void
  initialValue?: SelectOption[] | SelectOption
  style?: React.CSSProperties
  allowRemoval?: boolean
  freeSolo?: boolean
  isMultiple?: boolean
  renderInput?: (params: any) => React.ReactNode
  renderOption?: (props: any, option: SelectOption) => React.ReactNode
  filterOptions?: (options: SelectOption[], params: any) => SelectOption[]
  noOptionsText?: string | React.ReactNode
}

export const AutoCompleteLabel: React.FC<AutoCompleteLabelProps> = ({
  label,
  labelIcon,
  options,
  disabled,
  onChange,
  onChangeSingle,
  initialValue,
  style,
  renderInput,
  renderOption,
  filterOptions,
  noOptionsText,
  allowRemoval = true,
  freeSolo = false,
  isMultiple = true
}) => {
  const [value, setValue] = React.useState<SelectOption[] | SelectOption>()
  const filter = createFilterOptions<SelectOption>()

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | SelectOption | (string | SelectOption)[] | null
  ) => {
    if (newValue === null) {
      return
    }
    let updateValue: SelectOption[] | SelectOption
    if (Array.isArray(newValue)) {
      updateValue = newValue.map((v) => {
        let label = typeof v === 'string' ? v : v.label
        if (label.substring(0, 5) === 'Add "') {
          label = label.substring(5, label.length - 1)
        }
        return { label, value: typeof v === 'string' ? label : v.value }
      })
    } else {
      updateValue = {
        label: typeof newValue === 'string' ? newValue : newValue.label,
        value: typeof newValue === 'string' ? newValue : newValue.value
      }
    }
    //
    setValue(updateValue)
    if (!Array.isArray(updateValue) && onChangeSingle) {
      onChangeSingle(updateValue)
    } else if (Array.isArray(updateValue) && onChange) {
      onChange(updateValue)
    }
  }

  const handleFilters = (
    optn: SelectOption[],
    params: FilterOptionsState<SelectOption>
  ) => {
    const filtered = filter(optn, params)
    const { inputValue } = params
    const isExisting = optn.some((o) => inputValue === o.label)
    //
    if (freeSolo && inputValue !== '' && !isExisting) {
      filtered.push({
        value: inputValue,
        label: `Add "${inputValue}"`
      })
    }
    return filtered
  }

  const getOptionLabel = (option: string | SelectOption): string => {
    return typeof option === 'string' ? option : option.label
  }

  const getOptionDisabled = (option: SelectOption): boolean => {
    if (allowRemoval || !value) {
      return false
    } else {
      if (Array.isArray(value)) {
        return value.some((v) => option.value === v.value)
      }
      return option.value === value.value
    }
  }

  const isOptionEqualToValue = (option: SelectOption, value: SelectOption) => {
    return option.value === value.value || value.value === ''
  }

  React.useEffect(() => {
    if (initialValue !== undefined) {
      setValue(initialValue)
    }
  }, [initialValue])

  return (
    <AutoCompleteLabelWrapper
      sx={{ '&&': { ...style } }}
      data-allow-removal={allowRemoval}
      className="AutoCompleteLabel"
    >
      <LabelTypography variant="subtitle2">
        {label} {labelIcon}
      </LabelTypography>

      <FormControlStyled size="small" sx={{ width: '100%' }}>
        {value !== undefined && (
          <Autocomplete
            multiple={isMultiple}
            freeSolo={freeSolo}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            disabled={disabled}
            value={value}
            autoHighlight={true}
            options={options}
            onChange={handleChange}
            filterOptions={filterOptions ? filterOptions : handleFilters}
            getOptionLabel={getOptionLabel}
            getOptionDisabled={getOptionDisabled}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={
              renderOption
                ? renderOption
                : (props, option) => <li {...props}>{option.label}</li>
            }
            renderInput={
              renderInput ? renderInput : (params) => <TextField {...params} />
            }
            noOptionsText={noOptionsText}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option: string | SelectOption, index) => (
                <Chip
                  label={typeof option === 'string' ? option : option.label}
                  {...getTagProps({ index })}
                  disabled={!allowRemoval}
                  size="small"
                />
              ))
            }
            disablePortal={true}
          />
        )}
      </FormControlStyled>
    </AutoCompleteLabelWrapper>
  )
}
