import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import React from 'react'
import { getFormula } from 'state/formulator/FormulatorSlice'
import { getExecutedCompanyTargets } from 'state/formulator/formulaTargets/FormulaTargetsSlice'
import { getFormulaHistory } from 'state/formulator/history/FormulaHistorySlice'
import { removeFormulaIngredients } from 'state/formulator/ingredients/FormulatorIngredientsSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { FormulatorContext } from '../../FormulatorProvider'
import { FormulaDetails } from './FormulaDetails'
import { NotesContainer } from './components/Notes/NotesContainer'
import { ViewControllerContainer } from './components/ViewController/ViewControllerContainer'
import { AddControllerContainer } from './components/AddController/AddControllerContainer'
import { ModalContext } from 'components/Modal/ModalContext'
import { pluralize } from 'common/utils'

export const FormulaDetailsContainer: React.FC = () => {
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const { showConfirmationModal } = React.useContext(ModalContext)
  const dispatch = useAppDispatch()

  const {
    selectedIngredientIdsMap,
    clearIngredientIdsSelection: clearIngredientIdSelection
  } = React.useContext(FormulatorContext)

  const selectedIngredientIds = React.useMemo(() => {
    return Array.from(selectedIngredientIdsMap)
      .filter(([_, value]) => value === true)
      .map(([key, _]) => key)
  }, [selectedIngredientIdsMap])

  const analytics = React.useContext(AnalyticsContext)

  const handleRemoveSelectedIngredients = React.useCallback(() => {
    showConfirmationModal({
      title: 'Remove Ingredients',
      danger: true,
      message: (
        <>
          Are you sure you want to permanently remove{' '}
          <b>
            {selectedIngredientIds.length}{' '}
            {pluralize(selectedIngredientIds.length, 'ingredient')}
          </b>{' '}
          from this formula?
        </>
      ),
      yesText: 'Remove',
      noText: 'Cancel',
      onYesClicked: () => {
        analytics.removedIngredientFromFormula(formulaId)
        void dispatch(
          removeFormulaIngredients({
            companyId: currentCompany.id,
            formulaId: formulaId,
            formulaIngredientIds: selectedIngredientIds
          })
        )
          .then((action) => {
            if (removeFormulaIngredients.fulfilled.match(action)) {
              void dispatch(
                getExecutedCompanyTargets({
                  companyId: currentCompany.id,
                  formulaId: formulaId
                })
              )
              void dispatch(
                getFormulaHistory({
                  companyId: currentCompany.id,
                  formulaId: formulaId
                })
              )
              clearIngredientIdSelection()
            }
          })
          .finally(() => {
            void dispatch(
              getFormula({ companyId: currentCompany.id, formulaId: formulaId })
            )
          })
      }
    })
  }, [currentCompany.id, formulaId, selectedIngredientIds])

  return (
    <FormulaDetails
      ingredientsDelete={{
        showButton: selectedIngredientIds.length > 0,
        onRemoveSelectedIngredients: handleRemoveSelectedIngredients
      }}
      notesContainer={<NotesContainer />}
      viewControllerContainer={<ViewControllerContainer />}
      addControllerContainer={<AddControllerContainer />}
    />
  )
}
