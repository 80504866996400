import { Box } from '@mui/material'
import { BoxColumnBasic } from 'components/common'
import React from 'react'

export interface FormulatorIngredientsProps {
  statusBar: React.ReactNode
  formulaDetails: React.ReactNode
  ingredientsTable: React.ReactNode
  totalsBar: React.ReactNode
  yieldAdjustments: React.ReactNode
}

export const FormulatorIngredients: React.FC<FormulatorIngredientsProps> = ({
  statusBar,
  formulaDetails,
  ingredientsTable,
  totalsBar,
  yieldAdjustments
}) => {
  return (
    <>
      <Box
        paddingLeft={'4px'}
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1
        }}
      >
        {statusBar}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              flex: 1,
              position: 'relative',
              overflowY: 'auto',
              marginBottom: '20px'
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                paddingBottom: '1rem',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                justifyContent: 'space-between'
              }}
            >
              <BoxColumnBasic sx={{ gap: '20px' }}>
                {formulaDetails}
                {ingredientsTable}
              </BoxColumnBasic>
              {yieldAdjustments}
            </Box>
          </Box>
          {totalsBar}
        </Box>
      </Box>
    </>
  )
}
