import { FormulaNutritionFactNutrient } from 'models/FormulaLabel'
import { ItemTableContent } from '../../components/ItemTableContent'
import { NutritionLabelSeparator } from '../../components/NutritionLabelSeparator'
import { NutritionLabelText } from '../../components/NutritionLabelText'
import { PercentDailyValueContainer } from '../NutritionLabelVerticalStyles'

export const TopNutrientsTable: React.FC<{
  topNutrients: FormulaNutritionFactNutrient[]
  showDvPercentageStar?: boolean
}> = ({ topNutrients = [], showDvPercentageStar = true }) => (
  <>
    <PercentDailyValueContainer>
      <NutritionLabelText bold points={8} leading={4}>
        &nbsp;
      </NutritionLabelText>
      <NutritionLabelText bold points={6} leading={4}>
        % Daily Value{showDvPercentageStar ? '*' : ''}
      </NutritionLabelText>
    </PercentDailyValueContainer>
    <NutritionLabelSeparator type="hairline" />
    <ItemTableContent
      key={`topNutrients-${0}`}
      tableItemRowsProps={topNutrients.map((fnfn) => {
        return {
          nutrientType: fnfn.nutrient.type,
          itemRowValues: {
            name: fnfn.displayNameOptions[0],
            amount: fnfn.displayAmountOptions[0]?.amount,
            unit: fnfn.nutrient.unit,
            dvPercentage: fnfn.displayDvOptions[0]?.amount
          }
        }
      })}
    />
  </>
)
