import { TableCell, TableRow } from '@mui/material'
import React from 'react'

import { IngredientSearch, IngredientSearchProps } from './IngredientSearch'

interface IngredientSearchRowProps {
  ingredientSearchProps: IngredientSearchProps
  span?: number
}

export const IngredientSearchRow: React.FC<IngredientSearchRowProps> = ({
  ingredientSearchProps,
  span = 4
}) => {
  return (
    <TableRow>
      <TableCell />
      <TableCell colSpan={span}>
        <IngredientSearch {...ingredientSearchProps} />
      </TableCell>
    </TableRow>
  )
}
