import { Box } from '@mui/material'
import { ViewerContainer } from 'components/common'
import styled from 'styled-components'

export const EditorViewer = styled(ViewerContainer)({
  height: '100%',
  width: '100%',
  minWidth: 'min(918px, 100%)',
  gridTemplateRows: 'auto 1fr',
  gap: 0,
  top: 0,
  right: 0,
  padding: 0
})

export const EditorContentBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr minmax(40%, 60%)',
  overflow: 'hidden'
})
