import { Box } from '@mui/material'
import { styled } from '@mui/system'
import { PATHS } from 'common/constants'
import { useLocation } from 'react-router-dom'

interface GlowProps {
  color: string
}

const Glow = styled(Box)<GlowProps>`
  position: absolute;
  width: 901px;
  height: 168px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  mix-blend-mode: multiply;
  ${({ color }) => `
    background: radial-gradient(45.7% 106.36% at 50.07% -44.55%, ${color} 0%, rgba(255, 255, 255, 0) 100%);
  `};
`

export const BackgroundGlow: React.FC = () => {
  const location = useLocation()

  const makeCombinedGlows = (
    color1: string,
    color2: string,
    leftSide = true
  ) => {
    return (
      <div
        style={{
          position: 'fixed',
          width: '90%',
          height: '168px',
          top: '-24px',
          left: leftSide ? '32px' : '50%',
          transform: leftSide ? '' : 'translateX(-50%)',
          zIndex: -10,
          opacity: 0.7
        }}
      >
        <Glow
          color={color1}
          style={{
            left: leftSide ? '0' : '50%',
            transform: leftSide ? 'translateX(-20%)' : 'translateX(-80%)'
          }}
        />
        <Glow
          color={color2}
          style={{
            left: leftSide ? '0' : '50%',
            transform: leftSide ? 'translateX(35%)' : 'translateX(-20%)'
          }}
        />
      </div>
    )
  }

  const makeGlow = () => {
    switch (location.pathname) {
      case PATHS.FORMULAS:
        return makeCombinedGlows('#59CDFF', '#8E59FF')
      case PATHS.INGREDIENTS:
        return makeCombinedGlows('#12DCB8', '#597EFF')
      case PATHS.SUPPLIERS:
        return makeCombinedGlows('#FFB800', '#FF98F5')
      case PATHS.TARGETS:
        return makeCombinedGlows('#FF98F5', '#597EFF')
      default:
        return null
    }
  }

  return <Box sx={{ position: 'relative' }}>{makeGlow()}</Box>
}
