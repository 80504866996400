import styled from 'styled-components'
import { Box } from '@mui/material'

export const AutoCompleteLabelWrapper = styled(Box)`
  .MuiAutocomplete-clearIndicator {
    display: none;
  }

  &[data-allow-removal='false'] {
    .MuiAutocomplete-tag {
      svg {
        display: none;
      }
    }

    .Mui-disabled {
      opacity: 1;
    }
  }

  .MuiInput-root {
    padding: 0;
    min-height: 33px;
  }

  .MuiAutocomplete-popupIndicator {
    display: none;
  }

  &:has(div.Mui-disabled) {
    color: rgba(0, 0, 0, 0.26);
    svg {
      path {
        fill: rgba(0, 0, 0, 0.26);
        fill-opacity: 1;
      }
    }
  }
`
