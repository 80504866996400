import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { App } from 'App'
// import reportWebVitals from './reportWebVitals'
import { store } from 'state/store'
import { entrTheme } from 'entrTheme'
import { CssBaseline } from '@mui/material'
import * as Sentry from '@sentry/react'
import markerSDK from '@marker.io/browser'
import './fonts.css'

const initializeSentry = () => {
  const dsn = process.env.REACT_APP_SENTRY_DSN || ''
  const environment = process.env.REACT_APP_ENVIRONMENT || 'local'
  const sentryEnabled = process.env.REACT_APP_SENTRY_ENABLED == 'true'

  const tracesSampleRate: number | undefined = process.env
    .REACT_APP_SENTRY_TRACE_SAMPLE_RATE
    ? parseFloat(process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE)
    : 1.0

  const replaysSessionSampleRate: number | undefined = process.env
    .REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
    ? parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE)
    : 0.1

  const replaysOnErrorSampleRate: number | undefined = process.env
    .REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
    ? parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE)
    : 1.0

  if (sentryEnabled) {
    console.log('Sentry listening')
    Sentry.init({
      dsn: dsn,
      integrations: [new Sentry.BrowserTracing({}), new Sentry.Replay()],
      tracesSampleRate: tracesSampleRate,
      replaysSessionSampleRate: replaysSessionSampleRate,
      replaysOnErrorSampleRate: replaysOnErrorSampleRate,
      environment: environment
    })
  }
}

initializeSentry()

void markerSDK.loadWidget({
  project: '64f6549e5a9c0be49a98e41c'
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={entrTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
