import { Box, FormGroup, MenuItem, Popover, Typography } from '@mui/material'
import { ReactComponent as OpenArrowIcon } from 'assets/OpenArrow.svg'
import { Button } from 'components/Button/Button'
import React from 'react'
import { BoxRow, Option } from '../../common'
import {
  ChipCount,
  FilterSearchTextField
} from './EntrTableFilterDropDownStyles'
import { InView } from 'react-intersection-observer'

export interface EntrTableFilterProps {
  name: string
  options: Option<boolean>[]
  onSelected: (option: Option<boolean>) => void
  allPagesLoaded?: boolean
  onLoadNextPage?: () => void
  search: {
    value: string
    onChange: (search: string) => void
    placeholder?: string
  }
  filteredOptions?: Option<boolean>[] | null
}

export const EntrTableFilter = ({
  name = '',
  options = [],
  onSelected,
  allPagesLoaded = true,
  onLoadNextPage,
  search,
  filteredOptions
}: EntrTableFilterProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const popoverContentRef = React.useRef<HTMLDivElement | null>(null) // Create a ref for the popover content.
  const scrollPositionRef = React.useRef<number>(0) // Create a ref to store the scroll position.
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const selectedOptions = options.filter((option) => option.value)
  const displayedOptions = filteredOptions ?? options

  const handleInViewChange = (inView: boolean) => {
    if (inView && displayedOptions.length > 0 && onLoadNextPage) {
      scrollPositionRef.current = popoverContentRef.current?.scrollTop || 0 // Save scroll position before loading new data
      onLoadNextPage()
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    search.onChange('')
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOnClick = (option: Option<boolean>) => {
    scrollPositionRef.current = popoverContentRef.current?.scrollTop || 0 // Save the scroll position before updating
    onSelected(option)
  }

  React.useEffect(() => {
    if (popoverContentRef.current) {
      popoverContentRef.current.scrollTop = scrollPositionRef.current // Restore the scroll position after updating
    }
  }, [displayedOptions]) // Run when displayedOptions changes.

  return (
    <Box>
      <Button color="secondary" onClick={handleClick}>
        <BoxRow sx={{ gap: '5px', fontWeight: '400', fontFamily: 'Inter' }}>
          {name}
          {selectedOptions.length > 0 && (
            <ChipCount size="small" label={selectedOptions.length} />
          )}
          <OpenArrowIcon />
        </BoxRow>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        sx={{
          borderRadius: '8px'
        }}
      >
        <FormGroup
          ref={popoverContentRef} // Attach the ref to the FormGroup.
          sx={{
            padding: '6px',
            width: '20vw',
            maxHeight: '50vh',
            flexWrap: 'nowrap',
            overflowY: 'auto' // Ensure overflow is handled.
          }}
        >
          <FilterSearchTextField
            placeholder={search.placeholder}
            onChange={(e) => search.onChange(e.target.value)}
            debounceTime={300}
          />
          {displayedOptions
            .sort((a, b) => {
              if (a.value && !b.value) {
                return -1
              }
              if (!a.value && b.value) {
                return 1
              }
              return 0
            })
            .map((o) => (
              <MenuItem
                key={o.id}
                title={o.label}
                sx={{
                  padding: '6px'
                }}
                onClick={() => handleOnClick({ ...o, value: !o.value })}
                selected={o.value}
              >
                <Typography
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {o.label}
                </Typography>
              </MenuItem>
            ))}
          {!allPagesLoaded && (
            <InView
              as="div"
              key={`${search.value}-${displayedOptions.length}`}
              onChange={handleInViewChange}
              trackVisibility={true}
              delay={300}
            >
              <div
                style={{
                  height: '1px',
                  display: 'block'
                }}
              ></div>
            </InView>
          )}
          {displayedOptions.length === 0 && (
            <MenuItem sx={{ padding: '6px' }} disabled={true}>
              No {name.toLowerCase()} found
            </MenuItem>
          )}
        </FormGroup>
      </Popover>
    </Box>
  )
}
