import React from 'react'
import { useInView } from 'react-intersection-observer'
import { CircularProgress, Box } from '@mui/material'

interface InfiniteScrollWrapperProps {
  onEndOfList: () => void
  isLoading: boolean
  children: React.ReactNode
  rootMargin?: string
  threshold?: number
  isHidden?: boolean
  observerContainerStyles?: React.CSSProperties
  loadingComponent?: React.ReactNode
}

export const InfiniteScrollWrapper: React.FC<InfiniteScrollWrapperProps> = ({
  onEndOfList,
  isLoading,
  children,
  rootMargin,
  threshold,
  isHidden,
  observerContainerStyles,
  loadingComponent
}) => {
  const { ref, inView } = useInView({
    rootMargin,
    threshold
  })

  React.useEffect(() => {
    if (inView && !isLoading) {
      onEndOfList()
    }
  }, [inView, isLoading, onEndOfList])

  return (
    <>
      {children}
      <Box
        ref={ref}
        sx={{
          ...observerContainerStyles,
          justifyContent: 'center',
          alignItems: 'center'
        }}
        display={isHidden ? 'none' : 'flex'}
      >
        {isLoading &&
          (loadingComponent || (
            <CircularProgress size={30} style={{ color: 'lightgray' }} />
          ))}
      </Box>
    </>
  )
}
