import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { UserApi } from 'services/apis/user/UserApi'
import { User } from 'models/User'
import { UpdateUserRequest } from './UsersRequest'
import { DeletingState, LoadingState, UpdatingState } from '../CommonState'

interface UsersState extends UpdatingState, LoadingState, DeletingState {
  currentUser: User
}

const initialState: UsersState = {
  updating: false,
  loading: false,
  deleting: false,
  error: false,
  currentUser: {
    id: '',
    firstName: '',
    lastName: '',
    companyId: '',
    email: '',
    imagePath: '',
    fullName: '',
    createdAt: '',
    authenticatorId: ''
  }
}

export const getUser = createAsyncThunk('users/get', async () => {
  return await UserApi.getUser()
})

export const deleteUser = createAsyncThunk('users/delete', async () => {
  return await UserApi.deleteUser()
})

export const updateUser = createAsyncThunk(
  'users/update',
  async ({ firstName, lastName, imagePath }: UpdateUserRequest) => {
    return await UserApi.updateUser({ firstName, lastName, imagePath })
  }
)

export const usersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state: UsersState) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getUser.fulfilled, (state: UsersState, action) => {
      state.currentUser = action.payload
      state.loading = false
    })
    builder.addCase(getUser.rejected, (state: UsersState) => {
      state.currentUser = {
        id: '',
        firstName: '',
        lastName: '',
        companyId: '',
        email: '',
        imagePath: '',
        fullName: '',
        createdAt: '',
        authenticatorId: ''
      }
      state.loading = false
      state.error = true
    })

    builder.addCase(updateUser.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(updateUser.fulfilled, (state) => {
      state.updating = false
    })
    builder.addCase(updateUser.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    builder.addCase(deleteUser.pending, (state) => {
      state.deleting = true
      state.error = false
    })
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.deleting = false
    })
    builder.addCase(deleteUser.rejected, (state) => {
      state.deleting = false
      state.error = true
    })
  }
})

export default usersSlice.reducer
