import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs, { Dayjs } from 'dayjs'
import { FormulaStatus } from 'models/Formula'
import { EMAIL_REGEX } from './constants'
import { FormulaNutritionFactLanguage, languageDisplayPriority } from 'models/FormulaLabel'

export const addAlpha = (color: string, alpha: number) => {
  if (alpha > 1 || alpha < 0) {
    throw new Error('Alpha must be between 0 and 1')
  }

  return `${color}${Math.round(alpha * 255).toString(16)}`
}

export const mixColors = (
  color1: string,
  weight1: number,
  color2: string
): string => {
  const weight2 = 1 - weight1

  const hexToRgb = (hex: string) => {
    if (hex.length > 4) {
      const r = parseInt(hex.slice(1, 3), 16)
      const g = parseInt(hex.slice(3, 5), 16)
      const b = parseInt(hex.slice(5, 7), 16)
      return { r, g, b }
    } else {
      const r = parseInt(hex.slice(1, 2), 16)
      const g = parseInt(hex.slice(2, 3), 16)
      const b = parseInt(hex.slice(3, 4), 16)
      return { r, g, b }
    }
  }
  const rgb1 = hexToRgb(color1)
  const rgb2 = hexToRgb(color2)

  const r = Math.round(rgb1.r * weight1 + rgb2.r * weight2)
  const g = Math.round(rgb1.g * weight1 + rgb2.g * weight2)
  const b = Math.round(rgb1.b * weight1 + rgb2.b * weight2)

  const componentToHex = (c: number) => {
    const hex = c.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }
  const hexColor =
    '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
  return hexColor
}

export const getInitials = (str: string): string => {
  if (!str) {
    return '?'
  }

  return str.charAt(0).toUpperCase()
}

// generated with chatgpt
export const applyFakeTransparency = (
  color: string,
  weight: number
): string => {
  const whiteness = 1 - weight
  interface RGB {
    r: number
    g: number
    b: number
    a?: number
  }

  const hexToRgb = (colorString: string): RGB => {
    if (colorString.startsWith('#')) {
      const hex = colorString.replace('#', '')
      if (hex.length === 3) {
        const r = parseInt(hex.charAt(0), 16) * 17
        const g = parseInt(hex.charAt(1), 16) * 17
        const b = parseInt(hex.charAt(2), 16) * 17
        return { r, g, b }
      } else {
        const r = parseInt(hex.slice(0, 2), 16)
        const g = parseInt(hex.slice(2, 4), 16)
        const b = parseInt(hex.slice(4, 6), 16)
        return { r, g, b }
      }
    } else if (colorString.startsWith('rgba(')) {
      const rgba = colorString
        .replace('rgba(', '')
        .replace(')', '')
        .split(',')
        .map((s) => s.trim())
      const r = parseInt(rgba[0])
      const g = parseInt(rgba[1])
      const b = parseInt(rgba[2])
      const a = parseFloat(rgba[3])
      return { r, g, b, a }
    } else if (colorString.startsWith('rgb(')) {
      const rgb = colorString
        .replace('rgb(', '')
        .replace(')', '')
        .split(',')
        .map((s) => s.trim())
      const r = parseInt(rgb[0])
      const g = parseInt(rgb[1])
      const b = parseInt(rgb[2])
      return { r, g, b }
    } else {
      throw new Error('Invalid color format: ' + colorString)
    }
  }

  const rgb1 = hexToRgb(color)

  const r = Math.min(
    Math.round(255 * whiteness) + Math.round(rgb1.r * weight),
    255
  )
  const g = Math.min(
    Math.round(255 * whiteness) + Math.round(rgb1.g * weight),
    255
  )
  const b = Math.min(
    Math.round(255 * whiteness) + Math.round(rgb1.b * weight),
    255
  )

  let colorString = ''
  if ('a' in rgb1) {
    const a = Math.min(Math.max(0, (rgb1.a || 1) * whiteness), 1)
    colorString = `rgba(${r}, ${g}, ${b}, ${a})`
  } else {
    colorString = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`
  }

  return colorString
}

// pseudo-random but deterministicly select a color from a list of colors
export const selectColor = (input: string, colors: string[]): string => {
  let hash = 0
  for (let i = 0; i < input.length; i++) {
    hash = (hash << 5) - hash + input.charCodeAt(i)
    hash |= 0
  }

  // Scale the hash value to fit within the range of hex values
  const maxHexValue = 0xffffff
  hash = hash < 0 ? -hash : hash // ensure positive value
  hash = hash % maxHexValue

  let minDiff = Infinity
  let result = ''
  colors.forEach((value) => {
    const diff = Math.abs(parseInt(value.substr(1), 16) - hash)
    if (diff < minDiff) {
      minDiff = diff
      result = value
    }
  })

  return result
}

export const pluralize = (num: number, word: string) => {
  return `${word}${num !== 1 ? 's' : ''}`
}

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs().format()

export const displayDate = (date: string): string => {
  const day: Dayjs = dayjs(date)
  const now: Dayjs = dayjs()
  const diffMinutes: number = now.diff(date, 'minutes')
  const diffHours: number = now.diff(date, 'hours')

  if (diffMinutes === 1) {
    return 'Just now'
  } else if (diffMinutes < 60) {
    return `${diffMinutes} ${pluralize(diffMinutes, 'minute')} ago`
  } else if (diffHours < 24) {
    return `${diffHours} ${pluralize(diffHours, 'hour')} ago`
  } else if (day.isSame(now.subtract(1, 'day'), 'day')) {
    return 'yesterday'
  } else {
    return day.format('MMM D, YYYY')
  }
}

export const compareDates = (date1?: string, date2?: string): number => {
  if (!date1 && !date2) {
    return 0
  } else if (!date1) {
    return 1
  } else if (!date2) {
    return -1
  } else {
    return dayjs(date1).diff(date2)
  }
}

export const convertStatusToCamelCase = (status: string): FormulaStatus => {
  return status as FormulaStatus
}

export const convertFieldNameToCamelCase = (name: string): string => {
  return name
    .split(' ')
    .map((v, i) => (!i ? v.toLowerCase() : v))
    .join('')
}

export const emailValidator = (value: string): boolean => {
  return EMAIL_REGEX.test(value)
}

/**
 * Deal with javascript floating point number precision.
 *
 * ie: parseFloat('10.2') = 10.199999809265137
 *
 * This will convert 10.19999980926513 to '10.2'
 * @param num {number | string} Number to be coverted
 * @param maxDecimal {number} maximum number of characters after decimal point
 * @param cleanValue {boolean} Set to true to remove trailing 0's or trailing decimal. Should not be used on input's change event.
 * @returns {string} Fixed floating point number as string
 */
export const convertToFixedFloat = (
  num: number | string,
  maxDecimal: number,
  cleanValue = false
): string => {
  const val = num.toString()
  let fixedLength = val.split('.')[1]?.length || 0
  if (fixedLength > maxDecimal) fixedLength = maxDecimal
  let str = parseFloat(val).toFixed(fixedLength)
  if (cleanValue && fixedLength) {
    while (
      (str.endsWith('0') && str.includes('.'))
      || str.endsWith('.')
    ) {
      str = str.substring(0, str.length - 1)
    }
  }
  return str
}

export const getEnumByEnumValue = <T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): T[keyof T] => {
  const foundKey = Object.keys(myEnum).find((x) => myEnum[x] === enumValue)
  if (foundKey) {
    return myEnum[foundKey as keyof T]
  }
  throw new Error(`Key not found for value "${enumValue}" in enum`)
}

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const langDisplaySorter = (
  languageA: FormulaNutritionFactLanguage,
  languageB: FormulaNutritionFactLanguage
) => {
  const priorityA = languageDisplayPriority[languageA]
  const priorityB = languageDisplayPriority[languageB]
  return priorityA - priorityB
}