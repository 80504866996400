import React from 'react'
import { FormulasTable } from './FormulasTable'
import { useLoadNextPage } from 'common/hooks'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { useNavigate } from 'react-router'
import { getFormulas } from 'state/formulas/FormulasSlice'
import { FormulaStatus } from 'models/Formula'
import { QueriesContext } from 'components/EntrTable/Filter/QueriesContext'

export const FormulasTableContainer: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { items, page, pages } = useAppSelector(
    (state) => state.formulas.formulas
  )
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const { filters, search } = React.useContext(QueriesContext)
  const { status, withArchived, createdBy } = filters

  const setCurrentPageCallback = React.useCallback(
    (page: number) => {
      void dispatch(
        getFormulas({
          companyId: companyId,
          page: page,
          fuzzyName: search
        })
      )
    },
    [companyId, search]
  )

  React.useEffect(() => {
    void dispatch(
      getFormulas({
        companyId: companyId,
        page: 1,
        fuzzyName: search,
        status: status as FormulaStatus[],
        withArchived: Boolean(withArchived),
        createdBy: createdBy
      })
    )
  }, [companyId, search, status, withArchived, createdBy])

  const { nextPageCallback, allPagesLoaded } = useLoadNextPage({
    page,
    pages,
    setCurrentPageCallback
  })

  const handleRowClick = React.useCallback(
    (formulaId: string) => {
      navigate(`/formulas/${formulaId}`)
    },
    [navigate]
  )

  const rows = React.useMemo(() => {
    return items.map((item) => ({
      id: item.id,
      name: item.name,
      imagePath: item.imagePath,
      friendlyId: item.friendlyId,
      createdBy: {
        fullName: item.createdBy?.fullName,
        imagePath: item.createdBy?.imagePath
      },
      status: item.status,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      archived: item.isArchived
    }))
  }, [items])

  return (
    <FormulasTable
      allPagesLoaded={allPagesLoaded}
      nextPageCallback={nextPageCallback}
      onRowClick={handleRowClick}
      rows={rows}
    />
  )
}
