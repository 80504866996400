import React from 'react'
import { useDropzone } from 'react-dropzone'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Box } from '@mui/material'
import { uploadIconStyles } from 'components/DocumentsPanel/DocumentsPanelStyles'

interface UploadDropZoneProps {
  onDrop: (files: File[]) => void
  zoneStyle: React.CSSProperties
  activeZoneAppendedStyle: React.CSSProperties
}

export const UploadDropZone: React.FC<UploadDropZoneProps> = ({
  onDrop,
  zoneStyle,
  activeZoneAppendedStyle
}) => {
  const [isDropZoneActive, setIsDropZoneActive] = React.useState<boolean>(false)

  const activateDropZone = React.useCallback((e: DragEvent) => {
    e.preventDefault()
    setIsDropZoneActive(true)
  }, [])
  const deactivateDropZone = React.useCallback((e: DragEvent) => {
    e.preventDefault()
    setIsDropZoneActive(false)
  }, [])

  React.useEffect(() => {
    window.addEventListener('dragover', activateDropZone)
    window.addEventListener('dragleave', deactivateDropZone)

    return () => {
      window.removeEventListener('dragover', activateDropZone)
      window.removeEventListener('dragleave', deactivateDropZone)
    }
  }, [])

  const onDropCallback = React.useCallback(
    (acceptedFiles: File[]) => {
      if (onDrop) {
        onDrop(acceptedFiles)
      }
      setIsDropZoneActive(false)
    },
    [onDrop]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropCallback
  })

  const { onClick, ...rootProps } = getRootProps()

  const completeStyle: React.CSSProperties = {
    ...zoneStyle,
    ...(isDragActive ? activeZoneAppendedStyle : {})
  }

  if (!isDropZoneActive) {
    return <></>
  }

  return (
    <>
      <Box
        {...rootProps}
        onClick={(e) => {
          e.preventDefault()
        }}
        style={completeStyle}
      >
        <input {...getInputProps()} />
      </Box>
      {isDragActive && <CloudUploadIcon style={uploadIconStyles} />}
    </>
  )
}
