import { Box } from '@mui/material'
import React from 'react'
import { EditorSection } from '../common/EditorSection'

interface OverridesProps {
  label: React.ReactNode
  nutrients: React.ReactNode
}

export const Overrides: React.FC<OverridesProps> = ({ label, nutrients }) => {
  return (
    <Box sx={{ height: '100%' }}>
      <EditorSection title="Label" content={label} />
      <EditorSection title="Nutrients" content={nutrients} />
    </Box>
  )
}
