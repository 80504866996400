import { NutrientType } from 'models/Nutrient'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  getAvailableOptionalNutrients,
  setNutritionFactLabelEdits
} from 'state/labels/nutritions/NutritionFactLabelsSlice'
import { OptionalNutrients } from './OptionalNutrients'

export const OptionalNutrientsContainer: React.FC = () => {
  const dispatch = useAppDispatch()

  const availableOptionalNutrients = useAppSelector(
    (state) => state.nutritionFactLabels.availableOptionalNutrients
  )
  const selectedOptionalNutrients = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.optionalNutrientsType
  )
  const nutritionFactLabelEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits
  )
  const originalOptionalNutrientsTypes = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabel?.optionalNutrientsType
  )
  const nutritionFactLabelEditsRegulationId =
    nutritionFactLabelEdits.nutritionFactLabelPreview.regulationId

  React.useEffect(() => {
    if (nutritionFactLabelEditsRegulationId) {
      void dispatch(
        getAvailableOptionalNutrients({
          regulationId: nutritionFactLabelEditsRegulationId
        })
      )
    }
  }, [nutritionFactLabelEditsRegulationId])

  const optionalNutrients = React.useMemo(() => {
    return availableOptionalNutrients.map((optionalNutrient) => {
      return {
        id: optionalNutrient.type,
        label: optionalNutrient.name,
        value: selectedOptionalNutrients.includes(optionalNutrient.type)
      }
    })
  }, [selectedOptionalNutrients, availableOptionalNutrients])

  const handleChange = React.useCallback(
    (id: string, checked: boolean) => {
      dispatch(
        setNutritionFactLabelEdits({
          ...nutritionFactLabelEdits,
          nutritionFactLabelPreview: {
            ...nutritionFactLabelEdits.nutritionFactLabelPreview,
            optionalNutrientsType: checked
              ? [...selectedOptionalNutrients, id as NutrientType]
              : selectedOptionalNutrients.filter(
                  (type) => type !== (id as NutrientType)
                )
          }
        })
      )
    },
    [selectedOptionalNutrients, nutritionFactLabelEdits]
  )

  React.useEffect(() => {
    /*
      Reset the selected optional nutrients to their original state
      if the available optional nutrients change.
    */
    if (originalOptionalNutrientsTypes) {
      dispatch(
        setNutritionFactLabelEdits({
          ...nutritionFactLabelEdits,
          nutritionFactLabelPreview: {
            ...nutritionFactLabelEdits.nutritionFactLabelPreview,
            optionalNutrientsType: originalOptionalNutrientsTypes
          }
        })
      )
    }
  }, [availableOptionalNutrients])

  return (
    <OptionalNutrients
      optionalNutrients={optionalNutrients}
      onChange={handleChange}
    />
  )
}
