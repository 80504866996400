import { EntrTableFiltersV2 } from 'components/EntrTable/EntrTableFiltersV2'
import {
  FilterSearchResponse,
  useFilter
} from 'components/EntrTable/Filter/FiltersHook'
import { getOptionsFromFilterApiResponse } from 'components/EntrTable/Filter/helpers'
import { SearchFiltersContext } from 'components/EntrTable/Filter/SearchFiltersContext'
import React from 'react'
import { SimpleIngredientApi } from 'services/apis/simple_ingredient/SimpleIngredientApi'
import { useAppSelector } from 'state/hooks'

export interface AdvancedIngredientsSearchFiltersContainerProps {
  initialSearchTerm?: string
}

export const AdvancedIngredientsSearchFiltersContainer: React.FC<
  AdvancedIngredientsSearchFiltersContainerProps
> = ({ initialSearchTerm }) => {
  const { setSearch, setFilters, filters } =
    React.useContext(SearchFiltersContext)
  const { suppliers } = filters
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)

  React.useEffect(() => {
    if (initialSearchTerm) {
      setSearch(initialSearchTerm)
    }
  }, [initialSearchTerm])

  // Search bar.
  const handleSearchUpdate = React.useCallback(
    (value: string) => {
      setSearch(value)
    },
    [setSearch]
  )

  const handleSuppliersSearchChange = React.useCallback(
    (search?: string, page = 1): Promise<FilterSearchResponse> => {
      return SimpleIngredientApi.getSimpleIngredientSuppliersFilter(companyId, {
        searchTerm: search,
        page: page,
        selectedSupplierIds: suppliers,
        withPublicSuppliers: true
      }).then((suppliersFilter) => {
        return getOptionsFromFilterApiResponse(
          suppliersFilter,
          (responseItem) => {
            return {
              label: responseItem.name,
              value: false,
              id: responseItem.id
            }
          },
          suppliers ?? [],
          Boolean(search)
        )
      })
    },
    [suppliers, companyId]
  )

  const suppliersFilter = useFilter({
    name: 'Suppliers',
    onSearchChange: handleSuppliersSearchChange,
    localSearch: false,
    onChange: (values) => setFilters('suppliers', values)
  })

  return (
    <EntrTableFiltersV2
      search={{
        placeholder: 'Search all ingredients',
        onUpdate: handleSearchUpdate,
        initialSearch: initialSearchTerm
      }}
      filters={[suppliersFilter]}
      sx={{
        padding: '24px 0px 24px 24px',
        borderBottom: '1px solid rgba(35, 60, 88, 0.06)',
        marginBottom: '0px'
      }}
    />
  )
}
