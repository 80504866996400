import { Outlet } from 'react-router-dom'
import { Navbar } from '../NavBar/NavBar'
import { Grid } from '@mui/material'

const NavBarLayout = () => (
  <Grid
    container
    direction="row"
    alignItems="stretch"
    style={{ height: '100vh' }}
  >
    <Grid item>
      <Navbar />
    </Grid>
    <Grid item xs>
      <Outlet />
    </Grid>
  </Grid>
)

export default NavBarLayout
