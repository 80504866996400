import React from 'react'
import { SxProps } from '@mui/material'
import { StyledTab } from './TabStyle'

// Define the type for the variant of the tab.
export type TabVariant = 'normal' | 'square'

// Interface for the props accepted by the Tab component.
export interface TabProps {
  /** If the tab should be disabled or not. */
  disabled?: boolean
  /** The label of the tab. */
  label?: string | React.ReactNode
  /** Changes the variant type of the tab. */
  variant?: TabVariant
  /** Index of the current tab. */
  value?: number
  /** Styling for MUI Tab. */
  sx?: SxProps
}

/**
 * Tab allows users to navigate from one tab to the other.
 * This component is memoized to prevent unnecessary re-renders.
 */
export const Tab = React.memo(
  React.forwardRef<HTMLDivElement, TabProps>(
    (
      { disabled = false, label, sx, variant = 'normal', value, ...props },
      ref
    ) => {
      // Render the StyledTab component with passed and default props.
      return (
        <StyledTab
          label={label} // Display the label.
          disabled={disabled} // Control the disabled state.
          value={value} // Set the value, used for identifying the tab in a group.
          disableRipple // Disable the ripple effect for a cleaner look.
          tVariant={variant}
          {...props} // Forward all other props.
          ref={ref} // Forward the ref to the underlying DOM element.
          sx={sx} // Apply MUI styling.
        />
      )
    }
  )
)
