import { Skeleton, Typography } from '@mui/material'
import { AvatarInitials } from 'components/AvatarInitials/AvatarInitials'
import { displayDate } from 'common/utils'
import { BoxColumnBasic, BoxRowBasic } from 'components/common'

export interface CommentItemProps {
  createdBy: {
    name: string
    avatarSrc?: string
  }
  timestamp: string
  comment: string
}

export const CommentItemSkeleton = () => {
  return (
    <BoxRowBasic gap="1rem">
      <BoxColumnBasic>
        <Skeleton variant="circular" height={40} width={40} />
      </BoxColumnBasic>
      <BoxColumnBasic gap={1} sx={{ flexGrow: 1 }}>
        <BoxRowBasic gap={1}>
          <Skeleton variant="rectangular" height={10} sx={{ flexGrow: 0.25 }} />
        </BoxRowBasic>
        <Skeleton variant="rectangular" height={30} sx={{ flexGrow: 1 }} />
      </BoxColumnBasic>
    </BoxRowBasic>
  )
}

export const CommentItem: React.FC<CommentItemProps> = ({
  createdBy,
  timestamp,
  comment
}) => {
  return (
    <BoxRowBasic gap="1rem">
      <BoxColumnBasic>
        <AvatarInitials
          name={createdBy.name}
          avatarSrc={createdBy.avatarSrc}
          size={40}
        />
      </BoxColumnBasic>
      <BoxColumnBasic gap={1}>
        <BoxRowBasic gap={1}>
          <Typography variant="subtitle1" fontWeight={600}>
            {createdBy.name}
          </Typography>
          <Typography variant="subtitle2" fontWeight={400}>
            {displayDate(timestamp)}
          </Typography>
        </BoxRowBasic>
        <Typography>{comment}</Typography>
      </BoxColumnBasic>
    </BoxRowBasic>
  )
}
