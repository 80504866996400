import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React from 'react'

import { ReactComponent as SmallDropdownArrow } from 'assets/SmallDropdownArrow.svg'
import { BoxRow } from '../common'
import { DropDownTypography } from './SmallDropdownStyles'

interface SmallDropDownProps {
  options?: string[]
  initialOption?: string
  onDropDownOptionSelected?: (selectedOption: string) => void
}

export const SmallDropDown: React.FC<SmallDropDownProps> = ({
  options = [],
  initialOption,
  onDropDownOptionSelected: handleDropDownOptionSelected
}) => {
  const [value, setValue] = React.useState('')

  const handleValueChange = (event: SelectChangeEvent) => {
    if (handleDropDownOptionSelected) {
      const v = event.target.value
      setValue(v)
      handleDropDownOptionSelected(v)
    }
  }

  React.useEffect(() => {
    if (options) {
      setValue(initialOption || options[0])
    }
  }, [options])

  return (
    <BoxRow>
      <DropDownTypography>{value}</DropDownTypography>
      <BoxRow position={'relative'}>
        <SmallDropdownArrow />
        <Select
          onChange={handleValueChange}
          value={value}
          variant="standard"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            }
          }}
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            opacity: 0
          }}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </BoxRow>
    </BoxRow>
  )
}

export default React.memo(SmallDropDown, (prevProps, nextProps) => {
  // Check if both options are either undefined or have the same length.
  const areOptionsLengthSame =
    (prevProps.options?.length || 0) === (nextProps.options?.length || 0)

  // Compare the contents of the options array if they are not undefined.
  const areOptionsContentSame =
    areOptionsLengthSame &&
    (prevProps.options?.every(
      (option, index) => option === nextProps.options?.[index]
    ) ??
      true)

  // Only prevent re-render if both initialOption and options are the same.
  return areOptionsContentSame
})
