import { Typography } from '@mui/material'
import { Button } from 'components/Button/Button'
import { BoxRow } from 'components/common'
import React from 'react'
import { EditorTopBarBox } from './EditorTopBarStyles'

export interface EditorTopBarProps {
  onDiscard: () => void
  onSave: () => void
  saveEnabled: boolean
}

export const EditorTopBar: React.FC<EditorTopBarProps> = ({
  onDiscard,
  onSave,
  saveEnabled
}) => {
  return (
    <EditorTopBarBox>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: '16px', fontWeight: 700 }}
      >
        Edit Label
      </Typography>

      <BoxRow gap="8px" justifyContent="flex-end">
        <Button onClick={onDiscard} color="secondary" size="small">
          Discard Changes
        </Button>

        <Button
          type="submit"
          form="label-form"
          onClick={onSave}
          color="primary"
          disabled={!saveEnabled}
          size="small"
        >
          Save
        </Button>
      </BoxRow>
    </EditorTopBarBox>
  )
}
