import React, { useRef } from 'react'
import { Button } from '../Button/Button'

interface FilesUploadButtonProps {
  text: string
  uploadFiles: (files: File[]) => void
  size?: 'small' | 'medium' | 'large'
}

export const FilesUploadButton: React.FC<FilesUploadButtonProps> = ({
  text,
  uploadFiles,
  size = 'medium'
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleFilesSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const selectedFiles = Array.from(e.target.files)
    uploadFiles(selectedFiles)
  }

  const handleOpenFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div>
      <Button
        color="secondary"
        onClick={handleOpenFileInput}
        size={size}
        style={{ whiteSpace: 'nowrap' }}
      >
        {text}
      </Button>
      <input
        type="file"
        multiple
        style={{ display: 'none' }}
        onChange={handleFilesSelect}
        ref={fileInputRef}
      />
    </div>
  )
}

export default FilesUploadButton
