import { Outlet } from 'react-router-dom'
import { BackgroundGlow } from '../BackgroundGlow/BackgroundGlow'

const BackgroundGlowLayout = () => (
  <>
    <BackgroundGlow />
    <Outlet />
  </>
)

export default BackgroundGlowLayout
