import React from 'react'
import { Delete } from '@mui/icons-material'
import { BoxRow } from 'components/common'
import { Box } from '@mui/material'

interface DeleteHoverableWrapperProps {
  children: React.ReactNode
  onDelete: () => void
  disableEdit?: boolean
}

export const DeleteHoverableWrapper: React.FC<DeleteHoverableWrapperProps> = ({
  children,
  onDelete,
  disableEdit = false
}) => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false)

  const handleMouseEnter = () => {
    if (disableEdit) {
      return
    }
    if (!isHovering) {
      setIsHovering(true)
    }
  }
  const handleMouseLeave = () => {
    if (disableEdit) {
      return
    }
    if (isHovering) {
      setIsHovering(false)
    }
  }

  return (
    <BoxRow
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        paddingLeft: '4px'
      }}
    >
      {isHovering && (
        <Box style={{ position: 'relative', cursor: 'pointer' }}>
          <Delete
            onClick={onDelete}
            color={disableEdit ? 'disabled' : 'error'}
          />
        </Box>
      )}
      <Box style={{ paddingLeft: !isHovering ? '24px' : '0' }}>{children}</Box>
    </BoxRow>
  )
}
