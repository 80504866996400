import React from 'react'
import Grid from '@mui/material/Grid'
import { Skeleton } from '@mui/material'
import { SettingSubPageLayout } from './SettingSubPageLayout'

export const CompanySkeleton: React.FC = () => {
  return (
    <SettingSubPageLayout title="Company">
      <Grid container spacing={2} direction="column" width={'20vw'}>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <Skeleton
              variant={'rectangular'}
              height={42}
              width={43}
              sx={{ borderRadius: '10px' }}
            />
          </Grid>
          <Grid item>
            <Skeleton
              variant={'rectangular'}
              height={29}
              width={143}
              sx={{ marginTop: '4px', marginRight: '10px' }}
            />
          </Grid>
        </Grid>

        <Grid item sx={{ marginTop: '7px' }}>
          <Skeleton variant={'text'} height={20} width={163} />
          <Skeleton variant={'rectangular'} height={29} />
        </Grid>

        <Grid item sx={{ marginTop: '23px' }}>
          <Skeleton variant={'rectangular'} height={39} />
        </Grid>

        <Grid item sx={{ marginTop: '23px' }}>
          <Skeleton variant={'rectangular'} height={39} />
        </Grid>
      </Grid>
    </SettingSubPageLayout>
  )
}
