import { Button } from '@mui/material'
import styled from 'styled-components'

export const MenuButtonStyled = styled(Button)({
  height: '26px',
  color: 'var(--secondary, rgba(26, 44, 66, 0.75))',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 'bolder',
  padding: '7px 10px',
  letterSpacing: '0.5px'
})
