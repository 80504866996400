import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const Signup = () => {
  const { loginWithRedirect } = useAuth0()

  React.useEffect(() => {
    void loginWithRedirect({
      authorizationParams: {
        show: 'signup'
      }
    })
  })

  return <div></div>
}

export default Signup
