import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { InvitationApi } from 'services/apis/invitation/InvitationApi'
import { Invitation } from 'models/Invitation'
import {
  GetInvitationRequest,
  SendInvitationRequest,
  WithdrawInvitationRequest
} from './InvitationsRequest'
import { SendingState, LoadingState, WithdrawingState } from '../CommonState'

interface InvitationsState
  extends LoadingState,
    WithdrawingState,
    SendingState {
  invitations: Invitation[]
}

const initialState: InvitationsState = {
  loading: false,
  sending: false,
  withdrawing: false,
  error: false,
  invitations: []
}

export const getInvitations = createAsyncThunk(
  'invitations/get',
  async ({ companyId }: GetInvitationRequest) => {
    return await InvitationApi.getInvitations(companyId)
  }
)

export const withdrawInvitation = createAsyncThunk(
  'invitations/withdraw',
  async ({ email, companyId }: WithdrawInvitationRequest) => {
    return await InvitationApi.withdrawInvitation(companyId, { email })
  }
)

export const sendInvitation = createAsyncThunk(
  'invitations/send',
  async ({ email, companyId }: SendInvitationRequest) => {
    return await InvitationApi.sendInvitation(companyId, { email })
  }
)
export const invitationsSlice = createSlice({
  name: 'invitationsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendInvitation.pending, (state) => {
      state.sending = true
      state.error = false
    })
    builder.addCase(sendInvitation.fulfilled, (state) => {
      state.sending = false
    })
    builder.addCase(sendInvitation.rejected, (state) => {
      state.sending = false
      state.error = true
    })

    builder.addCase(withdrawInvitation.pending, (state) => {
      state.withdrawing = true
      state.error = false
    })
    builder.addCase(withdrawInvitation.fulfilled, (state) => {
      state.withdrawing = false
    })
    builder.addCase(withdrawInvitation.rejected, (state) => {
      state.withdrawing = false
      state.error = true
    })

    builder.addCase(getInvitations.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      getInvitations.fulfilled,
      (state, action: PayloadAction<Invitation[]>) => {
        state.invitations = action.payload
        state.loading = false
      }
    )
    builder.addCase(getInvitations.rejected, (state) => {
      state.invitations = []
      state.loading = false
      state.error = true
    })
  }
})

export default invitationsSlice.reducer
