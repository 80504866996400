export enum Actions {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete'
}

export enum Subjects {
  COMPANIES = 'companies',
  CURRENT_USER = 'current_user',
  USERS = 'users',
  INVITATIONS = 'invitations',
  SUPPLIERS = 'suppliers',
  TAGS = 'tags',
  INGREDIENTS = 'ingredients',
  INGREDIENT_NUTRIENTS = 'ingredient_nutrients',
  INGREDIENT_TAGS = 'ingredient_tags',
  ALLERGEN = 'allergens',
  FORMULAS = 'formulas',
  FORMULA_INGREDIENTS = 'formula_ingredients',
  FORMULA_COMMENTS = 'formula_comments',
  FORMULA_ALLERGENS = 'formula_allergens',
  FORMULA_INGREDIENT_STATEMENT = 'formula_ingredient_statement',
  FORMULA_NUTRIENTS = 'formula_nutrients',
  NUTRITION_FACTS = 'nutrition_facts',
  SUPPLIER_TAGS = 'supplier_tags',
  SUPPLIER_ALLERGENS = 'supplier_allergens',
  SUPPLIER_INGREDIENTS = 'supplier_ingredients',
  ROLES = 'roles'
}
