import { Box, Typography } from '@mui/material'
import { pluralize } from 'common/utils'
import React from 'react'
import { EditorSwitch, EditorSwitchProps } from '../common/EditorSwitch'
import { InfoBubble } from 'components/InfoBubble/InfoBubble'

interface AllergenSwitchProps extends EditorSwitchProps {
  ingredientsCount: number
  description?: string
}

export const AllergenSwitch: React.FC<AllergenSwitchProps> = ({
  label,
  checked,
  onChange,
  ingredientsCount,
  description
}) => {
  const ingredientsCountMessage = React.useMemo(
    () =>
      `${ingredientsCount} ${pluralize(
        ingredientsCount,
        'ingredient'
      )} contain${ingredientsCount > 1 ? '' : 's'} this`,
    [ingredientsCount]
  )

  return (
    <Box>
      <EditorSwitch
        label={label}
        checked={checked}
        onChange={onChange}
        labelIcon={description && <InfoBubble text={description} />}
      />
      {ingredientsCount > 0 && (
        <Typography variant="subtitle2">{ingredientsCountMessage}</Typography>
      )}
    </Box>
  )
}
