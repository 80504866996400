import { Box } from '@mui/material'
import { LoadingAnimation } from 'components/LoadingAnimation/LoadingAmination'
import { ZoomButtons } from 'components/ZoomButtons/ZoomButtons'
import React from 'react'
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper
} from 'react-zoom-pan-pinch'

export interface ImagePreviewProps {
  fileSource?: string
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({ fileSource }) => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const transformWrapperRef = React.useRef<any>(null)
  const [currentScale, setCurrentScale] = React.useState<number>(1)

  const MIN_SCALE = 0.5
  const MAX_SCALE = 5

  // Reset zoom when fileSource changes
  React.useEffect(() => {
    if (transformWrapperRef.current) {
      transformWrapperRef.current.resetTransform() // Reset the transform when fileSource changes
      setLoading(true)
    }
  }, [fileSource])

  const handleOnTransformed = React.useCallback(
    (_: ReactZoomPanPinchRef, s: any) => {
      setCurrentScale(s.scale)
    },
    []
  )

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex'
      }}
    >
      {loading && (
        <LoadingAnimation sx={{ alignSelf: 'center' }} text="Loading preview" />
      )}
      {fileSource && (
        <TransformWrapper
          ref={transformWrapperRef}
          centerOnInit
          minScale={MIN_SCALE}
          maxScale={MAX_SCALE}
          centerZoomedOut
          onTransformed={handleOnTransformed}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <TransformComponent
                wrapperStyle={{
                  width: '100%',
                  height: '100%',
                  display: loading ? 'none' : 'inherit'
                }}
              >
                <div style={{ height: '100%' }}>
                  <img
                    src={fileSource}
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover'
                    }}
                    onLoad={() => setLoading(false)}
                  />
                </div>
              </TransformComponent>
              <ZoomButtons
                sx={{ bottom: '12px', left: '12px' }}
                onZoomInClicked={() => zoomIn()}
                onZoomOutClicked={() => zoomOut()}
                onZoomResetClicked={() => resetTransform()}
                zoomInDisabled={currentScale >= MAX_SCALE}
                zoomOutDisabled={currentScale <= MIN_SCALE}
              />
            </>
          )}
        </TransformWrapper>
      )}
    </Box>
  )
}

export default ImagePreview
