import { useLoadNextPage } from 'common/hooks'
import { QueriesContext } from 'components/EntrTable/Filter/QueriesContext'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { advancedSearchIngredients } from 'state/ingredients/search/IngredientSearchSlice'
import {
  AdvancedIngredientsSearchTable,
  AdvancedIngredientsSearchTableRow
} from './AdvancedIngredientsSearchTable'
import { SearchFiltersContext } from 'components/EntrTable/Filter/SearchFiltersContext'

export interface AdvancedIngredientsSearchTableContainerProps {
  onIngredientClick: (ingredientId: string, supplierId?: string) => void
}

export const AdvancedIngredientsSearchTableContainer: React.FC<
  AdvancedIngredientsSearchTableContainerProps
> = ({ onIngredientClick }) => {
  const dispatch = useAppDispatch()

  const { items, page, pages } = useAppSelector(
    (state) => state.ingredientSearch.ingredientSuggestions
  )
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const { filters, search } = React.useContext(SearchFiltersContext)
  const { suppliers, allergens } = filters

  const setCurrentPageCallback = React.useCallback(
    (page: number) => {
      void dispatch(
        advancedSearchIngredients({
          companyId: companyId,
          page: page,
          size: 50,
          withPublicIngredients: true,
          supplierIds: suppliers,
          allergenTypes: allergens,
          query: search,
          requireAllTerms: true
        })
      )
    },
    [companyId, search, suppliers, allergens]
  )

  React.useEffect(() => {
    void dispatch(
      advancedSearchIngredients({
        companyId: companyId,
        page: 1,
        size: 50,
        withPublicIngredients: true,
        supplierIds: suppliers,
        allergenTypes: allergens,
        query: search,
        requireAllTerms: true
      })
    )
  }, [companyId, search, suppliers, allergens])

  const { nextPageCallback, allPagesLoaded } = useLoadNextPage({
    page,
    pages,
    setCurrentPageCallback
  })

  const handleRowClick = React.useCallback(
    (ingredientId: string) => {
      onIngredientClick(ingredientId)
    },
    [onIngredientClick]
  )

  const rows: AdvancedIngredientsSearchTableRow[] = React.useMemo(() => {
    return items.map((item) => ({
      id: item.id,
      name: item.name,
      friendlyId: item.friendlyId,
      suppliers: item.suppliers.map((supplier) => supplier.name)
    }))
  }, [items])

  return (
    <AdvancedIngredientsSearchTable
      allPagesLoaded={allPagesLoaded}
      nextPageCallback={nextPageCallback}
      onRowClick={handleRowClick}
      rows={rows}
    />
  )
}
