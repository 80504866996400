import React from 'react'
import { ReactComponent as ENTR } from 'assets/ENTR.svg'
import { Fade } from '@mui/material'

const Loading = () => {
  const [show, setShow] = React.useState(true)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setShow((prevShow) => !prevShow)
    }, 2000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Fade in={show} timeout={1000}>
        <ENTR />
      </Fade>
    </div>
  )
}

export default Loading
