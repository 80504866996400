import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AuthState {
  authToken: string | null
}

const initialState: AuthState = {
  authToken: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.authToken = action.payload
    },
    unsetAuthToken: (state) => {
      state.authToken = null
    }
  }
})

export const { setAuthToken, unsetAuthToken } = authSlice.actions
export default authSlice.reducer
