import { FormulaNutrient } from 'models/Formula'
import { NutrientRowProps } from './components/NutrientRow'
import { NutrientRequirement, NutrientType } from 'models/Nutrient'
import { FormulaNFPOverride } from 'models/FormulaLabel'
import { NutrientTableRowGroup } from './NutrientsOverides'

const toTableNutrientsRow = (
  fn: FormulaNutrient,
  level = 0,
  overridesMap: Record<string, FormulaNFPOverride>,
  onOverrideChange: (
    id: string,
    name?: string,
    amount?: string,
    dv?: string
  ) => void
): NutrientRowProps => {
  return {
    nutrient: {
      id: fn.nutrient.id,
      name: fn.nutrient.name,
      amount: fn.total,
      unit: fn.nutrient.unit,
      dvPercentage: fn.dvPercentage
    },
    level: level,
    overrides: {
      name: overridesMap[fn.nutrient.id]?.nutrientDisplayName,
      amount: overridesMap[fn.nutrient.id]?.amount,
      dvPercentage: overridesMap[fn.nutrient.id]?.dv,
      onChange: onOverrideChange
    },
    disabledName: [
      NutrientType.CALORIES,
      NutrientType.ADDED_SUGAR,
      NutrientType.FOLATE_DFE,
      NutrientType.TRANS_FATTY_ACID
    ].includes(fn.nutrient.type)
  }
}

const toTableNutrientRows = (
  fn: FormulaNutrient,
  level = 0,
  overridesMap: Record<string, FormulaNFPOverride>,
  onOverrideChange: (
    id: string,
    name?: string,
    amount?: string,
    dv?: string
  ) => void
): NutrientRowProps[] => {
  const children = fn.children.flatMap((c) => {
    return toTableNutrientRows(c, level + 1, overridesMap, onOverrideChange)
  })
  if (
    [NutrientRequirement.MANDATORY, NutrientRequirement.OPTIONAL].includes(
      fn.nutrient.requirement
    )
  ) {
    const row = toTableNutrientsRow(fn, level, overridesMap, onOverrideChange)
    return [row, ...children]
  }
  return [...children]
}

export const toTableNutrientsRowGroup = (
  formulaNutrientGroups: FormulaNutrient[][],
  overridesMap: Record<string, FormulaNFPOverride>,
  onOverrideChange: (
    id: string,
    name?: string,
    amount?: string,
    dv?: string
  ) => void
): NutrientTableRowGroup[] => {
  return formulaNutrientGroups
    .map((fng) => ({
      nutrientRows: fng.flatMap((fn) =>
        toTableNutrientRows(fn, 0, overridesMap, onOverrideChange)
      )
    }))
    .filter((group) => group.nutrientRows.length > 0)
}
