import React from 'react'
import { Avatar, useTheme } from '@mui/material'
import {
  applyFakeTransparency,
  getInitials,
  selectColor
} from 'common/utils'

interface AvatarInitialsProps {
  name: string
  avatarSrc?: string
  variant?: 'circle' | 'square'
  size?: number
}

export const AvatarInitials: React.FC<AvatarInitialsProps> = ({
  name,
  avatarSrc,
  variant,
  size
}) => {
  // defaults to circle
  const radius = variant === 'square' ? '6px' : '50%'
  const actualSize = size ? size : 32
  const avatarSize = `${actualSize}px`

  const {
    palette: { formulaColors }
  } = useTheme()
  const colors: string[] = Object.entries(formulaColors).map(
    ([, color]) => color.main
  )
  const color = selectColor(name, colors)

  return (
    <Avatar
      sx={{
        height: avatarSize,
        width: avatarSize,
        borderRadius: radius,
        fontSize: `${Math.round(actualSize * 0.625)}px`,
        color,
        backgroundColor: applyFakeTransparency(color, 0.15)
      }}
      src={avatarSrc}
      alt={name}
    >
      {getInitials(name)}
    </Avatar>
  )
}
