import { Invitation } from 'models/Invitation'
import { toInvitation, toInvitations } from './InvitationApiMapper'
import { api } from '../config/AxiosConfig'
import {
  InvitationApiResponse,
  InvitationApiPaginatedResponse
} from './InvitationApiResponse'
import {
  SendInvitationApiData,
  WithdrawInvitationApiData
} from './InvitationApiRequest'

export const InvitationApi = {
  sendInvitation: (
    companyId: string,
    data: SendInvitationApiData
  ): Promise<Invitation> => {
    return api
      .post<InvitationApiResponse>(`/companies/${companyId}/invitations`, data)
      .then((res) => {
        return toInvitation(res.data)
      })
  },

  getInvitations: (companyId: string): Promise<Invitation[]> => {
    return api
      .get<InvitationApiPaginatedResponse>(
        `/companies/${companyId}/invitations`
      )
      .then((res) => {
        return toInvitations(res.data)
      })
  },

  withdrawInvitation: (companyId: string, data: WithdrawInvitationApiData) => {
    return api.delete<void>(`/companies/${companyId}/invitations`, {
      data: data
    })
  }
}
