import { Invitation } from 'models/Invitation'
import {
  InvitationApiResponse,
  InvitationApiPaginatedResponse
} from './InvitationApiResponse'

export const toInvitation = (response: InvitationApiResponse): Invitation => {
  return { ...response }
}

export const toInvitations = (
  response: InvitationApiPaginatedResponse
): Invitation[] => {
  return response.content.map(toInvitation)
}
