import { useAuth0 } from '@auth0/auth0-react'
import { Box, Link, Typography } from '@mui/material'
import React from 'react'

export const Activation: React.FC = () => {
  const { user, logout } = useAuth0()

  // const handleResendEmail = () => {
  //   // TODO: Replace with API Call.
  //   console.log('Reset email sent.')
  // }

  const handleLogOut = () => {
    void logout()
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        position: 'relative'
      }}
    >
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          width: '50vw',
          gap: '20px'
        }}
      >
        <Typography variant="h1">Verify your email</Typography>
        <Box>
          <Typography>
            Wohoo! 🎉 Thanks for signing up for ENTR! Before we get started,
            we've sent an email to <b>{user?.email}</b> to verify your email
            address and activate your account.
          </Typography>
          <Typography>
            If you're still not receiving a verification email, please contact{' '}
            <Link href="mailto:accounts@entrtechnologies.com">
              accounts@entrtechnologies.com
            </Link>
            .
          </Typography>
        </Box>
        <Typography>
          Want to change your account? Click{' '}
          <Link onClick={handleLogOut}>here</Link> to log out.
        </Typography>
      </Box>
    </Box>
  )
}
