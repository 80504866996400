import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert, { AlertColor } from '@mui/material/Alert'
import Slide, { SlideProps } from '@mui/material/Slide'
import { Typography } from '@mui/material'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

const SNACKBAR_TIMEOUT = 5000 // in milliseconds.
const NEXT_MESSAGE_DELAY = 1000 // in milliseconds.

interface SnackBarContextInterface {
  showError: (msg: string) => void
  showSuccess: (msg: string) => void
  showInfo: (msg: string) => void
  showWarning: (msg: string) => void
}

export const SnackbarContext = React.createContext<SnackBarContextInterface>({
  showError: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  showSuccess: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  showInfo: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  showWarning: () => {} // eslint-disable-line @typescript-eslint/no-empty-function
})

interface AlertInfo {
  message: string
  severity: AlertColor
}

interface SnackbarProviderProps {
  children: React.ReactNode
}

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction="up" />
}

/**
 * SnackbarProvider shows the different alerts on the screen one after the other.
 */
export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
  children
}) => {
  const [queue, setQueue] = React.useState<AlertInfo[]>([])
  const [currentAlertInfo, setCurrentAlertInfo] =
    React.useState<AlertInfo | null>(null)
  const [showing, setShowing] = React.useState(false)

  const showMessage = (alertInfo: AlertInfo) => {
    // If a message is being shown, queue the alerts so it appears next.
    if (showing) {
      setQueue((prev) => [...prev, alertInfo])
    } else {
      // Otherwise, show it right away.
      setCurrentAlertInfo(alertInfo)
      setShowing(true)
    }
  }

  const showNextAlert = () => {
    // Check if there are pending alerts to be shown.
    if (queue.length > 0) {
      // If there are, remove the oldest one and display it.
      const nextAlertInfo = queue.shift()
      if (nextAlertInfo) {
        setCurrentAlertInfo(nextAlertInfo)
      }
      setQueue([...queue])
      setShowing(true)
    }
  }

  const handleClose = (_: React.SyntheticEvent | Event, reason: string) => {
    // If the user clicks away on the screen, the alert shouldn't be affected.
    if (reason === 'clickaway') {
      return
    }
    // Hide the current alert and show the next one after some delay.
    setShowing(false)
    setTimeout(showNextAlert, NEXT_MESSAGE_DELAY)
  }

  return (
    <SnackbarContext.Provider
      value={{
        showError: (message) =>
          showMessage({
            message: message,
            severity: 'error'
          }),
        showSuccess: (message) =>
          showMessage({
            message: message,
            severity: 'success'
          }),
        showInfo: (message) =>
          showMessage({
            message: message,
            severity: 'info'
          }),
        showWarning: (message) =>
          showMessage({
            message: message,
            severity: 'warning'
          })
      }}
    >
      <Snackbar
        open={showing}
        autoHideDuration={SNACKBAR_TIMEOUT}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
      >
        <Alert
          severity={currentAlertInfo?.severity}
          iconMapping={{
            success: <CheckCircleOutlinedIcon />,
            error: <HighlightOffOutlinedIcon />,
            warning: <ErrorOutlineOutlinedIcon />
          }}
        >
          <Typography variant="subtitle1" fontWeight={600}>
            {currentAlertInfo?.message}
          </Typography>
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  )
}
