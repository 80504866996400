import {
  FlexContainerItemRow,
  POINT_TO_PIXEL_RATIO
} from './NutritionLabelStyles'
import { NutritionLabelText } from './NutritionLabelText'

export interface ItemRowSettings {
  level?: number
  boldName?: boolean
  boldPercentage?: boolean
  topLine?: boolean
}

export interface ItemRowValues {
  name: string
  amount?: string | number
  unit?: string
  dvPercentage?: string
  override?: React.ReactNode
}

export interface ItemRowProps extends ItemRowSettings {
  name: string
  amount?: string | number
  unit?: string
  dvPercentage?: string
  override?: React.ReactNode
}

export const ItemRow: React.FC<ItemRowProps> = ({
  name,
  amount,
  unit,
  dvPercentage,
  level = 0,
  boldName = false,
  boldPercentage = false,
  override
}) => (
  <>
    <FlexContainerItemRow
      style={{
        paddingLeft: `${level * 10 * POINT_TO_PIXEL_RATIO}px`
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        {!override && (
          <>
            <NutritionLabelText bold={boldName} points={8} leading={4}>
              {name}
            </NutritionLabelText>
            <NutritionLabelText points={8} leading={4}>
              {'\u00A0'}
              {amount}
              {unit}
            </NutritionLabelText>
          </>
        )}
        {override}
      </div>
      <NutritionLabelText bold={boldPercentage} points={8} leading={4}>
        {dvPercentage && `${dvPercentage}%`}
      </NutritionLabelText>
    </FlexContainerItemRow>
  </>
)
