import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  GetFormulaIngredientStatementRequest,
  UpdateFormulaIngredientStatementRequest
} from './IngredientStatementsRequest'
import { FormulaIngredientStatement } from 'models/FormulaLabel'
import { LoadingState, UpdatingState } from '../../CommonState'
import { FormulaApi } from 'services/apis/formula/FormulaApi'

export interface IngredientStatementsState extends LoadingState, UpdatingState {
  ingredientStatement?: FormulaIngredientStatement
}

const initialState: IngredientStatementsState = {
  error: false,
  updating: false,
  loading: false
}

export const getFormulaIngredientStatement = createAsyncThunk(
  'formulas/ingredientStatement/get',
  async ({
    companyId,
    formulaId,
    withSuggestions = false
  }: GetFormulaIngredientStatementRequest): Promise<FormulaIngredientStatement> => {
    return await FormulaApi.getIngredientStatement(
      companyId,
      formulaId,
      withSuggestions
    )
  }
)

export const updateFormulaIngredientStatement = createAsyncThunk(
  'formulas/ingredientStatement/update',
  async ({
    companyId,
    formulaId,
    ingredientStatement
  }: UpdateFormulaIngredientStatementRequest): Promise<FormulaIngredientStatement> => {
    return await FormulaApi.updateIngredientStatement(companyId, formulaId, {
      ingredientStatement
    })
  }
)

const labelSlice = createSlice({
  name: 'labelSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getFormulaIngredientStatement.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      getFormulaIngredientStatement.fulfilled,
      (state, action) => {
        state.ingredientStatement = action.payload
        state.loading = false
      }
    )
    builder.addCase(getFormulaIngredientStatement.rejected, (state) => {
      state.loading = false
      state.error = true
    })

    builder.addCase(updateFormulaIngredientStatement.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      updateFormulaIngredientStatement.fulfilled,
      (state, action) => {
        state.ingredientStatement = {
          ...state.ingredientStatement,
          ingredientStatement: action.payload.ingredientStatement
        }
        state.loading = false
      }
    )
    builder.addCase(updateFormulaIngredientStatement.rejected, (state) => {
      state.loading = false
      state.error = true
    })
  }
})

export default labelSlice.reducer
