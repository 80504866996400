import {
  Box,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent
} from '@mui/material'
import React from 'react'
import { useAppSelector } from 'state/hooks'
import { ConditionalType, NormalizedConditionalOperation } from 'models/Target'
import { isConditionalOperation } from 'services/apis/target/TargetApiMapper'
import { grayedOutInputStyle } from '../../../TargetsStyles'
import { SimpleAutoComplete } from '../SimpleAutoComplete'

interface ConditionalOperationProps {
  operationId: string
  setNewOperation: (newOperation: any) => void
  disableEdit?: boolean
}

export const ConditionalOperation: React.FC<ConditionalOperationProps> = ({
  operationId,
  setNewOperation,
  disableEdit = false
}) => {
  const operation = useAppSelector(
    (state) => state.targets.activeOperations[operationId]
  )
  const allergens = useAppSelector((state) => state.allergens.allergens)

  if (!operationId || !isConditionalOperation(operation)) {
    return <></>
  }

  const getInitialValue = () => {
    if (
      isConditionalOperation(operation) &&
      operation.conditionalType === ConditionalType.MEAL_AND_MAIN_DISH
    ) {
      return operation.isMealOrMainDish ? 'is' : 'is not'
    } else if (
      isConditionalOperation(operation) &&
      operation.conditionalType === ConditionalType.SMALL_RACC
    ) {
      return operation.isSmallRacc ? 'is' : 'is not'
    }
    return 'No'
  }

  const handleBoolValueChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const newBoolText = e.target.value
    const newBool = newBoolText === 'is' ? true : false
    if (
      isConditionalOperation(operation) &&
      operation.conditionalType === ConditionalType.MEAL_AND_MAIN_DISH &&
      newBool !== operation.isMealOrMainDish
    ) {
      setNewOperation({
        ...operation,
        isMealOrMainDish: newBool
      } as NormalizedConditionalOperation)
    } else if (
      isConditionalOperation(operation) &&
      operation.conditionalType === ConditionalType.SMALL_RACC &&
      newBool !== operation.isSmallRacc
    ) {
      setNewOperation({
        ...operation,
        isSmallRacc: newBool
      } as NormalizedConditionalOperation)
    }
  }

  const handleAllergenChange = (e: SelectChangeEvent<string>) => {
    const newAllergenType = e.target.value as string
    if (
      operation.conditionalType === ConditionalType.ALLERGEN_FREE &&
      newAllergenType !== operation.allergenType
    ) {
      setNewOperation({
        ...operation,
        allergenType: newAllergenType
      } as NormalizedConditionalOperation)
    }
  }

  if (operation.conditionalType === ConditionalType.ALLERGEN_FREE) {
    return (
      <>
        <Box style={grayedOutInputStyle}>formula is</Box>
        <Select
          value={operation.allergenType}
          onChange={handleAllergenChange}
          disabled={disableEdit}
          style={{ width: '120px', height: '32px' }}
        >
          {allergens.map((allergen) => (
            <MenuItem key={allergen.type} value={allergen.type}>
              {allergen.name}
            </MenuItem>
          ))}
        </Select>
        <Box style={grayedOutInputStyle}>allergen free</Box>
      </>
    )
  }

  return (
    <>
      <Box style={grayedOutInputStyle}>
        <Typography>
          {operation.conditionalType === ConditionalType.MEAL_AND_MAIN_DISH
            ? 'formula'
            : "formula's RACC"}
        </Typography>
      </Box>
      <SimpleAutoComplete
        initialValue={getInitialValue()}
        options={['is', 'is not']}
        onBlur={handleBoolValueChange}
        disabled={disableEdit}
      />
      <Box style={grayedOutInputStyle}>
        <Typography>
          {operation.conditionalType === ConditionalType.MEAL_AND_MAIN_DISH
            ? ConditionalType.MEAL_AND_MAIN_DISH
            : 'small'}
        </Typography>
      </Box>
    </>
  )
}
