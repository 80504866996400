import {
  EditorGroup,
  EditorGroupSeparator,
  EditorGroupTitle
} from '../../EditorPanelStyles'

interface GroupProps {
  title: string
  content: React.ReactNode
  withSeparator?: boolean
}

export const EditorSection: React.FC<GroupProps> = ({
  title,
  content,
  withSeparator = true
}) => {
  return (
    <>
      <EditorGroup>
        <EditorGroupTitle>{title}</EditorGroupTitle>
        {content}
      </EditorGroup>
      {withSeparator && <EditorGroupSeparator />}
    </>
  )
}
