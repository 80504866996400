import { ItemRowValues } from 'components/NutritionLabel/components/ItemRow'
import {
  FlexContainerColumn,
  FlexContainerColumnCentered,
  FlexContainerItemRow,
  FlexContainerRow
} from 'components/NutritionLabel/components/NutritionLabelStyles'
import { NutritionLabelText } from 'components/NutritionLabel/components/NutritionLabelText'

export interface ItemRowSettings {
  level?: number
  boldName?: boolean
  boldPercentage?: boolean
  topLine?: boolean
}

export interface FatItemRowProps extends ItemRowSettings {
  saturated: ItemRowValues
  trans: ItemRowValues
}

export const FatItemRow: React.FC<FatItemRowProps> = ({
  saturated,
  trans,
  level = 0,
  boldName = false,
  boldPercentage = false
}) => {
  return (
    <>
      <FlexContainerItemRow
        style={{
          paddingLeft: `${level * 10}px`,
          alignItems: 'center',
          marginBottom: '3px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline'
          }}
        >
          <FlexContainerColumn>
            <FlexContainerRow>
              <NutritionLabelText bold={boldName} points={8} leading={1}>
                {saturated.name}
              </NutritionLabelText>
              <NutritionLabelText points={8} leading={1}>
                {'\u00A0'}
                {saturated.amount}
                {'\u00A0'}
                {saturated.unit}
              </NutritionLabelText>
            </FlexContainerRow>
            <FlexContainerRow>
              <NutritionLabelText bold={boldName} points={8} leading={1}>
                + {trans.name}
              </NutritionLabelText>
              <NutritionLabelText points={8} leading={1}>
                {'\u00A0'}
                {trans.amount}
                {'\u00A0'}
                {trans.unit}
              </NutritionLabelText>
            </FlexContainerRow>
          </FlexContainerColumn>
        </div>
        <div>
          <FlexContainerColumnCentered>
            <NutritionLabelText bold={boldPercentage} points={8} leading={1}>
              {trans.dvPercentage &&
                saturated.dvPercentage &&
                `${(
                  (Number(trans.dvPercentage) +
                    Number(saturated.dvPercentage)) /
                  2
                ).toFixed(0)} %`}
            </NutritionLabelText>
          </FlexContainerColumnCentered>
        </div>
      </FlexContainerItemRow>
    </>
  )
}
