import { AllergenDisplayName, AllergenDisplayNameLanguage, BasicAllergen } from 'models/Allergen'
import { AllergenApiResponse, AllergenDisplayNameApiResponse, AllergenDisplayNameLanguageApiResponse } from './AllergensApiResponse'


const toAllergenDisplayName = (
  response: AllergenDisplayNameApiResponse
): AllergenDisplayName => {
  return {
    [AllergenDisplayNameLanguage.ENGLISH]: response[AllergenDisplayNameLanguageApiResponse.ENGLISH],
    [AllergenDisplayNameLanguage.FRENCH]: response[AllergenDisplayNameLanguageApiResponse.FRENCH],
    [AllergenDisplayNameLanguage.SPANISH]: response[AllergenDisplayNameLanguageApiResponse.SPANISH]
  }
}

export const toAllergens = (
  response: AllergenApiResponse[]
): BasicAllergen[] => {
  return response.map((allergenApiResponse) => {
    return toAllergen(allergenApiResponse)
  })
}

export const toAllergen = (response: AllergenApiResponse): BasicAllergen => {
  return {
    ...response,
    displayNames: response.displayNames
      ? toAllergenDisplayName(response.displayNames)
      : undefined
  }
}
