import { Typography, useTheme } from '@mui/material'
import React, { KeyboardEvent } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BoxRow } from 'components/common'
import { UnstyledInput } from '../../../../../../../FormulatorStyles'

export interface IngredientWastePercentageProps {
  percentage: number
  onUpdate?: (percentage: number) => void
  disabled?: boolean
}

export const blockInvalidChar = (e: KeyboardEvent) =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

export const IngredientWastePercentage: React.FC<
  IngredientWastePercentageProps
> = ({
  percentage: wastePercentage,
  onUpdate: updateWastePercentage,
  disabled = false
}) => {
  const theme = useTheme()

  const [textPercentage, setTextPercentage] = React.useState<string>(
    (wastePercentage * 100).toString()
  )

  const handlePercentageChange = (value: string) => {
    // Remove leading zeros.
    let newValue = value
    if (value && value[0] !== '.' && value[value.length - 1] !== '0') {
      newValue = parseFloat(value).toString()
    }

    // Limit to 5 digits.
    if (newValue.length > 5) {
      newValue = newValue.slice(0, 5)
    }

    // Set a max.
    if (parseFloat(newValue) > 100) {
      newValue = '100'
    }

    if (newValue != textPercentage) {
      setTextPercentage(newValue)
      handleWastePercentageChangeDebounced(newValue)
    }
  }

  const handleWastePercentageChangeDebounced = useDebouncedCallback(
    (value: string) => {
      if (updateWastePercentage) {
        updateWastePercentage(parseFloat(value) / 100 || 0)
      }
    },
    500
  )

  const handleOnBlur = (value: string) => {
    if (value === '') {
      setTextPercentage('0')
    } else if (value[0] === '.') {
      setTextPercentage('0' + value)
    }
  }

  return (
    <BoxRow>
      <UnstyledInput
        disabled={disabled}
        value={textPercentage}
        endAdornment={
          <Typography marginLeft={'0.25em'} color={theme.palette.tertiary.main}>
            %
          </Typography>
        }
        onChange={(event) => {
          handlePercentageChange(event.target.value)
        }}
        inputProps={{
          style: { textAlign: 'right' },
          step: 0.01
        }}
        onBlur={(event) => {
          handleOnBlur(event.target.value)
        }}
        type="number"
        sx={{
          borderRadius: '8px',
          flex: 1
        }}
        onKeyDown={(e) => blockInvalidChar(e)}
      />
    </BoxRow>
  )
}
