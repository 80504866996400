import React from 'react'
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material'
import {
  BoxColumnBasic,
  BoxRow,
  BoxRowBasic
} from 'components/common'

import { isNormalizedLogicalOperation } from 'services/apis/target/TargetApiMapper'
import { LeafOperation } from '../LeafOperation/LeafOperation'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { CreateOperationButton } from './CreateOperationButton'
import {
  LogicalOperator,
  NormalizedLogicalOperation
} from 'models/Target'
import { grayedOutInputStyle } from '../../TargetsStyles'
import { ModalContext } from 'components/Modal/ModalContext'
import {
  deleteActiveOperation,
  updateActiveOperation
} from 'state/targets'
import { SimpleAutoComplete } from '../LeafOperation/SimpleAutoComplete'
import { CreateBlockButton } from './CreateBlockButton'
import { DeleteHoverableWrapper } from '../LeafOperation/DeleteHoverableWrapper'

export const LogicOperation: React.FC<{
  operationId: string
  logicalOperator?: LogicalOperator
  isRootOperation?: boolean
  parentOperationId?: string
  operation_index?: number
}> = ({
  operationId,
  logicalOperator,
  parentOperationId,
  operation_index,
  isRootOperation = false
}) => {
  const operation = useAppSelector(
    (state) => state.targets.activeOperations[operationId]
  )
  const parentOperation = useAppSelector(
    (state) => state.targets.activeOperations[parentOperationId || '']
  )

  const isActiveTargetEditable = useAppSelector(
    (state) => state.targets.activeTarget.isEditable
  )
  const [selectedOperator, setSelectedOperator] = React.useState(
    parentOperation && isNormalizedLogicalOperation(parentOperation)
      ? parentOperation.operator
      : LogicalOperator.AND
  )

  const dispatch = useAppDispatch()

  if (!operation || !isNormalizedLogicalOperation(operation)) {
    return <></>
  }

  const { showConfirmationModal } = React.useContext(ModalContext)
  const handleConfirmedDelete = () => {
    dispatch(
      deleteActiveOperation({
        operationId: operation.id,
        parentOperationId: operation.parentOperationId
      })
    )
  }

  const onDeleteIconClick = () =>
    showConfirmationModal({
      title: 'Delete Condition Block',
      message: `Are you sure you want to delete this condition block? This will also delete all its conditions.`,
      danger: true,
      yesText: 'Delete',
      noText: 'Cancel',
      onYesClicked: handleConfirmedDelete
    })

  const onParentLogicalOperatorChange = (
    newLogicalOperator: LogicalOperator
  ) => {
    if (
      isNormalizedLogicalOperation(parentOperation) &&
      parentOperation.operator !== newLogicalOperator
    ) {
      dispatch(
        updateActiveOperation({
          ...parentOperation,
          operator: newLogicalOperator
        } as NormalizedLogicalOperation)
      )
    }
  }

  const getHeaderMessageText = () => {
    if (operation.childOperationsIds.length === 0) {
      return 'Add a condition...'
    }
    if (operation.childOperationsIds.length === 1) {
      return 'The following is true...'
    }
    if (operation.operator === LogicalOperator.AND) {
      return 'All of the following are true...'
    }
    return 'Any of the following is true...'
  }

  const handleOperatorChange = (event: SelectChangeEvent<LogicalOperator>) => {
    const newLogicalOperator = event.target.value as LogicalOperator
    setSelectedOperator(newLogicalOperator)
    onParentLogicalOperatorChange(newLogicalOperator)
  }

  return (
    <BoxColumnBasic>
      <BoxRowBasic style={{ gap: '8px', paddingLeft: '10px' }}>
        <Box
          style={{
            width: '45px',
            height: '20px',
            marginTop: '12px',
            marginLeft:
              operation.level && operation.level != 0
                ? operation.level + 45
                : 0,
            display: isRootOperation ? 'none' : 'flex',
            justifyContent: 'end'
          }}
        >
          <BoxRow style={{ gap: '4px' }}>
            {operation_index === 0 ? (
              <Box style={{ ...grayedOutInputStyle, width: '72px' }}>
                {'Where'}
              </Box>
            ) : operation_index === 1 ? (
              <Select
                value={selectedOperator}
                onChange={handleOperatorChange}
                disabled={!isActiveTargetEditable}
                style={{ width: '72px', height: '32px' }}
                sx={{
                  '& .MuiSelect-icon': {
                    fontSize: '1rem'
                  }
                }}
              >
                <MenuItem value={LogicalOperator.AND}>
                  {LogicalOperator.AND}
                </MenuItem>
                <MenuItem value={LogicalOperator.OR}>
                  {LogicalOperator.OR}
                </MenuItem>
              </Select>
            ) : (
              <Box style={{ ...grayedOutInputStyle, width: '72px' }}>
                {logicalOperator}
              </Box>
            )}
          </BoxRow>
        </Box>
        <BoxColumnBasic
          style={{
            width: '100%',
            height: '100%',
            border: !isRootOperation ? '1px solid rgba(0, 0, 0, 0.06)' : 'none',
            borderRadius: '4px',
            paddingBottom: '16px',
            paddingRight: '16px'
          }}
        >
          {!isRootOperation && (
            <DeleteHoverableWrapper
              disableEdit={!isActiveTargetEditable}
              onDelete={onDeleteIconClick}
            >
              <Typography
                style={{
                  margin: '12px 0px 12px 2px'
                }}
              >
                {getHeaderMessageText()}
              </Typography>
            </DeleteHoverableWrapper>
          )}
          <BoxColumnBasic
            style={{
              gap: '8px'
            }}
          >
            {operation.childOperationsIds.map((childOperationId, index) => (
              <React.Fragment key={childOperationId}>
                <LogicOperation
                  operationId={childOperationId}
                  logicalOperator={operation.operator}
                  parentOperationId={operation.id}
                  operation_index={index}
                />
                <LeafOperation
                  operationId={childOperationId}
                  logicalOperator={operation.operator}
                  parentOperationId={operation.id}
                  operation_index={index}
                />
              </React.Fragment>
            ))}

            <BoxRowBasic style={{ gap: '4px' }}>
              <CreateOperationButton
                operationId={operationId}
                disabled={!isActiveTargetEditable}
              />
              <CreateBlockButton
                operationId={operationId}
                disabled={!isActiveTargetEditable}
              />
            </BoxRowBasic>
          </BoxColumnBasic>
        </BoxColumnBasic>
      </BoxRowBasic>
    </BoxColumnBasic>
  )
}
