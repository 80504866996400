import React from 'react'
import { NutrientType } from 'models/Nutrient'
import { ItemRow, ItemRowSettings, ItemRowValues } from './ItemRow'
import { NutritionLabelSeparator } from './NutritionLabelSeparator'
import { NutritionLabelText } from './NutritionLabelText'

export interface TableContentProps {
  tableItemRowsProps: TableItemRowProps[]
}

export interface TableItemRowProps {
  nutrientType: NutrientType
  itemRowValues: ItemRowValues
  numberOnly?: boolean
}

export const NUTRIENT_TYPE_ITEM_ROW_SETTINGS: Partial<
  Record<NutrientType, ItemRowSettings>
> = {
  [NutrientType.TOTAL_FAT]: {
    boldName: true,
    boldPercentage: true
  },
  [NutrientType.SATURATED_FAT]: {
    boldPercentage: true,
    level: 1
  },
  [NutrientType.TRANS_FATTY_ACID]: {
    boldPercentage: true,
    level: 1
  },
  [NutrientType.POLY_FAT]: {
    boldPercentage: true,
    level: 1
  },
  [NutrientType.MONO_FAT]: {
    boldPercentage: true,
    level: 1
  },
  [NutrientType.CHOLESTEROL]: {
    boldPercentage: true,
    boldName: true
  },
  [NutrientType.SODIUM]: {
    boldPercentage: true,
    boldName: true
  },
  [NutrientType.CARBOHYDRATES]: {
    boldPercentage: true,
    boldName: true
  },
  [NutrientType.TOTAL_DIETARY_FIBER]: {
    boldPercentage: true,
    level: 1
  },
  [NutrientType.TOTAL_SUGARS]: {
    boldPercentage: true,
    level: 1
  },
  [NutrientType.ADDED_SUGAR]: {
    boldPercentage: true,
    level: 2
  },
  [NutrientType.SUGAR_ALCOHOL]: {
    boldPercentage: true,
    level: 1
  },
  [NutrientType.PROTEIN]: {
    boldPercentage: true,
    boldName: true
  }
}

const getTableItemRowSettings = (
  nutrientType: NutrientType
): ItemRowSettings => {
  return NUTRIENT_TYPE_ITEM_ROW_SETTINGS[nutrientType] || {}
}

const getTableItemRowOverride = (
  nutrientType: NutrientType,
  amount: string,
  unit: string
): React.ReactNode => {
  switch (nutrientType) {
    case NutrientType.ADDED_SUGAR:
      return (
        <NutritionLabelText
          points={8}
          leading={4}
        >{`Includes ${amount}${unit} Added Sugars`}</NutritionLabelText>
      )
    case NutrientType.TRANS_FATTY_ACID:
      return (
        <NutritionLabelText points={8} leading={4}>
          <i>Trans </i>
          {`Fat ${amount}${unit}`}
        </NutritionLabelText>
      )
    case NutrientType.FOLATE_DFE:
      return (
        <NutritionLabelText points={8} leading={4}>
          {`Folate ${amount}${unit} DFE`}
        </NutritionLabelText>
      )
  }
  return undefined
}

export const ItemTableContent: React.FC<TableContentProps> = ({
  tableItemRowsProps = []
}) => {
  return (
    <>
      {tableItemRowsProps.map((tableItemRowProps, index) => {
        const itemRowSettings = getTableItemRowSettings(
          tableItemRowProps.nutrientType
        )

        return (
          <React.Fragment key={`fragment-${tableItemRowProps.nutrientType}`}>
            {index > 0 && (
              <NutritionLabelSeparator
                key={`separator-${tableItemRowProps.nutrientType}`}
                type="hairline"
                short={
                  itemRowSettings.level !== undefined &&
                  itemRowSettings.level > 1 &&
                  !tableItemRowProps.numberOnly
                }
              />
            )}
            <ItemRow
              key={`item-row-${tableItemRowProps.nutrientType}`}
              {...itemRowSettings}
              level={tableItemRowProps.numberOnly ? 0 : itemRowSettings.level}
              {...tableItemRowProps.itemRowValues}
              override={
                !tableItemRowProps.numberOnly
                  ? getTableItemRowOverride(
                      tableItemRowProps.nutrientType,
                      (tableItemRowProps.itemRowValues.amount as string) || '',
                      tableItemRowProps.itemRowValues.unit || ''
                    )
                  : undefined
              }
            />
          </React.Fragment>
        )
      })}
    </>
  )
}
