import React from 'react'
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import NutrientRow, { NutrientRowProps } from './NutrientRow'

export interface NutrientRowGroupProps {
  nutrientRows: NutrientRowProps[]
  placeholderRows?: number
  header?: boolean
  disabled?: boolean
}

const getPlaceholderRows = (rows: number) => {
  return (
    <>
      {Array.from({ length: rows }).map((_, idx) => (
        <TableRow key={`ng-placeholder-${idx}`}>
          <TableCell colSpan={3}>
            <Skeleton variant={'rectangular'} height={'30px'} />
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}

export const NutrientRowGroup: React.FC<NutrientRowGroupProps> = ({
  nutrientRows,
  header = false,
  placeholderRows = 5,
  disabled = false
}) => {
  return (
    <TableContainer sx={{ padding: '20px' }}>
      <Table>
        <colgroup>
          <col style={{ width: '75%' }} />
          <col style={{ width: '25%' }} />
        </colgroup>
        {header && (
          <TableHead>
            <TableRow key="nutrient-table-header">
              <TableCell align="left">
                <Typography color="secondary" variant="body1">
                  Name
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography color="secondary" variant="body1">
                  Amount
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {nutrientRows &&
            nutrientRows.map((nr) => (
              <NutrientRow
                key={`nr-${nr.nutrient.id}`}
                nutrient={nr.nutrient}
                disabled={disabled || nr.disabled}
                onAmountChange={nr.onAmountChange}
                level={nr.level}
                calculated={nr.calculated}
              />
            ))}
          {nutrientRows.length === 0 && getPlaceholderRows(placeholderRows)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default NutrientRowGroup
