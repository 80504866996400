import React from 'react'
import PlusOneRoundedIcon from '@mui/icons-material/PlusOneRounded'

interface AddSecondNutrientIconProps {
  onClick: () => void
  disableEdit: boolean
  show: boolean
}

export const AddSecondNutrientIcon: React.FC<AddSecondNutrientIconProps> = ({
  onClick,
  disableEdit,
  show
}) => {
  return show ? (
    <PlusOneRoundedIcon
      onClick={onClick}
      style={{
        position: 'absolute',
        right: '4',
        top: '6',
        cursor: 'pointer',
        fontSize: '20px'
      }}
      color={disableEdit ? 'disabled' : 'secondary'}
    />
  ) : null
}
