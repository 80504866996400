import { Option } from 'components/common'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { AddController } from './AddController'
import { getYieldAdjustments } from 'state/yield_adjustments/YieldAdjustmentsSlice'
import {
  addFormulaYieldAdjustment,
  getFormulaYieldAdjustments
} from 'state/formulator/yieldAdjustments/FormulaYieldAdjustmentsSlice'

export const AddControllerContainer: React.FC = () => {
  const yieldAdjustments = useAppSelector(
    (state) => state.yieldAdjustmentsSlice.yieldAdjustments
  )
  const formulaYieldAdjustments = useAppSelector(
    (state) => state.formulaYieldAdjustmentsSlice.formulaYieldAdjustments
  )
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const dispatch = useAppDispatch()

  const options = React.useMemo(() => {
    return yieldAdjustments.map((yieldAdjustment) => ({
      id: yieldAdjustment.id,
      label: yieldAdjustment.name,
      value: formulaYieldAdjustments
        .map((fya) => fya.yieldAdjustment.id)
        .includes(yieldAdjustment.id)
    }))
  }, [yieldAdjustments, formulaYieldAdjustments])

  React.useEffect(() => {
    if (yieldAdjustments.length === 0) {
      void dispatch(getYieldAdjustments())
    }
  }, [yieldAdjustments])

  React.useEffect(() => {
    if (companyId && formulaId) {
      void dispatch(
        getFormulaYieldAdjustments({
          companyId,
          formulaId
        })
      )
    }
  }, [companyId, formulaId, yieldAdjustments])

  const handleOnChange = React.useCallback(
    (option: Option<boolean>) => {
      if (option.value) {
        void dispatch(
          addFormulaYieldAdjustment({
            companyId,
            formulaId,
            yieldAdjustmentId: option.id
          })
        )
      }
    },
    [options]
  )

  return (
    <AddController
      optionGroups={[
        {
          label: 'Yield Adjustment',
          options: options
        }
      ]}
      onChange={handleOnChange}
    />
  )
}
