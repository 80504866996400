import { Action, Middleware } from '@reduxjs/toolkit'

type ExtendedMeta = {
  requestId?: string
  sequentialThunk?: boolean
  arguments?: string[]
}

type ExtendedAction = Action<string> & { meta?: ExtendedMeta }

const currentRequestIds: Map<string, string> = new Map()

const splitThunk = (actionType: string): [string, string] => {
  const actionThunkSplit = actionType.split('/')
  // Remove the status.
  const status = actionThunkSplit.pop() || ''
  // Join the parts back together.
  const thunkName = actionThunkSplit.join('/')
  return [thunkName, status]
}

const generateKey = (thunkName: string, args: string[] = []): string => {
  return thunkName + '_' + args.join('_')
}

export const asyncThunkMiddleware: Middleware =
  () => (next) => (action: ExtendedAction) => {
    // Thunk has a format of {thunkName}/{status}.
    const [thunkName, status] = splitThunk(action.type)

    const key = generateKey(thunkName, action.meta?.arguments)

    // If the action is pending and is sequential, store it.
    if (status === 'pending' && action.meta?.sequentialThunk) {
      currentRequestIds.set(key, action.meta.requestId || '')
    }

    // If the action is fullfilled or rejected and is sequential, check if it's the current action.
    if (
      ['fulfilled', 'rejected'].includes(status) &&
      currentRequestIds.has(key) &&
      action.meta?.requestId !== currentRequestIds.get(key)
    ) {
      return
    }

    return next(action)
  }
