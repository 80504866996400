import { Typography } from '@mui/material'
import { Banner } from 'components/Banner/Banner'
import { Button } from 'components/Button/Button'
import { Multibutton } from 'components/Multibutton/Multibutton'
import { NoPreview } from 'components/NoPreview/NoPreview'
import { BoxColumnBasic, BoxRow, Option } from 'components/common'
import React from 'react'
import { NFPLabel, NFPLabelProps } from './components/NFPLabel'

interface LabelProps {
  noPreview: boolean
  onEditClicked: () => void
  download: {
    onDownload: (option: Option<string>) => void
    downloadOptions: Option<string>[]
  }
  nfpLabel: NFPLabelProps
  showServingSizeWarning?: boolean
}

export const Label: React.FC<LabelProps> = ({
  noPreview,
  onEditClicked,
  nfpLabel,
  showServingSizeWarning = false,
  download
}) => {
  return (
    <>
      {noPreview && (
        <NoPreview text="Add an ingredient to your formula to get a preview of your nutritional label" />
      )}
      {!noPreview && (
        <BoxColumnBasic flexGrow={1}>
          <BoxColumnBasic>
            <BoxRow
              justifyContent={'flex-end'}
              marginBottom={'20px'}
              gap={'8px'}
            >
              <Multibutton
                label="Download"
                onClick={download.onDownload}
                options={download.downloadOptions}
              />
              <Button onClick={onEditClicked} color="secondary">
                Edit Label
              </Button>
            </BoxRow>
            {showServingSizeWarning && (
              <Banner type="warning">
                <BoxColumnBasic sx={{ gap: '5px' }}>
                  <Typography>
                    <b>Missing Serving Size</b>
                  </Typography>
                  <Typography>
                    Please input serving size to populate the Nutrition Facts
                  </Typography>
                </BoxColumnBasic>
              </Banner>
            )}
          </BoxColumnBasic>
          <NFPLabel {...nfpLabel} />
        </BoxColumnBasic>
      )}
    </>
  )
}
