import { Autocomplete, TextField, Typography } from '@mui/material'
import { ReactComponent as CloseCircle } from 'assets/CloseCircle.svg'
import { BoxRow, Option } from 'components/common'
import React from 'react'

interface OptionalNutrientsProps {
  optionalNutrients: Option<boolean>[]
  onChange: (id: string, value: boolean) => void
}

export const OptionalNutrients: React.FC<OptionalNutrientsProps> = ({
  optionalNutrients,
  onChange
}) => {
  const [value, setValue] = React.useState<Option<boolean> | null>(null)
  const [inputValue, setInputValue] = React.useState<string>('')

  const handleAutoCompleteChange = (option: Option<boolean> | null) => {
    if (option) {
      onChange(option.id, true)
      setValue(null)
      setInputValue('')
    }
  }

  const handleInputChange = (newInputValue: string) => {
    setInputValue(newInputValue)
  }

  const handleRemoveClick = (id: string) => {
    onChange(id, false)
  }

  return (
    <>
      <Autocomplete
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        value={value}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => handleInputChange(newInputValue)}
        options={optionalNutrients.filter((v) => v.value === false)}
        onChange={(_, option) => handleAutoCompleteChange(option)}
        getOptionLabel={(option: Option<boolean>) => option.label}
        renderInput={(params) => <TextField {...params} />}
        disablePortal={true}
      />

      {optionalNutrients
        .filter((v) => v.value === true)
        .map((optionalNutrient) => (
          <BoxRow
            sx={{ justifyContent: 'space-between' }}
            key={`son-${optionalNutrient.id}`}
          >
            <Typography>{optionalNutrient.label}</Typography>
            <CloseCircle
              role="button"
              title="remove"
              aria-label="remove"
              style={{ cursor: 'pointer' }}
              onClick={() => handleRemoveClick(optionalNutrient.id)}
            />
          </BoxRow>
        ))}
    </>
  )
}
