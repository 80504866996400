import { Box, styled } from '@mui/material'
import React from 'react'
import { NutrientRowProps } from './components/NutrientRow'
import { NutrientRowGroup } from './components/NutrientRowGroup'

const Separator = styled(Box)`
  height: 8px;
  background: var(--Beige, #f6f5f4);
`

const getPlaceholderRowGroups = () => {
  return (
    <>
      <NutrientRowGroup nutrientRows={[]} header placeholderRows={5} />
      <Separator />
      <NutrientRowGroup nutrientRows={[]} placeholderRows={5} />
      <Separator />
      <NutrientRowGroup nutrientRows={[]} placeholderRows={5} />
      <Separator />
      <NutrientRowGroup nutrientRows={[]} placeholderRows={5} />
    </>
  )
}

export interface OverridesProps {
  nutrientTableRowGroups?: NutrientTableRowGroup[]
}
export interface NutrientTableRowGroup {
  nutrientRows: NutrientRowProps[]
}

export const NutrientsOverrides: React.FC<OverridesProps> = ({
  nutrientTableRowGroups = []
}) => {
  return (
    <Box sx={{ overflowX: 'auto', height: '100%', width: '100%' }}>
      {nutrientTableRowGroups.length > 0 &&
        nutrientTableRowGroups.map((nrg, index) => (
          <Box key={`nrg-frag-${index}`} sx={{ display: 'inline-block' }}>
            <NutrientRowGroup
              key={`nrg-${index}`}
              nutrientRows={nrg.nutrientRows}
              header={index === 0}
            />
            {index < nutrientTableRowGroups.length - 1 && (
              <Separator key={`nrg-sep-${index}`} />
            )}
          </Box>
        ))}
      {nutrientTableRowGroups.length === 0 && getPlaceholderRowGroups()}
    </Box>
  )
}
