import { useQuery } from 'common/hooks'
import React, { createContext } from 'react'

export interface ViewerRequest {
  type: 'show' | 'hide'
  id?: string
}
export interface PageViewerContextProps {
  sendShowRequest: (id?: string) => void
  sendHideRequest: () => void
  clearRequest: () => void
  setViewedItemId: (value?: string) => void
  viewedItemId?: string
  request?: ViewerRequest
}

export const PageViewerContext = createContext<PageViewerContextProps>({
  sendShowRequest: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  sendHideRequest: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  clearRequest: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  setViewedItemId: () => {} // eslint-disable-line @typescript-eslint/no-empty-function
})

export const PageViewerProvider: React.FC<{
  children: React.ReactNode
  queryKey?: string
}> = ({ children, queryKey = 'viewedItemId' }) => {
  const [queries, setSearchParams, searchParams] = useQuery()
  const [request, setRequest] = React.useState<ViewerRequest | undefined>(
    undefined
  )

  const viewedItemId = React.useMemo(() => {
    return searchParams.get(queryKey) || undefined
  }, [searchParams])

  const setViewedItemId = (value?: string) => {
    if (value) {
      searchParams.set(queryKey, value)
    } else {
      searchParams.delete(queryKey)
    }
    setSearchParams(searchParams)
  }

  const sendShowRequest = React.useCallback(
    (id?: string) => {
      setRequest({ type: 'show', id })
    },
    [setRequest]
  )

  const sendHideRequest = React.useCallback(() => {
    setRequest({ type: 'hide' })
  }, [setRequest])

  const clearRequest = React.useCallback(() => {
    setRequest(undefined)
  }, [setRequest])

  return (
    <PageViewerContext.Provider
      value={{
        sendShowRequest,
        sendHideRequest,
        setViewedItemId,
        clearRequest,
        request,
        viewedItemId
      }}
    >
      {children}
    </PageViewerContext.Provider>
  )
}
