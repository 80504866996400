import React from 'react'
import {
  FormulaHistoryActivity,
  FormulaHistoryActivitySubject
} from 'models/FormulaHistory'
import { BoxColumnBasic, BoxRowBasic } from 'components/common'
import { DateWithTooltip } from 'components/DateWithTooltip/DateWithTooltip'
import { AvatarInitials } from 'components/AvatarInitials/AvatarInitials'
import { Skeleton, Typography } from '@mui/material'
import { ActivitySentenceBoxStyled } from './FormulaHistoryItemStyles'

interface FormulaHistoryItemProps {
  activity: FormulaHistoryActivity
}

const createPhraseFromList = (words: string[]): string => {
  const wordsCopy = [...words]
  const lastWord = wordsCopy.pop()
  if (words.length === 0) return ''
  if (words.length === 1 && lastWord) return `${lastWord}`
  return `${wordsCopy.join(', ')}, and ${lastWord}`
}

const getPreSubjectWord = (subject: string) => {
  const vowels = ['a', 'e', 'i', 'o', 'u']
  if (subject === FormulaHistoryActivitySubject.FORMULA) return 'this'
  if (subject.charAt(subject.length - 1) === 's') return 'these'
  const firstLetter = subject.charAt(0).toLowerCase()
  return vowels.includes(firstLetter) ? 'an' : 'a'
}

const ActivitySentence: React.FC<{ activity: FormulaHistoryActivity }> = ({
  activity
}) => {
  return (
    <ActivitySentenceBoxStyled>
      {/* user full name */}
      <Typography fontWeight={600}>{`${activity.user.fullName} `}</Typography>

      {/* action */}
      {`${activity.action} `}

      {/* subject */}
      {`${getPreSubjectWord(activity.subject)} ${activity.subject} `}

      {/* name */}
      {activity.name && (
        <Typography fontWeight={600}>
          {activity.subject != FormulaHistoryActivitySubject.FORMULA &&
            `${activity.name} `}
        </Typography>
      )}

      {/* names */}
      {activity.names &&
        createPhraseFromList(
          activity.names.map((name) => {
            return name
          })
        )
          .split(' ')
          .map((word, index) => (
            <Typography fontWeight={600} key={index}>
              {`${word} `}
            </Typography>
          ))}

      {/* modifications */}
      {activity.modifications &&
        createPhraseFromList(
          activity.modifications.map((modification) => {
            return `${modification.field}${
              modification.oldValue
                ? ` from ${modification.oldValue} `
                : ' from 0.0 '
            }${
              modification.newValue ? `to ${modification.newValue}` : 'to 0.0'
            }`
          })
        )
          .split(' ')
          .map((word, index) => (
            <Typography key={index}>{`${word} `}</Typography>
          ))}
    </ActivitySentenceBoxStyled>
  )
}

export const FormulaHistoryItemSkeleton: React.FC = () => {
  return (
    <BoxRowBasic gap="1rem">
      <BoxColumnBasic>
        <Skeleton variant="circular" height={40} width={40} />
      </BoxColumnBasic>
      <BoxColumnBasic gap={0.5} sx={{ flexGrow: 1 }}>
        <BoxRowBasic>
          <Skeleton variant="rectangular" height={30} sx={{ flexGrow: 1 }} />
        </BoxRowBasic>
        <BoxRowBasic gap={1}>
          <Skeleton variant="rectangular" height={10} sx={{ flexGrow: 0.25 }} />
        </BoxRowBasic>
      </BoxColumnBasic>
    </BoxRowBasic>
  )
}

export const FormulaHistoryItem: React.FC<FormulaHistoryItemProps> = ({
  activity
}) => {
  return (
    <BoxRowBasic gap="1rem">
      <BoxColumnBasic>
        <AvatarInitials
          name={activity.user.fullName}
          avatarSrc={activity.user.imagePath}
          size={40}
        />
      </BoxColumnBasic>
      <BoxColumnBasic gap={0.5}>
        <BoxRowBasic>
          <ActivitySentence activity={activity} />
        </BoxRowBasic>
        <BoxRowBasic gap={1}>
          <DateWithTooltip
            date={activity.createdAt}
            style={{ fontWeight: '400' }}
          />
        </BoxRowBasic>
      </BoxColumnBasic>
    </BoxRowBasic>
  )
}
