import { BasicAllergen, SupplierAllergen } from 'models/Allergen'
import {
  BasicSupplier,
  BasicSupplierIngredientsPaginated,
  DetailedSupplier,
  DetailedSupplierPaginated,
  SimpleSupplier
} from 'models/Supplier'
import { SupplierTag } from 'models/Tags'
import { toBasicCompany } from '../company/CompanyApiMapper'
import { toBasicIngredient } from '../ingredient/IngredientApiMapper'
import { toBasicTag } from '../tags/TagsApiMapper'
import { toBasicUser } from '../user/UserApiMapper'
import {
  BasicSupplierAllergenApiResponse,
  BasicSupplierApiResponse,
  BasicSupplierIngredientApiPaginatedResponse,
  DetailedSupplierApiPaginatedResponse,
  DetailedSupplierApiResponse,
  SimpleSupplierApiResponse,
  SupplierAllergenApiResponse,
  SupplierTagApiResponse
} from './SupplierApiResponse'

export const toBasicSupplier = (
  response: BasicSupplierApiResponse
): BasicSupplier => {
  return {
    ...response
  }
}

export const toBasicSuppliers = (
  response: BasicSupplierApiResponse[]
): BasicSupplier[] => {
  return response.map((a) => toBasicSupplier(a))
}

export const toSimpleSupplier = (
  response: SimpleSupplierApiResponse
): SimpleSupplier => {
  return {
    ...response,
    createdBy: response.createdBy ? toBasicUser(response.createdBy) : undefined,
    company: toBasicCompany(response.company)
  }
}

export const toDetailedSupplier = (
  response: DetailedSupplierApiResponse
): DetailedSupplier => {
  return {
    ...response,
    createdBy: response.createdBy ? toBasicUser(response.createdBy) : undefined,
    company: toBasicCompany(response.company),
    tags: response.tags.map((tag) => toBasicTag(tag))
  }
}

export const toDetailedSupplierPaginated = (
  response: DetailedSupplierApiPaginatedResponse
): DetailedSupplierPaginated => {
  return {
    ...response,
    items: response.items.map((r) => toDetailedSupplier(r))
  }
}

export const toBasicSupplierIngredientsPaginated = (
  response: BasicSupplierIngredientApiPaginatedResponse
): BasicSupplierIngredientsPaginated => {
  return {
    ...response,
    items: response.items.map((r) => toBasicIngredient(r))
  }
}

export const toBasicAllergen = (
  response: BasicSupplierAllergenApiResponse
): BasicAllergen => {
  return {
    ...response
  }
}

export const toSimpleAllergen = (
  response: SupplierAllergenApiResponse
): SupplierAllergen => {
  return {
    supplier: toBasicSupplier(response.supplier),
    allergen: toBasicAllergen(response.allergen)
  }
}

export const toSimpleAllergens = (
  response: SupplierAllergenApiResponse[]
): SupplierAllergen[] => {
  return response.map((a) => toSimpleAllergen(a))
}

export const toSupplierTag = (
  response: SupplierTagApiResponse
): SupplierTag => {
  return {
    id: response.id,
    supplier: toBasicSupplier(response.supplier),
    tag: toBasicTag(response.tag)
  }
}
